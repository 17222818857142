import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 0c-.919 0-1.678.707-1.78 1.6H3.8c-.987 0-1.8.813-1.8 1.8v10.8c0 .987.813 1.8 1.8 1.8H13c.987 0 1.8-.813 1.8-1.8V3.4c0-.987-.813-1.8-1.8-1.8h-1.42A1.804 1.804 0 009.8 0H7zm0 1.2h2.8c.339 0 .6.261.6.6 0 .339-.261.6-.6.6H7a.591.591 0 01-.6-.6c0-.339.261-.6.6-.6zM3.8 2.8h1.708c.324.48.874.8 1.492.8h2.8c.618 0 1.167-.32 1.492-.8H13c.339 0 .6.261.6.6v10.8c0 .339-.261.6-.6.6H3.8a.591.591 0 01-.6-.6V3.4c0-.339.261-.6.6-.6z" />
      <path d="M7.7 6.17a.592.592 0 01.731-.081c.099.061.176.15.222.253a.543.543 0 01-.13.621L6.994 8.437h4.416a.601.601 0 01.417.162.562.562 0 01.173.4.543.543 0 01-.173.4.585.585 0 01-.417.16H6.994l1.529 1.475a.562.562 0 01.179.399.544.544 0 01-.17.403.585.585 0 01-.418.164.6.6 0 01-.414-.172L5.23 9.447a.545.545 0 01-.003-.893L7.7 6.17z" />
    </svg>
  );
}

export default SvgComponent;
