import React from "react";
import Navigator, { NavigatorProps } from "components/UI/Navigator";
import { connect } from "react-redux";
import { getSidebarMenu } from "ducks/menu";
import Logo from "containers/Clinic/Logo";
import { useParams } from "react-router-dom";

export type MenuProps = NavigatorProps & ReturnType<typeof getSidebarMenu>;

const Menu = (p: MenuProps) => {
  const { slug } = useParams<{ slug: string }>();
  return (
    <Navigator
      {...p}
      categories={p.menu}
      navigationPrefix={`/${slug}`}
      headerTitle={<Logo height={50} />}
    />
  );
};

export default connect(getSidebarMenu, () => ({}))(Menu);
