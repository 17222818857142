import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  KYCLink,
  MarketplaceRecipient,
  MarketplaceRecipienUpdate,
  MarketplaceRecipientFilter,
} from "@udok/lib/api/models";

export const createRecipient = async (
  token: string,
  note: MarketplaceRecipient
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/marketplace/recipients`, note, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as MarketplaceRecipient;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MKTRC1");
      return Promise.reject(new Error(err));
    });
};

export const fetchRecipients = (
  token: string,
  filter?: MarketplaceRecipientFilter
) => {
  const q = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/marketplace/recipients${q}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as MarketplaceRecipient[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MKTRC2");
      return Promise.reject(new Error(err));
    });
};

export const fetchRecipient = (token: string, mareID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/marketplace/recipients/${mareID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as MarketplaceRecipient;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MKTRC3");
      return Promise.reject(new Error(err));
    });
};

export const deleteRecipient = async (token: string, mareID: string) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/marketplace/recipients/${mareID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as MarketplaceRecipient;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MKTRC4");
      return Promise.reject(new Error(err));
    });
};

export const updateRecipient = async (
  token: string,
  mareID: string,
  note: MarketplaceRecipienUpdate
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/marketplace/recipients/${mareID}`,
      note,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as MarketplaceRecipient;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MKTRC5");
      return Promise.reject(new Error(err));
    });
};

export const fetchKYCLink = (token: string, mareID: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/marketplace/recipients/${mareID}/kyc_link`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as KYCLink;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MKTRC6");
      return Promise.reject(new Error(err));
    });
};
