export const DefaultAppointmentType = "default";

export enum ContactPhoneApps {
  WhatsApp = "whatsapp",
  Telegram = "telegram",
}

const PhoneAppsLabel = {
  [ContactPhoneApps.WhatsApp]: "WhatsApp",
  [ContactPhoneApps.Telegram]: "Telegram",
};

export function getPhoneAppsLabel(s: ContactPhoneApps): string {
  return PhoneAppsLabel[s] || "";
}

export type ContactPhone = {
  phone: string;
  linkedApps: string[];
};

type DoctorCurriculum = {
  academicFormation: string[];
  experiences: any[];
  languages: string[];
  professionalExperience: any[];
  treatMedicalConditions: any[];
};

type DoctorInfo = {
  about: string;
  curriculum: DoctorCurriculum;
  name: string;
  publicEmail: string;
  publicPhone: string;
  signature: string;
  logo?: string;
  subSpecialty?: string;
  latitude?: string;
  longitude?: string;
  socialMedia?: SocialMedia;
  profileColor?: string;
};

export type UdokUser = {
  applID: string;
  createdAt: string;
  deletedAt: string | null;
  email: string;
  userID: string;
  pushTokens: string[];
  roles?: string[];
  name: string;
  avatar?: string;
};

export type UserProfile = {
  user: UdokUser;
  patient?: {
    cpf?: string;
    info?: PatientInfo;
    name: string;
    pathology?: string;
    patiID?: string;
    dateOfBirth?: string;
    avatar?: string;
    email?: string;
    sex?: string;
  };
  doctor?: {
    doctID: string;
    info: DoctorInfo;
    licenses: DoctorLicense[];
    name: string;
    specialty: any;
    userID: string;
    avatar?: string;
  };
  clinic?: {
    clusID: string;
    clinID: string;
    userID: string;
    name: string;
    email: string;
  };
};

export type ClinicUser = {
  clinID: string;
  roles: string[];
  name: string;
} & UdokUser;

export type DoctorRating = {
  rating: number;
  totalReviews: number;
  ratingEnabled: boolean;
};

export type Doctor = {
  doctID: string;
  info: DoctorInfo;
  name: string;
  specialty?: { specID: number; nameSpecialty: string }[];
  userID: string;
  mareID?: string;
  licenses?: DoctorLicense[];
  clinic?: { phone?: string; doctorPhone?: string };
  avatar?: string;
  slug?: string;
  doctorRating: DoctorRating;
};

export interface ClinicDoctor {
  cldoID?: string;
  clinID: string;
  userID: string;
  doctID: string;
  clinName: string;
  doctName: string;
  doctorPhone?: string;
  doctorAvatar?: string;
  licenses: DoctorLicense[];
  info: DoctorInfo;
  visible: boolean;
  deniedAt?: string;
  createdAt: string;
  deletedAt?: string;
  mareID?: string;
  specialty?: { specID: number; nameSpecialty: string }[];
  preferences: {
    hideProfileInProcedure?: boolean;
  };
  displayOrder?: number;
}

export type DoctorSearch = {
  userID: string;
  doctID: string;
  name: string;
  email: string;
  /**
   * @deprecated Info should no longer be used
   */
  info: {
    avatar: string;
    age?: string;
    sendMail?: string;
    latitude?: string;
    longitude?: string;
  };
  healthPlans: {
    heplID: string;
    name: string;
  }[];
  specialtys: string[];
  locations: DoctorSearchLocation[];
  schedules?: {
    [k: string]: Array<SearchResultSchedule>;
  };
  score: number;
  comunicative: boolean;
  price: number;
  slug?: string;
  about?: string;
  avatar?: string;
  subSpecialty?: string;
  licenses?: [DoctorLicense];
};

export type DoctorSearchLocation = {
  locaID: string;
  name: string;
  coords: string;
  address: string;
  healthPlans: string[];
};

export type SearchResultSchedule = {
  startAt: string;
  endAt: string;
  locaID: string;
  sescID: string;
  clinID?: string;
  maxAppointments: number;
  appointmentDuration: number;
  marketplaceOffer: boolean;
  markedAt: [];
  weekday: string[];
  type: string;
  price: number;
  healthPlans: string[];
  appointmentOptions: { aptyID: string; default: boolean; name: string }[];
};

export type User = {
  applID: string;
  createdAt: string;
  deletedAt: string | null;
  email: string;
  userID: string;
  pushTokens: string[];
  avatar?: string;
  doctor?: Doctor;
  patient?: Patient;
  clinic?: {
    clusID: string;
    clinID: string;
    userID: string;
    name: string;
    email: string;
  };
};

export type HealthcareLicense = {
  heliID: string;
  name: string;
  description: string;
};

export type DoctorLicense = License & {
  doliID: number;
  doctID: string;
  createdAt: string;
  deletedAt?: string;
  allowedByPrescription: boolean;
};

export type License = {
  heliID: string;
  country: string;
  region: string;
  documentType: string;
  documentNumber: string;
};

export type ActiveSubscription = {
  subsID: string;
  suplID: string;
  price: number;
  trialUntil: string;
  planName: string;
  planDescription: string;
  planFeatures: PlanFeatures;
  status: string;
  provider?: string;
  providerSubscription?: string;
  billingDescription: string;
  createdAt: string;
  info: {
    customPlan?: boolean;
  };
};

export type SubscriptionOffer = {
  suofID: string;
  suplID: string;
  name: string;
  description: string;
  price: number;
  trialDays: number;
  validUntil: string;
  createdAt: string;
  deletedAt?: string;
  category: string;
  visible: boolean;
  billingDescription?: string;
  planName: string;
  planDescription: string;
  planFeatures: {
    permissions: string[];
  };
  displaySettings: {
    recommended?: boolean;
    onSuccessMessage?: string;
    contactSalesForPricing?: boolean;
  };
  recurrenceDays: number;
  billingEnabled: boolean;
};

export enum OfferCategory {
  doctor = "doctor",
  clinic = "clinic",
}

export type FilterSubscriptionOffer = {
  category?: string;
  limit?: number;
  offset?: number;
};

export type BillingInformation = {
  biinID: string;
  userID: string;
  name: string;
  email: string;
  documentNumber: string;
  billingAddress: CustomerAddress;
  billingPhone: CustomerPhone;
  createdAt: string;
};

export type BillingInformationForm = {
  name: string;
  email: string;
  billingPhone: CustomerPhone;
  documentNumber: string;
  billingAddress: CustomerAddress;
};

export type CustomerAddress = {
  street: string;
  name: string;
  street_number: string;
  neighborhood: string;
  zipcode: string;
};

export type CustomerPhone = {
  ddd: string;
  number: string;
};

type PlanFeatures = {
  permissions: string[];
};

export type InvalidSubscription = {
  status: string;
  error: string;
};

export enum ActiveSubscriptionStatus {
  active = "active",
  invalid = "invalid",
  canceled = "canceled",
}

export enum PlanFeaturesPermissions {
  PermissionsAll = "*",
  WhatsappNotifications = "whatsapp-notifications",
  WhatsappInvitations = "whatsapp-invitations",
  WhatsappPrescriptions = "whatsapp-prescriptions",
  PrescriptionCustomization = "prescription-customization",
  AdvancedSettings = "advanced-settings",
  ScheduleLock = "schedule-lock",
  BankAccounts = "bank-accounts",
  HealthPlans = "health-plans",
  UnlimitedChat = "unlimited-chat",
  UnlimitedAddresses = "unlimited-addresses",
  UnlimitedSchedule = "unlimited-schedule",
  UnlimitedProfileSettings = "unlimited-profile-settings",
  CustomSchedulingFlow = "custom-scheduling-flow",
}

export type Verification = {
  verifiedAt?: string;
  info: { image?: string; phone?: string; licenses: Array<DoctorLicense> };
};

export type SEOListing = {
  doctID: string;
  slug: string;
};

export type ClinicFilter = {
  clinID?: string;
  doctID?: string;
  name?: string;
  url?: string;
  shortDescription?: string;
  limit?: number;
  offset?: number;
};

export type Clinic = {
  clinID: string;
  avatar?: string | null;
  banner?: string | null;
  createdAt?: string | null;
  deletedAt?: string | null;
  description?: string;
  shortDescription?: string;
  info?: {
    socialMedia?: SocialMedia;
    profileColor?: string;
  };
  name: string;
  visible: boolean;
  healthPlans: [{ name: string; image: string; heplID: string }];
  specialties: [
    {
      name: string;
      image: string;
      specID: string;
      slug: string;
      schedules: CountSchedulesByClinicSpecialty;
    }
  ];
  locations: ProfileLocation[];
  url: string;
  offers: {
    buttons: CustomOffers[];
    enableAppointmentTypeSelection: boolean;
    enableClinicDoctorProfileNavigation: boolean;
  };
  appointmentSettings: {
    createDefaultControlledPatient: boolean;
    requiredFields: string[];
    disableSteps: string[];
    highlightingAccountCreation?: boolean;
  };
  staff?: ClinicStaff;
  clinicBranches?: Branches;
  mainClinics: {
    name: string;
    slug: string;
  }[];
  displayPreferences: {
    enableDoctorScheduleBooking: boolean;
  };
  flowURL?: string;
};

type CountSchedulesByClinicSpecialty = {
  place: number;
  virtual: number;
  exam: number;
};

export type ClinicStaff = {
  medical?: ClinicStaffMember[];
};

export type ClinicStaffMember = {
  doctID: string;
  name: string;
  avatar?: string | null;
  description?: string;
  slug?: string;
  hideDoctor: boolean;
};

export type Branches = {
  clinic: ClinicBranchMember[];
};

type ClinicBranchMember = {
  name: string;
  avatar?: string;
  slug?: string;
  description?: string;
};

export type AddressInfo = {
  locationName: string;
  locationPhones: ContactPhone[];
  cnes: string;
  cep: string;
  uf: string;
  street: string;
  neighborhood: string;
  city: string;
};

export interface Location {
  address: string;
  coord: string;
  createdAt: string;
  deletedAt: string | null;
  healthPlans?: string[];
  locaID: string;
  name: string;
  price: number | null;
  userID?: string;
  addressDefault?: boolean;
  addressInfo?: AddressInfo;
  clinID?: string;
  doctID?: string;
  prescriptionLayouts?: string[];
}

export interface CepResponse {
  altitude: number;
  cep: string;
  latitude: string;
  longitude: string;
  logradouro: string;
  bairro: string;
  complemento: string;
  cidade: {
    ddd: number;
    ibge: string;
    nome: string;
  };
  estado: {
    sigla: string;
  };
}

export interface ViaCepAddress {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
}

export type CancelAfterMinutes = {
  payment?: number;
  document?: number;
};

export interface Schedule {
  sescID: string;
  locaID: string | null;
  userID: string;
  startAt: string;
  endAt: string;
  appointmentDuration: number;
  createdAt: string;
  deletedAt: null;
  appointmentCount: number;
  type: string;
  weekDay: string[];
  price: number;
  clinID: string;
  clinicInfo?: Clinic;
  localeInfo?: Location;
  appointmentOptions?: { aptyID: string; default: boolean; name?: string }[];
  selfService?: boolean;
  marketplaceOffer?: boolean;
  healthPlans?: string[];
  sharedResources?: string[];
  exclusiveListing?: boolean;
  blockUnpaidAppointment: boolean;
  paymentSplitValue?: string;
  paymentSplitPercent?: string;
  eventDuration: number;
  weekdayTimezoneOffset?: number;
  cancelAfterMinutes?: CancelAfterMinutes;
  procedures?: string[];
  simultaneousAppointments: number;
  defaultStatus?: string;
}

export enum ScheduleType {
  place = "place",
  virtual = "virtual",
  examProcedure = "exam_procedure",
}

export function getScheduleTypeLabel(s?: string | null, alt?: string) {
  if (s === undefined || s === null) {
    return alt;
  }
  return (
    (
      {
        [ScheduleType.virtual]: "Consulta virtual",
        [ScheduleType.place]: "Consulta presencial",
        [ScheduleType.examProcedure]: "Exames e procedimentos",
      } as any
    )[s] ?? ""
  );
}

export interface Phone {
  cellphone?: string;
  phone?: string;
}

export enum AppointmentStatus {
  scheduled = "scheduled",
  confirmed = "confirmed",
  done = "done",
  cancel = "canceled",
  overdue = "overdue",
  anticipate = "anticipated",
  confirmedAndReschedule = "confirmed.rescheduled",
  canceledForReschedule = "canceled.rescheduled",
  rescheduled = "rescheduled",
  notConfirmed = "not confirmed",
  paymentPending = "payment.pending",
  paymentPaid = "payment.paid",
  paymentError = "payment.error",
}

export enum AppointmentstatusReasons {
  doctor = "doctor",
  patient = "patient",
  clinic = "clinic",
  invoice = "invoice",
  unpaid = "unpaid",
  unfilled = "unfilled",
}

export type DoctorProfile = {
  userID: string;
  doctID: string;
  name: string;
  avatar?: string;
  specialties: string[];
  phone?: string;
};

export type LocationInfo = {
  locaID: string;
  name: string;
  address: string;
};

export type Controller = {
  coapID: string;
  controllerID: string;
  name: string;
  avatar?: string;
  controlerType: string;
  info: {
    doctorVideoPreference?: string;
    locationInfo?: LocationInfo;
    doctorProfile: DoctorProfile;
    patientProfile: {
      userID: string;
      patiID: string;
      name: string;
      email: string;
      phones?: ContactPhone[];
      birthdate?: string;
      sex?: string;
      info: {
        avatar?: string;
        dob?: string;
      };
    };
  };
};

export type AppointmentNotificationOptions = {
  disableWhatsapp?: boolean;
};

export type Appointment = {
  appoID: string;
  clinID?: string;
  imcsID?: string;
  createdAt: string;
  createdBy: string;
  doctEmail?: string;
  doctID: string;
  doctInfo?: {
    avatar?: string;
    latitude?: string;
    longitude?: string;
    email?: string;
    phone?: string;
  };
  doctorName?: string;
  doctorUserID?: string;
  healthplans: string[];
  healthplanNames: string[];
  patientHealthplanCard: string[];
  markedAt: string;
  patiID: string;
  patiInfo: {
    avatar?: string;
    latitude?: string;
    longitude?: string;
    email?: string;
    phones?: ContactPhone[];
    dateOfBirth?: string;
    sex?: string;
    cpf?: string;
  };
  patiName?: string;
  patiUserID?: string;
  requiredDocuments?: string[];
  requiredForms?: string[];
  medicalHistoryTemplates?: string[];
  collectDocumentVisibilitys: AppointmentTypeCollectDocumentVisibility[];
  documentTemplateVisibilitys: AppointmentTypeDocumentTemplateVisibility[];
  statusReasons?: AppointmentTypeStatusReason[];
  statusReasonRequired?: boolean;
  validationSchema: ValidateSchema;
  returnVisitEnabled?: boolean;
  returnForAppoID?: string;
  returnForMarkedAt?: string;
  sescID: string;
  status: string;
  statusInfo: AppointmentStatusInfo;
  statusReason: string;
  statusReasonDescription?: string;
  nameCreatedBy?: string;
  statusCreatedAt?: string;
  type: string;
  userID?: string;
  urlDoctorInvitation: string;
  urlPatientInvitation: string;
  confirmations: AppointmentConfirmation[];
  price: number;
  blockUnpaidAppointment: boolean;
  locaID?: string;
  appointmentDuration: number;
  specID?: number;
  procedures?: string[];
  procedureNames?: string[];
  readonly: boolean;
  patientCancellationDeadline?: CancellationDeadline;
  patientReschedulingDeadline?: ReschedulingDeadline;
  megrID?: string;
  displayPreferences?: {
    hideDoctor: boolean;
  };
  doctorAppointmentSettings: {
    requiredFields: string[];
    disableSteps: string[];
    highlightingAccountCreation?: boolean;
  };
  notificationOptions: AppointmentNotificationOptions;
};

export type AppointmentCommunication = {
  appoID: string;
  patientChatUserID: string;
  doctorChatUserID: string;
  clinicChatUserID?: string;
  megrID?: string;
  viseID?: string;
};

export type AppointmentDisplaySettings = {
  appoID: string;
  successMessage: {
    healthPlan?: string;
    private?: string;
  };
};

export type CancellationDeadline = {
  deadline: string;
  alertText: string;
};

export type ReschedulingDeadline = {
  enabled: boolean;
  deadline: string;
};

export type AppointmentStatusData = {
  info: AppointmentStatusInfo;
  status: string;
  appoID: string;
  apstID: number;
  createdAt: string;
  createdBy: string;
  nameCreatedBy: string;
  reason: string;
  reasonDescription?: string;
};

export type AppointmentStatusResponse = {
  apstID: number;
  appoID: string;
  createdBy: string;
  nameCreatedBy?: string;
  status: AppointmentStatus;
  createdAt: string;
  info: AppointmentStatusInfo;
  reasonDescription?: string;
};

export type NewAppointmentStatus = {
  status: AppointmentStatus;
  info?: AppointmentStatusInfo;
  reasonDescription?: string;
};

export type AppointmentStatusInfo = {
  appoID?: string;
  sescID?: string;
  date?: string;
  hour?: string;
  message?: string;
  originalAppoID?: string;
};

export type AppointmentConfirmation =
  | {
      doctID: string;
      patiID?: string;
      createdAt?: string;
      userID?: string;
    }
  | {
      doctID?: string;
      patiID: string;
      createdAt?: string;
      userID?: string;
    };

export function getStatusLabel(s: string): string {
  const l = {
    [AppointmentStatus.scheduled]: "Pendente",
    [AppointmentStatus.notConfirmed]: "Sem confirmação",
    [AppointmentStatus.confirmed]: "Confirmado",
    [AppointmentStatus.done]: "Concluído",
    [AppointmentStatus.cancel]: "Cancelado",
    [AppointmentStatus.overdue]: "Vencido",
    [AppointmentStatus.anticipate]: "Antecipado",
    [AppointmentStatus.canceledForReschedule]: "Em remarcação",
    [AppointmentStatus.confirmedAndReschedule]: "Confirmado e remarcado",
    [AppointmentStatus.rescheduled]: "Remarcado",
    [AppointmentStatus.paymentPaid]: "Pagamento realizado",
    [AppointmentStatus.paymentPending]: "Pagamento pendente",
    [AppointmentStatus.paymentError]: "Erro no pagamento",
  } as any;
  return l[s] || s;
}

export function getStatusColor(s: string): {
  text: string;
  background: string;
  border: string;
  main: string;
} {
  const l = {
    [AppointmentStatus.scheduled]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
    [AppointmentStatus.notConfirmed]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
    [AppointmentStatus.canceledForReschedule]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
    [AppointmentStatus.paymentPending]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
    [AppointmentStatus.paymentError]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
    [AppointmentStatus.confirmed]: {
      text: "#FFFFFF",
      background: "#008A65",
      border: "none",
      main: "#008A65",
    },
    [AppointmentStatus.confirmedAndReschedule]: {
      text: "#FFFFFF",
      background: "#008A65",
      border: "none",
      main: "#008A65",
    },
    [AppointmentStatus.paymentPaid]: {
      text: "#FFFFFF",
      background: "#008A65",
      border: "none",
      main: "#008A65",
    },
    [AppointmentStatus.anticipate]: {
      text: "#FFFFFF",
      background: "#148582",
      border: "none",
      main: "#148582",
    },
    [AppointmentStatus.rescheduled]: {
      text: "#FFFFFF",
      background: "#148582",
      border: "none",
      main: "#148582",
    },
    [AppointmentStatus.done]: {
      text: "#FFFFFF",
      background: "#0B93D8",
      border: "none",
      main: "#0B93D8",
    },
    [AppointmentStatus.cancel]: {
      text: "#FF4747",
      background: "transparent",
      border: "1px solid #FF4747",
      main: "#FF4747",
    },
    [AppointmentStatus.overdue]: {
      text: "#646464",
      background: "rgba(135, 135, 135, 0.29)",
      border: "none",
      main: "#646464",
    },
  } as any;
  return l[s] || { text: "", background: "" };
}

export type Specialty = {
  specID: string | number;
  name: string;
  image: string;
  description: string;
  info: {
    bgColor: string;
    minWidth: number;
    shortDescription: string;
  };
  order: number;
  slug: string;
};

export type ClinicSpecialty = {
  clspID: string;
  clinID: string;
  clinName: string;
  specialtyOrder: number;
} & Specialty;

export type Healthplan = {
  heplID: string;
  name: string;
  info: any;
  image: string;
  createdAt: string;
};

export type BasePrescription = {
  doctID?: string;
  presID: string;
  gid?: string;
  patiID?: string;
  appoID?: string;
  patientName: string;
  patientEmail?: string;
  patientPhone?: string;
  patientAddress?: string;
  patientCPF?: string;
  doctorName?: string;
  createdAt?: string;
  deletedAt?: null;
  prescriptionModel: DocumentModel;
  locaID?: string;
  plteID?: string;
  doliID?: number;
};

export type Medicine = {
  drugID?: string;
  drugName: string;
  quantity: string;
  usage: string;
  observation: string;
  drugType?: string;
  fields?: {
    concentration: string;
    pharmaceutical_form: string;
    adm_route: string;
    treatment_duration: string;
  };
  schedule?: string;
  retainsPrescription?: boolean;
};

export type TextEntry = {
  contents: string;
  fields: any;
};

export type PrescriptionModel = {
  entries: Array<Medicine> | Array<TextEntry>;
} & BasePrescription;

export type PrescriptionDocument = {
  certID?: string;
  name: string;
  prdoID: string;
  presID: string;
  prescriptionModel: string;
  signedAt?: string;
  url?: string;
  unsignedUrl?: string;
  entries: [
    {
      contents?: string;
      drugID: string;
      drugType: string;
      drugName: string;
      fields?: {
        concentration: string;
        pharmaceutical_form: string;
        adm_route: string;
        treatment_duration: string;
      };
      observation: string;
      prenID: number;
      prteID?: string;
      quantity: string;
      schedule?: string;
      usage: string;
    }
  ];
};

export type PatientInfo = {
  about?: string;
  address?: PatientAddress;
  phones?: ContactPhone[];
  contactEmail?: string;
};

export type Patient = {
  cpf?: string;
  info?: PatientInfo;
  name: string;
  pathology?: string;
  patiID?: string;
  userID?: string;
  verifiedAt?: string | null;
  pinned?: boolean;
  dateOfBirth?: string;
  sex?: string;
  avatar?: string;
};

export type PatientAddress = {
  cep?: string;
  street?: string;
  streetNumber?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  cityIdentifier?: string;
};

export type PatientCRMInformation = {
  patiID: string;
  preferredHealthPlan?: string;
  pcinID: number;
  createdBy: string;
  createdAt: string;
  deletedAt?: string;
};

export type PatientCRMInformationForm = Omit<
  PatientCRMInformation,
  "createdAt" | "deletedAt" | "createdBy" | "pcinID"
>;

export type PatientContactInformation = {
  patiID?: string;
  createdAt?: string;
  createdBy?: string;
  email: string;
  phones?: ContactPhone[];
  address: PatientAddress;
};

export type PatientControlledAuthentication = {
  patiID: string;
  username: string;
  password: string;
};

export type NonUdokPatient = {
  name: string;
  email: string;
  address?: string;
  age?: string;
  sex?: string;
  phone?: string;
  cpf?: string;
};

export type MedicationAutocomplete = {
  drugID?: string;
  drugName: string;
  drugType: string;
  quantity: string;
  usage: string;
  observation: string;
  fields?: {
    concentration: string;
    pharmaceutical_form: string;
    adm_route: string;
    treatment_duration: string;
  };
};

export type Drug = {
  inputValue?: string;
  drugID?: string;
  title: string;
  subtitle?: string;
  description?: string;
  status?: string;
  schedule?: string;
  type?: string;
  price?: string;
};

export type CredentialsBirdID = {
  username: number;
  password: number;
  accessToken?: string;
  expiresAt?: number;
};

export enum SignerProvider {
  Udok = "udok",
  BirdID = "birdid",
  VIDaaS = "vidaas",
}

type SignType = {
  presID: string;
  provider: SignerProvider;
};

export type SignTypeVIDaaS = {
  code: string;
  codeVerifier: string;
  accessToken: string;
} & SignType;

export type SignTypeBirdID = {
  accessToken: string;
} & SignType;

export type SignTypeUdok = {
  certID: string;
  password: string;
} & SignType;

export type CertificateModel = {
  certID: string;
  doctID?: string;
  name?: string;
  urlOriginal?: string;
  urlProcessed?: string;
  createdAt?: string;
  invalidAt?: string;
  deletedAt?: string;
};

export type CreateCertificateModel = {
  file: string;
  name: string;
  password: string;
};

export type TemplateModel = {
  presID: string;
  prmoID?: string;
  name: string;
  entries: Array<TextEntry> | Array<Medicine>;
};

export type TemplateModelFilter = {
  presID?: string;
  name?: string;
  limit?: number;
  model?: string;
};

export enum PrescriptionDrugType {
  simple = "simples",
  controlled = "controlado",
  antimicrobial = "Simples - 2 vias (Antibiótico)",
}

export enum DocumentModel {
  medicalCertificate = "medical_certificate",
  medicalReport = "medical_report",
  exam = "exam",
  prescription = "prescription",
  prescriptionSimple = "simple",
  prescriptionControlled = "controlled",
  prescriptionAntimicrobial = "antimicrobial",
  freeDocument = "free_document",
}

export const listPrescriptionType = {
  [DocumentModel.exam]: {
    primary: "Solicitação de Exames",
    secondary: "Conteúdo da solicitação",
  },
  [DocumentModel.medicalReport]: {
    primary: "Relatório Médico",
    secondary: "Conteúdo do relatório",
  },
  [DocumentModel.medicalCertificate]: {
    primary: "Atestado Médico",
    secondary: "Conteúdo do atestado",
  },
  [DocumentModel.prescription]: {
    primary: "Prescrição",
    secondary: "Medicações da prescrição",
  },
  [DocumentModel.prescriptionSimple]: {
    primary: "Prescrição Simples",
    secondary: "Medicações da prescrição",
  },
  [DocumentModel.prescriptionControlled]: {
    primary: "Prescrição Controlada",
    secondary: "Medicações da prescrição",
  },
  [DocumentModel.prescriptionAntimicrobial]: {
    primary: "Prescrição de antimicrobianos",
    secondary: "Medicações da prescrição",
  },
  [DocumentModel.freeDocument]: {
    primary: "Documento",
    secondary: "Conteúdo do documento",
  },
};

export function getDocumentModelLabel(s: DocumentModel): {
  primary: string;
  secondary: string;
} {
  return listPrescriptionType[s] || { primary: "", secondary: "" };
}

export const listDrugType: { [k: string]: string } = {
  [DocumentModel.prescriptionSimple]: "Simples",
  [DocumentModel.prescriptionControlled]: "Controlado",
  [DocumentModel.prescriptionAntimicrobial]: "Antimicrobial",
};

export function getlistDrugTypeLabel(s: string) {
  return listDrugType[s] || "";
}

export type Invitation = {
  inviID?: string;
  fromPatiID?: string;
  fromDoctID?: string;
  toDoctID?: string;
  toEmail?: string;
  toName?: string;
  info?: any;
  message?: string;
  status?: string;
  createdAt?: string;
};

type Itens = {
  type: string;
  enum: Array<string>;
  enumNames?: Array<string>;
};

export type Property = {
  type: string;
  title: string;
  placeholder?: string;
  label?: string;
  helperText?: string;
  multiline?: boolean;
  enum?: Array<string>;
  enumNames?: Array<string>;
  items?: Itens;
  isVertical?: boolean;
};

export interface FormSchema {
  type: string;
  required: Array<string>;
  properties: { [propName: string]: Property };
}

export interface ValidateSchema {
  type: string;
  required: string[];
  properties: { [propName: string]: { type: string } | { anyOf: object[] } };
}

export interface UiSchema {
  [propName: string]: { ["ui:widget"]: string } | Array<string>;
}

export interface PrintSchema {
  data: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  name: string | null;
}

export enum permissionModeStatus {
  doctor = "doctor",
  templateOwner = "template_owner",
}

export type Document = {
  doteID?: string;
  title: string;
  description: string;
  formSchema: FormSchema;
  uiSchema: UiSchema;
  printSchema?: PrintSchema;
  permissionMode?: string;
  createdAt?: string;
  updatedAt?: string | null;
  deletedAt?: string | null;
};

export type CollectDocument = {
  codoID: string;
  title: string;
  description: string;
  file: string;
  limitExtensions: string[];
  createdAt: string;
  deletedAt?: string;
};

export interface AppointmentType {
  aptyID: string;
  type: string;
  name: string;
  description: string;
  requiredDocuments?: string[];
  requiredForms?: string[];
  createdAt?: string;
  deletedAt?: string;
  statusReasons?: AppointmentTypeStatusReason[];
  statusReasonRequired?: boolean;
  validationSchema: ValidateSchema;
  collectDocumentVisibilitys: AppointmentTypeCollectDocumentVisibility[];
  documentTemplateVisibilitys: AppointmentTypeDocumentTemplateVisibility[];
  medicalHistoryTemplates: string[];
  visibility: string[];
}

export type FilterSearchAppointmentType = {
  doctID?: string;
  clinID?: string;
  doctorSlug?: string;
  clinicSlug?: string;
  plans?: string[];
  procedureSlugs?: string[];
  scheduleType?: string;
  sescID?: string[];
  limit?: number;
  offset?: number;
};

export type AppointmentTypeStatusReason = {
  aptyID: string;
  atsrID: number;
  status: string[];
  visibility: string[];
  description: string;
};

export type AppointmentTypeCollectDocumentVisibility = {
  cdviID: number;
  aptyID: string;
  codoID: string;
  visibility: boolean;
  heplID: string;
};

export type AppointmentTypeDocumentTemplateVisibility = {
  dtviID: number;
  aptyID: string;
  doteID: string;
  visibility: boolean;
  heplID: string;
};

export interface DocumentData {
  createdAt: string;
  data: any;
  deletedAt: string | null;
  description: string | null;
  doctID: string | null;
  docuID: string;
  doteID: string;
  formSchema: FormSchema;
  patiID: string | null;
  permissionMode: string | null;
  printSchema?: PrintSchema;
  title: string | null;
  uiSchema: UiSchema;
}

export type Attachment = {
  appoID: string;
  resource: string;
  resourceID: string;
  data?: Document;
};

export enum Preferences {
  presDefaultLocation = "prescription/default_location",
  financesDefaultCreditCard = "finances/default_credit_card",
  prescriptionUseDigitalizedSignature = "prescription/digitalized_signature",
  appointmentReturnUntil = "appointment/return_until",
  appointmentNotificationsEmail = "appointment/notifications_email",
  udokDigitalCustomButtonOffers = "appointment/udokDigitalCustomButtonOffers",
  udokDigitalOffers = "appointment/udokDigitalOffers",
  customSchedulingFlow = "appointment/customSchedulingFlow",
  appointmentScheduleClearance = "appointment/scheduleClearance",
  clinicDoctorLinkWithPatient = "clinicDoctor/linkWithPatient",
  clinicPatientRequiredInformation = "clinicPatient/requiredInformation",
  appointmentHealthPlanCard = "appointment/healthPlanCard",
  appointmentScheduleBooking = "appointment/scheduleBooking",
  patientHealthHistory = "patient/healthcareHistory",
  patientAccountCreationAlert = "patient/accountCreationAlert",
  patientRequiredInformation = "patient/requiredInformation",
  patientMedicalHistory = "patient/medicalHistory",
  appointmentDefaultAppointmentType = "appointment/default_appointment_type",
  appointmentCancellationDeadline = "appointment/cancellationDeadline",
  appointmentDefaultChatUser = "appointment/default_chat_user",
  appointmentSuccessMessage = "appointment/success_message",
  doctorSkipTutorial = "doctor/skip_tutorial",
  appointmentSatisfactionSurvey = "appointment/satisfaction_survey",
  appointmentPatientReschedule = "appointment/patient_reschedule",
  appointmentSteps = "appointment/steps",
  appointmentNotificationOptions = "appointment/notification_options",
  udokDigitalProfessionalsOrdination = "appointment/udokDigital_professionals_ordination",
  appointmentDefaultMedicalHistoryTemplate = "appointment/default_medical_history_template",
  appointmentPatientAutomaticRefund = "appointment/patient_automatic_refund",
  appointmentEnhancedScheduleOffering = "appointment/enhanced_schedule_offering",
  appointmentDuplicityValidation = "appointment/duplicity_validation",
  doctorRatingEnabled = "doctor/rating_enabled",
}

export type ClinicPreference = {
  clinID: string;
  prefID: string;
  options: any;
  updatedAt: string;
  createdAt: string;
};

export type ClinicPreferenceForm = {
  prefID: string;
  options: any;
};

export type DoctorPreference = {
  doctID: string;
  prefID: string;
  options: any;
  updatedAt: string;
  createdAt: string;
};

export type DoctorPreferenceForm = {
  prefID: string;
  options: any;
};

export enum ResourceType {
  document = "document",
  collectDocument = "collect_document",
  file = "file",
}

export type FilterAttachment = {
  resource?: string[];
  limit?: number;
  offset?: number;
};

export type CID10 = {
  ciliID: string;
  displayName: string;
  displayCode: string;
};

export type FileData = {
  fileID: string;
  userID: string;
  name: string;
  type: string;
  url: string;
  linkedResources: string[];
};

export enum FileLinkedResources {
  appointment = "appointment",
  message = "message",
}

export function getFirstFileResourceID(
  linkedResources: string[],
  resource: keyof typeof FileLinkedResources
) {
  const prefix = resource + "/";
  const item = linkedResources?.find?.((item) => item?.startsWith?.(prefix));
  return item?.replace?.(prefix, "");
}

export type QuickResponse = {
  title: string;
  quickID: number;
  doctID: string;
  data: {
    description: string;
  };
  keywords: string[];
  value: any;
  createdAt?: string;
};

export type QuickResponseFilter = {
  title?: string;
  value?: string;
  searchText?: string;
  keyword?: string[];
  limit?: number;
  offset?: number;
};

export type NewAppointment = {
  sescID: string;
  markedAt: string;
  patiID?: string;
  aptyID?: string;
  healthplans: string[];
  status: string;
  voucherCode?: string;
  invoicePatient?: boolean;
  returnForAppoID?: string;
  returnForMarkedAt?: string;
  returnVisitEnabled: boolean;
  forceCreate?: boolean;
  specID?: number;
  procedures?: string[];
  statusInfo?: AppointmentStatusInfo;
};

export type ImmediateCareAppointmentForm = {
  imcsID: string;
  status?: string;
  statusReason?: string;
  statusInfo?: AppointmentStatusInfo;
  healthplans: string[];
  markedAt: string;
  returnVisitEnabled?: boolean;
  aptyID?: string;
  invoicePatient: boolean;
  voucherCode?: string;
};

export type FileAttachments = {
  clinID?: string;
  codoID: string;
  title: string;
  description: string;
  limitExtensions: Array<string>;
  requiredIN?: Array<string>;
  file: string;
};

export type AppointmentCollectDocument = {
  apcdID: string;
  appoID: string;
  codoID: string;
  fileID: string;
};

export type AppointmentCollectDocumentPresentation =
  AppointmentCollectDocument & {
    collectDocumentTitle: string;
    collectDocumentDescription: string;
    filleName: string;
    filleUrl: string;
  };

export type DoctorNote = {
  donoID: string;
  appoID?: string;
  doctID: string;
  patiID: string;
  text: string;
  createdAt: string;
  editedAt?: string;
  replaceDonoID?: string;
};

export type FilterDoctorNote = {
  appoID?: string;
  patiID?: string;
  limit?: number;
  offset?: number;
};

export type AppointmentReschedule = {
  apscID: string;
  appoID: string;
  doctID: string;
  markedAt: string;
  createdAt: string;
  type?: string;
  acceptedBY?: string;
  acceptedAT?: string;
};

export type AppointmentRescheduleCreate = {
  appoID: string;
  markedAt: string;
  forceCreate?: boolean;
  isControlled?: boolean;
};

export type FilterAppointmentReschedule = {
  appoID?: string;
  type?: string;
  limit?: number;
  offset?: number;
};

export enum AppointmentRescheduleType {
  anticipation = "anticipation",
  default = "reschedule",
}

export type PatientHistory = {
  accountID: string;
  accountName: string;
  resourceID: string;
  resourceType: string;
  preview: string;
  resourceDate: string;
  resourceOwnerName: string;
  resourceOwnerID: string;
};

export type FilterPatientHistory = {
  resourceDate?: string;
  resourceYear?: number;
  preview?: string;
  limit?: number;
  offset?: number;
  type?: string[];
  includeAggregate?: boolean;
};

export enum HistoryResourceType {
  appointment = "appointment",
  note = "note",
  prescription = "prescription",
  exam = "exam",
  medicalReport = "medical_report",
  medicalCertificate = "medical_certificate",
  freeDocument = "free_document",
  appointmentDocumentAttachment = "appointment_document_attachment",
}

export const listHistoryResourceType = {
  [HistoryResourceType.appointment]: "Agendamento",
  [HistoryResourceType.note]: "Anotação",
  [HistoryResourceType.prescription]: "Prescrição",
  [HistoryResourceType.exam]: "Solicitação de Exames",
  [HistoryResourceType.medicalReport]: "Relatório Médico",
  [HistoryResourceType.medicalCertificate]: "Atestado",
  [HistoryResourceType.freeDocument]: "Documento",
  [HistoryResourceType.appointmentDocumentAttachment]: "Anexo de atendimento",
};

export function getHistoryTypeLabel(s: HistoryResourceType): string {
  return listHistoryResourceType[s] || "";
}

export function getHistoryTypeColor(s: string): {
  text: string;
  background: string;
} {
  const l = {
    [HistoryResourceType.prescription]: {
      text: "#EE734F",
      background: "#FDEEEA",
    },
    [HistoryResourceType.medicalCertificate]: {
      text: "#0981D8",
      background: "#DEF0FE",
    },
    [HistoryResourceType.medicalReport]: {
      text: "#ee4f4f",
      background: "#fa919166",
    },
    [HistoryResourceType.exam]: {
      text: "#163a57",
      background: "#71899c66",
    },
    [HistoryResourceType.note]: {
      text: "#5BBC9C",
      background: "#c3ebde99",
    },
    [HistoryResourceType.appointment]: {
      text: "#947eda",
      background: "#bdafec66",
    },
    [HistoryResourceType.appointmentDocumentAttachment]: {
      text: "#2C25F7",
      background: "#B5DFFB",
    },
  } as any;
  return l[s] || { text: "", background: "" };
}

export type MarketplaceRecipientAddress = {
  street?: string;
  complementary?: string;
  street_number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  reference_point: string;
};

type ManagingPartner = {
  name?: string;
  email?: string;
  document_number?: string;
  address: MarketplaceRecipientAddress;
  monthly_income: string;
  professional_occupation: string;
  self_declared_legal_representative: boolean;
};

type RecipientInformationIndividual = {
  type: string;
  name: string;
  email: string;
  document_number: string;
  address?: MarketplaceRecipientAddress;
  monthly_income?: string;
  professional_occupation?: string;
};

type RecipientInformationCorporation = {
  type: string;
  document_number: string;
  company_name: string;
  trading_name: string;
  annual_revenue: string;
  corporation_type: string;
  founding_date: string;
  email: string;
  main_address: MarketplaceRecipientAddress;
  managing_partners: ManagingPartner[];
};

type MarketplaceRecipientInformation = {
  corporation?: RecipientInformationCorporation;
  individual?: RecipientInformationIndividual;
};

export type MarketplaceRecipient = {
  mareID: string;
  bankCode: string;
  bankAgency: string;
  bankAgencyDigit: string;
  bankAccount: string;
  bankAccountDigit: string;
  bankType: BankType;
  bankDocumentType: string;
  bankDocumentNumber: string;
  bankAccountHolderName: string;
  method: string;
  methodID: string;
  transferInterval: "monthly" | "weekly" | "daily";
  transferDay: number;
  createdAt: string;
  deletedAt?: string;
  updatedAt?: string;
  info: MarketplaceRecipientInformation;
  activatedAt?: string;
  postbackVerification?: string;
  status: string;
  kycStatus: string;
  kycStatusReason: string;
};

export type MarketplaceRecipienUpdate = {
  bankCode: string;
  bankAgency: string;
  bankAgencyDigit: string;
  bankAccount: string;
  bankAccountDigit: string;
  bankDocumentNumber: string;
  bankAccountHolderName: string;
  method: string;
  methodID: string;
  transferInterval: "monthly" | "weekly" | "daily";
  transferDay: number;
  info: MarketplaceRecipientInformation;
};

export type MarketplaceRecipientFilter = {
  status?: string[];
  kycStatus?: string[];
  limit?: number;
  offset?: number;
};

export type KYCLink = {
  url: string;
  base64_qrcode: string;
  expires_at: string;
};

export enum RecipientStatus {
  active = "active",
  inactive = "inactive",
  registration = "registration",
  affiliation = "affiliation",
}

export enum RecipientKYCStatus {
  pending = "pending",
  partiallyDenied = "partially_denied",
  approved = "approved",
  denied = "denied",
}

export enum RecipientKYCStatusReason {
  inAnalysis = "in_analysis",
  waitingAnalysis = "waiting_analysis",
  answeredWaitingAnalysis = "answered_waiting_analysis",
  additionalDocumentsRequired = "additional_documents_required",
  fullyDenied = "fully_denied",
  ok = "ok",
}

export enum BankType {
  conta_corrente = "conta_corrente",
  conta_poupanca = "conta_poupanca",
  conta_corrente_conjunta = "conta_corrente_conjunta",
  conta_poupanca_conjunta = "conta_poupanca_conjunta",
}

export const BankTypeList = {
  [BankType.conta_corrente]: "Conta corrente",
  [BankType.conta_poupanca]: "Conta poupança",
  [BankType.conta_corrente_conjunta]: "Conta corrente conjunta",
  [BankType.conta_poupanca_conjunta]: "Conta poupança conjunta",
};

export const BanksOfBrazil = [
  { value: "001", name: "BANCO DO BRASIL" },
  { value: "341", name: "ITAÚ" },
  { value: "104", name: "CAIXA ECONÔMICA FEDERAL" },
  { value: "033", name: "BANCO SANTANDER S.A." },
  { value: "070", name: "BRB - BANCO DE BRASÍLIA" },
  { value: "077", name: "BANCO INTER" },
  { value: "237", name: "BRADESCO" },
  { value: "745", name: "CITIBANK" },
  { value: "422", name: "BANCO SAFRA" },
  { value: "399", name: "BANCO HSBC" },
  { value: "756", name: "BANCOOB" },
  { value: "212", name: "BANCO ORIGINAL" },
  { value: "002", name: "BANCO CENTRAL DO BRASIL" },
  { value: "003", name: "BANCO DA AMAZONIA S.A" },
  { value: "004", name: "BANCO DO NORDESTE DO BRASIL S.A" },
  { value: "007", name: "BANCO NAC DESENV. ECO. SOCIAL S.A" },
  { value: "008", name: "BANCO MERIDIONAL DO BRASIL" },
  { value: "020", name: "BANCO DO ESTADO DE ALAGOAS S.A" },
  { value: "021", name: "BANCO DO ESTADO DO ESPIRITO SANTO S.A" },
  { value: "022", name: "BANCO DE CREDITO REAL DE MINAS GERAIS SA" },
  { value: "024", name: "BANCO DO ESTADO DE PERNAMBUCO" },
  { value: "025", name: "BANCO ALFA S/A" },
  { value: "026", name: "BANCO DO ESTADO DO ACRE S.A" },
  { value: "027", name: "BANCO DO ESTADO DE SANTA CATARINA S.A" },
  { value: "028", name: "BANCO DO ESTADO DA BAHIA S.A" },
  { value: "029", name: "BANCO DO ESTADO DO RIO DE JANEIRO S.A" },
  { value: "030", name: "BANCO DO ESTADO DA PARAIBA S.A" },
  { value: "031", name: "BANCO DO ESTADO DE GOIAS S.A" },
  { value: "032", name: "BANCO DO ESTADO DO MATO GROSSO S.A." },
  { value: "034", name: "BANCO DO ESADO DO AMAZONAS S.A" },
  { value: "035", name: "BANCO DO ESTADO DO CEARA S.A" },
  { value: "036", name: "BANCO DO ESTADO DO MARANHAO S.A" },
  { value: "037", name: "BANCO DO ESTADO DO PARA S.A" },
  { value: "038", name: "BANCO DO ESTADO DO PARANA S.A" },
  { value: "039", name: "BANCO DO ESTADO DO PIAUI S.A" },
  { value: "041", name: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A" },
  { value: "047", name: "BANCO DO ESTADO DE SERGIPE S.A" },
  { value: "048", name: "BANCO DO ESTADO DE MINAS GERAIS S.A" },
  { value: "059", name: "BANCO DO ESTADO DE RONDONIA S.A" },
  { value: "106", name: "BANCO ITABANCO S.A." },
  { value: "107", name: "BANCO BBM S.A" },
  { value: "109", name: "BANCO CREDIBANCO S.A" },
  { value: "116", name: "BANCO B.N.L DO BRASIL S.A" },
  { value: "148", name: "MULTI BANCO S.A" },
  { value: "151", name: "CAIXA ECONOMICA DO ESTADO DE SAO PAULO" },
  { value: "153", name: "CAIXA ECONOMICA DO ESTADO DO R.G.SUL" },
  { value: "165", name: "BANCO NORCHEM S.A" },
  { value: "166", name: "BANCO INTER-ATLANTICO S.A" },
  { value: "168", name: "BANCO C.C.F. BRASIL S.A" },
  { value: "175", name: "CONTINENTAL BANCO S.A" },
  { value: "184", name: "BBA - CREDITANSTALT S.A" },
  { value: "199", name: "BANCO FINANCIAL PORTUGUES" },
  { value: "200", name: "BANCO FRICRISA AXELRUD S.A" },
  { value: "201", name: "BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A" },
  { value: "204", name: "BANCO S.R.L S.A" },
  { value: "205", name: "BANCO SUL AMERICA S.A" },
  { value: "206", name: "BANCO MARTINELLI S.A" },
  { value: "208", name: "BANCO PACTUAL S.A" },
  { value: "210", name: "DEUTSCH SUDAMERIKANICHE BANK AG" },
  { value: "211", name: "BANCO SISTEMA S.A" },
  { value: "213", name: "BANCO ARBI S.A" },
  { value: "214", name: "BANCO DIBENS S.A" },
  { value: "215", name: "BANCO AMERICA DO SUL S.A" },
  { value: "216", name: "BANCO REGIONAL MALCON S.A" },
  { value: "217", name: "BANCO AGROINVEST S.A" },
  { value: "218", name: "BS2" },
  { value: "219", name: "BANCO DE CREDITO DE SAO PAULO S.A" },
  { value: "220", name: "BANCO CREFISUL" },
  { value: "221", name: "BANCO GRAPHUS S.A" },
  { value: "222", name: "BANCO AGF BRASIL S. A." },
  { value: "223", name: "BANCO INTERUNION S.A" },
  { value: "224", name: "BANCO FIBRA S.A" },
  { value: "225", name: "BANCO BRASCAN S.A" },
  { value: "228", name: "BANCO ICATU S.A" },
  { value: "229", name: "BANCO CRUZEIRO S.A" },
  { value: "230", name: "BANCO BANDEIRANTES S.A" },
  { value: "231", name: "BANCO BOAVISTA S.A" },
  { value: "232", name: "BANCO INTERPART S.A" },
  { value: "233", name: "BANCO MAPPIN S.A" },
  { value: "234", name: "BANCO LAVRA S.A." },
  { value: "235", name: "BANCO LIBERAL S.A" },
  { value: "236", name: "BANCO CAMBIAL S.A" },
  { value: "239", name: "BANCO BANCRED S.A" },
  { value: "240", name: "BANCO DE CREDITO REAL DE MINAS GERAIS S." },
  { value: "241", name: "BANCO CLASSICO S.A" },
  { value: "242", name: "BANCO EUROINVEST S.A" },
  { value: "243", name: "BANCO MÁXIMA S.A" },
  { value: "244", name: "BANCO CIDADE S.A" },
  { value: "245", name: "BANCO EMPRESARIAL S.A" },
  { value: "246", name: "BANCO ABC ROMA S.A" },
  { value: "247", name: "BANCO OMEGA S.A" },
  { value: "249", name: "BANCO INVESTCRED S.A" },
  { value: "250", name: "BANCO SCHAHIN CURY S.A" },
  { value: "251", name: "BANCO SAO JORGE S.A." },
  { value: "252", name: "BANCO FININVEST S.A" },
  { value: "254", name: "BANCO PARANA BANCO S.A" },
  { value: "255", name: "MILBANCO S.A." },
  { value: "256", name: "BANCO GULVINVEST S.A" },
  { value: "258", name: "BANCO INDUSCRED S.A" },
  { value: "261", name: "BANCO VARIG S.A" },
  { value: "262", name: "BANCO BOREAL S.A" },
  { value: "263", name: "BANCO CACIQUE" },
  { value: "264", name: "BANCO PERFORMANCE S.A" },
  { value: "265", name: "BANCO FATOR S.A" },
  { value: "266", name: "BANCO CEDULA S.A" },
  { value: "267", name: "BANCO BBM-COM.C.IMOB.CFI S.A." },
  { value: "275", name: "BANCO REAL S.A" },
  { value: "277", name: "BANCO PLANIBANC S.A" },
  { value: "282", name: "BANCO BRASILEIRO COMERCIAL" },
  { value: "291", name: "BANCO DE CREDITO NACIONAL S.A" },
  { value: "294", name: "BCR - BANCO DE CREDITO REAL S.A" },
  { value: "295", name: "BANCO CREDIPLAN S.A" },
  { value: "300", name: "BANCO DE LA NACION ARGENTINA S.A" },
  { value: "302", name: "BANCO DO PROGRESSO S.A" },
  { value: "303", name: "BANCO HNF S.A." },
  { value: "304", name: "BANCO PONTUAL S.A" },
  { value: "308", name: "BANCO COMERCIAL BANCESA S.A." },
  { value: "318", name: "BANCO B.M.G. S.A" },
  { value: "320", name: "BANCO INDUSTRIAL E COMERCIAL" },
  { value: "346", name: "BANCO FRANCES E BRASILEIRO S.A" },
  { value: "347", name: "BANCO SUDAMERIS BRASIL S.A" },
  { value: "351", name: "BANCO BOZANO SIMONSEN S.A" },
  { value: "353", name: "BANCO GERAL DO COMERCIO S.A" },
  { value: "356", name: "ABN AMRO S.A" },
  { value: "366", name: "BANCO SOGERAL S.A" },
  { value: "369", name: "PONTUAL" },
  { value: "370", name: "BEAL - BANCO EUROPEU PARA AMERICA LATINA" },
  { value: "372", name: "BANCO ITAMARATI S.A" },
  { value: "375", name: "BANCO FENICIA S.A" },
  { value: "376", name: "CHASE MANHATTAN BANK S.A" },
  { value: "388", name: "BANCO MERCANTIL DE DESCONTOS S/A" },
  { value: "389", name: "BANCO MERCANTIL DO BRASIL S.A" },
  { value: "392", name: "BANCO MERCANTIL DE SAO PAULO S.A" },
  { value: "394", name: "BANCO B.M.C. S.A" },
  { value: "409", name: "UNIBANCO - UNIAO DOS BANCOS BRASILEIROS" },
  { value: "412", name: "BANCO NACIONAL DA BAHIA S.A" },
  { value: "415", name: "BANCO NACIONAL S.A" },
  { value: "420", name: "BANCO NACIONAL DO NORTE S.A" },
  { value: "424", name: "BANCO NOROESTE S.A" },
  { value: "434", name: "BANCO FORTALEZA S.A" },
  { value: "453", name: "BANCO RURAL S.A" },
  { value: "456", name: "BANCO TOKIO S.A" },
  { value: "464", name: "BANCO SUMITOMO BRASILEIRO S.A" },
  { value: "466", name: "BANCO MITSUBISHI BRASILEIRO S.A" },
  { value: "472", name: "LLOYDS BANK PLC" },
  { value: "473", name: "BANCO FINANCIAL PORTUGUES S.A" },
  { value: "477", name: "CITIBANK N.A" },
  { value: "479", name: "BANCO DE BOSTON S.A" },
  { value: "480", name: "BANCO PORTUGUES DO ATLANTICO-BRASIL S.A" },
  { value: "483", name: "BANCO AGRIMISA S.A." },
  { value: "487", name: "DEUTSCHE BANK S.A - BANCO ALEMAO" },
  { value: "488", name: "BANCO J. P. MORGAN S.A" },
  { value: "489", name: "BANESTO BANCO URUGAUAY S.A" },
  { value: "492", name: "INTERNATIONALE NEDERLANDEN BANK N.V." },
  { value: "493", name: "BANCO UNION S.A.C.A" },
  { value: "494", name: "BANCO LA REP. ORIENTAL DEL URUGUAY" },
  { value: "495", name: "BANCO LA PROVINCIA DE BUENOS AIRES" },
  { value: "496", name: "BANCO EXTERIOR DE ESPANA S.A" },
  { value: "498", name: "CENTRO HISPANO BANCO" },
  { value: "499", name: "BANCO IOCHPE S.A" },
  { value: "501", name: "BANCO BRASILEIRO IRAQUIANO S.A." },
  { value: "502", name: "BANCO SANTANDER DE NEGOCIOS S.A" },
  { value: "504", name: "BANCO MULTIPLIC S.A" },
  { value: "505", name: "BANCO GARANTIA S.A" },
  { value: "600", name: "BANCO LUSO BRASILEIRO S.A" },
  { value: "601", name: "BFC BANCO S.A." },
  { value: "602", name: "BANCO PATENTE S.A" },
  { value: "604", name: "BANCO INDUSTRIAL DO BRASIL S.A" },
  { value: "607", name: "BANCO SANTOS NEVES S.A" },
  { value: "608", name: "BANCO OPEN S.A" },
  { value: "610", name: "BANCO V.R. S.A" },
  { value: "611", name: "BANCO PAULISTA S.A" },
  { value: "612", name: "BANCO GUANABARA S.A" },
  { value: "613", name: "BANCO PECUNIA S.A" },
  { value: "616", name: "BANCO INTERPACIFICO S.A" },
  { value: "617", name: "BANCO INVESTOR S.A." },
  { value: "618", name: "BANCO TENDENCIA S.A" },
  { value: "621", name: "BANCO APLICAP S.A." },
  { value: "622", name: "BANCO DRACMA S.A" },
  { value: "623", name: "BANCO PANAMERICANO S.A" },
  { value: "624", name: "BANCO GENERAL MOTORS S.A" },
  { value: "625", name: "BANCO ARAUCARIA S.A" },
  { value: "626", name: "BANCO FICSA S.A" },
  { value: "627", name: "BANCO DESTAK S.A" },
  { value: "628", name: "BANCO CRITERIUM S.A" },
  { value: "629", name: "BANCORP BANCO COML. E. DE INVESTMENTO" },
  { value: "630", name: "BANCO INTERCAP S.A" },
  { value: "633", name: "BANCO REDIMENTO S.A" },
  { value: "634", name: "BANCO TRIANGULO S.A" },
  { value: "635", name: "BANCO DO ESTADO DO AMAPA S.A" },
  { value: "637", name: "BANCO SOFISA S.A" },
  { value: "638", name: "BANCO PROSPER S.A" },
  { value: "639", name: "BIG S.A. - BANCO IRMAOS GUIMARAES" },
  { value: "640", name: "BANCO DE CREDITO METROPOLITANO S.A" },
  { value: "641", name: "BANCO EXCEL ECONOMICO S/A" },
  { value: "643", name: "BANCO SEGMENTO S.A" },
  { value: "645", name: "BANCO DO ESTADO DE RORAIMA S.A" },
  { value: "647", name: "BANCO MARKA S.A" },
  { value: "648", name: "BANCO ATLANTIS S.A" },
  { value: "649", name: "BANCO DIMENSAO S.A" },
  { value: "650", name: "BANCO PEBB S.A" },
  { value: "652", name: "BANCO FRANCES E BRASILEIRO SA" },
  { value: "653", name: "BANCO INDUSVAL S.A" },
  { value: "654", name: "BANCO A. J. RENNER S.A" },
  { value: "655", name: "BANCO VOTORANTIM S.A." },
  { value: "656", name: "BANCO MATRIX S.A" },
  { value: "657", name: "BANCO TECNICORP S.A" },
  { value: "658", name: "BANCO PORTO REAL S.A" },
  { value: "702", name: "BANCO SANTOS S.A" },
  { value: "705", name: "BANCO INVESTCORP S.A." },
  { value: "707", name: "BANCO DAYCOVAL S.A" },
  { value: "711", name: "BANCO VETOR S.A." },
  { value: "713", name: "BANCO CINDAM S.A" },
  { value: "715", name: "BANCO VEGA S.A" },
  { value: "718", name: "BANCO OPERADOR S.A" },
  { value: "719", name: "BANCO PRIMUS S.A" },
  { value: "720", name: "BANCO MAXINVEST S.A" },
  { value: "721", name: "BANCO CREDIBEL S.A" },
  { value: "722", name: "BANCO INTERIOR DE SAO PAULO S.A" },
  { value: "724", name: "BANCO PORTO SEGURO S.A" },
  { value: "725", name: "BANCO FINABANCO S.A" },
  { value: "726", name: "BANCO UNIVERSAL S.A" },
  { value: "728", name: "BANCO FITAL S.A" },
  { value: "729", name: "BANCO FONTE S.A" },
  { value: "730", name: "BANCO COMERCIAL PARAGUAYO S.A" },
  { value: "731", name: "BANCO GNPP S.A." },
  { value: "732", name: "BANCO PREMIER S.A." },
  { value: "733", name: "BANCO NACOES S.A." },
  { value: "734", name: "BANCO GERDAU S.A" },
  { value: "735", name: "BANCO NEON" },
  { value: "736", name: "BANCO UNITED S.A" },
  { value: "737", name: "THECA" },
  { value: "738", name: "MARADA" },
  { value: "739", name: "BGN" },
  { value: "740", name: "BCN BARCLAYS" },
  { value: "741", name: "BRP" },
  { value: "742", name: "EQUATORIAL" },
  { value: "743", name: "BANCO EMBLEMA S.A" },
  { value: "744", name: "THE FIRST NATIONAL BANK OF BOSTON" },
  { value: "746", name: "MODAL S.A." },
  { value: "747", name: "RABOBANK DO BRASIL" },
  { value: "748", name: "SICREDI" },
  { value: "749", name: "BRMSANTIL SA" },
  { value: "750", name: "BANCO REPUBLIC NATIONAL OF NEW YORK (BRA" },
  { value: "751", name: "DRESDNER BANK LATEINAMERIKA-BRASIL S/A" },
  { value: "752", name: "BANCO BANQUE NATIONALE DE PARIS BRASIL S" },
  { value: "753", name: "BANCO COMERCIAL URUGUAI S.A." },
  { value: "755", name: "BANCO MERRILL LYNCH S.A" },
  { value: "757", name: "BANCO KEB DO BRASIL S.A." },
  { value: "260", name: "Nu Pagamentos S.A" },
  { value: "102", name: "XP INVESTIMENTOS" },
  { value: "336", name: "BANCO C6 S.A." },
  { value: "290", name: "PagSeguro Internet S.A." },
  { value: "323", name: "MercadoPago.com Representações Ltda." },
  { value: "332", name: "Acesso Soluções de Pagamento S.A." },
  { value: "325", name: "Órama DTVM S.A." },
  { value: "085", name: "COOPERATIVA CENTRAL DE CREDITO - AILOS" },
  { value: "125", name: "PLURAL S.A. BANCO MULTIPLO" },
  { value: "197", name: "STONE PAGAMENTOS S.A." },
  { value: "380", name: "PICPAY SERVIÇOS S.A." },
  { value: "335", name: "BANCO DIGIO S.A." },
];

export type Payment = {
  paymID: string;
  userID: string;
  invoID: string;
  value: string;
  methodName: string;
  methodData: string;
  methodCode: string;
  methodURL: string;
  status: string;
  createdAt: string;
};

export enum SupportedPaymentMethod {
  boleto = "pagarme.boleto",
  creditcard = "pagarme.card",
  pix = "pagarme.pix",

  voucher = "voucher",
}

export type PaymentMethod = {
  name: SupportedPaymentMethod;
  description: string;
};

export enum InvoiceFulfillmentVendor {
  udok = "udok",
  doctor = "doctor",
}
export type InvoiceView = {
  invoID: string;
  paymID?: string;
  paymentStatus?: string;
  description: string;
  value: string;
  fulfillmentVendor: InvoiceFulfillmentVendor;
  items: InvoiceItem[];
  createdAt: string;
  voucherCode?: string;
  paymentMethodName: string;
};

export enum PaymentStatus {
  paid = "paid",
  rejected = "rejected",
  refunded = "refunded",
  refused = "refused",
  pending = "pending",
  waiting = "waiting_payment",
  authorized = "authorized",
  canceled = "canceled",
  processing = "processing",
}

export function getPaymentStatusLabel(s?: string | null, alt?: string) {
  if (s === undefined || s === null) {
    return alt;
  }
  return (
    (
      {
        [PaymentStatus.paid]: "pago",
        [PaymentStatus.rejected]: "rejeitado",
        [PaymentStatus.refused]: "recusado",
        [PaymentStatus.refunded]: "estornado",
        [PaymentStatus.pending]: "pendente",
        [PaymentStatus.authorized]: "autorizado",
        [PaymentStatus.canceled]: "cancelado",
        [PaymentStatus.waiting]: "aguardando",
        [PaymentStatus.processing]: "em processamento",
      } as any
    )[s] ?? "erro na transação"
  );
}

export function getPaymentStatusColor(s: string): {
  text: string;
  background: string;
  border: string;
  main: string;
} {
  const l = {
    [PaymentStatus.paid]: {
      text: "#FFFFFF",
      background: "#008A65",
      border: "none",
      main: "#008A65",
    },
    [PaymentStatus.rejected]: {
      text: "#FF4747",
      background: "transparent",
      border: "1px solid #FF4747",
      main: "#FF4747",
    },
    [PaymentStatus.refused]: {
      text: "#FF4747",
      background: "transparent",
      border: "1px solid #FF4747",
      main: "#FF4747",
    },
    [PaymentStatus.refunded]: {
      text: "#646464",
      background: "rgba(135, 135, 135, 0.29)",
      border: "none",
      main: "#646464",
    },
    [PaymentStatus.pending]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
    [PaymentStatus.authorized]: {
      text: "#FFFFFF",
      background: "#0B93D8",
      border: "none",
      main: "#0B93D8",
    },
    [PaymentStatus.canceled]: {
      text: "#FF4747",
      background: "transparent",
      border: "1px solid #FF4747",
      main: "#FF4747",
    },
    [PaymentStatus.waiting]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
    [PaymentStatus.processing]: {
      text: "#FFFFFF",
      background: "#FFA400",
      border: "none",
      main: "#FFA400",
    },
  } as any;
  return l[s] || { text: "", background: "" };
}

export type InvoiceItem = {
  id: string;
  description: string;
  value: string;
};

export type CreditCard = {
  crcaID: string;
  userID: string;
  name: string;
  brand: string;
  provider: string;
  providerCard: string;
  expiresAt: string;
  createdAt: string;
  billingAddress: BillingAddress;
};

export type BillingAddress = {
  name: string;
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  streetNumber: string;
  zipcode: string;
};

export type CreditCardForm = {
  name: string;
  number: string;
  cvv: string;
  expiry: string;
  brand: string;
  billingAddress: BillingAddress;
};

export type FilterAppointment = {
  patiID?: string;
  clinID?: string;
  doctID?: string;
  sescID?: string;
  megrID?: string;
  startDate?: string;
  endDate?: string;
  markedAtGte?: string;
  markedAtLte?: string;
  immediateCare?: boolean;
  status?: string;
  statuses?: string[];
  limit?: number;
  offset?: number;
};

export type ActivitiesGroup = {
  activeAt: string;
  megrID: string;
  messagePreview: string;
  unreadCount: number;
  username: string;
  withUserID: string;
  deletedAt?: string;
};

export type ChatActivity = {
  activeAt: string;
  messagePreview: string;
  unreadCount: number;
  username: string;
  withUserID: string;
  applID: string;
};

export enum ServiceType {
  InstantCare = "pronto-atendimento",
}

export type Voucher = {
  voucID: number;
  appoID?: string;
  createdAt: string;
  expiredAt: string;
  code: string;
};

export type InvoiceVoucher = {
  invoID: string;
  voucID: number;
  fulfilledBy: string;
  fulfilledAt: string;
};

export type AppointmentReturnPending = {
  appoID: string;
  markedAt: string;
  patiID: string;
  doctID: string;
  doctorName: string;
  doctorAvatar?: string;
  clinID?: string;
  clinicName?: string;
  clinicAvatar?: string;
  healthplans: string[];
};

type ClinicInfo = {
  clinID: string;
  name: string;
  avatar?: string;
  banner?: string;
};

export type AppointmentPreview = {
  appoID: string;
  markedAt: string;
  type: string;
  doctorProfile: DoctorProfile;
  clinicProfile?: ClinicInfo;
  locationInfo?: LocationInfo;
  displayPreferences: {
    hideDoctor: boolean;
  };
};

export type DoctorPublicProfile = {
  doctID: string;
  name: string;
  slug: string;
  avatar: string;
  info: DoctorInfo;
  specialty?: {
    nameSpecialty: string;
    image: string;
    specID: number;
    slug: string;
  }[];
  licenses?: DoctorLicense[];
  healthcarePlans?: { heplID: string; name: string }[];
  locations: ProfileLocation[];
  offers: DoctorOffers;
  doctorAppointmentSettings: {
    requiredFields: string[];
    disableSteps: string[];
    highlightingAccountCreation?: boolean;
  };
  doctorRating: DoctorRating;
};

export type DoctorOffers = {
  buttons: CustomOffers[];
  enableAppointmentTypeSelection: boolean;
};

export type ProfileLocation = {
  name: string;
  address: string;
  locaID: string;
  phones?: ContactPhone[];
};

export type SendMailVoucherData = {
  voucID: number;
  recipientName: string;
  recipientEmail: string;
};

export enum ScheduleLockOrigin {
  appointment = "appointment",
  lock = "lock",
  sharedResource = "shared_resource",
}

export enum ScheduleLockType {
  place = "place",
  virtual = "virtual",
  all = "*",
}

export type ScheduleLockView = {
  id: string;
  userID: string;
  startAt: string;
  endAt: string;
  createdAt: string;
  clinID?: string;
  doctID?: string;
  sescID?: string;
  origin: ScheduleLockOrigin;
  type: ScheduleLockType;
};

export type ScheduleLock = {
  scloID: string;
  userID: string;
  startAt: string;
  endAt: string;
  createdAt: string;
  clinID?: string;
  doctID?: string;
  type: ScheduleLockType;
};

export type CreateScheduleLockForm = {
  startAt: string;
  endAt: string;
  doctID?: string;
  type: ScheduleLockType;
};

export type FilterScheduleLock = {
  clinID?: string;
  doctID?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
  offset?: number;
};

export type GeoLocation = {
  addressFormatted: string;
  address: {
    cep: string;
    street: string;
    complement: string;
    number?: string;
    district?: string;
    city: string;
    state: string;
    stateAcronym: string;
    country: string;
    coordinate: string;
    cityIdentifier: string;
  };
};

export type GeoCity = {
  geciID: number;
  gestID: number;
  identifier: string;
  name: string;
  coordinate: string;
};

export type FilterGeoCity = {
  name?: string;
  identifier?: string[];
  limit?: number;
  offset?: number;
};

export enum SEOSearchQueryOptions {
  SpecialtySlug = "especialidade",
  LocationCoordinates = "localizacao",
  LocationDescription = "lugar",
  DoctorSlug = "especialista",
  StartDate = "dataInicio",
  EndDate = "dataFim",
  ClinicID = "clinID",
  SescID = "sescID",
  ScheduleType = "mode",
  View = "view",
  Service = "servico",
  Controlled = "controlled",
  heplID = "heplID",
  planCardIsRequired = "planCardIsRequired",
  AptyID = "aptyID",
}

export enum SpecialtySlugNoFilter {
  medicine = "medicina",
}

export enum ViewType {
  exclusive = "exclusive",
}

export enum ServiceType {
  instantCare = "pronto-atendimento",
}

export type ExamProcedure = {
  exprID: string;
  tussCode?: string;
  title: string;
  description: string;
  tussTerm?: string;
  slug: string;
  doctID?: string;
  clinID?: string;
  createdAt?: string;
  deletedAt?: string;
};

export type ExamProcedureFilter = {
  searchText?: string;
  limit?: number;
  offset?: number;
  slug?: string;
  clinID?: string;
  doctID?: string;
  sescID?: string[];
  aptyID?: string[];
  scheduleSelfService?: boolean;
  createdByClinID?: string;
  createdByDoctID?: string;
  heplID?: string;
};

export type ClinicExamProcedureDoctor = {
  exprID: string;
  clinID: string;
  doctors: string[];
};

export type Tuss = {
  tussCode: string;
  tussTerm: string;
  effectiveDateStart: string;
  effectiveDateEnd?: string;
};

export type FilterTussList = {
  tussCode?: string;
  tussTerm?: string;
  searchTerm?: string;
  limit?: number;
  offset?: number;
};

export type SerarchAppointmentResult = {
  doctID: string;
  userID: string;
  name: string;
  slug: string;
  avatar: string;
  about?: string;
  subSpecialty?: string;
  specialtys: string[];
  schedules: SearchSchedule[];
  locations: SearchScheduleLocation[];
  healthPlans: {
    heplID: string;
    name: string;
  }[];
  licenses?: DoctorLicense[];
  procedures?: SearchExamProcedure[];
  displayPreferences: {
    enhancedScheduleOffering: boolean;
    hideProfileInClinicProcedures: boolean;
  };
  doctorAppointmentSettings: {
    requiredFields: string[];
    disableSteps: string[];
    highlightingAccountCreation?: boolean;
  };
};

export type SearchExamProcedure = {
  exprID: string;
  title: string;
  slug: string;
};

export type SearchSchedule = {
  sescID: string;
  locaID?: string;
  startAt: string;
  endAt: string;
  appointmentDuration: number;
  weekDay: string[];
  marketplaceOffer: boolean;
  type: string;
  price: number;
  appointmentOptions?: { aptyID: string; default: boolean; name?: string }[];
  healthPlans: string[];
  clinID?: string;
  scheduleCalendar: ScheduleCalendar[];
  blockUnpaidAppointment: boolean;
  procedures?: string[];
  defaultStatus?: string;
  displayPreferences?: {
    enableDuplicateAppointmentAlert: boolean;
    disableCreateDuplicateAppointment: boolean;
  };
};

export type ScheduleCalendar = {
  firstSlot: string;
  lastSlot: string;
  slots: string[];
};

export type SearchScheduleLocation = {
  locaID: string;
  name: string;
  coords: string;
  address: string;
};

export type SearchAppointmentsFilter = {
  startDate?: string;
  endDate?: string;
  coords?: string;
  specialty?: string[];
  specialtySlug?: string;
  doctID?: string;
  healthplans?: string[];
  clinID?: string;
  distance?: number;
  scheduleType?: ScheduleType;
  listing?: "subscribed";
  sescID?: string[];
  aptyID?: string[];
  marketplaceOffer?: boolean;
  doctorSlug?: string;
  rangeOfDays?: number;
  limit?: number;
  offset?: number;
  enableProcedures?: boolean;
  procedureSlugs?: string[];
  exprID?: string[];
  "order[asc]"?: string;
};

export enum QrCodePositions {
  upRight = "upRight",
  upLeft = "upLeft",
  downRight = "downRight",
  downLeft = "downLeft",
}

export type PrescriptionLayoutData = {
  image?: string;
  waterMark?: string;
  signatureImage?: string;
  qrCodePosition: string;
  waterMarkPosition?: {
    horizontal: string;
    vertical: string;
  };
  hideEmittedAt: boolean;
};

export type PrescriptionLayout = {
  plteID: string;
  data: PrescriptionLayoutData;
  name: string;
  createdAt: string;
  deletedAt?: string;
  prescriptionModel: string;
};

export type PrescriptionLayoutView = PrescriptionLayout & {
  clinID?: string;
  doctID?: string;
  locations: string[];
};

export type PrescriptionLayoutForm = {
  prescriptionModel: string;
  data: PrescriptionLayoutData;
  name: string;
};

export type PrescriptionLayoutFilter = {
  clinID?: string;
  locaID?: string;
  prescriptionModel?: string;
  limit?: number;
  offset?: number;
};

export enum PrescriptionLayoutBindType {
  location = "location",
  template = "template",
}

export type PrescriptionLayoutBindings = {
  resourceID: string;
  type: string;
  attachResources: string[];
};

export type PatientPlansHistory = {
  patiID: string;
  heplID: string;
  name: string;
  image?: string;
  usedAt: string;
  type: string;
};

export enum PatientPlansHistoryType {
  crm = "crm",
  appointment = "appointment",
}

export type PrescriptionLayoutHistory = PrescriptionLayout & {
  usedAt: string;
  presID: string;
};

export type ScheduleFilter = {
  sescID?: string;
  clinID?: string;
  appoID?: string;
  locaID?: string;
  "startAt[gte]"?: string;
  "startAt[lte]"?: string;
  "endAt[gte]"?: string;
  "endAt[lte]"?: string;
  "createAt[gte]"?: string;
  "createAt[lte]"?: string;
  type?: string;
  weekDay?: string;
};

export type PatientPersonalInformation = {
  ppinID: number;
  patiID: string;
  createdAt: string;
  deletedAt?: string;
  createdBy: string;
  name: string;
  dateOfBirth: string;
  sex?: string;
};

export type SendInvite = {
  recipient: {
    patiID?: string;
    recipientName?: string;
    recipientEmail?: string;
    recipientPhone?: string;
  };
  notificationChannels: Array<"phone" | "email">;
  invitation: "appointment" | "createAccount" | "recordsUpdate";
};

export type Message = {
  type: string;
  targets: Array<string>;
  value: string;
  data: any;
  messID: string;
  fromID: string;
  toID: string;
  megrID?: string;
  deletedAt?: string;
};

export type Msg = {
  createdAt: string;
  data:
    | {
        error?: string;
        file?: string;
      }
    | any;
  fromID: string;
  fromName: string;
  messID: string;
  status: Array<string>;
  toID: string;
  megrID?: string;
  toName: string;
  type: "audio" | "text" | "archive" | "image";
  value: string;
  // local properties
  _id?: string;
  loading?: boolean;
  text?: string;
  deletedAt?: string;
};

export type ClinicDoctorListFilter = {
  doctID?: string;
  doctName?: string;
  listAll?: boolean;
  Limit?: number;
  Offset?: number;
};

export type EventAlert = {
  notiID: string;
  path: string;
  data: any;
  createdAt: string;
  readAt?: string;
};

export type FilterEventAlert = {
  paths?: string[];
  "createdAt[gte]"?: string;
  "createdAt[lte]"?: string;
  filterData?: string;
  limit?: number;
  offset?: number;
};

export enum EventAlertType {
  newAppointment = "appointment/create",
  appointmentStatusChange = "appointment/status-change",
  appointmentReschedule = "appointment/reschedule",
  appointmentConfirmed = "appointment/confirmed",
  appointmentConfirmationViaPhone = "appointment/confirmation-via-phone",
  appointmentPhoneReview = "appointment/phone-review",
  appointmentDoctorTransfer = "appointment/doctor-transfer",
  clinicBranchCreate = "clinic-branch/create",
  clinicBranchUpdate = "clinic-branch/update",
  clinicBranchDelete = "clinic-branch/delete",
  doctorReviewCreate = "doctor-review/create",
}

export type VoucherValidateStatus = {
  voucID?: number;
  expiredAt?: string;
  isValid?: boolean;
};

export type ValidateVoucherBy = {
  invoID?: string;
  imcsID?: string;
  sescID?: string;
};

export type InstantAppointmentData = {
  type: string;
  location?: string;
  startAt: string;
  docUserID?: string;
  appointmentDuration: number;
  heplID: string;
  patiID?: string;
  patientBirthdate?: string;
  patientCPF?: string;
  patientPhone?: string;
  patientEmail?: string;
  patientName?: string;
  specID?: number;
  healthplanCardNumber?: { value: string; phcaID?: number };
  returnForAppoID?: string;
  returnForMarkedAt?: string;
  procedures?: string[];
  aptyID?: string;
};

export type FilterClinicUser = {
  clinID?: string;
  doctID?: string;
  email?: string;
  limit?: number;
  offset?: number;
};

export type DoctorClinicPatient = {
  clinID: string;
  doctID: string;
  patiID: string;
};

export type FilterHealthPlan = {
  clinID?: string;
  doctID?: string;
  sescID?: string[];
  aptyID?: string[];
  name?: string;
  specialty?: string[];
  specialtySlug?: string[];
  scheduleType?: string;
  procedures?: string[];
  procedureSlugs?: string[];
  scheduleSelfService?: boolean;
  limit?: number;
  offset?: number;
};

export type PatientWithStats = Patient & {
  pinned?: boolean;
  createdAt: string;
  lastAppointmentPlace?: string;
  linkedDoctorName?: string;
  linkedDoctorID?: string;
  healthPlanName?: string;
  healthPlanID?: string;
  healthPlanCardNumber?: string;
  lastAppointment?: string;
};

export type PatientHealthplanCard = {
  phcaID: number;
  createdBy?: string;
  patiID: string;
  heplID: string;
  createdAt: string;
  deletedAt?: string;
  cardNumber: string;
  cardName: string;
  planName: string;
};

export type FilterPatientHealthplanCard = {
  heplID?: string;
  limit?: number;
  offset?: number;
};

export type RecordFlag = {
  reflID: number;
  title: string;
  description: string;
  info?: {
    style?: {
      foregroundColor?: string;
      backgroundColor?: string;
    };
  };
  clinID?: string;
  doctID?: string;
  createdAt?: string;
};

export type RecordFlagFilter = {
  title?: string;
  limit?: number;
  offset?: number;
};

export type PatientRecordFlag = {
  parfID: number;
  reflID: number;
  patiID: string;
  createdBy: string;
  value: any;
  createdAt: string;
  deletedAt?: string;
  clinID?: string;
  doctID?: string;
};

export type PatientRecordFlagFilter = {
  patiID?: string;
  clinID?: string;
  doctID?: string;
  limit?: number;
  offset?: number;
};

export type PatientGovernmentID = {
  pgidID: number;
  createdBy: string;
  patiID: string;
  createdAt: string;
  deletedAt?: string;
  documentType: string;
  documentNumber: string;
};

export enum PatientSexType {
  SexMale = "m",
  SexFemale = "f",
}

const patientSexTypeLabel = {
  [PatientSexType.SexMale]: "Masculino",
  [PatientSexType.SexFemale]: "Feminino",
};

export function getSexTypeLabel(s: PatientSexType): string {
  return patientSexTypeLabel[s] || "";
}

export type SocialMedia = {
  facebook?: string;
  instagram?: string;
  linkedIn?: string;
  twitter?: string;
  youTube?: string;
};

export type EmailValidateStatus = {
  email: string;
  applID: string;
  isValid: boolean;
};

export type ClinicBranch = {
  clbrID: string;
  mainClinID: string;
  branchClinID: string;
  createdBy: string;
  respondedBy?: string;
  createdAt: string;
  deniedAt?: string;
  aceptedAt?: string;
  deletedAt?: string;
  mainClinName: string;
  branchClinName: string;
};

export type ClinicBranchFilter = {
  mainClinID?: string;
  branchClinID?: string;
  mainClinName?: string;
  clinName?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  deniedAtGte?: string;
  deniedAtLte?: string;
  aceptedAtGte?: string;
  aceptedAtLte?: string;
  limit?: number;
  offset?: number;
};

export type TriggerAction = {
  tracID: string;
  userID: string;
  createdAt: string;
  deletedAt?: string;
  trigger: string;
  action: string;
  validationSchema: TriggerActionSchema;
  actionFormData: any;
};

export type TriggerActionSchema = {
  type: string;
  required: string[];
  properties: {
    [key: string]: TriggerActionSchemaPropertie;
  };
};

export type TriggerActionSchemaPropertie = {
  type: string;
  enum?: string[];
  not?: { enum?: string[] };
  pattern?: string;
  minimum?: number;
  maximum?: number;
};

export type CreateTriggerForm = {
  tracID?: string;
  trigger: string;
  action: string;
  validationSchema: TriggerActionSchema;
  actionFormData: any;
};

export type FilterTriggerAction = {
  trigger?: string;
  action?: string;
  limit?: number;
  offset?: number;
};

export type TriggerField = {
  title: string;
  validationConditions: string[];
  required: boolean;
  options: { value: string; label: string }[];
};

export type Trigger = {
  name: string;
  title: string;
  fields: { [k: string]: TriggerField };
};

export type Action = {
  name: string;
  title: string;
  description: string;
  formSchema: any;
  uiSchema: any;
  permissions: string[];
};

export enum TriggerConditionType {
  triggerConditionEqual = "=",
  triggerConditionDifferent = "!=",
  triggerConditionContains = "contains",
  triggerConditionBiggerThen = ">",
  triggerConditionLessThan = "<",
}

export const TriggerConditionTypeLabel = {
  [TriggerConditionType.triggerConditionEqual]: "Igual à",
  [TriggerConditionType.triggerConditionDifferent]: "Diferente de",
  [TriggerConditionType.triggerConditionContains]: "Contém",
  [TriggerConditionType.triggerConditionBiggerThen]: "Maior que",
  [TriggerConditionType.triggerConditionLessThan]: "Menor que",
};

export type SpecialtyFilter = {
  clinID?: string;
  slug?: string;
  keywords?: string[];
  heliIDs?: string[];
  heliId?: string;
  limit?: number;
  offset?: number;
};

export type SharedResource = {
  shreID: string;
  name: string;
  concurrentUse: number;
  maxOverlapMinutes: number;
  createdAt: string;
  deletedAt?: string;
  doctID?: string;
  clinID?: string;
};

export type SharedResourceForm = {
  name: string;
  concurrentUse: number;
  maxOverlapMinutes: number;
};

export type SharedResourceFilter = {
  name?: string;
  doctID?: string;
  includeShreID?: string[];
  limit?: number;
  offset?: number;
};
export enum Mode {
  Virtual = "virtual",
  Place = "place",
  Exam = "exam_procedure",
  ImmediateCare = "immediate_care",
}

export const ModeLabels = {
  [Mode.Virtual]: "Atendimento virtual",
  [Mode.Place]: "Atendimento presencial",
  [Mode.Exam]: "Exames e procedimentos",
  [Mode.ImmediateCare]: "Pronto atendimento",
};

export type CustomOffers = {
  label: string;
  mode: Mode;
  sescID?: string[];
  imcaID?: string;
  visible: boolean;
};

export enum Visibilitys {
  All = "*",
  Doctor = "doctor",
  Clinic = "clinic",
  Patient = "patient",
}

export const VisibilityLabels = {
  [Visibilitys.All]: "Todos",
  [Visibilitys.Doctor]: "Profissionais",
  [Visibilitys.Clinic]: "Clínica",
  [Visibilitys.Patient]: "Pacientes",
};

export enum ReviewRating {
  Bad = 1,
  Regular = 2,
  Good = 3,
  Excellent = 4,
}

export const ReviewRatingLabels = {
  [ReviewRating.Bad]: "Pode melhorar",
  [ReviewRating.Regular]: "Regular",
  [ReviewRating.Good]: "Bom",
  [ReviewRating.Excellent]: "Muito Bom",
};

export type DisplayAppointmentReview = {
  arevID: number;
  appoID: string;
  ratedBy: string;
  reviewRating: number;
  observation: string;
  createdAt: string;
  patientName: string;
  doctorName: string;
  markedAt: string;
};

export type FilterAppointmentReview = {
  appoID?: string;
  patiID?: string;
  doctID?: string;
  markedAtGte?: string;
  markedAtLte?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  limit?: number;
  offset?: number;
};

export type MediaPost = {
  mepoID: number;
  mediaURL: string;
  mediaType: string;
  thumbnail: string;
  slug: string;
  title: string;
  description: string;
  updatedBy: string;
  updatedAt: string;
  createdAt: string;
  publishedAt: string;
};

export type MediaPostForm = {
  mediaURL: string;
  thumbnail: string;
  mediaType: string;
  title: string;
  description: string;
  publishedAt: string;
};

export type FilterMediaPost = {
  clinID?: string;
  doctID?: string;
  postSlug?: string;
  doctorSlug?: string;
  clinicSlug?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  limit?: number;
  offset?: number;
};

export interface AxiosProgressEvent {
  loaded: number;
  total?: number;
  progress?: number;
  bytes: number;
  rate?: number;
  estimated?: number;
  upload?: boolean;
  download?: boolean;
  event?: any;
}

export type AppointmentInstruction = {
  apinID: number;
  title: string;
  content: string;
  createdAt: string;
  deletedAt: string;
  createdBy: string;
  doctID?: string;
  clinID?: string;
  aptyID: string;
  appointmentTypeName: string;
};

export type AppointmentInstructionForm = {
  title: string;
  content: string;
  aptyID: string;
};

export type AppointmentInstructionFilter = {
  doctID?: string;
  clinID?: string;
  aptyID?: string;
  limit?: number;
  offset?: number;
};

export type AppointmentFile = {
  appoID: string;
  createdBy: string;
  fileID: string;
  permissionMode: string;
  createdAt: string;
  deletedAt?: string;
};

export type AppointmentFileForm = {
  appoID: string;
  fileID: string;
  permissionMode: string;
};

export type FilterAppointmentFile = {
  fileID?: string;
  limit?: number;
  offset?: number;
};

export enum AppoFilePermission {
  doctor = "doctor",
  doctorPatient = "doctor_patient",
}

export function getAppoFilePermissionLabel(s?: string | null, alt?: string) {
  if (s === undefined || s === null) {
    return alt;
  }
  return (
    (
      {
        [AppoFilePermission.doctor]: "Apenas profissional de saúde",
        [AppoFilePermission.doctorPatient]: "Profissional e paciente",
      } as any
    )[s] ?? ""
  );
}

export type MedicalHistoryTemplate = Document & {
  clinID?: string;
  doctID?: string;
};

export type MedicalHistoryTemplateForm = Omit<
  Document,
  "doteID" | "createdAt" | "updatedAt" | "deletedAt"
>;

export type MedicalHistoryDocumentView = {
  patiID: string;
  clinID?: string;
  createdAt: string;
  documentOwnerName: string;
  documentOwnerID: string;
  document: DocumentData;
};

export type MedicalHistoryDocumentForm = {
  patiID: string;
} & Omit<DocumentData, "doctID" | "docuID" | "createdAt" | "deletedAt">;

export type FilterMedicalHistoryTemplate = {
  owner?: boolean;
  title?: string;
  limit?: number;
  offset?: number;
};

export type FilterMedicalHistoryDocument = {
  clinID?: string;
  limit?: number;
  offset?: number;
};

export type FilterMedicalHistoryTimeline = {
  createdAtGte?: string;
  createdAtLte?: string;
  limit?: number;
  offset?: number;
};

export enum ImmediateCareType {
  place = "place",
  virtual = "virtual",
}

export type ImmediateCare = {
  imcaID: string;
  name: string;
  description: string;
  descriptionOffline: string;
  locaID?: string;
  createdAt: string;
  deletedAt?: string;
  type: string;
  marketplaceOffer: boolean;
  blockUnpaidAppointment: boolean;
  cancelAfterMinutes?: CancelAfterMinutes;
  defaultStatus?: string;
  slug: string;
  defaultDoctID: string;
  appointmentDuration: number;
};

export type ImmediateCareSession = {
  imcsID: string;
  imcaID: string;
  userID: string;
  startAt: string;
  endAt: string;
};

export type EntryToImmediateCareSession = {
  imcsID: string;
  doctID?: string;
  patiID?: string;
  appoID?: string;
};

export type ImmediateCareSessionForm = {
  imcaID: string;
  endAt: string;
};

export type ImmediateCareAppointmentOptions = {
  aptyID: string;
  default: boolean;
};

export type SplitValues = {
  value?: number;
  percent?: number;
};

export type SplitRules = {
  clinic?: SplitValues;
  doctor?: SplitValues;
};

export type ImmediateCareView = ImmediateCare & {
  price: number;
  splitRules: SplitRules;
  appointmentOptions: ImmediateCareAppointmentOptions[];
  healthPlans: string[];
  specID: number;
  currentSession?: ImmediateCareSession;
  clinicSlug?: string;
  clinID?: string;
};

export type ImmediateCareForm = {
  defaultDoctID: string;
  defaultStatus?: string;
  appointmentOptions: ImmediateCareAppointmentOptions[];
  price: number;
  locaID?: string;
  splitRules: SplitRules;
  name: string;
  description: string;
  descriptionOffline: string;
  type: string;
  cancelAfterMinutes?: CancelAfterMinutes;
  marketplaceOffer: boolean;
  blockUnpaidAppointment: boolean;
  specID: number;
  slug: string;
  healthPlans: string[];
  appointmentDuration: number;
};

export type FilterImmediateCare = {
  imcsID?: string;
  doctID?: string;
  name?: string;
  description?: string;
  descriptionOffline?: string;
  slug?: string;
  locaID?: string;
  type?: string;
  limit?: number;
  offset?: number;
};

export type CareRecipient = {
  patiID: string;
  userID: string;
  appoID: string;
};
export type Caregiver = {
  doctID: string;
  userID: string;
  startedAt: string;
};
export type Attending = {
  caregiver: Caregiver;
  careRecipient: CareRecipient;
  startedAt: string;
};
export type Call = {
  from: Caregiver;
  to: CareRecipient;
  startedAt: string;
};

export type ViewerRealtimeState = {
  imcsID: string;
  queue: CareRecipient[];
  caregivers: Caregiver[];
  attending: Attending[];
  calling: Call[];
};

export type PatientRealtime = {
  imcsID: string;
  queueNumber: number;
  attending?: Attending;
  calling?: Call;
};

export type FilterPublicDoctorProfiles = {
  query?: string;
  doctID?: string;
};

export type StartCareForm = {
  doctID: string;
  appoID: string;
};

export type PublicImmediateCare = {
  imcaID: string;
  clinID?: string;
  clinicSlug?: string;
  name: string;
  description: string;
  descriptionOffline: string;
  locaID?: string;
  type: string;
  slug: string;
  healthPlans: {
    heplID: string;
    name: string;
  }[];
  currentSession?: ImmediateCareSession;
  price: number;
  specialty: {
    specID: number;
    name: string;
  };
  appointmentOptions: ImmediateCareAppointmentOptions[];
  marketplaceOffer: boolean;
  defaultStatus?: string;
};

export type FilterPublicImmediateCare = {
  imcsID?: string;
  imcaID?: string;
  limit?: number;
  offset?: number;
};

export enum TaxStatus {
  processing = "processing",
  done = "done",
  rejected = "rejected",
  canceled = "canceled",
  unknown = "unknown",
  failed = "failed",
  processCancell = "processCancell",
  cancellationFailed = "cancellationFailed",
}
export function getTaxStatusLabel(s: string): string {
  const l = {
    [TaxStatus.processing]: "Processando",
    [TaxStatus.done]: "Concluido",
    [TaxStatus.rejected]: "Rejeitado",
    [TaxStatus.canceled]: "Cancelado",
    [TaxStatus.failed]: "Falhou",
    [TaxStatus.unknown]: "Indeterminado",
    [TaxStatus.processCancell]: "Processando cancelamento",
    [TaxStatus.cancellationFailed]: "Cancelamento falhou",
  } as any;
  return l[s] || s;
}

export type Slot = {
  startAt: string;
  endAt: string;
  locks: string[];
  maxSlots?: number;
  occupiedSlots?: number;
};

export type ScheduleSlot = {
  date: string;
  sescID: string;
  doctID: string;
  clinID?: string;
  locaID: string;
  doctorName: string;
  clinicName?: string;
  locationName: string;
  locationAddress: string;
  type: string;
  specialtyText: string;
  examProcedureText: string;
  firstSlot: string;
  lastSlot: string;
  maxSlots?: number;
  appointmentDuration?: number;
  sharedResources?: string[];
  displayPreferences: {
    hideProfileInProcedure: boolean;
    clearancePeriod: number;
    enableDoctorScheduleBooking: boolean;
  };
};

export type FilterScheduleSlots = {
  clinID?: string;
  userID?: string;
  doctID?: string;
  locaID?: string;
  exprID?: string;
  specID?: number;
  searchText?: string;
  date: string;
  limit?: number;
  offset?: number;
};

export type EventData = {
  appoID: string;
  type: string;
  status: string;
  title: string;
  startAt: string;
  endAt: string;
  confirmations: AppointmentConfirmation[];
};

export type EventsPerDay = {
  date: string;
  markeds: number;
  availables: number;
  disabled: number;
  appointments: EventData[];
};

export type FilterCalendarEvents = {
  clinID?: string;
  doctID?: string;
  userID?: string;
  exprID?: string;
  patiID?: string;
  specID?: number;
  startDate: string;
  endDate: string;
};

export type ExamProcedureForm = {
  tussCode: string;
  title: string;
  description: string;
  slug?: string;
};

export type TaxInvoiceItem = {
  description: string;
  value: string;
};

export type TaxInvoice = {
  tainID: string;
  invoID: string;
  description: string;
  items: TaxInvoiceItem[];
  code: string;
  externalID: string;
  urlPDF: string;
  urlXML: string;
};

export type TaxInvoiceStatusInfo = {
  externalResponse: any;
  error: string;
  errorBody: string;
};

export type TaxInvoiceStatus = {
  taisID: number;
  tainID: string;
  status: string;
  info: TaxInvoiceStatusInfo;
  createdAt: string;
};
export type TaxInvoiceView = TaxInvoice & {
  lastStatus: TaxInvoiceStatus;
};

export type TaxInvoiceFilter = {
  invoID?: string;
  status?: string;
  code?: string;
  externalID?: string;
  limit?: number;
  offset?: number;
};

export type signedDownloadURL = {
  url: string;
  expiresAt: string;
};

export type downloadUrlParams = {
  file: string;
  expirationInMinutes: number;
};

export type TaxInvoiceSettings = {
  tiseID: number;
  settings: any;
  createdAt: string;
  updatedAt: string;
  lastIssuedSeries: string;
  lastIssuedNumber: number;
};

export type TaxInvoiceSettingsForm = {
  lastIssuedSeries: string;
  lastIssuedNumber: number;
  settings: any;
};

export type TaxInvoiceSettingsView = {
  settings?: TaxInvoiceSettings;
  formSchema: any;
  uiSchema: any;
};

export type TaxInvoiceServiceCode = {
  tiscID: number;
  specID: string;
  code: string;
  scheduleType: string;
  exameProcedure: string;
  createdAt: string;
};

export type TaxInvoiceServiceCodeForm = {
  specID: string;
  code: string;
  scheduleType: string;
  exameProcedure: string;
};

export type TaxInvoiceServiceCodeFilter = {
  specID?: string;
  code?: string;
  scheduleType?: string;
  exameProcedure?: string;
  offset?: number;
  limit?: number;
};

export enum AppointmentExpiredReason {
  unfilled = "unfilled",
  unpaid = "unpaid",
}

export type AppointmentSettingsInfo = {
  appoID: string;
  expiredAt?: string;
  expiredReason?: string;
};

export type AppointmentCheckIn = {
  acinID: number;
  appoID: string;
  userID: string;
  createdAt: string;
  deletedAt?: string;
};

export type FilterAppointmentCheckIn = {
  createdBy?: string;
  markedAtGte?: string;
  markedAtLte?: string;
  limit?: number;
  offset?: number;
};

export type RecordFlagForm = {
  title: string;
  description: string;
  info: {
    style?: {
      foregroundColor?: string;
      backgroundColor?: string;
    };
  };
  clinID?: string;
  doctID?: string;
};

export type DoctorReview = {
  doreID: number;
  doctID: string;
  patiID: string;
  message: string;
  rating: number;
  verified: boolean;
  info: any;
  createdAt: string;
  rejected: boolean;
  publishedAt: string;
};

export type DoctorReviewView = DoctorReview & {
  patientName: string;
  patientAvatar: string;
};
export type DoctorReviewForm = {
  doctID: string;
  message: string;
  rating: number;
  info: any;
};

export type FilterDoctorReview = {
  verified?: boolean;
  approved?: boolean;
  rating?: number;
  ratingGte?: number;
  ratingLte?: number;
  createdAtGte?: string;
  createdAtLte?: string;
  "order[asc]"?: "createdAt" | "rating";
  "order[desc]"?: "createdAt" | "rating";
  limit?: number;
  offset?: number;
};

export type CidsusUsage = {
  ciliID: string;
  displayCode: string;
  displayName: string;
  emissions: number;
};

export type MedicineUsage = {
  drugID: string | null;
  drugName: string;
  emissions: number;
};

export type PrescriptionDashboard = {
  documents: number;
  prescriptions: number;
  medicalCertificates: number;
  medicalReports: number;
  exams: number;
};

export type SpecialtyData = {
  specID: number;
  name: string;
  image?: string | null;
  slug?: string | null;
};

export type DoctorsSpecialties = SpecialtyData[];

export type DoctorPrescriptionDashboard = {
  doctID: string;
  doctorName: string;
  doctorAvatar: string;
  specialty: DoctorsSpecialties;
  documents: number;
  prescriptions: number;
  medicalCertificates: number;
  medicalReports: number;
  exams: number;
  lastIssuedAt: string;
};

export type OverviewData = {
  total: number;
  percentage: number;
};

export type AppointmentTypeDashboard = {
  total: number;
  percentage: number;
  type: "virtual" | "place" | "exam_procedure";
  healthPlanTotal: OverviewData;
  privateTotal: OverviewData;
};

export type AppointmentPlanDashboard = {
  total: number;
  percentage: number;
  planName: string;
  heplID: string;
};

export type ComparativeOverviewData = {
  total: number;
  percentage: number;
  comparativePercentage: number;
};

export type AppointmentStatusDashboard = {
  total: number;
  percentage: number;
  comparativePercentage: number;
  status: string;
};

export type StatusStatistics = AppointmentStatusDashboard[];

export type AppointmentDashboard = {
  total: number;
  statusStatistics: StatusStatistics;
  confirmationTotal: ComparativeOverviewData;
  fidelityTotal: ComparativeOverviewData;
  startReferenceDate: string;
  endReferenceDate: string;
  startComparativeDate: string;
  endComparativeDate: string;
};

export type AppointmentDoctorDashboard = {
  doctID: string;
  doctorName: string;
  doctorAvatar: string;
  specialty: DoctorsSpecialties;
  appointments: number;
  lastIssuedAt: string;
};

export type DateOverview = {
  date: string;
  total: number;
};

export type DateOverviews = DateOverview[];

export type AppointmentTypeByDateDashboard = {
  type: "virtual" | "place" | "exam_procedure";
  total: number;
  appointmentsByDate: DateOverviews;
};

export type PatientDashboard = {
  total: number;
  female: OverviewData;
  male: OverviewData;
  ageUpToThirty: OverviewData;
  ageBetweenThirtyAndSixtyFive: OverviewData;
  ageAboveSixtyFive: OverviewData;
};

export type ChatDashboard = {
  avgResponseTimeInSeconds: number;
  comparativeAVGResponseTimeInSeconds: number;
  startReferenceDate: string;
  endReferenceDate: string;
  startComparativeDate: string;
  endComparativeDate: string;
};
