import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  Clinic,
  Specialty,
  ClinicSpecialty,
  Healthplan,
  FilterHealthPlan,
  ActiveSubscription,
  InvalidSubscription,
  ActiveSubscriptionStatus,
  ClinicBranch,
  ClinicFilter,
  ClinicBranchFilter,
  AxiosProgressEvent,
} from "@udok/lib/api/models";
import {
  fetchClinicProfile,
  fetchClinicBranches,
  createClinicBranches,
  updateClinicBranches,
  deleteClinicBranches,
  fetchClinicBranch,
  fetchClinics,
  updateClinicProfile as putClinicProfile,
} from "@udok/lib/api/clinic";
import { fetchCurrentSubscription } from "@udok/lib/api/user";
import { uploadOneFile } from "@udok/lib/api/files";
import {
  fetchHealthplans,
  fetchHealthplan,
  fetchclinic,
} from "@udok/lib/api/schedule";
import {
  fetchClinicSpecialties,
  deleteClinicSpecialty,
  CreateClinicSpecialtyRequest,
  createClinicSpecialty,
  fetchSpecialties,
  fetchSpecialty,
} from "@udok/lib/api/specialty";
import { getToken, UNAUTHORIZED } from "./auth";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  profile?: Clinic;
  clinicSpecialtyByID: { [specID: string]: ClinicSpecialty | undefined };
  specialtyByID: { [specID: string]: Specialty | undefined };
  clinicHealthplans: string[];
  healthplanByID: { [heplID: string]: Healthplan | undefined };
  currentActiveSubscription: ActiveSubscription | InvalidSubscription | null;
  clinicBranchByID: { [clbrID: string]: ClinicBranch | undefined };
  clinicBranchByClinID: { [clinID: string]: string[] | undefined };
  clinByID: { [clinID: string]: Clinic | undefined };
  listClinics: string[];
};

// Reducers
const initialState: InitialState = {
  profile: undefined,
  clinicSpecialtyByID: {},
  specialtyByID: {},
  clinicHealthplans: [],
  healthplanByID: {},
  currentActiveSubscription: null,
  clinicBranchByID: {},
  clinicBranchByClinID: {},
  clinByID: {},
  listClinics: [],
};

class ClinicSlice extends Hen<InitialState> {
  profileLoaded(v: Clinic) {
    this.state.profile = v;
  }

  clinicSpecialtiesLoaded(l: ClinicSpecialty[]) {
    l.forEach((s) => {
      this.state.clinicSpecialtyByID[String(s.specID)] = s;
    });
  }

  clinicSpecialtyRemoved(specID: string) {
    delete this.state.clinicSpecialtyByID[specID];
  }

  specialtiesLoaded(l: Specialty[]) {
    l.forEach((s) => {
      this.state.specialtyByID[String(s.specID)] = s;
    });
  }

  loadSpecialty(spec: Specialty) {
    const specID =
      typeof spec.specID === "number" ? spec.specID.toString() : spec.specID;
    this.state.specialtyByID[specID] = spec;
  }

  healthplansLoaded(l: Healthplan[], clinID?: string) {
    l.forEach((s) => {
      this.state.healthplanByID[String(s.heplID)] = s;
    });
    if (clinID) {
      this.state.clinicHealthplans = l.map((hp) => hp.heplID);
    }
  }

  healthplanLoaded(hp: Healthplan) {
    this.state.healthplanByID[String(hp.heplID)] = hp;
  }

  activeSubscriptionLoaded(s: ActiveSubscription | InvalidSubscription) {
    this.state.currentActiveSubscription = s;
  }

  clinicBranchesLoaded(l: ClinicBranch[]) {
    l.forEach((b) => {
      this.state.clinicBranchByID[b.clbrID] = b;
      const currentList = this.state.clinicBranchByClinID[b.branchClinID] ?? [];
      this.state.clinicBranchByClinID[b.branchClinID] = [
        ...currentList,
        b.clbrID,
      ];
    });
  }

  clinicBranchLoaded(b: ClinicBranch) {
    this.state.clinicBranchByID[b.clbrID] = b;
    const currentList = this.state.clinicBranchByClinID[b.branchClinID] ?? [];
    this.state.clinicBranchByClinID[b.branchClinID] = [
      ...currentList,
      b.clbrID,
    ];
  }

  clinicBranchRemoved(b: ClinicBranch) {
    delete this.state.clinicBranchByID[b.clbrID];
    const newList = this.state.clinicBranchByClinID[b.branchClinID] ?? [];
    const index = newList.indexOf(b.clbrID);
    if (index > -1) {
      newList.splice(index, 1);
    }
    this.state.clinicBranchByClinID[b.branchClinID] = newList;
  }

  loadClinic(clinic: Clinic) {
    this.state.clinByID[clinic.clinID] = clinic;
  }

  loadClinics(clinics: Clinic[]) {
    this.state.listClinics = clinics.map((c: Clinic) => {
      this.state.clinByID[c.clinID] = c;
      return c.clinID;
    });
  }
}

export const [Reducer, actions] = hen(new ClinicSlice(initialState), {
  [UNAUTHORIZED]: (state: InitialState) => {
    return {
      ...initialState,
      profile: state.profile,
    };
  },
});

// Selectors
const mainSelector = (state: RootState) => state.clinic;
export const plansSelector = (state: RootState) => state.clinic.healthplanByID;
export const clinicPlansSelector = (state: RootState) =>
  state.clinic.clinicHealthplans;
export const clinicSpecialtySelector = (state: RootState) =>
  state.clinic.clinicSpecialtyByID;
const subscriptionSelector = (state: RootState) =>
  state.clinic.currentActiveSubscription;
export const clinicSpecialtyRepositoryByID = (state: RootState) =>
  mainSelector(state).clinicSpecialtyByID;
export const profileRepository = (state: RootState) =>
  mainSelector(state).profile;
const clinicBranchRepository = (state: RootState) =>
  mainSelector(state).clinicBranchByID;
const clinicBranchByClinIDRepository = (state: RootState) =>
  mainSelector(state).clinicBranchByClinID;
const clinicRepository = (state: RootState) => mainSelector(state).clinByID;
const listClinicsRepository = (state: RootState) =>
  mainSelector(state).listClinics;
const specialtyRepository = (state: RootState) =>
  mainSelector(state).specialtyByID;

export const getClinicProfile = createSelector(mainSelector, (state) => {
  return {
    profile: state.profile,
  };
});

export const getClinicProfileLoader = createSelector(
  [mainSelector, getToken],
  (state, token) => {
    return {
      profile: state.profile,
      loggedInUser: token.token.payload,
    };
  }
);

export const clinicSpecialtyListView = createSelector(
  [mainSelector],
  (state) => {
    return {
      list: Object.keys(state.clinicSpecialtyByID)
        .map((sescID) => state.clinicSpecialtyByID[sescID]!)
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          if (a.specialtyOrder > b.specialtyOrder) {
            return 1;
          }
          if (a.specialtyOrder < b.specialtyOrder) {
            return -1;
          }
          return a.name > b.name ? 1 : -1;
        }),
    };
  }
);

export const specialtyListView = createSelector([mainSelector], (state) => {
  return {
    list: Object.keys(state.specialtyByID)
      .map((sescID) => state.specialtyByID[sescID]!)
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return a.name > b.name ? 1 : -1;
      }),
  };
});

export const clinicBranchListView = createSelector(
  [clinicBranchRepository],
  (clinicBranchByID) => {
    return {
      list: Object.keys(clinicBranchByID)
        .map((clbrID) => clinicBranchByID[clbrID]!)
        .filter((cb) => !cb?.deletedAt)
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
    };
  }
);

export const getClinicBranch = (props: { clbrID: string }) =>
  createSelector([clinicBranchRepository], (clinicBranchByID) => {
    return {
      branch: clinicBranchByID[props.clbrID],
    };
  });

export const getClinicBranchByClinID = (props: { clinID: string }) =>
  createSelector(
    [clinicBranchByClinIDRepository, clinicBranchRepository],
    (branchesByClinID, clinicBranchByID) => {
      return {
        branches: (branchesByClinID[props.clinID] ?? [])
          .map((clbrID) => clinicBranchByID[clbrID])
          .filter((b) => !!b)
          .sort((a, b) =>
            moment(b?.createdAt).diff(moment(a?.createdAt))
          ) as ClinicBranch[],
      };
    }
  );

export const listClinics = createSelector(
  [clinicRepository, listClinicsRepository, profileRepository],
  (clinicByID, listClinic, profile) => {
    return {
      list: listClinic
        .filter((clinID) => clinID !== profile?.clinID)
        .map((clinID) => clinicByID[clinID]!),
    };
  }
);

export const getClinic = (props: { clinID: string }) =>
  createSelector([clinicRepository], (clinicByID) => {
    return {
      clinic: clinicByID[props.clinID],
    };
  });

export const healthplanListView = createSelector(
  [plansSelector, clinicPlansSelector],
  (healthplanByID, clinicHealthplans) => {
    const healthplans = Object.keys(healthplanByID)
      .map((heplID) => healthplanByID[heplID]!)
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    const clinicplans = clinicHealthplans
      .map((heplID) => healthplanByID[heplID]!)
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    return {
      listAll: healthplans,
      filtredList: clinicplans,
    };
  }
);

export const healthplanView = (props: { heplID: string }) =>
  createSelector(plansSelector, (healthplanByID) => {
    return {
      healthplan: healthplanByID[props.heplID],
    };
  });

export const getHealthplanByID = createSelector(
  [plansSelector],
  (healthplanByID) => {
    return {
      healthplanByID,
    };
  }
);

export const getOneSpecialty = (props: { specID: string }) =>
  createSelector(specialtyRepository, (specialtyByID) => {
    return {
      specialty: specialtyByID[props.specID],
    };
  });

export const getValidSubscription = createSelector(
  [subscriptionSelector],
  (subscription) => {
    let sub: ActiveSubscription | undefined;
    if (!!subscription && !(subscription as any)?.error) {
      sub = subscription as ActiveSubscription;
    }
    return {
      subscription: sub,
    };
  }
);

// Actions
export function loadClinicProfile(slug: string): AppThunk<Promise<Clinic>> {
  return async (dispatch, getState) => {
    return fetchClinicProfile(slug)
      .then((r) => {
        dispatch(actions.profileLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateClinicProfile(c: Clinic): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return putClinicProfile(apiToken, c)
      .then((r) => {
        dispatch(actions.profileLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Atualizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function uploadFile(
  f: File,
  saveTo?: "s3" | "local",
  cb?: (e: AxiosProgressEvent) => void
): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return uploadOneFile(apiToken, f, saveTo, cb)
      .then((r) => {
        return r.filename;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadSpecialties(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return fetchSpecialties()
      .then((r) => {
        dispatch(actions.specialtiesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadClinicSpecialties(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicSpecialties(apiToken)
      .then((r) => {
        dispatch(actions.clinicSpecialtiesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function removeClinicSpecialty(specID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteClinicSpecialty(apiToken, specID)
      .then((r) => {
        dispatch(actions.clinicSpecialtyRemoved(String(r.specID)));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function addClinicSpecialty(
  spec: CreateClinicSpecialtyRequest
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createClinicSpecialty(apiToken, spec)
      .then((r) => {
        dispatch(actions.clinicSpecialtiesLoaded([r]));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadHealthplans(f?: FilterHealthPlan): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchHealthplans(f)
      .then((r) => {
        dispatch(actions.healthplansLoaded(r, f?.clinID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadHealthplan(heplID: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchHealthplan(heplID)
      .then((r) => {
        dispatch(actions.healthplanLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedHealthplan(heplID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const healthplanExist = Boolean(state.clinic.healthplanByID[heplID]);
    if (healthplanExist) {
      return Promise.resolve();
    }
    return dispatch(loadHealthplan(heplID));
  };
}

export function loadActiveSubscription(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCurrentSubscription(apiToken)
      .then((r) => {
        // don't allow reducer errors to affect proper subscription identification
        try {
          dispatch(actions.activeSubscriptionLoaded(r));
        } catch (e) {
          dispatch(
            newNotification("general", {
              status: "error",
              message: (e as any).message,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          actions.activeSubscriptionLoaded({
            status: ActiveSubscriptionStatus.invalid,
            error: "Network Error",
          })
        );
      });
  };
}

export function fetchCachedActiveSubscription(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const sub = state.clinic.currentActiveSubscription;
    const subscriptionExist = !!sub && !(sub as any)?.error;
    if (subscriptionExist) {
      return Promise.resolve();
    }
    return dispatch(loadActiveSubscription());
  };
}

export function loadClinicBranches(
  f?: ClinicBranchFilter
): AppThunk<Promise<ClinicBranch[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicBranches(apiToken, f)
      .then((r) => {
        dispatch(actions.clinicBranchesLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function addClinicBranch(clinID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createClinicBranches(apiToken, clinID)
      .then((r) => {
        dispatch(actions.clinicBranchLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateClinicBranch(data: {
  clbrID: string;
  deniedAt?: string;
  aceptedAt?: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateClinicBranches(apiToken, data)
      .then((r) => {
        dispatch(actions.clinicBranchLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeClinicBranch(clbrID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteClinicBranches(apiToken, clbrID)
      .then((r) => {
        dispatch(actions.clinicBranchRemoved(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function getOneClinicBranch(clbrID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicBranch(apiToken, clbrID)
      .then((r) => {
        dispatch(actions.clinicBranchLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedClinicBranch(
  clbrID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const branchExist = !!state.clinic.clinicBranchByID[clbrID];
    if (branchExist) {
      return Promise.resolve();
    }
    return dispatch(getOneClinicBranch(clbrID));
  };
}

export function loadOneClinic(clinID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchclinic(apiToken, clinID)
      .then((r) => {
        dispatch(actions.loadClinic(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedClinic(clinID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const clinicExist = !!state.clinic.clinByID[clinID];
    if (clinicExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneClinic(clinID));
  };
}

export function loadAllClinics(f?: ClinicFilter): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchClinics(apiToken, f)
      .then((r) => {
        dispatch(actions.loadClinics(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchOneSpecialty(specID: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchSpecialty(specID)
      .then((r) => {
        dispatch(actions.loadSpecialty(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedSpecialty(specID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const specialtyExist = state.clinic.specialtyByID[specID];
    if (!!specialtyExist) {
      return Promise.resolve();
    }
    return dispatch(fetchOneSpecialty(specID));
  };
}
