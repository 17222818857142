import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={23}
      height={24}
      viewBox="0 0 23 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.984 19h-3.5a.5.5 0 00-.5.5V23h-11V1h15v4h1V1c0-.552-.448-1-1-1h-15a1 1 0 00-1 1v22a1 1 0 001 1h13c.133 0 .26-.053.354-.146l2.5-2.5a.499.499 0 00.146-.354v-3h-1v1zm0 1.793L14.777 23h-.793v-3h3v.793z" />
      <path d="M14.984 6V4.5a.5.5 0 00-.5-.5h-10a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h6.5v-1h-6V5h9v1h1zM3.984 13h5v1h-5v-1zM9.984 13h1v1h-1v-1zM3.984 16h3v1h-3v-1zM7.984 16h5v1h-5v-1zM3.984 19h5v1h-5v-1zM9.984 19h2v1h-2v-1z" />
      <path d="M17.484 6a5.506 5.506 0 00-5.5 5.5c0 3.033 2.468 5.5 5.5 5.5 3.033 0 5.5-2.467 5.5-5.5 0-3.032-2.467-5.5-5.5-5.5zm-2.5 9.239V15c0-.827.673-1.5 1.5-1.5h2c.827 0 1.5.673 1.5 1.5v.239c-.715.48-1.575.76-2.5.76-.924 0-1.784-.28-2.5-.76zm2.5-3.239c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm3.423 2.414a2.498 2.498 0 00-1.991-1.87 2.494 2.494 0 001.068-2.044c0-1.378-1.121-2.5-2.5-2.5a2.503 2.503 0 00-2.5 2.5c0 .846.424 1.591 1.069 2.043-.98.173-1.76.913-1.992 1.87a4.478 4.478 0 01-1.077-2.913c0-2.482 2.019-4.5 4.5-4.5 2.482 0 4.5 2.018 4.5 4.5a4.478 4.478 0 01-1.077 2.914z" />
    </svg>
  );
}

export default SvgComponent;
