import React from "react";
import { Link, useParams } from "react-router-dom";

import SigninFormContainer from "containers/Auth/SigninFormContainer";
import { Typography, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import Notification from "containers/Notification";
import AuthCard from "containers/Clinic/CardLogo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: blueGrey[100],
    },
  })
);

const AuthNotification: any = Notification("auth");

const SigninContainer = (props: { onLogin?: (r: any) => any }) => {
  const { slug } = useParams<{ slug: string }>();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AuthNotification />
      <AuthCard title="Entre na sua conta">
        <>
          <SigninFormContainer onLogin={props.onLogin} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="secondary"
              component={Link}
              to={`/${slug}/admin/token_login`}
              fullWidth
            >
              Login sem senha
            </Button>
          </div>
          <div style={{ textAlign: "center", marginTop: 24 }}>
            <Typography variant="caption">
              <span>Esqueceu sua senha, &nbsp;</span>
              <Link
                to={`/${slug}/password_reset`}
                style={{ textDecoration: "underline #cecece" }}
              >
                <Typography color="primary" variant="caption">
                  clique aqui
                </Typography>
              </Link>
            </Typography>
          </div>
        </>
      </AuthCard>
    </div>
  );
};

export default SigninContainer;
