import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M43.367 21.005c-1.026 0-2.152.675-4.546 1.917-1.983 1.03-3.817 2.009-4.982 2.723C33.94 26.083 34 26.537 34 27.005c0 3.309-2.692 6-6 6h-7.5c-.828 0-1.5-.671-1.5-1.5 0-.829.672-1.5 1.5-1.5H28c1.654 0 3-1.346 3-3 0-.897-.404-1.694-1.03-2.245-.711-.47-1.555-.755-2.47-.755h-4.393H22.5c-.029 0-.056-.007-.085-.009-2.636-.041-3.902-.313-5.015-.558-1.012-.223-1.968-.433-3.573-.433-7.332 0-11.518 9.001-11.692 9.384-.226.496-.165 1.074.158 1.512l7 9.494C9.578 43.782 10.027 44 10.497 44c.095 0 .189-.009.284-.027.566-.108 1.024-.536 1.171-1.093.079-.295.843-2.875 3.548-2.875 1.952 0 3.971.252 5.924.495 1.989.249 4.047.505 6.076.505 2.763 0 4.752-2.119 10.5-8 7.302-7.471 8-8.124 8-9.605C46 22.118 44.875 21.005 43.367 21.005M18 7h7v10h-7c-2.76 0-5-2.24-5-5S15.24 7 18 7zM40 12c0 2.76-2.24 5-5 5h-7V7h7C37.76 7 40 9.24 40 12z" />
    </svg>
  );
}

export default SvgComponent;
