import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  getClientsStatus,
  userStatusView,
  getMessages,
  fetchMessages,
  fetchActivities,
  getConversationsView,
  fetchActivitiesGroup,
  getActivitiesGroupByMegrID,
} from "ducks/socket";
import {
  loadUsers,
  userListView,
  avatarByUserIDViewCreator,
  fetchCachedProfileForUserID,
} from "ducks/user";
import { clinicDoctorList, loadClinicDoctors } from "ducks/doctor";
import { ChatActivity } from "@udok/lib/api/models";

export const useGetClientsStatus = (userID: string) => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    const t = setInterval(() => {
      dispatch(getClientsStatus([userID]));
    }, 30000);
    dispatch(fetchCachedProfileForUserID(userID));
    return () => {
      clearInterval(t);
    };
  }, [userID, dispatch]);

  const getStatus = React.useCallback(userStatusView({ userID }), [userID]);
  const getAvatar = React.useCallback(avatarByUserIDViewCreator({ userID }), [
    userID,
  ]);

  const { status } = useSelector(getStatus);
  const { src, name } = useSelector(getAvatar);

  const memoStatus = React.useMemo(() => status, [status]);
  const memoSrc = React.useMemo(() => src, [src]);
  const memoName = React.useMemo(() => name, [name]);

  return [memoStatus, memoSrc, memoName] as [
    typeof memoStatus,
    typeof memoSrc,
    typeof memoName
  ];
};

export const useListMessages = (userID: string, megrID?: string) => {
  const dispatch: AppDispatch = useDispatch();
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const loadMessages = React.useCallback(
    (count = 1) => {
      if (count > 100) {
        return;
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        dispatch(fetchMessages(userID, -1, megrID)).then((success: boolean) => {
          if (!success) {
            loadMessages(count + 1);
          }
        });
      }, 1000 * (count * 0.03));
    },
    [dispatch, userID, megrID]
  );

  React.useEffect(() => {
    dispatch(fetchMessages(userID, -1, megrID)).then((success: boolean) => {
      if (!success) {
        loadMessages(1);
      }
    });
  }, [dispatch, loadMessages, userID, megrID]);

  const messages = React.useCallback(getMessages({ userID }), [userID]);
  const { listMessages = [], unreadMessages } = useSelector(messages);

  return [listMessages, unreadMessages] as [
    typeof listMessages,
    typeof unreadMessages
  ];
};

export const useConversationsWithUsers = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadUsers()),
      dispatch(fetchActivities()),
      dispatch(loadClinicDoctors()),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const { listActivities, currentUser } = useSelector(getConversationsView);
  const { list: clinicUsers } = useSelector(userListView);
  const { list: clinicDoc } = useSelector(clinicDoctorList);
  const clu = clinicUsers
    .map((c) => {
      return {
        activeAt: "",
        messagePreview: "",
        unreadCount: 0,
        username: c.name,
        withUserID: c.userID,
        applID: c.applID,
      } as ChatActivity;
    })
    .filter((c) => c.withUserID !== currentUser?.userID);
  const cld = clinicDoc
    .filter((c) => !!c)
    .map((c) => {
      return {
        activeAt: "",
        messagePreview: "",
        unreadCount: 0,
        username: c?.doctName ?? "",
        withUserID: c?.userID ?? "",
        applID: "doctor",
      } as ChatActivity;
    });

  const list: typeof cld = [
    ...listActivities,
    ...[...cld, ...clu].filter(
      (c) =>
        listActivities.findIndex((ac) => ac.withUserID === c.withUserID) === -1
    ),
  ];

  return [loading, list] as [typeof loading, typeof list];
};

export const useGetActivitiesGroupByMegrID = (megrID?: string) => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchActivitiesGroup({ megrID }));
  }, [dispatch, megrID]);

  const getActivities = React.useCallback(
    getActivitiesGroupByMegrID({ megrID }),
    [megrID]
  );
  const { activeGroups } = useSelector(getActivities);

  return [activeGroups] as [typeof activeGroups];
};
