import React from "react";
import { connect } from "react-redux";
import { fileDisplayView, loadFile, loadFileData } from "ducks/files";

export interface ImageLoaderProps {
  children?: React.ReactNode;
  fileID: string;
  fetchMeta: (fileID: string) => Promise<void>;
  fetchBlob: (fileID: string) => Promise<void>;
  src?: Blob;
  filename?: string;
}

const ImageLoader = (props: ImageLoaderProps) => {
  const { fileID, fetchMeta, fetchBlob, src, filename, children } = props;
  const [loading, setL] = React.useState(false);
  const [failed, setF] = React.useState(false);
  const [dataurl, setD] = React.useState("");
  const reload = React.useCallback(() => {
    setL(true);
    if (fileID) {
      Promise.all([fetchMeta(fileID), fetchBlob(fileID)])
        .finally(() => {
          setL(false);
        })
        .then(() => setF(false))
        .catch(() => {
          setF(true);
        });
    }
  }, [fileID, fetchMeta, fetchBlob]);
  React.useEffect(() => {
    reload();
  }, [fileID, reload]);
  React.useEffect(() => {
    if (!src) {
      return;
    }
    var a = new FileReader();
    a.onload = function (e) {
      setD(String(e?.target?.result ?? ""));
    };
    a.readAsDataURL(src);
  }, [src, setD]);

  if (typeof children === "function") {
    return children({ src: dataurl, filename, loading, failed });
  }

  return (
    <>
      {React.cloneElement(children as any, {
        src: dataurl,
        filename,
        loading,
        failed,
      })}
    </>
  );
};

export default connect(fileDisplayView, (dispatch: any) => ({
  fetchBlob: (fileID: string) => dispatch(loadFile(fileID)),
  fetchMeta: (fileID: string) => dispatch(loadFileData(fileID)),
}))(ImageLoader);
