import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M256 512c-68.38 0-132.668-26.628-181.02-74.98S0 324.38 0 256 26.629 123.333 74.98 74.98 187.62 0 256 0s132.668 26.628 181.02 74.98S512 187.62 512 256s-26.629 132.667-74.98 181.02S324.38 512 256 512zm0-480C132.486 32 32 132.486 32 256s100.486 224 224 224 224-100.486 224-224S379.514 32 256 32z" />
      <path d="M256 240c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40c0 8.836 7.163 16 16 16s16-7.164 16-16c0-34.201-23.978-62.888-56-70.186V112c0-8.836-7.163-16-16-16s-16 7.164-16 16v17.814c-32.022 7.298-56 35.985-56 70.186 0 39.701 32.299 72 72 72 22.056 0 40 17.944 40 40s-17.944 40-40 40-40-17.944-40-40c0-8.836-7.163-16-16-16s-16 7.164-16 16c0 34.201 23.978 62.888 56 70.186V400c0 8.836 7.163 16 16 16s16-7.164 16-16v-17.814c32.022-7.298 56-35.985 56-70.186 0-39.701-32.299-72-72-72z" />
    </svg>
  );
}

export default SvgComponent;
