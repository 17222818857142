import React from "react";
import { connect } from "react-redux";
import { AppDispatch } from "ducks/state";
import { getUserProfile } from "ducks/user";
import {
  Button,
  Grid,
  Avatar,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  useTheme,
} from "@material-ui/core/styles";
import ProfileButton from "components/ProfileButton";
import { useParams } from "react-router-dom";
import Icons from "components/Icon";

const styles = (theme: Theme) =>
  createStyles({
    iconButtonAvatar: {
      padding: 4,
    },
  });

export type ProfileMenuProps = WithStyles<typeof styles> &
  ReturnType<typeof getUserProfile>;

const Menu = (p: ProfileMenuProps) => {
  const { currentUser } = p;
  const { slug } = useParams<{ slug: string }>();
  return (
    <>
      <Grid item>
        <ProfileButton
          profile={{ name: currentUser?.name ?? currentUser?.email ?? "u" }}
          urls={{ signout: `/${slug}/admin/signout` }}
        />
      </Grid>
    </>
  );
};

export default connect(getUserProfile, () => ({}))(withStyles(styles)(Menu));

const extendedStyles = (theme: Theme) =>
  createStyles({
    buttonAvatar: {
      color: theme.palette.neutral.main,
      "&:hover": {
        background: "inherit",
      },
    },
    iconButtonAvatar: {
      padding: 4,
      color: theme.palette.primary.light,
      // overwrite material ui button styles
      "& > :first-child": {
        fontSize: 32,
      },
      [theme.breakpoints.down("sm")]: {
        margin: " 0 10px",
      },
    },
    name: {
      marginLeft: theme.spacing(1),
    },
    layout: {
      display: "flex",
      flexDirection: "row",
      marginLeft: theme.spacing(3),
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.lightest,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  });

export type WideMenuProps = WithStyles<typeof extendedStyles> &
  ReturnType<typeof getUserProfile>;

const WideMenu = (p: WideMenuProps) => {
  const { slug } = useParams<{ slug: string }>();
  const { currentUser } = p;
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const avatar = currentUser?.avatar
    ? `${process.env.REACT_APP_BASE_PATH}/files/${currentUser?.avatar}`
    : "";

  return (
    <ProfileButton
      component={(p: any) => <StyledComponent small={isSmallerScreen} {...p} />}
      profile={{
        name: currentUser?.name ?? currentUser?.email ?? "u",
        avatar: avatar,
      }}
      urls={{ signout: `/${slug}/admin/signout` }}
    />
  );
};

const ResponsiveComponent = (
  props: {
    small?: boolean;
    src?: string;
    alt?: string;
    onClick?: (e: any) => void;
  } & WithStyles<typeof extendedStyles>
) => {
  const { small, classes, src, alt, onClick } = props;

  const iconUser = (
    <Avatar className={classes.avatar} alt={alt} src={src}>
      <Icons.User />
    </Avatar>
  );
  if (small) {
    return (
      <IconButton className={classes.iconButtonAvatar} onClick={onClick}>
        {iconUser}
      </IconButton>
    );
  }
  return (
    <Grid item className={classes.layout}>
      {iconUser}
      <Button
        disableRipple
        variant="text"
        endIcon={<Icons.ExpandMore fontSize="large" />}
        classes={{ endIcon: classes.iconButtonAvatar }}
        className={classes.buttonAvatar}
        onClick={onClick}
      >
        <span className={classes.name}>{alt}</span>
      </Button>
    </Grid>
  );
};

const StyledComponent = withStyles(extendedStyles)(ResponsiveComponent);

export const ExtendedMenu = connect(
  getUserProfile,
  (dispatch: AppDispatch) => ({})
)(withStyles(extendedStyles)(WideMenu));
