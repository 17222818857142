import React from "react";

export default function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M22.5 16H20.3L22 12H17V18H19V23L22.5 16M15 18H13.9L10.2 21.7C10 21.9 9.8 22 9.5 22H9C8.4 22 8 21.6 8 21V18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V10H15V18Z"
      />
    </svg>
  );
}
