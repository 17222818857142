import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <title>{"image2VectorSplit-svg"}</title>
      <style />
      <path
        fillRule="evenodd"
        d="M285.41 5.51c14.24 5.71 15.32 6.65 73.01 64.38l50.51 50.54-10.15.33c-17.27.57-28.07 3.92-41.75 12.96-4.64 3.07-10.93 9.1-47.42 45.46-45.78 45.62-43.94 44.02-50.6 44.02-6.66 0-4.78 1.64-51.17-44.56-44.96-44.79-45.4-45.18-56-50.52-11.22-5.66-20.33-7.52-36.74-7.52-6.37 0-11.58-.2-11.58-.44 0-1.61 103.58-103.69 108.54-106.97 10.15-6.7 18.17-9.94 30.46-12.31 11.77-2.28 30.74-.22 42.89 4.63zM81.31 141.92l19.77.14c41.89.3 36.26-2.94 92.83 53.33 24.9 24.76 44.13 43.44 45.78 44.47 8.89 5.55 19.88 7.02 29.84 3.98 8.66-2.63 7.28-1.41 52.87-46.8 56.8-56.54 52.15-53.62 86.42-54.24 10.78-.2 20-.55 20.47-.77 1.51-.73 64.83 63.28 69.3 70.05 17.89 27.06 17.89 60.59 0 87.65-4.47 6.77-67.79 70.78-69.3 70.06-.47-.23-9.69-.58-20.47-.77-34.26-.63-29.67 2.25-86.14-53.97-38.76-38.6-42.39-42.06-46.16-44-11.01-5.65-23.09-5.76-34.28-.3-4.83 2.36-4.88 2.41-48.04 45.36-47.93 47.7-46.53 46.48-58.13 50.41-7.91 2.69-11.6 3.02-34.47 3.13l-20.4.1-30.94-30.91c-31.34-31.31-34.82-35.11-39.93-43.62-12.97-21.58-13.81-51.91-2.07-75.07 4.78-9.43 7.3-12.26 40.75-45.83l32.3-32.4zm228.01 190.41c36.75 36.63 43.07 42.69 47.71 45.76 13.67 9.04 24.48 12.39 41.75 12.96l10.15.33-50.51 50.55c-27.78 27.79-52.31 51.92-54.5 53.61-26.85 20.7-63.31 21.93-91.86 3.08-4.96-3.27-108.54-105.35-108.54-106.97 0-.24 5.18-.44 11.51-.44 16.41 0 25.64-1.88 36.81-7.51 10.6-5.34 11.02-5.72 56.29-50.82 40.04-39.9 43.11-42.83 45.76-43.63 9.94-3.01 6.55-5.64 55.43 43.08z"
      />
    </svg>
  );
}

export default SvgComponent;
