import { RootState, AppThunk } from "ducks/state";
import { profileRepository } from "ducks/clinic";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  Tuss,
  ExamProcedure,
  FilterTussList,
  ExamProcedureForm,
  ExamProcedureFilter,
  ClinicExamProcedureDoctor,
} from "@udok/lib/api/models";
import {
  fetchAllTuss,
  searchProcedures,
  fetchProcedure,
  createProcedures,
  updateProcedures,
  deleteProcedures,
  createClinicExamsProcedures,
  updateClinicExamsProcedures,
  getClinicExamsProcedures,
  listClinicExamsProcedures,
  deleteClinicExamsProcedures,
} from "@udok/lib/api/procedure";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  tussSearch: string[];
  tussByCode: { [tussCode: string]: Tuss };
  proceduresByID: { [exprID: string]: ExamProcedure | undefined };
  filteredProcedures: string[];
  examProcedureDoctorByID: {
    [exprID: string]: ClinicExamProcedureDoctor | undefined;
  };
};

// Reducers
const initialState: InitialState = {
  tussSearch: [],
  tussByCode: {},
  proceduresByID: {},
  filteredProcedures: [],
  examProcedureDoctorByID: {},
};

class Procedures extends Hen<InitialState> {
  listProceduresLoaded(list: ExamProcedure[]) {
    this.state.filteredProcedures = list.map((p) => {
      this.state.proceduresByID[p.exprID] = p;
      return p.exprID;
    });
  }
  procedureRemoved(pr: ExamProcedure) {
    delete this.state.proceduresByID[pr.exprID];
  }
  procedureLoaded(pr: ExamProcedure) {
    this.state.proceduresByID[pr.exprID] = pr;
  }
  procedureDoctorLoaded(epd: ClinicExamProcedureDoctor) {
    this.state.examProcedureDoctorByID[epd.exprID] = epd;
  }
  removeDoctorFromExamProcedure(doctID: string) {
    Object.keys(this.state.examProcedureDoctorByID).forEach((exprID) => {
      const exams = this.state.examProcedureDoctorByID[exprID];
      if (!!exams) {
        let doctors = [...exams.doctors];
        const index = doctors.indexOf(doctID);
        if (index !== -1) {
          if (doctors.length === 1) {
            delete this.state.examProcedureDoctorByID[exprID];
            return;
          }
          doctors = doctors.splice(index, 1);
          this.state.examProcedureDoctorByID[exprID] = {
            ...exams,
            doctors,
          };
        }
      }
    });
  }
  procedureDoctorsLoaded(epds: ClinicExamProcedureDoctor[]) {
    epds.forEach((epd) => {
      this.state.examProcedureDoctorByID[epd.exprID] = epd;
    });
  }
  procedureDoctorsRemoved(epd: ClinicExamProcedureDoctor) {
    delete this.state.examProcedureDoctorByID[epd.exprID];
  }
  listTussLoaded(list: Tuss[]) {
    this.state.tussSearch = list.map((t) => {
      this.state.tussByCode[t.tussCode] = t;
      return t.tussCode;
    });
  }
  tussLoaded(tuss: Tuss) {
    this.state.tussByCode[tuss.tussCode] = tuss;
  }
}

export const [Reducer, actions] = hen(new Procedures(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
export const proceduresSelector = (state: RootState) =>
  state.procedure.proceduresByID;
const filteredProceduresSelector = (state: RootState) =>
  state.procedure.filteredProcedures;
const examProcedureDoctorByIDSelector = (state: RootState) =>
  state.procedure.examProcedureDoctorByID;
const tussSearchSelector = (state: RootState) => state.procedure.tussSearch;
const tussByCodeSelector = (state: RootState) => state.procedure.tussByCode;

export const searchProceduresListView = createSelector(
  [proceduresSelector, filteredProceduresSelector, profileRepository],
  (proceduresByID, filtered, profile) => {
    const allProcedures = Object.keys(proceduresByID)
      .map((id) => proceduresByID[id])
      .filter(
        (b) =>
          !!b && !b?.deletedAt && (!b?.clinID || b?.clinID === profile?.clinID)
      ) as ExamProcedure[];
    const filteredProcedures = filtered
      .map((id) => proceduresByID[id])
      .filter(
        (b) =>
          !!b && !b?.deletedAt && (!b?.clinID || b?.clinID === profile?.clinID)
      ) as ExamProcedure[];
    return {
      allProcedures,
      filteredProcedures,
    };
  }
);

export type ExamProcedureListView = {
  doctors: string[];
} & ExamProcedure;

export const examProcedureListView = createSelector(
  [proceduresSelector, examProcedureDoctorByIDSelector, profileRepository],
  (proceduresByID, epdByID, profile) => {
    let list: ExamProcedureListView[] = [];

    Object.keys(proceduresByID).forEach((exprID) => {
      const procedure = proceduresByID[exprID];
      const doc = epdByID[exprID];
      const isClinic =
        procedure?.clinID && procedure?.clinID === profile?.clinID;
      if (!procedure || !!procedure?.deletedAt || (!isClinic && !doc)) {
        return;
      }
      list = [
        ...list,
        {
          ...procedure,
          doctors: doc?.doctors ?? [],
        },
      ];
    });

    return { list };
  }
);

export const searchTussListView = createSelector(
  [tussByCodeSelector, tussSearchSelector],
  (tussByCode, filtered) => {
    const allTuss = Object.keys(tussByCode)
      .map((id) => tussByCode[id])
      .filter((b) => !!b) as Tuss[];
    const filteredTuss = filtered
      .map((id) => tussByCode[id])
      .filter((b) => !!b) as Tuss[];
    return {
      allTuss,
      filteredTuss,
    };
  }
);

export const getOneProcedureView = (
  state: RootState,
  props: { exprID: string }
) =>
  createSelector([proceduresSelector], (proceduresByID) => {
    return {
      procedure: proceduresByID[props.exprID],
    };
  });

export const getOneProcedure = (props: { exprID: string }) =>
  createSelector([proceduresSelector], (proceduresByID) => {
    return {
      procedure: proceduresByID[props.exprID],
    };
  });

export const getProceduresByID = createSelector(
  [proceduresSelector],
  (proceduresByID) => {
    return {
      proceduresByID,
    };
  }
);

export const listExamProcedureDoctor = createSelector(
  [examProcedureDoctorByIDSelector],
  (epd) => {
    return {
      list: Object.keys(epd)
        .map((exprID) => epd[exprID])
        .filter((epd) => !!epd) as ClinicExamProcedureDoctor[],
    };
  }
);

export const getExamProcedureDoctor = (props: { exprID: string }) =>
  createSelector([examProcedureDoctorByIDSelector], (epd) => {
    return {
      examProcedureDoctor: epd[props.exprID],
    };
  });

export const listClinicExamProcedure = createSelector(
  [examProcedureDoctorByIDSelector, proceduresSelector],
  (epd, proceduresByID) => {
    return {
      list: Object.keys(epd)
        .map((exprID) => proceduresByID[exprID])
        .filter((epd) => !!epd) as ExamProcedure[],
    };
  }
);

// Actions
export function searchListProcedures(
  f?: ExamProcedureFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return searchProcedures(f, apiToken)
      .then((r) => {
        dispatch(actions.listProceduresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function searchScheduleProcedures(
  f?: ExamProcedureFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const clinID = t.token.payload.clinID;

    const fil: ExamProcedureFilter = {
      limit: 100,
      clinID,
      ...f,
    };
    return searchProcedures(fil, apiToken)
      .then((r) => {
        dispatch(actions.listProceduresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function searchMyProcedures(
  f?: ExamProcedureFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const createdByClinID = t.token.payload.clinID;
    const fil: ExamProcedureFilter = {
      createdByClinID,
      ...f,
    };
    return searchProcedures(fil, apiToken)
      .then((r) => {
        dispatch(actions.listProceduresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadProcedure(exprID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return fetchProcedure(exprID)
      .then((r) => {
        dispatch(actions.procedureLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedProcedure(exprID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const p = state.procedure.proceduresByID;
    if (Boolean(p[exprID])) {
      return Promise.resolve();
    }

    return dispatch(loadProcedure(exprID));
  };
}

export function createOneClinicExamsProcedures(data: {
  exprID: string;
  doctors: string[];
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createClinicExamsProcedures(apiToken, data)
      .then((r) => {
        dispatch(actions.procedureDoctorLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneClinicExamsProcedures(data: {
  exprID: string;
  doctors: string[];
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateClinicExamsProcedures(apiToken, data)
      .then((r) => {
        dispatch(actions.procedureDoctorLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getOneClinicExamsProcedures(
  exprID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return getClinicExamsProcedures(apiToken, exprID)
      .then((r) => {
        dispatch(actions.procedureDoctorLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedClinicExamsProcedures(
  exprID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const p = state.procedure.examProcedureDoctorByID;
    if (Boolean(p[exprID])) {
      return Promise.resolve();
    }

    return dispatch(getOneClinicExamsProcedures(exprID));
  };
}

export function fetchClinicExamsProcedures(): AppThunk<
  Promise<ClinicExamProcedureDoctor[]>
> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return listClinicExamsProcedures(apiToken)
      .then((r) => {
        dispatch(actions.procedureDoctorsLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeClinicExamsProcedures(
  exprID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteClinicExamsProcedures(apiToken, exprID)
      .then((r) => {
        dispatch(actions.procedureDoctorsRemoved(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createExamsProcedure(
  data: ExamProcedureForm
): AppThunk<Promise<ExamProcedure>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createProcedures(apiToken, data)
      .then((r) => {
        dispatch(actions.procedureLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateExamsProcedure(
  exprID: string,
  data: ExamProcedureForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateProcedures(apiToken, exprID, data)
      .then((r) => {
        dispatch(actions.procedureLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeExamsProcedure(exprID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteProcedures(apiToken, exprID)
      .then((r) => {
        dispatch(actions.procedureRemoved(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function searchTuss(filter: FilterTussList): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAllTuss(apiToken, filter)
      .then((r) => {
        dispatch(actions.listTussLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadTuss(tussCode: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAllTuss(apiToken, { tussCode })
      .then((r) => {
        dispatch(actions.tussLoaded(r[0]));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedTuss(tussCode: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const p = state.procedure.tussByCode[tussCode];
    if (Boolean(p)) {
      return Promise.resolve();
    }

    return dispatch(loadTuss(tussCode));
  };
}
