import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M1.805 1.601C.804 1.601 0 2.447 0 3.447v11.075c0 1 .804 1.846 1.805 1.846h12.39c1.001 0 1.805-.845 1.805-1.846V3.447c0-1.001-.804-1.846-1.805-1.846H1.805zm0 1.23h12.39c.311 0 .574.258.574.616v11.075c0 .358-.261.615-.574.615H1.805c-.313 0-.574-.257-.574-.615V3.447c0-.358.261-.615.574-.615zm1.271 1.231a.615.615 0 00-.615.616v1.23a.615.615 0 00.615.615h9.845a.615.615 0 00.615-.615v-1.23a.615.615 0 00-.615-.616H3.076zm0 3.692a.615.615 0 00-.615.615v4.923a.615.615 0 00.615.615h3.692a.615.615 0 00.616-.615V8.369a.615.615 0 00-.616-.615H3.076zm6.153 0a.615.615 0 00-.615.615v4.923a.615.615 0 00.615.615h3.692a.615.615 0 00.615-.615V8.369a.615.615 0 00-.615-.615H9.23zm-5.537 1.23h2.46v3.692h-2.46V8.985zm6.153 0h2.46v3.692h-2.46V8.985z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .601)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
