import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.166 1.333c-.827 0-1.5.673-1.5 1.5v10.334c0 .827.673 1.5 1.5 1.5h6.833v-.017a3.323 3.323 0 01-2.043-.983h-4.79a.5.5 0 01-.5-.5V2.833a.5.5 0 01.5-.5h3.833v2.834c0 .827.673 1.5 1.5 1.5h2.834v1.487c.365.115.7.294 1 .52V6.166a.498.498 0 00-.147-.354L8.853 1.48a.499.499 0 00-.354-.147H4.166zM8.999 3.04l2.627 2.627H9.499a.5.5 0 01-.5-.5V3.04zm2.334 5.627a2.67 2.67 0 00-2.667 2.666A2.67 2.67 0 0011.333 14c.555 0 1.07-.171 1.497-.462l1.65 1.649a.497.497 0 00.706 0 .5.5 0 000-.707l-1.648-1.65a2.65 2.65 0 00.461-1.497 2.67 2.67 0 00-2.666-2.666zm0 1c.919 0 1.666.747 1.666 1.666 0 .92-.747 1.667-1.666 1.667a1.669 1.669 0 01-1.667-1.667c0-.919.748-1.666 1.667-1.666z" />
    </svg>
  );
}

export default SvgComponent;
