import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M12.113 1.601a2.482 2.482 0 00-2.474 2.474 2.482 2.482 0 002.474 2.473 2.482 2.482 0 002.473-2.473A2.482 2.482 0 0012.113 1.6zm0 1.06c.787 0 1.414.627 1.414 1.414 0 .787-.627 1.413-1.414 1.413A1.405 1.405 0 0110.7 4.075c0-.787.626-1.414 1.413-1.414zm-9.824.737a.883.883 0 00-.754.424C.22 4.172-.471 5.806.364 7l3.161 4.516v.363a4.602 4.602 0 004.594 4.594c.762 0 1.48-.19 2.113-.52.558.31 1.2.49 1.881.49A3.895 3.895 0 0016 12.554v-3.98c0-.723-.598-1.32-1.32-1.32H9.546c-.723 0-1.32.598-1.32 1.32v3.98c0 1.028.405 1.962 1.06 2.658a3.526 3.526 0 01-4.7-3.334v-.353l-.001-.018L7.553 6.94c.718-1.105.178-2.537-.925-3.02a.883.883 0 10-.249 1.045c.448.279.619.883.285 1.397L4.04 10.401l-2.806-4.01c-.399-.568-.154-1.271.416-1.501a.883.883 0 10.64-1.492zm7.257 4.917h5.134c.15 0 .26.11.26.26v3.98a2.819 2.819 0 01-2.827 2.827 2.84 2.84 0 01-.878-.14 4.577 4.577 0 001.478-3.363v-1.238a.883.883 0 00-1.046-1.422.884.884 0 00-.014 1.423v1.237a3.52 3.52 0 01-1.395 2.811 2.816 2.816 0 01-.972-2.135v-3.98c0-.15.11-.26.26-.26z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .601)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
