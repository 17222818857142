import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Chip, IconButton, TextField } from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import Fieldset, {
  CustomFieldsetProps,
} from "@udok/lib/components/CustomFieldset";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
    },
    ul: {
      flex: 1,
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      margin: 0,
      padding: theme.spacing(0.5),
      "& > *": {
        marginBottom: theme.spacing(0.5),
      },
      "& > *:nth-child(n+2)": {
        marginLeft: theme.spacing(0.7),
      },
    },
    chip: {
      maxWidth: 200,
    },
    inputContainer: {
      display: "flex",
      flex: 1,
      position: "relative",
    },
    buttonContainer: {
      display: "flex",
      flex: 0,
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: "calc(22.8px / 2 * -1)",
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-end",
      },
    },
    input: {
      minWidth: 150,
      zIndex: 1,
      height: "100%",
      "&::before": {
        display: "none",
      },
    },
    placeholderChip: {
      border: "1px dashed rgba(0,0,0,0.26)",
      color: "rgba(0,0,0,0.54)",
      background: theme.palette.neutral.lightest,
      position: "absolute",
      bottom: 0,
      height: 32,
      zIndex: 0,
      fontSize: 14,
    },
  })
);

export type TextListInputProps = {
  placeholder?: React.ReactNode;
  value?: string[];
  onChange?: (value: string[]) => void;
} & CustomFieldsetProps;

const TextListInput = React.forwardRef(
  (props: TextListInputProps, ref: React.Ref<any>) => {
    const {
      value = [],
      onChange,
      placeholder = "Adicionar",
      className,
      ...others
    } = props;
    const [inputValue, setInputValue] = React.useState("");
    const classes = useStyles();

    const handleInputChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setInputValue(val);
      },
      []
    );

    const handleAdd = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (inputValue.trim().length > 0) {
          const newList = [...value, inputValue];
          onChange?.(newList);
          setInputValue("");
        }
      },
      [onChange, value, inputValue]
    );

    const handleRemove = React.useCallback(
      (index: number) => {
        const newList = [...value];
        newList.splice(index, 1);
        onChange?.(newList);
      },
      [onChange, value]
    );

    return (
      <Fieldset ref={ref} className={clsx(classes.root, className)} {...others}>
        <ul className={classes.ul}>
          {value.map((val, index) => (
            <li key={`${val} ${index}`}>
              <Chip
                label={val}
                onDelete={() => handleRemove(index)}
                className={classes.chip}
              />
            </li>
          ))}
          <li className={classes.inputContainer}>
            <TextField
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              InputProps={{ className: classes.input }}
            />
            {inputValue.length <= 0 ? (
              <Chip className={classes.placeholderChip} label={placeholder} />
            ) : null}
          </li>
        </ul>
        <div className={classes.buttonContainer}>
          <IconButton
            type="submit"
            color="primary"
            disabled={inputValue.trim().length <= 0}
            onClick={handleAdd}
          >
            <Icons.AddCircle />
          </IconButton>
        </div>
      </Fieldset>
    );
  }
);

export default TextListInput;
