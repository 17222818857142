import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M2 .601c-1.097 0-2 .903-2 2v1.333c0 1.097.903 2 2 2h3.556c1.096 0 2-.903 2-2V2.601c0-1.097-.904-2-2-2H2zm8.444 0c-1.096 0-2 .903-2 2V8.38c0 1.097.904 2 2 2H14c1.097 0 2-.903 2-2V2.6c0-1.097-.903-2-2-2h-3.556zM2 1.934h3.556c.376 0 .666.29.666.667v1.333c0 .377-.29.667-.666.667H2a.657.657 0 01-.667-.667V2.601c0-.376.29-.667.667-.667zm8.444 0H14c.376 0 .667.29.667.667V8.38c0 .376-.29.667-.667.667h-3.556a.657.657 0 01-.666-.667V2.6c0-.376.29-.667.666-.667zM2 6.824c-1.097 0-2 .903-2 2V14.6c0 1.097.903 2 2 2h3.556c1.096 0 2-.903 2-2V8.823c0-1.096-.904-2-2-2H2zm0 1.333h3.556c.376 0 .666.29.666.666v5.778c0 .376-.29.667-.666.667H2a.657.657 0 01-.667-.667V8.823c0-.376.29-.666.667-.666zm8.444 3.11c-1.096 0-2 .904-2 2v1.334c0 1.097.904 2 2 2H14c1.097 0 2-.903 2-2v-1.333c0-1.097-.903-2-2-2h-3.556zm0 1.334H14c.376 0 .667.29.667.667V14.6c0 .376-.29.667-.667.667h-3.556a.657.657 0 01-.666-.667v-1.333c0-.377.29-.667.666-.667z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .601)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
