import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={15}
      viewBox="0 0 16 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.465.975a.64.64 0 00-.441.184.608.608 0 00-.182.432v3.196c-1.119.122-2.959.435-4.81 1.604C1.927 7.719.058 10.159.015 14.189l-.004.008h.002c0 .044-.014.076-.014.121 0 .153.059.3.164.413a.644.644 0 00.843.082.615.615 0 00.244-.374c.5-2.437 2.058-3.517 3.785-4.07C6.494 9.9 7.921 9.9 8.842 9.947v3.138c0 .122.037.241.107.342.07.102.168.18.283.227a.647.647 0 00.688-.133l5.895-5.748a.608.608 0 000-.87L9.92 1.155a.633.633 0 00-.455-.18zm.64 2.103l4.37 4.26-4.37 4.262V9.344a.607.607 0 00-.164-.415.638.638 0 00-.408-.198c-.877-.08-2.885-.176-4.892.467a7.32 7.32 0 00-2.667 1.53c.64-1.525 1.624-2.596 2.744-3.303C6.56 6.263 8.676 5.98 9.52 5.92a.638.638 0 00.416-.195.607.607 0 00.17-.419V3.078z" />
    </svg>
  );
}

export default SvgComponent;
