import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage } from "@udok/lib/internal/util";
import { UserProfile, User, ContactPhone, EmailValidateStatus } from "./models";

export type LoginUsernameForm = {
  username: string;
  password: string;
  rememberMe?: boolean;
};
export type LoginForm = {
  email: string;
  password: string;
  rememberMe?: boolean;
  clinSlug?: string;
};
export type LoginResponse = {
  user: UserProfile;
  jwt: string;
};
export type OTPLoginResponse = {
  user: User;
  jwt: string;
  token: string;
};
export type PasswordRecoveryForm = {
  email: string;
  clinSlug?: string;
};
export type PasswordRecoveryResponde = {
  acveID: string;
  createdAt: string;
};
export type ValidateVerificationResponse = {
  isValid: boolean;
};

export const authenticate = async (f: LoginForm) => {
  return axios
    .post(`${process.env.REACT_APP_AUTH_PATH}/auth/signin`, {
      ...f,
      applID: process.env.REACT_APP_APPLICATION_ID,
    })
    .then((r) => {
      return r.data.data.item as LoginResponse;
    })
    .catch((e) => {
      const error = (e?.response?.data?.error || e)?.message;
      const isWrongUser = error.match(/No user with this e/);
      const isWrongAuth = error.match(/Wrong email\/password/);
      const errorMessage = isWrongUser
        ? "Usuário desativado ou não existente."
        : "";
      const errorMessage2 = isWrongAuth ? "Email ou senha errados." : "";
      const err =
        errorMessage ||
        errorMessage2 ||
        "Falha ao fazer login, Verifique o email e senha digitado.";
      return Promise.reject(err);
    });
};

export const authenticateWithUsername = async (f: LoginUsernameForm) => {
  return axios
    .post(`${process.env.REACT_APP_AUTH_PATH}/auth/signin/username`, {
      ...f,
      applID: process.env.REACT_APP_APPLICATION_ID,
    })
    .then((r) => {
      return r.data.data.item as LoginResponse;
    })
    .catch((e) => {
      const error = (e?.response?.data?.error || e)?.message;
      const isWrongUser = error.match(/No user with this e/);
      const isWrongAuth = error.match(/Wrong email\/password/);
      const errorMessage = isWrongUser
        ? "Usuário desativado ou não existente."
        : "";
      const errorMessage2 = isWrongAuth
        ? "Nome de usuário ou senha errados."
        : "";
      const err =
        errorMessage ||
        errorMessage2 ||
        "Falha ao fazer login, Verifique o nome de usuário e senha digitado.";
      return Promise.reject(err);
    });
};

export const requestPasswordRecover = async (f: PasswordRecoveryForm) => {
  return axios
    .post(`${process.env.REACT_APP_AUTH_PATH}/auth/password-recover`, {
      ...f,
      applID: process.env.REACT_APP_APPLICATION_ID,
      accssesModel: "link",
    })
    .then((r) => {
      return r.data.data.item as PasswordRecoveryResponde;
    })
    .catch((e) => {
      const error = (e?.response?.data?.error || e)?.message;
      const isWrongUser = error.match(/No user with this email/);
      const errorMessage = isWrongUser
        ? "Usuário desativado ou não existente."
        : "Falha no envio do email. Verifique o email informado.";
      return Promise.reject(errorMessage);
    });
};

export const validateVerification = async (
  apiToken: string,
  verification: string,
  resetID: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_AUTH_PATH}/auth/password-reset/${resetID}/validate`,
      { verification },
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as ValidateVerificationResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "rcvr2");
      return Promise.reject(err);
    });
};

export const resetToNewPassword = async (
  apiToken: string,
  resetID: string,
  password: string,
  verification: string,
  clinSlug?: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_AUTH_PATH}/auth/password-reset/${resetID}`,
      { password, verification, clinSlug },
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {})
    .catch((e) => {
      const error = (e?.response?.data?.error || e)?.message;
      const invalidPassword = error.match(/Password invalid!/);
      const errorMessage = invalidPassword
        ? "A senha digitada não possui os critérios especificados"
        : "Falha ao Redefinir a senha, Verifique se o link da página está correto";
      return Promise.reject(errorMessage);
    });
};

export type OnboardingDoctorForm = {
  userEmail: string;
  userPassword: string;
  userName: string;
  specIDs: number[];
  license: {
    heliID: string;
    country: string;
    documentNumber: string;
    documentType: string;
    region: string;
  };
  suofID?: string;
  rememberMe: boolean;
};
export type OnboardingResponse = {
  user: User;
  jwt?: string;
};
export const createNewDoctor = async (form: OnboardingDoctorForm) => {
  const { license, ...onboarding } = form;
  const onboardData = {
    name: onboarding.userName,
    email: onboarding.userEmail,
    password: onboarding.userPassword,
    specID: onboarding.specIDs,
    suofID: onboarding.suofID,
  };
  return axios
    .post(
      `${process.env.REACT_APP_AUTH_PATH}/onboarding/create-account-doctor`,
      onboardData
    )
    .then((r) => {
      return r.data.item as OnboardingResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "onbc1");
      return Promise.reject(err);
    });
};

export type VerificationForm = {
  doctID: string;
  heliID: string;
  country: string;
  documentNumber: string;
  documentType: string;
  region: string;
  phone?: string;
};
export type VerificationResponse = {
  doctID: string;
  info: {
    image: string;
    phone: string;
    licenses: [
      {
        heliID: string;
        country: string;
        region: string;
        documentType: string;
        documentNumber: string;
      }
    ];
  };
  verifiedAt: null;
};
export const setDoctorVerification = async (
  apiToken: string,
  form: VerificationForm
) => {
  const { doctID, phone, ...license } = form;
  const verification = {
    info: {
      licenses: [license],
      phone,
    },
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/doctors/${doctID}/verification`,
      verification,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.item as VerificationResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "onbc2");
      return Promise.reject(err);
    });
};

export type EmailTokenResponse = {
  acveID: string;
  createdAt: string;
  type: string;
  userID: string;
};
export const emailTokenRequest = async (email: string, clinSlug?: string) => {
  return axios
    .post(`${process.env.REACT_APP_AUTH_PATH}/auth/token-auth`, {
      email,
      applID: process.env.REACT_APP_APPLICATION_ID,
      clinSlug: clinSlug,
    })
    .then((r) => {
      return r.data.data.item as EmailTokenResponse;
    })
    .catch((e) => {
      const error = (e?.response?.data?.error || e)?.message;
      const isWrongUser = error.match(/No user with this e/);
      const errorMessage = isWrongUser
        ? "Usuário desativado ou não existente."
        : "";

      const err =
        errorMessage || "Falha ao enviar o token, Verifique o email digitado.";
      return Promise.reject(err);
    });
};
export type EmailTokenAuthResponse = {
  jwt: string;
  user: {
    userID: string;
    email: string;
    info: {
      avatar: string;
      dob: string;
    };
    applID: string;
    name: string;
  };
};
export const emailTokenSignin = async (reqst: {
  acveID: string;
  verification: string;
}) => {
  return axios
    .post(`${process.env.REACT_APP_AUTH_PATH}/auth/token-signin`, reqst)
    .then((r) => {
      return r.data.data.item as EmailTokenAuthResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "rcvr5");
      return Promise.reject(new Error(err));
    });
};

export type OnboardingPatientForm = {
  name: string;
  email?: string;
  password?: string;
  pathology?: string;
  cpf: string;
  phones?: ContactPhone[];
  dateOfBirth?: string;
  sex?: string;
  avatar?: string;
  address?: {
    cep: string;
    street: string;
    streetNumber: string;
    city: string;
    neighborhood: string;
    state: string;
    cityIdentifier?: string;
  };
  controlledAppointment?: {
    clinID?: string;
    doctID?: string;
  };
  createdFor?: {
    clinID?: string;
    doctID?: string;
  };
};

export const createNewPatient = async (
  formData: OnboardingPatientForm,
  token?: string
) => {
  const headers = token ? { headers: { Authorization: token } } : {};
  return axios
    .post(
      `${process.env.REACT_APP_AUTH_PATH}/onboarding/create-account-patient`,
      formData,
      headers
    )
    .then((r) => {
      return r.data.item as OnboardingResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "rcvr7");
      return Promise.reject(new Error(err));
    });
};

export const otpLogin = async (otp: string) => {
  return axios
    .post(`${process.env.REACT_APP_AUTH_PATH}/auth/one-time-login`, {
      token: otp,
    })
    .then((r) => {
      const item = r.data.data.item;
      return Promise.resolve({
        ...item,
        token: otp,
      } as OTPLoginResponse);
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "rcvr4");
      return Promise.reject(new Error(err));
    });
};

export type ClinicOnboardingForm = {
  userName: string;
  userEmail: string;
  userPassword: string;
  name: string;
  shortDescription: string;
  description: string;
  rememberMe: boolean;
  suofID?: string;
};
export type ClinicOnboardingResponse = {
  onboarding: {
    name: string;
    shortDescription: string;
    userEmail: string;
    userPassword: string;
    url: string;
  };
  jwt: string;
};
export const createNewClinic = async (form: ClinicOnboardingForm) => {
  return axios
    .post(
      `${process.env.REACT_APP_AUTH_PATH}/onboarding/create-account-clinic`,
      form
    )
    .then((r) => {
      return r.data.item as ClinicOnboardingResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "onbc1");
      return Promise.reject(err);
    });
};

const makeCanceableEmailVerification = () => {
  let cancel: CancelTokenSource | undefined;

  return async (applID: string, email: string) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/email-verification/${applID}/${email}`,
        { cancelToken: cancel.token }
      )
      .then((r) => {
        return r.data.data.item as EmailValidateStatus;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return undefined;
        }
        const err = deriveErrorMessage(e, "oev1");
        return Promise.reject(new Error(err));
      });
  };
};

export const emailVerification = makeCanceableEmailVerification();
