import React from "react";
import { useDispatch } from "react-redux";
import { connectRealtime } from "ducks/realtime";
import { AppDispatch } from "ducks/state";

export const useConnectRealtime = () => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(connectRealtime());
  }, [dispatch]);
};
