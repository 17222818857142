import React from "react";
import { Route, Switch, useParams } from "react-router";
import { Redirect } from "react-router-dom";
import {
  PasswordReset,
  PasswordResetChoose,
  SignoutContainer,
  PasswordResetSucess,
  TokenLogin,
} from "screens/onboarding";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "@udok/lib/themes/paperbase";
import Admin from "screens/admin";
import ProfileLoader from "containers/Clinic/ProfileLoader";
import UserLoader from "containers/Auth/UserLoader";
import Notification from "containers/Notification";

const GeneralNotifications = Notification("general");

const RedirectToAdmin = (p: any) => {
  const { slug } = useParams<{ slug: string }>();
  return <Redirect to={`/${slug}/admin`} />;
};

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <ProfileLoader>
        <>
          <UserLoader />
          <Switch>
            <Route exact path="/:slug">
              <RedirectToAdmin />
            </Route>
            <Route
              exact
              path="/:slug/password_reset"
              component={PasswordReset}
            />
            <Route
              exact
              path="/:slug/password_reset_sucess"
              component={PasswordResetSucess}
            />
            <Route
              exact
              path="/:slug/password_reset/:reset_id/:verification"
              component={PasswordResetChoose}
            />
            <Route
              exact
              path="/:slug/admin/signout"
              component={SignoutContainer}
            />
            <Route
              exact
              path="/:slug/admin/token_login"
              component={TokenLogin}
            />
            <Route path="/:slug/admin" component={Admin} />
          </Switch>
          <GeneralNotifications />
        </>
      </ProfileLoader>
    </ThemeProvider>
  );
};
