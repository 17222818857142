import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={23}
      height={24}
      viewBox="0 0 23 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.815.5H3.68c-.22 0-.398.19-.398.425V23c0 .235.179.425.398.425h15.134c.22 0 .398-.19.398-.425V.925c0-.235-.178-.425-.398-.425zm-.398 22.076H4.08V1.349h10.36l.032 2.585c.009.68.536 1.236 1.176 1.236.648 0 1.176-.562 1.176-1.253V2.198h-.797v1.719c0 .223-.17.404-.38.404-.206 0-.376-.18-.378-.399l-.033-2.573h3.18v21.227z" />
      <path d="M5.673 3.047h3.186v.85H5.673v-.85zM9.655 3.047h1.593v.85H9.655v-.85zM13.638 20.028h3.186v.85h-3.186v-.85zM11.248 20.028h1.593v.85h-1.593v-.85zM8.438 9.215l-.89-.95-.564.601L8.18 10.14c.075.08.176.124.282.124h.015a.39.39 0 00.287-.148l2.39-2.972-.605-.552-2.11 2.623z" />
      <path d="M6.071 11.538h3.983c.22 0 .398-.19.398-.425V9.84h-.797v.849H6.47V7.292h2.39v-.849H6.07c-.22 0-.398.19-.398.425v4.245c0 .235.178.425.398.425zM8.438 16.008l-.89-.95-.564.6 1.195 1.274c.075.08.176.125.282.125h.015a.39.39 0 00.287-.149l2.39-2.972-.605-.552-2.11 2.624z" />
      <path d="M9.655 17.481H6.47v-3.396h2.39v-.85H6.07c-.22 0-.398.19-.398.425v4.246c0 .235.178.424.398.424h3.983c.22 0 .398-.19.398-.424v-1.274h-.797v.85zM12.045 6.443h4.779v.85h-4.78v-.85zM12.045 8.566h4.779v.85h-4.78v-.85zM12.045 10.689h4.779v.849h-4.78v-.85zM12.045 13.236h4.779v.849h-4.78v-.85zM12.045 15.358h4.779v.85h-4.78v-.85zM12.045 17.481h4.779v.85h-4.78v-.85z" />
    </svg>
  );
}

export default SvgComponent;
