import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Tuss,
  FilterTussList,
  ExamProcedure,
  ExamProcedureFilter,
  ClinicExamProcedureDoctor,
  ExamProcedureForm,
} from "@udok/lib/api/models";
import {
  makeItemFetch,
  makeItemsFetch,
  makeItemPost,
  makeItemPut,
  makeItemDelete,
} from "@udok/lib/api/axios";

export const createProcedures = async (
  token: string,
  data: ExamProcedureForm
) =>
  makeItemPost<ExamProcedure, ExamProcedureForm>({
    url: `/api/exams-procedures`,
    errLabel: "EXPR10",
    token,
    data,
  });

export const updateProcedures = async (
  token: string,
  exprID: string,
  data: ExamProcedureForm
) =>
  makeItemPut<ExamProcedure, ExamProcedureForm>({
    url: `/api/exams-procedures/${exprID}`,
    errLabel: "EXPR11",
    token,
    data,
  });

export const deleteProcedures = (token: string, exprID: string) =>
  makeItemDelete<ExamProcedure>({
    url: `/api/exams-procedures/${exprID}`,
    errLabel: "EXPR12",
    token,
  });

const makeCanceableSearchProcedures = () => {
  let cancel: CancelTokenSource | undefined;

  return async (filter?: ExamProcedureFilter, token?: string) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const f = filter ? toQueryString(filter) : "";
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/search/exams-procedures${f}`, {
        cancelToken: cancel.token,
        ...(token
          ? {
              headers: {
                Authorization: token,
              },
            }
          : {}),
      })
      .then((r) => {
        return r.data.data.items as ExamProcedure[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as ExamProcedure[];
        }
        const err = deriveErrorMessage(e, "expr1");
        return Promise.reject(new Error(err));
      });
  };
};

export const searchProcedures = makeCanceableSearchProcedures();

export const fetchProcedure = (exprID: string) =>
  makeItemFetch<ExamProcedure>({
    url: `/api/exams-procedures/${exprID}`,
    errLabel: "EXPR2",
  });

export const createClinicExamsProcedures = async (
  token: string,
  data: { exprID: string; doctors: string[] }
) =>
  makeItemPost<
    ClinicExamProcedureDoctor,
    { exprID: string; doctors: string[] }
  >({
    url: `/api/clinic-exams-procedures`,
    errLabel: "EXPR3",
    token,
    data,
  });

export const updateClinicExamsProcedures = async (
  token: string,
  data: { exprID: string; doctors: string[] }
) =>
  makeItemPut<ClinicExamProcedureDoctor, { exprID: string; doctors: string[] }>(
    {
      url: `/api/clinic-exams-procedures/${data.exprID}`,
      errLabel: "EXPR4",
      token,
      data,
    }
  );

export const getClinicExamsProcedures = (token: string, exprID: string) =>
  makeItemFetch<ClinicExamProcedureDoctor>({
    url: `/api/clinic-exams-procedures/${exprID}`,
    errLabel: "EXPR5",
    token,
  });

export const listClinicExamsProcedures = (token: string) => {
  return makeItemsFetch<ClinicExamProcedureDoctor[]>({
    url: `/api/clinic-exams-procedures`,
    errLabel: "EXPR6",
    token,
  });
};

export const deleteClinicExamsProcedures = (token: string, exprID: string) =>
  makeItemDelete<ClinicExamProcedureDoctor>({
    url: `/api/clinic-exams-procedures/${exprID}`,
    errLabel: "EXPR7",
    token,
  });

export const registerTussUsage = async (token: string, tussCode: string) =>
  makeItemPost<void, { tussCode: string }>({
    url: `/api/tuss-usages`,
    errLabel: "EXPR14",
    token,
    data: { tussCode },
  });

const makeCanceableTussFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, filter: FilterTussList) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/tuss${toQueryString(filter)}`, {
        cancelToken: cancel.token,
        headers: {
          Authorization: token,
        },
      })
      .then((r) => {
        return r.data.data.items as Tuss[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as Tuss[];
        }
        const err = deriveErrorMessage(e, "expr8");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchAllTuss = makeCanceableTussFetch();

export const fetchRecentTuss = (token: string) => {
  return makeItemsFetch<Tuss[]>({
    url: `/api/tuss-usages`,
    errLabel: "EXPR9",
    token,
  });
};
