import React from "react";
import moment from "moment";

import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InlineBadge from "@udok/lib/components/InlineBadge";
import { Link } from "react-router-dom";
import Icons from "@udok/lib/components/Icon";

export interface ItemProps {
  item: any;
  selectedUserID?: string;
  to?: (userID: string) => string;
  avatarComponent?: (p: { userID: string }) => React.ReactNode;
  component?: React.ElementType;
  handleProfile?: (userID: string) => void;
}

export default class Item extends React.Component<ItemProps> {
  render() {
    const {
      to,
      item,
      selectedUserID,
      handleProfile,
      component,
      avatarComponent,
    } = this.props;
    const ListItemComponent = component ?? Link;

    return (
      <ListItem
        button
        selected={selectedUserID === item.withUserID}
        component={ListItemComponent}
        to={to?.(item.withUserID)}
        userID={item.withUserID}
      >
        {avatarComponent ? (
          <ListItemAvatar>
            <IconButton onClick={() => handleProfile?.(item.withUserID)}>
              {avatarComponent?.({ userID: item.withUserID })}
            </IconButton>
          </ListItemAvatar>
        ) : null}
        <ListItemText
          primary={
            <Typography
              style={{
                color: "rgb(68, 68, 68)",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              noWrap
            >
              {item?.username}
            </Typography>
          }
          secondary={
            !item?.deletedAt ? (
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                noWrap
              >
                {item.messagePreview}
              </Typography>
            ) : (
              <span style={{ display: "flex", alignItems: "center" }}>
                <Icons.NotInterested fontSize="small" color="disabled" />
                <Typography
                  color="textSecondary"
                  variant="body2"
                  style={{ marginLeft: 8 }}
                >
                  <i>Mensagem apagada</i>
                </Typography>
              </span>
            )
          }
        />
        {item?.activeAt ? (
          <ListItemSecondaryAction>
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontSize: 12,
                    color: "rgb(68, 68, 68)",
                  }}
                >
                  {moment(item.activeAt).format("HH:mm")}
                </Typography>
              }
              secondary={
                <span
                  style={{
                    textAlign: "right",
                    display: "block",
                  }}
                >
                  <InlineBadge
                    badgeContent={item.unreadCount}
                    color="primary"
                  />
                </span>
              }
            />
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    ) as any;
  }
}
