import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { ClinicUser, User } from "@udok/lib/api/models";
import { Action } from "ducks";
import {
  fetchClinicUser,
  fetchClinicUsers,
  createClinicUser,
  updateClinicUser,
  deleteClinicUser,
  oneTimeLogin,
  fetchUserByID,
  uploadPrivateFile,
  PrivateFileUploadedResponse,
} from "@udok/lib/api/user";
import { getToken, getPayload, UNAUTHORIZED } from "./auth";

export type InitialState = {
  myProfile?: ClinicUser;
  clinicUserByID: { [userID: string]: ClinicUser };
  userByID: { [k: string]: User | undefined };
};

// Reducers
const initialState: InitialState = {
  myProfile: undefined,
  clinicUserByID: {},
  userByID: {},
};

class UserSlice extends Hen<InitialState> {
  profileLoaded(v: ClinicUser) {
    this.state.myProfile = v;
  }

  clinicUsersLoaded(list: ClinicUser[]) {
    list.forEach((u) => {
      this.state.clinicUserByID[u.userID] = u;
    });
  }

  clinicUserRemoved(userID: string) {
    delete this.state.clinicUserByID[userID];
  }

  userUdokLoaded(u: User) {
    this.state.userByID[u.userID] = u;
  }
}

export const extraReducers = {
  [UNAUTHORIZED](state: InitialState, a: Action) {
    state = initialState;
    return state;
  },
};

export const [Reducer, actions] = hen(
  new UserSlice(initialState),
  extraReducers
);

// Selectors
const mainSelector = (state: RootState) => state.user;
const roleselector = (state: RootState) => state.user.myProfile?.roles;
const clinicUserSelector = (state: RootState) => state.user.clinicUserByID;
const udokUserSelector = (state: RootState) => state.user.userByID;
const profileSelector = (state: RootState) => state.user.myProfile;

export const getUserProfile = createSelector(mainSelector, (state) => {
  return {
    currentUser: state.myProfile,
  };
});

export const getUserMe = createSelector(mainSelector, (state) => {
  return {
    currentUser: state.myProfile,
  };
});

export const userListView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.clinicUserByID).map(
      (userID) => state.clinicUserByID[userID]
    ),
  };
});

export const oneUserView = (state: RootState, props: { userID: string }) =>
  createSelector([mainSelector], (state) => {
    return {
      user: state.clinicUserByID[props.userID],
    };
  });

export const avatarByUserIDViewCreator = (props: { userID: string }) =>
  createSelector(
    [clinicUserSelector, udokUserSelector],
    (clinicUserByID, userByID) => {
      const cliUser = clinicUserByID[props.userID];
      const udokUser = userByID[props.userID];
      const user = {
        name:
          cliUser?.name ??
          cliUser?.email ??
          udokUser?.doctor?.name ??
          udokUser?.patient?.name,
        avatar:
          cliUser?.avatar ??
          udokUser?.doctor?.avatar ??
          udokUser?.patient?.avatar,
      };
      return {
        src: user?.avatar
          ? `${process.env.REACT_APP_BASE_PATH}/files/${user?.avatar}`
          : "",
        name: user?.name ?? "Usuário",
      };
    }
  );

export const userLoadedView = createSelector(
  [profileSelector, getPayload],
  (myProfile, auth) => {
    return {
      profile: myProfile,
      payload: auth.payload,
    };
  }
);

export const selectUserPermissions = roleselector;

// Actions
export function loadUserMe(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const userID = t.token.payload.userID;
    if (!userID) {
      return Promise.reject("no user id");
    }
    return fetchClinicUser(apiToken, userID)
      .then((r) => {
        dispatch(actions.profileLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadUsers(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicUsers(apiToken)
      .then((r) => {
        dispatch(actions.clinicUsersLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadOneUser(userID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicUser(apiToken, userID)
      .then((r) => {
        dispatch(actions.clinicUsersLoaded([r]));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function addOneUser(u: ClinicUser): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createClinicUser(apiToken, u)
      .then((r) => {
        dispatch(actions.clinicUsersLoaded([r]));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function updateOneUser(u: ClinicUser): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateClinicUser(apiToken, u)
      .then((r) => {
        dispatch(actions.clinicUsersLoaded([r]));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function removeUser(userID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteClinicUser(apiToken, userID)
      .then((r) => {
        dispatch(actions.clinicUserRemoved(r.userID));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchProfileForUserID(userID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchUserByID(apiToken, userID)
      .then((r) => {
        dispatch(actions.userUdokLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          }) as any
        );
      });
  };
}

export function fetchCachedProfileForUserID(
  userID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const userExist = Boolean(state.user.userByID[userID]);
    if (userExist) {
      return Promise.resolve();
    }
    return dispatch(fetchProfileForUserID(userID));
  };
}

export function createOnetimeLogin(): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    let validUntil = new Date();
    validUntil.setHours(validUntil.getHours() + 1);
    return oneTimeLogin(validUntil, apiToken)
      .then((r) => {
        const otlogin = r;
        return otlogin.token;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Falha ao criar sessão de vídeo",
          }) as any
        );
        throw e;
      });
  };
}

export function savePrivateFile(
  file: File,
  name: string,
  permittedUser: string
): AppThunk<Promise<PrivateFileUploadedResponse>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;

    return uploadPrivateFile(apiToken, {
      file,
      name,
      permittedUsers: [permittedUser],
    }).then((r) => {
      return r;
    });
  };
}
