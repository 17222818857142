import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Clinic,
  DoctorPublicProfile,
  GeoLocation,
  SearchAppointmentsFilter,
  SerarchAppointmentResult,
} from "@udok/lib/api/models";

export const searchDoctor = async (filter: SearchAppointmentsFilter) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/search/appointments${toQueryString(
        filter
      )}`
    )
    .then((r) => {
      return r.data.data.items as SerarchAppointmentResult[];
    })
    .catch((e) => {
      const error = deriveErrorMessage(e, "doc3");
      const err = error.match(/Failed to parse dateFrom/)
        ? { message: "Horário não encontrado" }
        : new Error(error);
      return Promise.reject(err);
    });
};

export const fetchDoctorPublicProfileSearch = (() => {
  let cancel: CancelTokenSource | undefined;
  return async (search: string) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    return axios
      .get(`${process.env.API_PATH}/search/doctor-profiles?query=${search}`, {
        cancelToken: cancel.token,
      })
      .then((r) => {
        return r.data.data.items as DoctorPublicProfile[];
      })
      .catch((e) => {
        const err = deriveErrorMessage(e, "sear2");
        return Promise.reject(new Error(err));
      });
  };
})();

export const fetchClinicProfileSearch = (() => {
  let cancel: CancelTokenSource | undefined;

  return async (search: string) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    return axios
      .get(`${process.env.API_PATH}/search/clinic-profiles?query=${search}`, {
        cancelToken: cancel.token,
      })
      .then((r) => {
        return r.data.data.items as Clinic[];
      })
      .catch((e) => {
        const err = deriveErrorMessage(e, "sear3");
        return Promise.reject(new Error(err));
      });
  };
})();

export const fetchGeoLocationSearch = async (search: string) => {
  return axios
    .get(
      `${
        process.env.API_PATH || process.env.REACT_APP_API_PATH
      }/search/geo-locations?search=${search}`
    )
    .then((r) => {
      return r.data.data.items as GeoLocation[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sear4");
      return Promise.reject(new Error(err));
    });
};
