import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35.874}
      height={51.021}
      viewBox="0 0 35.874 51.021"
      fill="#2680eb"
      {...props}
    >
      <g data-name={3198809}>
        <path
          data-name="Caminho 36"
          d="M30.629 41.293a21.7 21.7 0 00-5.343-1.6 55.061 55.061 0 001.873-2.039 41.233 41.233 0 005.966-8.763 24.771 24.771 0 002.75-10.95 17.937 17.937 0 00-35.874 0 24.771 24.771 0 002.75 10.951 41.233 41.233 0 005.966 8.763c.63.722 1.258 1.4 1.873 2.039a21.7 21.7 0 00-5.343 1.6c-2.688 1.283-3.252 2.747-3.252 3.749 0 1.269.878 3.075 5.059 4.469a35.776 35.776 0 0010.885 1.511 35.776 35.776 0 0010.885-1.511c4.182-1.394 5.059-3.2 5.059-4.469 0-1-.564-2.466-3.252-3.749zM1.993 17.937a15.944 15.944 0 1131.888 0c0 7.4-4.457 14.073-8.2 18.369a54.972 54.972 0 01-7.744 7.294 54.979 54.979 0 01-7.749-7.294c-3.739-4.3-8.2-10.971-8.2-18.37zm26.2 29.683a33.769 33.769 0 01-10.254 1.408A33.769 33.769 0 017.683 47.62c-2.729-.91-3.7-1.957-3.7-2.578 0-1.035 2.6-2.818 8.354-3.615.853.81 1.647 1.516 2.34 2.106-1.057.365-1.726.905-1.726 1.508 0 1.1 2.231 1.993 4.983 1.993s4.983-.892 4.983-1.993c0-.6-.669-1.143-1.726-1.508.694-.591 1.488-1.3 2.34-2.106 5.757.8 8.354 2.579 8.354 3.615 0 .621-.968 1.668-3.7 2.578z"
        />
        <path
          data-name="Caminho 37"
          d="M31.886 17.937a13.951 13.951 0 10-13.949 13.949 13.967 13.967 0 0013.949-13.949zm-25.909 0a11.958 11.958 0 1111.958 11.958A11.972 11.972 0 015.979 17.937z"
        />
        <path
          data-name="Caminho 38"
          d="M18.934 24.913a1 1 0 001 1h3.986a1 1 0 001-1V19.93h1.993a1 1 0 00.662-1.741l-8.969-7.972a1 1 0 00-1.324 0l-8.969 7.972a1 1 0 00.662 1.741h1.993v4.983a1 1 0 001 1h3.986a1 1 0 001-1v-2.99h1.993zm-2.99-4.983a1 1 0 00-1 1v2.99h-1.993v-4.983a1 1 0 00-1-1h-.369l6.348-5.642 6.348 5.642h-.368a1 1 0 00-1 1v4.983h-1.993v-2.99a1 1 0 00-1-1z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
