import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M2.889.601A2.9 2.9 0 000 3.49v10.222a2.9 2.9 0 002.889 2.89H13.11A2.9 2.9 0 0016 13.711V3.49A2.9 2.9 0 0013.111.601H2.89zm0 1.333H13.11c.867 0 1.556.69 1.556 1.556v.667H1.333V3.49c0-.867.69-1.556 1.556-1.556zM1.333 5.49h13.334v8.222c0 .867-.69 1.556-1.556 1.556H2.89a1.546 1.546 0 01-1.556-1.556V5.49zm2.89 1.778a1.111 1.111 0 100 2.222 1.111 1.111 0 000-2.222zm3.777 0A1.111 1.111 0 108 9.49a1.111 1.111 0 000-2.222zm3.778 0a1.111 1.111 0 100 2.222 1.111 1.111 0 000-2.222zm-7.556 4a1.111 1.111 0 100 2.222 1.111 1.111 0 000-2.222zm3.778 0a1.111 1.111 0 100 2.222 1.111 1.111 0 000-2.222z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .601)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
