import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "ducks/state";
import {
  createOneSchedule,
  removeOneSchedule,
  createControlledAppointment,
  createPatientForControlledAppointment,
  loadAppointments,
  scheduleLockSelector,
  fetchCachedAppointmentByMegrID,
  getAppointmentByMegrID,
  appointmentSelector,
  scheduleSelector,
  getSuggestView,
  loadAllSchedules,
  loadAllScheduleLocks,
  createClinicAppointmentView,
  appointmentPendingReturn,
  loadPendingReturns,
  fetchCachedAppointment,
  getAppointmentView,
  fetchCachedAppointmentCommunication,
  getOneAppointmentCommunication,
  addAppointmentConfirmation,
  scheduleSlotByDay,
  listScheduleSlots,
  getCalendarFilter,
  getListedSchedulesLock,
  getAppointsByDay,
  listCalendarEvents,
  appointmentCalendarView,
  fetchCachedAppointmentByDay,
  clearInvoicesByAppoID,
  oneAppointmentCheckIn,
  fetchCachedAppointmentCheckIn,
  appointmentPaymentView,
  loadInvoicesByAppoID,
} from "ducks/schedule";
import {
  loadClinicDoctors,
  doctorRepositoryByUserID,
  getClinicDoctorByID,
} from "ducks/doctor";
import {
  healthplanListView,
  loadHealthplans,
  getClinicProfile,
  fetchCachedHealthplan,
  healthplanView,
  getHealthplanByID,
} from "ducks/clinic";
import {
  createOneHealthplanCard,
  deleteOneHealthplanCard,
} from "ducks/patient";
import { locationByIDSelector, loadLocations } from "ducks/location";
import { searchProceduresListView, getProceduresByID } from "ducks/procedure";
import {
  Schedule,
  ScheduleType,
  Appointment,
  NewAppointment,
  InstantAppointmentData,
  FilterAppointment,
  ScheduleLockView,
  PatientHealthplanCard,
  FilterScheduleSlots,
  ExamProcedure,
  FilterCalendarEvents,
} from "@udok/lib/api/models";
import { OnboardingPatientForm, OnboardingResponse } from "@udok/lib/api/auth";
import { format, onlyNumbers, AppointmentError } from "@udok/lib/internal/util";
import { DefaultPlan, DefaultAptyID } from "@udok/lib/internal/constants";
import { generateCalendarEvents } from "@udok/lib/app/schedule";
import { DayViewAppo } from "@udok/lib/app/appointment";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export const useCreateInstantAppointment = (notifyByPhone?: boolean) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleChangeCardNumber = React.useCallback(
    async (value: {
      patiID: string;
      heplID: string;
      card: { value: string; phcaID?: number };
    }) => {
      const cardNumber = value.card.value;
      const phcaID = value.card?.phcaID;

      const cardData: Partial<PatientHealthplanCard> = {
        patiID: value.patiID,
        heplID: value.heplID,
        cardName: "",
        cardNumber,
      };
      if (phcaID) {
        dispatch(deleteOneHealthplanCard(value.patiID, phcaID));
      }
      return dispatch(createOneHealthplanCard(cardData));
    },
    [dispatch]
  );
  const onCreatePatient = React.useCallback(
    async (values: InstantAppointmentData) => {
      const dob = moment(values?.patientBirthdate, format.DASHUN);
      const pat: OnboardingPatientForm = {
        cpf: onlyNumbers(values?.patientCPF ?? ""),
        dateOfBirth: dob.isValid() ? dob.format(format.DASHUN) : undefined,
        phones: values?.patientPhone
          ? [{ phone: values?.patientPhone, linkedApps: [] }]
          : [],
        email: values?.patientEmail,
        name: values?.patientName ?? "",
      };
      let res: void | OnboardingResponse;
      try {
        res = await dispatch(createPatientForControlledAppointment(pat));
        if (!res) {
          throw new Error("Falha ao criar paciente");
        }
      } catch (e) {
        throw new AppointmentError((e as Error)?.message);
      }
      return res;
    },
    [dispatch]
  );
  const onCreateSchedule = React.useCallback(
    async (values: InstantAppointmentData) => {
      const startAt = moment(values.startAt, format.DATETIMELOCAL);
      const schedule: Partial<Schedule> = {
        type: values.type,
        locaID: values?.location,
        userID: values.docUserID,
        price: 0,
        weekDay: [],
        startAt: startAt.utc().format(format.RFC3349),
        endAt: startAt
          .clone()
          .add(values.appointmentDuration, "minute")
          .utc()
          .format(format.RFC3349),
        appointmentDuration: values.appointmentDuration,
        eventDuration: values.appointmentDuration,
        appointmentOptions: [
          { aptyID: values?.aptyID ?? DefaultAptyID, default: true },
        ],
        marketplaceOffer: false,
        selfService: false,
        healthPlans: [values?.heplID ?? DefaultPlan],
        blockUnpaidAppointment: false,
        exclusiveListing: true,
        weekdayTimezoneOffset: moment().utcOffset() * 60,
        procedures: values?.procedures,
      };

      let res: void | Schedule;
      try {
        res = await dispatch(createOneSchedule(schedule as Schedule, false));
        if (!res) {
          throw new Error("Falha ao criar horário");
        }
      } catch (e) {
        throw new AppointmentError((e as Error)?.message);
      }
      return res;
    },
    [dispatch]
  );
  const onCreateAppointment = React.useCallback(
    (
      values: {
        sescID: string;
        markedAt: string;
        patiID: string;
        heplID: string;
        specID?: number;
        aptyID: string;
        returnForAppoID?: string;
        returnForMarkedAt?: string;
        procedures?: string[];
      },
      force: boolean
    ) => {
      const appointment = {
        sescID: values.sescID,
        markedAt: values.markedAt,
        patiID: values.patiID,
        specID: values?.specID,
        aptyID: values?.aptyID,
        healthplans: [values.heplID],
        invoicePatient: false,
        forceCreate: force,
        returnForAppoID: values?.returnForAppoID,
        returnForMarkedAt: values?.returnForMarkedAt,
        procedures: values?.procedures,
      } as NewAppointment;

      return dispatch(
        createControlledAppointment(appointment, undefined, notifyByPhone)
      );
    },
    [dispatch, notifyByPhone]
  );

  const onCreate = React.useCallback(
    async (data: InstantAppointmentData, force: boolean) => {
      setLoading(true);
      try {
        const sch = await onCreateSchedule(data);
        if (!sch) {
          setLoading(false);
          return;
        }
        let patiID = data?.patiID;
        if (!patiID) {
          const p = await onCreatePatient(data);
          if (!p) {
            setLoading(false);
            return;
          }
          patiID = p.user.patient?.patiID;
        }
        const appoVal = {
          sescID: sch.sescID,
          patiID: patiID ?? "",
          markedAt: sch.startAt,
          aptyID: (sch?.appointmentOptions ?? [])?.[0]?.aptyID ?? DefaultAptyID,
          heplID: data?.heplID ?? DefaultPlan,
          specID: data?.specID,
          returnForAppoID: data?.returnForAppoID,
          returnForMarkedAt: data?.returnForMarkedAt,
          procedures: sch.procedures,
        };
        let resp: Appointment | undefined;
        await onCreateAppointment(appoVal, force)
          .then((r) => {
            resp = r;
            if (
              patiID &&
              data?.heplID &&
              data?.heplID !== DefaultPlan &&
              data?.healthplanCardNumber?.value
            ) {
              handleChangeCardNumber({
                patiID,
                heplID: data?.heplID,
                card: data?.healthplanCardNumber,
              });
            }
          })
          .catch((e) => {
            let message = "Falha ao criar agendamento";
            if (e.message.indexOf("appointment: schedule unavailable") > -1) {
              message = "Horário indisponível.";
            }
            throw new AppointmentError(message, { patiID });
          })
          .finally(() => {
            dispatch(removeOneSchedule(sch.sescID, false)).catch(console.warn);
          });
        setLoading(false);
        return resp;
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [
      onCreatePatient,
      onCreateSchedule,
      onCreateAppointment,
      handleChangeCardNumber,
      dispatch,
    ]
  );

  return [loading, onCreate] as [typeof loading, typeof onCreate];
};

export const useFetchAppointmentData = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadClinicDoctors()),
      dispatch(loadLocations()),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const onLoadAppointment = React.useCallback(
    (f?: FilterAppointment) => {
      setLoading(true);
      return dispatch(loadAppointments(f)).finally(() => setLoading(false));
    },
    [dispatch]
  );

  return [onLoadAppointment, loading] as [
    typeof onLoadAppointment,
    typeof loading
  ];
};

export const useListScheduleLocks = (ids: string[]) => {
  const scheduleLockByID = useSelector(scheduleLockSelector);
  const filtredIds = Object.keys(scheduleLockByID).filter(
    (id) => ids.indexOf(id) !== -1
  );
  const blockList = filtredIds
    .map((id) => scheduleLockByID[id])
    .filter((b) => !!b) as ScheduleLockView[];

  return [blockList] as [typeof blockList];
};

export const useGetAppointmentByMegrID = (megrID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchCachedAppointmentByMegrID(megrID)).finally(() =>
      setLoading(false)
    );
  }, [dispatch, megrID]);

  const { appointment } = useSelector((state: RootState) =>
    getAppointmentByMegrID(state, { megrID })(state)
  );

  return [loading, appointment] as [typeof loading, typeof appointment];
};

export const useListHealthplans = (loadData?: boolean) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { profile } = useSelector(getClinicProfile);
  const clinID = profile?.clinID;

  const load = React.useMemo(() => {
    if (typeof loadData !== "boolean") {
      return true;
    }
    return loadData;
  }, [loadData]);

  React.useEffect(() => {
    if (load) {
      setLoading(true);
      Promise.all([
        dispatch(loadHealthplans()),
        ...(clinID ? [dispatch(loadHealthplans({ clinID }))] : []),
      ]).finally(() => setLoading(false));
    }
  }, [dispatch, clinID, load]);

  const { listAll, filtredList } = useSelector(healthplanListView);

  return [loading, listAll, filtredList] as [
    typeof loading,
    typeof listAll,
    typeof filtredList
  ];
};

export const useGetHealthplan = (heplID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchCachedHealthplan(heplID)).finally(() => setLoading(false));
  }, [heplID, dispatch]);

  const getHealthplan = React.useCallback(healthplanView({ heplID }), [heplID]);
  const { healthplan } = useSelector(getHealthplan);

  return [loading, healthplan] as [typeof loading, typeof healthplan];
};

export const useSuggestcalendar = (appoID: string, selectedDay: string) => {
  const appointmentByID = useSelector(appointmentSelector);
  const scheduleByID = useSelector(scheduleSelector);
  const scheduleLockByID = useSelector(scheduleLockSelector);
  const doctorByUserID = useSelector(doctorRepositoryByUserID);

  const appo = appointmentByID[appoID];
  const schedules = Object.keys(scheduleByID)
    .map((s) => {
      const sch = scheduleByID[s] as Schedule;
      const doctID = doctorByUserID?.[sch.userID]?.doctID ?? "";
      return {
        ...sch,
        doctID,
      };
    })
    .filter(
      (sch) =>
        sch?.clinID === appo?.clinID &&
        sch?.type === appo?.type &&
        sch?.doctID === appo?.doctID &&
        !sch?.deletedAt
    )
    .filter((sch) => !!sch) as (Schedule & { doctID: string })[];
  const appointments = Object.keys(appointmentByID)
    .map((s) => appointmentByID[s])
    .filter((a) => !!a) as Appointment[];
  const blockList = Object.keys(scheduleLockByID)
    .map((id) => scheduleLockByID[id])
    .filter((b) => !!b) as ScheduleLockView[];

  const cal = generateCalendarEvents(
    selectedDay,
    appointments,
    schedules,
    blockList,
    moment().add(15, "minutes").format()
  );
  const calendar = Object.keys(cal).map((k) => cal[k]);
  return [calendar] as [typeof calendar];
};

export const useSuggestScheduleList = (appoID: string) => {
  const [loading, setLoading] = React.useState(false);

  const dispatch: AppDispatch = useDispatch();
  const getSuggest = React.useCallback(getSuggestView({ appoID }), [appoID]);
  const { appointment, blockList, schedules } = useSelector(getSuggest);
  const clinID = React.useMemo(() => appointment?.clinID, [appointment]);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadAllSchedules({ "endAt[gte]": moment().format(), clinID })),
      dispatch(loadAllScheduleLocks()),
    ]).finally(() => setLoading(false));
  }, [clinID, dispatch]);

  return [loading, blockList, schedules] as [
    typeof loading,
    typeof blockList,
    typeof schedules
  ];
};

export const useCreateClinicAppointmentView = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadAllSchedules({ "endAt[gte]": moment().format() })),
      dispatch(loadClinicDoctors({ listAll: true })),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const { blockList, schedules, doctors } = useSelector(
    createClinicAppointmentView
  );
  return [loading, blockList, schedules, doctors] as [
    typeof loading,
    typeof blockList,
    typeof schedules,
    typeof doctors
  ];
};

export const useGetAppointmentPendingReturn = (
  patiID?: string,
  doctID?: string
) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (patiID && doctID) {
      setLoading(true);
      dispatch(loadPendingReturns(patiID, doctID)).finally(() =>
        setLoading(false)
      );
    }
  }, [patiID, doctID, dispatch]);

  const getPendingReturn = React.useCallback(
    patiID && doctID
      ? appointmentPendingReturn({ patiID, doctID })
      : () => ({ patientPendingReturn: undefined }),
    [patiID]
  );

  const { patientPendingReturn } = useSelector(getPendingReturn);

  return [loading, patientPendingReturn] as [
    typeof loading,
    typeof patientPendingReturn
  ];
};

export const useGetAppointment = (appoID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (appoID) {
      setLoading(true);
      dispatch(fetchCachedAppointment(appoID)).finally(() => setLoading(false));
    }
  }, [dispatch, appoID]);

  const getAppointment = React.useCallback(
    getAppointmentView({ appoID: appoID ?? "" }),
    [appoID]
  );
  const { appointment } = useSelector(getAppointment);

  return [loading, appointment] as [typeof loading, typeof appointment];
};

export type ScheduleListItem = Schedule & {
  doctorName: string;
  locationName: string;
  procedureNames: string[];
};

export const useSchedulesPresentation = () => {
  const locationByID = useSelector(locationByIDSelector);
  const doctorByUserID = useSelector(doctorRepositoryByUserID);
  const scheduleByID = useSelector(scheduleSelector);
  const { filteredProcedures } = useSelector(searchProceduresListView);

  const list = Object.keys(scheduleByID)
    .map((sescID) => {
      const shedule = scheduleByID[sescID];

      const loc = shedule?.locaID ? locationByID[shedule.locaID] : undefined;
      let locationName = "Plataforma Udok";
      if (shedule?.type !== ScheduleType.virtual) {
        locationName = loc?.name ? loc.name : "";
      }
      let procedureNames: string[] = [];
      if (shedule?.type === ScheduleType.examProcedure) {
        const procedures = shedule?.procedures ?? [];
        procedureNames = filteredProcedures
          .filter((p) => procedures.indexOf(p.exprID) !== -1)
          .map((p) => p.title);
      }

      return {
        ...shedule,
        doctorName: doctorByUserID[shedule?.userID ?? ""]?.doctName ?? "",
        locationName,
        procedureNames,
      } as ScheduleListItem;
    })
    .filter(
      (sch) =>
        !sch.deletedAt &&
        !moment(sch.endAt).isBefore(moment().subtract(2, "days"), "day")
    )
    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

  return [list] as [typeof list];
};

export const useGetAppointmentCommunication = (appoID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (appoID) {
      setLoading(true);
      dispatch(fetchCachedAppointmentCommunication(appoID)).finally(() =>
        setLoading(false)
      );
    }
  }, [dispatch, appoID]);

  const getCommunication = React.useCallback(
    getOneAppointmentCommunication({ appoID }),
    [appoID]
  );
  const { communication } = useSelector(getCommunication);

  return [loading, communication] as [typeof loading, typeof communication];
};

export const useAppointmentConfirmation = (appoID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const confirmAppo = React.useCallback(
    (patiID: string) => {
      setLoading(true);
      dispatch(addAppointmentConfirmation(appoID, patiID)).finally(() =>
        setLoading(false)
      );
    },
    [appoID, dispatch]
  );

  const getAppointment = React.useCallback(
    getAppointmentView({ appoID: appoID ?? "" }),
    [appoID]
  );
  const { appointment } = useSelector(getAppointment);

  return [loading, appointment, confirmAppo] as [
    typeof loading,
    typeof appointment,
    typeof confirmAppo
  ];
};

export const useScheduleSlotByDay = (f: {
  searchText?: string | undefined;
  date: string;
}) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { calendarFilter } = useSelector(getCalendarFilter);
  const date = React.useMemo(() => moment(f.date, format.DASHUN), [f.date]);
  const filter = React.useMemo(
    () =>
      ({
        ...f,
        exprID: calendarFilter.exprID,
        specID: calendarFilter.specID,
        doctID: calendarFilter.doctID,
      } as FilterScheduleSlots),
    [calendarFilter, f]
  );

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(
        loadAllScheduleLocks({
          startDate: date
            .clone()
            .subtract(1, "day")
            .utc()
            .startOf("day")
            .format(format.RFC3349),
          endDate: date
            .clone()
            .add(1, "day")
            .utc()
            .endOf("day")
            .format(format.RFC3349),
        })
      ),
      dispatch(listScheduleSlots(filter)),
    ])
      .finally(() => setLoading(false))
      .catch(console.warn);
  }, [date, filter, dispatch]);

  const { locks } = useSelector(getListedSchedulesLock);

  const getAppoints = React.useCallback(
    getAppointsByDay({ day: date.format(format.DASHUN) }),
    [date]
  );
  const getScheduleSlot = React.useCallback(
    scheduleSlotByDay({ selectedDay: date.format(format.DASHUN) }),
    [date]
  );

  const { appointments } = useSelector(getAppoints);
  const { scheduleSlots } = useSelector(getScheduleSlot);
  return [loading, scheduleSlots, locks, appointments] as [
    typeof loading,
    typeof scheduleSlots,
    typeof locks,
    typeof appointments
  ];
};

export const useCalendarEvents = (date: string) => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();
  const { calendarFilter } = useSelector(getCalendarFilter);

  const filter = React.useMemo(() => {
    const d = moment(date, format.HUDDLED);
    return {
      startDate: d.startOf("month").format(format.RFC3349),
      endDate: d.endOf("month").format(format.RFC3349),
      specID: calendarFilter?.specID,
      patiID: calendarFilter?.patiID,
    } as FilterCalendarEvents;
  }, [date, calendarFilter]);

  React.useEffect(() => {
    setLoading(true);
    dispatch(listCalendarEvents(filter)).finally(() => setLoading(false));
  }, [filter, dispatch]);

  const getScheduleSlot = React.useCallback(
    appointmentCalendarView({ daySelected: date }),
    [date]
  );

  const { calendarEvents } = useSelector(getScheduleSlot);
  return [loading, calendarEvents] as [typeof loading, typeof calendarEvents];
};

type AppointmentByDayProps = {
  includeStatus?: string[];
  excludeStatus?: string[];
  ignoreFilters?: boolean;
};
export const useAppointmentByDay = (
  date: string,
  dateFormat: string,
  props?: AppointmentByDayProps
) => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();
  const { calendarFilter } = useSelector(getCalendarFilter);

  const onRefresh = React.useCallback(() => {
    setLoading(true);
    const d = moment(date, dateFormat);
    return dispatch(
      loadAppointments({
        immediateCare: false,
        markedAtGte: moment.utc(d.startOf("day")).format(format.RFC3349),
        markedAtLte: moment.utc(d.endOf("day")).format(format.RFC3349),
      })
    )
      .then(() => {
        dispatch(clearInvoicesByAppoID);
      })
      .finally(() => setLoading(false));
  }, [date, dateFormat, dispatch]);

  React.useEffect(() => {
    setLoading(true);
    dispatch(
      fetchCachedAppointmentByDay(
        moment(date, dateFormat).format(format.DASHUN),
        {
          immediateCare: false,
        } as FilterAppointment
      )
    ).finally(() => setLoading(false));
  }, [date, dateFormat, dispatch]);

  const getAppoints = React.useCallback(
    getAppointsByDay({
      day: moment(date, dateFormat).format(format.DASHUN),
    }),
    [date, dateFormat]
  );

  const locationByID = useSelector(locationByIDSelector);
  const { clinicDoctorByID } = useSelector(getClinicDoctorByID);
  const { proceduresByID } = useSelector(getProceduresByID);
  const { healthplanByID } = useSelector(getHealthplanByID);

  const { appointments } = useSelector(getAppoints);
  const allList = appointments.filter((a) => {
    if (a.imcsID) {
      return false;
    }
    const invalidStatus = props?.excludeStatus ?? [];
    if (invalidStatus.length > 0 && invalidStatus.indexOf(a.status) !== -1) {
      return false;
    }
    const validStatus = props?.includeStatus ?? [];
    if (validStatus.length > 0 && validStatus.indexOf(a.status) === -1) {
      return false;
    }
    if (!a.markedAt) {
      return false;
    }
    if (props?.ignoreFilters) {
      return true;
    }
    if (calendarFilter?.patiID && a.patiID !== calendarFilter?.patiID) {
      return false;
    }
    if (
      calendarFilter?.exprID &&
      (a?.procedures ?? []).indexOf(calendarFilter.exprID) === -1
    ) {
      return false;
    }
    const doctor = clinicDoctorByID[a?.doctID ?? ""];
    if (
      calendarFilter?.specID &&
      !doctor?.specialty?.some?.(
        (item) => String(item?.specID) === calendarFilter.specID
      )
    ) {
      return false;
    }
    if (calendarFilter?.doctID && calendarFilter?.doctID !== a?.doctID) {
      return false;
    }
    return true;
  });

  const dayEvents = allList.map((app) => {
    const plan = healthplanByID[app?.healthplans?.[0] ?? ""];
    const doctor = clinicDoctorByID[app?.doctID ?? ""];
    const location = app?.locaID ? locationByID[app.locaID] : undefined;
    const procedures = (app?.procedures ?? [])
      .map((id) => proceduresByID[id])
      .filter((p) => !!p) as ExamProcedure[];

    return {
      plan,
      doctor,
      location,
      procedures,
      appointment: app,
    } as DayViewAppo;
  });

  return [loading, dayEvents, onRefresh] as [
    typeof loading,
    typeof dayEvents,
    typeof onRefresh
  ];
};

export const useAppointmentCheckIn = (appoID: string) => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchCachedAppointmentCheckIn(appoID))
      .catch(console.warn)
      .finally(() => setLoading(false));
  }, [appoID, dispatch]);

  const getAppointmentCheckIn = React.useCallback(
    oneAppointmentCheckIn({ appoID }),
    [appoID]
  );
  const { CheckIn } = useSelector(getAppointmentCheckIn);

  return [loading, CheckIn] as [typeof loading, typeof CheckIn];
};

export const useAppointmentPayment = (appoID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (appoID) {
      setLoading(true);
      Promise.all([
        dispatch(fetchCachedAppointment(appoID)),
        dispatch(loadInvoicesByAppoID(appoID)),
      ]).finally(() => setLoading(false));
    }
  }, [dispatch, appoID]);

  const getAppointment = React.useCallback(
    appointmentPaymentView({ appoID: appoID ?? "" }),
    [appoID]
  );
  const { appointment, invoices } = useSelector(getAppointment);

  return [loading, appointment, invoices] as [
    typeof loading,
    typeof appointment,
    typeof invoices
  ];
};
