import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  ClinicPreference,
  Preferences,
  HealthcareLicense,
  ClinicPreferenceForm,
} from "@udok/lib/api/models";
import {
  createClinicPreference,
  fetchClinicPreference,
  fetchListClinicPreference,
} from "@udok/lib/api/clinic";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  preferenceByID: { [prefID: string]: ClinicPreference | undefined };
  licenseTypes: Array<HealthcareLicense>;
};

// Reducers
const initialState: InitialState = {
  preferenceByID: {},
  licenseTypes: [],
};

class ClinicPreferenceSlice extends Hen<InitialState> {
  prefLoaded(pref: ClinicPreference) {
    this.state.preferenceByID[pref.prefID] = pref;
  }

  prefsLoaded(prefs: ClinicPreference[]) {
    prefs.forEach((pref) => {
      this.state.preferenceByID[pref.prefID] = pref;
    });
  }

  licenseTypesLoaded(t: Array<HealthcareLicense>) {
    this.state.licenseTypes = t;
  }
}

export const [Reducer, actions] = hen(new ClinicPreferenceSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.clinicPreferences;
export const preferenceSelector = (state: RootState) =>
  state.clinicPreferences.preferenceByID;

export const getPreferenceRepo = createSelector(mainSelector, (state) => ({
  preferenceByID: state.preferenceByID ?? {},
}));

export const licenseTypesView = createSelector(mainSelector, (state) => {
  return {
    list: state.licenseTypes ?? [],
  };
});

export const getOneClinicPreferences = (props: { prefID: string }) =>
  createSelector([preferenceSelector], (preferenceByID) => {
    return {
      location: preferenceByID[props.prefID],
    };
  });

export const getAllClinicPreferences = createSelector(
  [preferenceSelector],
  (preferenceByID) => {
    return {
      list: Object.keys(preferenceByID).map((prefID) => preferenceByID[prefID]),
    };
  }
);

//Actions
export function createOneClinicPreference(
  pref: ClinicPreferenceForm,
  section?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createClinicPreference(apiToken, pref)
      .then((r) => {
        dispatch(actions.prefLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification(section ?? "general", {
            status: "error",
            message: (e.response?.data?.error || e).message,
          }) as any
        );
        throw e;
      });
  };
}

export function fetchOneClinicPreference(
  prefID: Preferences
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const codedID = encodeURIComponent(prefID);
    return fetchClinicPreference(apiToken, codedID)
      .then((r) => {
        dispatch(actions.prefLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e.response?.data?.error || e).message,
          }) as any
        );
      });
  };
}

export function fetchAllClinicPreference(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchListClinicPreference(apiToken)
      .then((r) => {
        dispatch(actions.prefsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          }) as any
        );
      });
  };
}
