import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Badge } from "@material-ui/core";
import {
  GroupedVirtuoso as Virtuoso,
  GroupedVirtuosoProps,
} from "react-virtuoso";
import { format, getScrollbarWidth } from "@udok/lib/internal/util";
import Icons from "@udok/lib/components/Icon";
import clsx from "clsx";

import moment from "moment";
moment.locale("pt-br");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupSection: {
      padding: "2px 8px",
      background: "#dbdbdb",
      borderRadius: 8,
      margin: 12,
      marginBottom: 0,
    },
    scrollDownButtonContainer: {
      position: "absolute",
      bottom: 10,
      right: 0,
      marginRight: `calc(5px + ${getScrollbarWidth()}px)`,
    },
    scrollDownButton: {
      color: theme.palette.neutral.lightest,
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(0.5),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    scrollButtonHidden: {
      display: "none",
    },
  })
);

export type MessageListRef = { scrollToBottom: () => void };
export type MessageListProps = {
  listMessages: any[];
  unreadCount: number;
} & Omit<
  GroupedVirtuosoProps<undefined>,
  | "ref"
  | "followOutput"
  | "atBottomStateChange"
  | "overscan"
  | "groupCounts"
  | "initialTopMostItemIndex"
  | "group"
>;

const MessageList = React.forwardRef(
  (props: MessageListProps, ref: React.Ref<MessageListRef | null>) => {
    const { listMessages, unreadCount, ...others } = props;
    const [followOutput, setFollowOutput] = React.useState(true);
    const classes = useStyles();
    const virtuoso = React.useRef(null);

    const groupCounts = React.useMemo(() => {
      let counts: number[] = [];
      let groups: string[] = [];
      let date = "";
      let count = 0;
      listMessages.forEach((message: any, i, l) => {
        const d = message.createdAt.slice(0, 10);
        if (i === 0) {
          date = d;
          count++;
          if (i === l.length - 1) {
            groups.push(date);
            counts.push(count);
          }
          return;
        }
        if (d === date) {
          count++;
        }
        if (d !== date) {
          groups.push(date);
          counts.push(count);
          count = 1;
          date = d;
        }
        if (i === l.length - 1) {
          groups.push(date);
          counts.push(count);
        }
      });
      return { counts, groups };
    }, [listMessages]);

    const scrollToBottom = React.useCallback(() => {
      (virtuoso as any)?.current?.scrollToIndex?.({
        index: listMessages?.length - 1,
        align: "end",
      });
    }, [listMessages, virtuoso]);

    React.useImperativeHandle(ref, () => ({ scrollToBottom }));
    return (
      <>
        <Virtuoso
          ref={virtuoso}
          followOutput={followOutput}
          atBottomStateChange={setFollowOutput}
          groupCounts={groupCounts.counts}
          overscan={10}
          initialTopMostItemIndex={listMessages.length - 1}
          groupContent={(index) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.groupSection}>
                  {moment(groupCounts.groups[index], format.DASHUN).format(
                    format.EXTPTBR
                  )}
                </div>
              </div>
            );
          }}
          style={{ flex: 1 }}
          {...others}
        />
        <ScrollToBottom
          followOutput={followOutput}
          badgeContent={unreadCount}
          onClick={scrollToBottom}
        />
      </>
    );
  }
);

export default MessageList;

let scrollTime: any = null;
const ScrollToBottom = ({
  badgeContent = 0,
  followOutput,
  onClick,
}: {
  badgeContent?: number;
  followOutput: boolean;
  onClick: () => void;
}) => {
  const [openScrollDown, setOpenScrollDown] = React.useState(true);
  const classes = useStyles();
  React.useEffect(() => {
    if (!followOutput) {
      scrollTime = setTimeout(() => setOpenScrollDown(followOutput), 500);
    } else {
      clearTimeout(scrollTime);
      setOpenScrollDown(followOutput);
    }
  }, [followOutput]);

  return (
    <div
      className={clsx(classes.scrollDownButtonContainer, {
        [classes.scrollButtonHidden]: openScrollDown,
      })}
    >
      <Badge
        variant="standard"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        badgeContent={badgeContent}
        color="error"
        max={9}
        showZero={false}
      >
        <IconButton
          color="primary"
          className={classes.scrollDownButton}
          onClick={onClick}
        >
          <Icons.ScrollDown height={20} width={20} />
        </IconButton>
      </Badge>
    </div>
  );
};
