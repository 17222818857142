import { RootState, AppThunk } from "ducks/state";
import { fetchFile, fetchFileMetadata } from "@udok/lib/api/files";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { getToken, UNAUTHORIZED } from "./auth";
import fileDownload from "js-file-download";
import {
  FileData,
  AxiosProgressEvent,
  signedDownloadURL,
  downloadUrlParams,
} from "@udok/lib/api/models";
import {
  uploadOneFile as uploadFileToS3,
  getDownloadLink,
} from "@udok/lib/api/files";

export type Blob = any;

export type InitialState = {
  fileByID: { [fileID: string]: FileData };
  fileBlobByID: { [fileID: string]: Blob };
};

// Reducers
const initialState: InitialState = {
  fileByID: {},
  fileBlobByID: {},
};

class FilesSlice extends Hen<InitialState> {
  blobLoaded(fileID: string, v: Blob) {
    this.state.fileBlobByID[fileID] = v;
  }
  fileMetadataLoaded(f: FileData) {
    this.state.fileByID[f.fileID] = f;
  }
}

export const [Reducer, actions] = hen(new FilesSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.files;
const fileBlobByIDSelector = (state: RootState) => state.files.fileBlobByID;
const fileByIDSelector = (state: RootState) => state.files.fileByID;

export const fileDisplayView = (state: RootState, props: { fileID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      src: state.fileBlobByID[props.fileID],
      filename: state.fileByID[props.fileID]?.name,
    };
  });

// Actions
export function loadFile(fileID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const fbid = fileBlobByIDSelector(state)[fileID];
    if (fbid) {
      return Promise.resolve();
    }
    return fetchFile(apiToken, fileID)
      .then((r) => {
        dispatch(actions.blobLoaded(fileID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadFileData(fileID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const fbid = fileByIDSelector(state)[fileID];
    if (fbid) {
      return Promise.resolve();
    }
    return fetchFileMetadata(apiToken, fileID)
      .then((r) => {
        dispatch(actions.fileMetadataLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function downloadFileBlob(
  fileID: string,
  filename?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    try {
      if (filename === undefined) {
        const r = await fetchFileMetadata(apiToken, fileID);
        filename = r.name;
      }

      const file = await fetchFile(apiToken, fileID);
      fileDownload(file as any, filename);
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: (e as Error).message,
        })
      );
      throw e;
    }
  };
}

export function uploadOneFileToS3(
  f: File,
  cb?: (e: AxiosProgressEvent) => void
): AppThunk<Promise<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return uploadFileToS3(apiToken, f, "s3", cb)
      .then((r) => {
        return r.filename;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getSignedDownloadURL(
  data: downloadUrlParams
): AppThunk<Promise<signedDownloadURL>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return getDownloadLink(apiToken, data)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
