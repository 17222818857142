import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  TaxStatus,
  InvoiceView,
  TaxInvoiceView,
  TaxInvoiceFilter,
  TaxInvoiceSettingsView,
  TaxInvoiceSettingsForm,
  TaxInvoiceServiceCode,
  TaxInvoiceServiceCodeForm,
  TaxInvoiceServiceCodeFilter,
} from "@udok/lib/api/models";
import { fetchInvoices, fetchInvoice } from "@udok/lib/api/billing";
import {
  createTaxInvoice,
  cancelTaxInvoice,
  getTaxInvoice,
  listImmediateCare,
  getTaxInvoiceSettings,
  createTaxInvoiceSettings,
  createTaxInvoiceServiceCode,
  listTaxInvoiceServiceCode,
  deleteTaxInvoiceServiceCode,
} from "@udok/lib/api/TaxInvoice";
import { getToken, UNAUTHORIZED } from "./auth";
import {
  invoicesByIDSelector,
  listedInvoicesSelector,
  actions as actionsBilling,
} from "ducks/billing";

export type InitialState = {
  taxInvoiceByID: { [tainID: string]: TaxInvoiceView | undefined };
  taxInvoiceInvoID: { [invoID: string]: string[] | undefined };
  taxInvoiceSettings: TaxInvoiceSettingsView | undefined;
  taxInvoiceServiceCodes: TaxInvoiceServiceCode[];
};

// Reducers
const initialState: InitialState = {
  taxInvoiceByID: {},
  taxInvoiceInvoID: {},
  taxInvoiceSettings: undefined,
  taxInvoiceServiceCodes: [],
};

class FinancialsSlice extends Hen<InitialState> {
  taxInvoicesLoaded(list: TaxInvoiceView[]) {
    list.forEach((tax) => {
      this.state.taxInvoiceByID[tax.tainID] = tax;
      const current = this.state.taxInvoiceInvoID?.[tax.invoID] ?? [];
      this.state.taxInvoiceInvoID[tax.invoID] = [...current, tax.tainID];
    });
  }
  taxInvoiceLoaded(tax: TaxInvoiceView) {
    this.state.taxInvoiceByID[tax.tainID] = tax;
    const current = this.state.taxInvoiceInvoID?.[tax.invoID] ?? [];
    this.state.taxInvoiceInvoID[tax.invoID] = [...current, tax.tainID].filter(
      (v, i, a) => a.indexOf(v) === i
    );
  }
  invoiceSettingsLoaded(v: TaxInvoiceSettingsView) {
    this.state.taxInvoiceSettings = v;
  }
  oneTaxInvoiceServiceCodeLoaded(tisc: TaxInvoiceServiceCode) {
    const current = this.state.taxInvoiceServiceCodes;
    const index = this.state.taxInvoiceServiceCodes.findIndex(
      (t) => t.tiscID === tisc.tiscID
    );
    if (index === -1) {
      this.state.taxInvoiceServiceCodes = [tisc, ...current];
    } else {
      this.state.taxInvoiceServiceCodes[index] = tisc;
    }
  }
  removeInvoiceServiceCode(tisc: TaxInvoiceServiceCode) {
    const current = [...this.state.taxInvoiceServiceCodes];
    const index = this.state.taxInvoiceServiceCodes.findIndex(
      (t) => t.tiscID === tisc.tiscID
    );
    if (index > -1) {
      current.splice(index, 1);
    }
    this.state.taxInvoiceServiceCodes = current;
  }
  taxInvoiceServiceCodeLoaded(list: TaxInvoiceServiceCode[]) {
    this.state.taxInvoiceServiceCodes = list;
  }
}

export const [Reducer, actions] = hen(new FinancialsSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const taxInvoiceSelector = (state: RootState) =>
  state.financials.taxInvoiceByID;
const taxInvoiceByInvoIDSelector = (state: RootState) =>
  state.financials.taxInvoiceInvoID;
const taxInvoiceSettingsSelector = (state: RootState) =>
  state.financials.taxInvoiceSettings;
const taxInvoiceServiceCodesSelector = (state: RootState) =>
  state.financials.taxInvoiceServiceCodes;

export const invoiceListView = createSelector(
  [invoicesByIDSelector, listedInvoicesSelector],
  (invoiceByID, listedInvoices) => {
    return {
      list: listedInvoices
        .map((invoID) => invoiceByID[invoID])
        .filter((inv) => !!inv) as InvoiceView[],
    };
  }
);

export const listTaxInvoiceServiceCodes = createSelector(
  taxInvoiceServiceCodesSelector,
  (list) => {
    return {
      list,
    };
  }
);

export const oneInvoiceView = (props: { invoID: string }) =>
  createSelector([invoicesByIDSelector], (invoiceByID) => {
    return {
      invoice: invoiceByID[props.invoID],
    };
  });

export const invoicesView = (
  state: RootState,
  props: { codoIDList?: string[] }
) =>
  createSelector([invoicesByIDSelector], (invoiceByID) => {
    return {
      invoices: (props?.codoIDList ?? []).map(
        (invoID) => invoiceByID[invoID] ?? {}
      ),
    };
  });

export const getTaxInvoiceByInvoID = (props: { invoID: string }) =>
  createSelector(
    [taxInvoiceSelector, taxInvoiceByInvoIDSelector],
    (taxInvoiceByID, taxInvoiceInvoID) => {
      const list = taxInvoiceInvoID?.[props?.invoID] ?? [];
      return {
        taxInvoices: list
          .map((tainID) => taxInvoiceByID?.[tainID])
          .filter((tax) => !!tax) as TaxInvoiceView[],
      };
    }
  );

export const getOneTaxInvoice = (props: { tainID: string }) =>
  createSelector([taxInvoiceSelector], (taxInvoiceByID) => {
    return {
      taxInvoice: taxInvoiceByID?.[props?.tainID],
    };
  });

export const getInvoiceSettings = createSelector(
  [taxInvoiceSettingsSelector],
  (taxInvoiceSettings) => {
    return {
      lastIssuedSeries: taxInvoiceSettings?.settings?.lastIssuedSeries,
      lastIssuedNumber: taxInvoiceSettings?.settings?.lastIssuedNumber,
      data: taxInvoiceSettings?.settings?.settings,
      formSchema: taxInvoiceSettings?.formSchema,
      uiSchema: taxInvoiceSettings?.uiSchema,
    };
  }
);

// Actions
export function loadAllInvoices(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchInvoices(apiToken, {})
      .then((r) => {
        dispatch(actionsBilling.invoicesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneInvoice(invoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchInvoice(apiToken, invoID)
      .then((r) => {
        dispatch(actionsBilling.invoiceLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedInvoice(invoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const invoiceExist = Boolean(state.billing.invoicesByID[invoID]);
    if (invoiceExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneInvoice(invoID));
  };
}

export function createNewTaxInvoice(invoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createTaxInvoice(apiToken, invoID)
      .then((r) => {
        dispatch(actions.taxInvoiceLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function cancelOneTaxInvoice(
  tainID: string
): AppThunk<Promise<TaxInvoiceView>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return cancelTaxInvoice(apiToken, tainID)
      .then((r) => {
        dispatch(actions.taxInvoiceLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message:
              r.lastStatus.status === TaxStatus.canceled
                ? "Cancelado com sucesso"
                : "Não foi possivel solicitar o cancelamento",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneTaxInvoice(tainID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return getTaxInvoice(apiToken, tainID)
      .then((r) => {
        dispatch(actions.taxInvoiceLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedTaxInvoice(tainID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const invoiceExist = Boolean(state.financials.taxInvoiceByID[tainID]);
    if (invoiceExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneTaxInvoice(tainID));
  };
}

export function loadTaxInvoices(
  filter?: TaxInvoiceFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return listImmediateCare(apiToken, filter)
      .then((r) => {
        dispatch(actions.taxInvoicesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createNewTaxInvoiceSettings(
  data: TaxInvoiceSettingsForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createTaxInvoiceSettings(apiToken, data)
      .then((r) => {
        dispatch(actions.invoiceSettingsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadTaxInvoiceSettings(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return getTaxInvoiceSettings(apiToken)
      .then((r) => {
        dispatch(actions.invoiceSettingsLoaded(r));
      })
      .catch((e) => {
        console.warn(e);
        throw e;
      });
  };
}

export function loadCachedTaxInvoiceSettings(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const exist = Boolean(state.financials.taxInvoiceSettings);
    if (exist) {
      return Promise.resolve();
    }
    return dispatch(loadTaxInvoiceSettings());
  };
}

export function loadTaxInvoiceServiceCode(
  filter?: TaxInvoiceServiceCodeFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return listTaxInvoiceServiceCode(apiToken, filter)
      .then((r) => {
        dispatch(actions.taxInvoiceServiceCodeLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeTaxInvoiceServiceCode(
  tiscID: number
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteTaxInvoiceServiceCode(apiToken, tiscID)
      .then((r) => {
        dispatch(actions.removeInvoiceServiceCode(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Removido com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createNewTaxInvoiceServiceCode(
  data: TaxInvoiceServiceCodeForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createTaxInvoiceServiceCode(apiToken, data)
      .then((r) => {
        dispatch(actions.oneTaxInvoiceServiceCodeLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
