import { Theme } from "@material-ui/core";
import { Mode } from "@udok/lib/api/models";

export enum Permission {
  Admin = "admin",
  Agenda = "agenda",
}

export interface PermissionList {
  value: Permission;
  label: string;
}

export const PermissionLabels: { [p: string]: string } = {
  [Permission.Admin]: "Admin",
  [Permission.Agenda]: "Agenda",
};

export const Permissions: Array<PermissionList> = [
  { value: Permission.Admin, label: PermissionLabels[Permission.Admin] },
  { value: Permission.Agenda, label: PermissionLabels[Permission.Agenda] },
];

export const Week = {
  sunday: "domingo",
  monday: "segunda",
  tuesday: "terça",
  wednesday: "quarta",
  thursday: "quinta",
  friday: "sexta",
  saturday: "sábado",
};

export const ShortWeek = {
  sunday: "dom",
  monday: "seg",
  tuesday: "ter",
  wednesday: "qua",
  thursday: "qui",
  friday: "sex",
  saturday: "sáb",
};

export const DaysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const DaysOfWeekLabel = {
  monday: "Segunda",
  tuesday: "Terça",
  wednesday: "Quarta",
  thursday: "Quinta",
  friday: "Sexta",
  saturday: "Sábado",
  sunday: "Domingo",
};

export const UfList = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
];

export const RegionList = [
  { value: "1", label: "Região 1" },
  { value: "1", label: "Região 1" },
  { value: "1", label: "Região 1" },
  { value: "2", label: "Região 2" },
  { value: "3", label: "Região 3" },
  { value: "4", label: "Região 4" },
  { value: "5", label: "Região 5" },
  { value: "6", label: "Região 6" },
  { value: "7", label: "Região 7" },
  { value: "8", label: "Região 8" },
  { value: "9", label: "Região 9" },
  { value: "10", label: "Região 10" },
  { value: "11", label: "Região 11" },
  { value: "12", label: "Região 12" },
  { value: "13", label: "Região 13" },
  { value: "14", label: "Região 14" },
  { value: "15", label: "Região 15" },
  { value: "16", label: "Região 16" },
  { value: "17", label: "Região 17" },
  { value: "18", label: "Região 18" },
  { value: "19", label: "Região 19" },
  { value: "20", label: "Região 20" },
  { value: "21", label: "Região 21" },
  { value: "22", label: "Região 22" },
  { value: "23", label: "Região 23" },
  { value: "24", label: "Região 24" },
  { value: "25", label: "Região 25" },
  { value: "26", label: "Região 26" },
  { value: "27", label: "Região 27" },
];

export const AccessRouteList = [
  { label: "IV", value: "USO INTRAVENOSO:" },
  { label: "IM", value: "USO INTRAMUSCULAR:" },
  { label: "Oral", value: "USO ORAL:" },
  { label: "Tópica", value: "USO TÓPICO:" },
  { label: "Sublingual", value: "USO SUBLINGUAL:" },
  { label: "Subcutânea", value: "USO SUBCUTÂNEO:" },
  { label: "Retal", value: "USO RETAL:" },
];

export const FrequencyList = [
  { label: "2/2h", value: "de 2 em 2 horas" },
  { label: "3/3h", value: "de 3 em 3 horas" },
  { label: "4/4h", value: "de 4 em 4 horas" },
  { label: "6/6h", value: "de 6 em 6 horas" },
  { label: "8/8h", value: "de 8 em 8 horas" },
  { label: "12/12h", value: "de 12 em 12 horas" },
  { label: "1/D", value: "uma vez por dia" },
  { label: "1/S", value: "uma vez por semana" },
  { label: "1/M", value: "uma vez por mês" },
  { label: "1x", value: "uma vez" },
  { label: "2x", value: "duas vezes" },
  { label: "3x", value: "três vezes" },
  { label: "ACM", value: "á critério médico" },
  { label: "UNICA", value: "uma única vez" },
  { label: "NO MOMENTO", value: "no momento" },
];

export const DefaultAptyID = "default";

export const DefaultPlan = "1";

export enum AppoFormState {
  updateData = "updateData",
  payment = "payment",
  uploadAttachments = "uploadAttachments",
  consultationInfo = "consultationInfo",

  uploadForms = "uploadAttachments.uploadForms",
  uploadDocuments = "uploadAttachments.uploadDocuments",

  pendingAlert = "pendingAlert",
  successAlert = "successAlert",
}

export const TimeZonesInSeconds = [
  { value: 43200, label: "+12h" },
  { value: 39600, label: "+11h" },
  { value: 36000, label: "+10h" },
  { value: 32400, label: "+9h" },
  { value: 28800, label: "+8h" },
  { value: 25200, label: "+7h" },
  { value: 21600, label: "+6h" },
  { value: 18000, label: "+5h" },
  { value: 14400, label: "+4h" },
  { value: 10800, label: "+3h" },
  { value: 7200, label: "+2h" },
  { value: 3600, label: "+1h" },
  { value: 0, label: "UTC" },
  { value: -3600, label: "-1h" },
  { value: -7200, label: "-2h" },
  { value: -10800, label: "Horário de Brasília" },
  { value: -14400, label: "-4h" },
  { value: -18000, label: "-5h" },
  { value: -21600, label: "-6h" },
  { value: -25200, label: "-7h" },
  { value: -28800, label: "-8h" },
  { value: -32400, label: "-9h" },
  { value: -36000, label: "-10h" },
  { value: -39600, label: "-11h" },
  { value: -43200, label: "-12h" },
];

export const PrescriptionAny = "*";

export const DefaultPlteID = "default";

export const FutureInfiniteDate = "9999-12-31";

export const MaxFileSize = 50000000;

export const DefaultCancelAfterMinutes = 30;

export const PatientSexText = `Aqui, o termo "sexo" se refere à divisão biológica em macho e fêmea.
Não deve ser preenchido baseado em identidade de gênero.`;

export const PhonePrefixesByCountry = [
  { value: "+55", label: "+55 Brasil" },
  { value: "+51", label: "+51 Peru" },
  { value: "+54", label: "+54 Argentina" },
  { value: "+1", label: "+1 Estados Unidos" },
  { value: "+56", label: "+56 Chile" },
  { value: "+57", label: "+57 Colômbia" },
  { value: "+58", label: "+58 Venezuela" },
  { value: "+591", label: "+591 Bolívia" },
  { value: "+592", label: "+592 Guiana" },
  { value: "+593", label: "+593 Equador" },
  { value: "+594", label: "+594 Guiana Francesa" },
  { value: "+595", label: "+595 Paraguai" },
  { value: "+597", label: "+597 Suriname" },
  { value: "+598", label: "+598 Uruguai" },
  { value: "+1", label: "+1 Anguila" },
  { value: "+1", label: "+1 Antígua e Barbuda" },
  { value: "+1", label: "+1 Bahamas" },
  { value: "+1", label: "+1 Barbados" },
  { value: "+1", label: "+1 Bermudas" },
  { value: "+1", label: "+1 Canadá" },
  { value: "+1", label: "+1 Dominica" },
  { value: "+1", label: "+1 Granada" },
  { value: "+1", label: "+1 Guam" },
  { value: "+1", label: "+1 Ilhas Caimã" },
  { value: "+1", label: "+1 Ilhas Marianas do Norte" },
  { value: "+1", label: "+1 Ilhas Virgens Americanas" },
  { value: "+1", label: "+1 Ilhas Virgens Britânicas" },
  { value: "+1", label: "+1 Jamaica" },
  { value: "+1", label: "+1 Monserrate" },
  { value: "+1", label: "+1 Porto Rico" },
  { value: "+1", label: "+1 República Dominicana" },
  { value: "+1", label: "+1 Samoa Americana" },
  { value: "+1", label: "+1 Santa Lúcia" },
  { value: "+1", label: "+1 São Cristóvão e Neves" },
  { value: "+1", label: "+1 São Martinho" },
  { value: "+1", label: "+1 São Vicente e Granadinas" },
  { value: "+1", label: "+1 Trindade e Tobago" },
  { value: "+1", label: "+1 Turcas e Caicos" },
  { value: "+20", label: "+20 Egipto" },
  { value: "+211", label: "+211 Sudão do Sul" },
  { value: "+212", label: "+212 Marrocos" },
  { value: "+212", label: "+212 Saara Ocidental" },
  { value: "+213", label: "+213 Argélia" },
  { value: "+216", label: "+216 Tunísia" },
  { value: "+218", label: "+218 Líbia" },
  { value: "+220", label: "+220 Gâmbia" },
  { value: "+221", label: "+221 Senegal" },
  { value: "+222", label: "+222 Mauritânia" },
  { value: "+223", label: "+223 Mali" },
  { value: "+224", label: "+224 Guiné" },
  { value: "+225", label: "+225 Costa do Marfim" },
  { value: "+226", label: "+226 Burquina Fasso" },
  { value: "+227", label: "+227 Níger" },
  { value: "+228", label: "+228 Togo" },
  { value: "+229", label: "+229 Benim" },
  { value: "+230", label: "+230 Maurícia" },
  { value: "+231", label: "+231 Libéria" },
  { value: "+232", label: "+232 Serra Leoa" },
  { value: "+233", label: "+233 Gana" },
  { value: "+234", label: "+234 Nigéria" },
  { value: "+235", label: "+235 Chade" },
  { value: "+236", label: "+236 República Centro-Africana" },
  { value: "+237", label: "+237 Camarões" },
  { value: "+238", label: "+238 Cabo Verde" },
  { value: "+239", label: "+239 São Tomé e Príncipe" },
  { value: "+240", label: "+240 Guiné Equatorial" },
  { value: "+500", label: "+500 Ilhas Malvinas" },
  { value: "+500", label: "+500 Ilhas Geórgia do Sul e Sandwich do Sul" },
  { value: "+241", label: "+241 Gabão" },
  { value: "+242", label: "+242 Congo-Brazavile" },
  { value: "+243", label: "+243 Congo-Quinxassa" },
  { value: "+244", label: "+244 Angola" },
  { value: "+245", label: "+245 Guiné-Bissau" },
  { value: "+246", label: "+246 Território Britânico do Oceano Índico" },
  { value: "+247", label: "+247 Ascensão" },
  { value: "+248", label: "+248 Seicheles" },
  { value: "+249", label: "+249 Sudão" },
  { value: "+250", label: "+250 Ruanda" },
  { value: "+251", label: "+251 Etiópia" },
  { value: "+252", label: "+252 Somália" },
  { value: "+253", label: "+253 Djibuti" },
  { value: "+254", label: "+254 Quênia" },
  { value: "+255", label: "+255 Tanzânia" },
  { value: "+256", label: "+256 Uganda" },
  { value: "+257", label: "+257 Burundi" },
  { value: "+258", label: "+258 Moçambique" },
  { value: "+260", label: "+260 Zâmbia" },
  { value: "+261", label: "+261 Madagascar" },
  { value: "+262", label: "+262 Maiote" },
  { value: "+262", label: "+262 Reunião" },
  { value: "+263", label: "+263 Zimbábue" },
  { value: "+264", label: "+264 Namíbia" },
  { value: "+265", label: "+265 Maláui" },
  { value: "+266", label: "+266 Lesoto" },
  { value: "+267", label: "+267 Botsuana" },
  { value: "+268", label: "+268 Essuatíni" },
  { value: "+269", label: "+269 Comores" },
  { value: "+27", label: "+27 África do Sul" },
  { value: "+290", label: "+290 Santa Helena" },
  { value: "+290", label: "+290 Tristão da Cunha" },
  { value: "+291", label: "+291 Eritreia" },
  { value: "+297", label: "+297 Aruba" },
  { value: "+298", label: "+298 Ilhas Féroe" },
  { value: "+299", label: "+299 Groenlândia" },
  { value: "+30", label: "+30 Grécia" },
  { value: "+31", label: "+31 Países Baixos" },
  { value: "+32", label: "+32 Bélgica" },
  { value: "+33", label: "+33 França" },
  { value: "+34", label: "+34 Espanha" },
  { value: "+350", label: "+350 Gibraltar" },
  { value: "+351", label: "+351 Portugal" },
  { value: "+352", label: "+352 Luxemburgo" },
  { value: "+353", label: "+353 Irlanda" },
  { value: "+354", label: "+354 Islândia" },
  { value: "+355", label: "+355 Albânia" },
  { value: "+356", label: "+356 Malta" },
  { value: "+357", label: "+357 Chipre" },
  { value: "+358", label: "+358 Finlândia" },
  { value: "+359", label: "+359 Bulgária" },
  { value: "+36", label: "+36 Hungria" },
  { value: "+370", label: "+370 Lituânia" },
  { value: "+371", label: "+371 Letônia" },
  { value: "+372", label: "+372 Estónia" },
  { value: "+373", label: "+373 Moldávia" },
  { value: "+374", label: "+374 Armênia" },
  { value: "+375", label: "+375 Bielorrússia" },
  { value: "+376", label: "+376 Andorra" },
  { value: "+377", label: "+377 Mônaco" },
  { value: "+378", label: "+378 São Marinho" },
  { value: "+379", label: "+379 Vaticano" },
  { value: "+380", label: "+380 Ucrânia" },
  { value: "+381", label: "+381 Sérvia" },
  { value: "+382", label: "+382 Montenegro" },
  { value: "+383", label: "+383 Kosovo" },
  { value: "+385", label: "+385 Croácia" },
  { value: "+386", label: "+386 Eslovénia" },
  { value: "+387", label: "+387 Bósnia e Herzegovina" },
  { value: "+389", label: "+389 Macedônia do Norte" },
  { value: "+39", label: "+39 Itália" },
  { value: "+40", label: "+40 Romênia" },
  { value: "+41", label: "+41 Suíça" },
  { value: "+420", label: "+420 Tchéquia" },
  { value: "+421", label: "+421 Eslováquia" },
  { value: "+423", label: "+423 Liechtenstein" },
  { value: "+43", label: "+43 Áustria" },
  { value: "+44", label: "+44 Reino Unido" },
  { value: "+45", label: "+45 Dinamarca" },
  { value: "+46", label: "+46 Suécia" },
  { value: "+47", label: "+47 Noruega" },
  { value: "+48", label: "+48 Polônia" },
  { value: "+49", label: "+49 Alemanha" },
  { value: "+501", label: "+501 Belize" },
  { value: "+502", label: "+502 Guatemala" },
  { value: "+503", label: "+503 El Salvador" },
  { value: "+504", label: "+504 Honduras" },
  { value: "+505", label: "+505 Nicarágua" },
  { value: "+506", label: "+506 Costa Rica" },
  { value: "+507", label: "+507 Panamá" },
  { value: "+508", label: "+508 São Pedro e Miquelão" },
  { value: "+509", label: "+509 Haiti" },
  { value: "+52", label: "+52 México" },
  { value: "+53", label: "+53 Cuba" },
  { value: "+590", label: "+590 Guadalupe" },
  { value: "+590", label: "+590 São Bartolomeu" },
  { value: "+590", label: "+590 São Martinho" },
  { value: "+596", label: "+596 Martinica" },
  { value: "+599", label: "+599 Bonaire" },
  { value: "+599", label: "+599 Curaçao" },
  { value: "+599", label: "+599 Saba" },
  { value: "+599", label: "+599 Santo Eustáquio" },
  { value: "+60", label: "+60 Malásia" },
  { value: "+61", label: "+61 Austrália" },
  { value: "+61", label: "+61 Ilha Christmas" },
  { value: "+61", label: "+61 Ilhas Cocos" },
  { value: "+62", label: "+62 Indonésia" },
  { value: "+63", label: "+63 Filipinas" },
  { value: "+64", label: "+64 Nova Zelândia" },
  { value: "+64", label: "+64 Ilhas Pitcairn" },
  { value: "+65", label: "+65 Singapura" },
  { value: "+66", label: "+66 Tailândia" },
  { value: "+670", label: "+670 Timor-Leste" },
  { value: "+672", label: "+672 Ilha Norfolque" },
  { value: "+672", label: "+672 Território Antártico Australiano" },
  { value: "+673", label: "+673 Brunei" },
  { value: "+674", label: "+674 Nauru" },
  { value: "+675", label: "+675 Papua-Nova Guiné" },
  { value: "+676", label: "+676 Tonga" },
  { value: "+677", label: "+677 Ilhas Salomão" },
  { value: "+678", label: "+678 Vanuatu" },
  { value: "+679", label: "+679 Fiji" },
  { value: "+680", label: "+680 Palau" },
  { value: "+681", label: "+681 Wallis e Futuna" },
  { value: "+682", label: "+682 Ilhas Cook" },
  { value: "+683", label: "+683 Niue" },
  { value: "+685", label: "+685 Samoa" },
  { value: "+686", label: "+686 Quiribáti" },
  { value: "+687", label: "+687 Nova Caledônia" },
  { value: "+688", label: "+688 Tuvalu" },
  { value: "+689", label: "+689 Polinésia Francesa" },
  { value: "+690", label: "+690 Toquelau" },
  { value: "+691", label: "+691 Estados Federados da Micronésia" },
  { value: "+692", label: "+692 Ilhas Marshall" },
  { value: "+7", label: "+7 Cazaquistão" },
  { value: "+7", label: "+7 Rússia" },
  { value: "+81", label: "+81 Japão" },
  { value: "+82", label: "+82 Coreia do Sul" },
  { value: "+84", label: "+84 Vietnã" },
  { value: "+850", label: "+850 Coreia do Norte" },
  { value: "+852", label: "+852 Hong Kong" },
  { value: "+853", label: "+853 Macau" },
  { value: "+855", label: "+855 Camboja" },
  { value: "+856", label: "+856 Laos" },
  { value: "+86", label: "+86 China" },
  { value: "+880", label: "+880 Bangladexe" },
  { value: "+886", label: "+886 Taiwan" },
  { value: "+90", label: "+90 Turquia" },
  { value: "+91", label: "+91 Índia" },
  { value: "+92", label: "+92 Paquistão" },
  { value: "+93", label: "+93 Afeganistão" },
  { value: "+94", label: "+94 Seri Lanca" },
  { value: "+95", label: "+95 Mianmar" },
  { value: "+960", label: "+960 Maldivas" },
  { value: "+961", label: "+961 Líbano" },
  { value: "+962", label: "+962 Jordânia" },
  { value: "+963", label: "+963 Síria" },
  { value: "+964", label: "+964 Iraque" },
  { value: "+965", label: "+965 Kuwait" },
  { value: "+966", label: "+966 Arábia Saudita" },
  { value: "+967", label: "+967 Iêmen" },
  { value: "+968", label: "+968 Omã" },
  { value: "+970", label: "+970 Palestina" },
  { value: "+971", label: "+971 Emirados Árabes Unidos" },
  { value: "+972", label: "+972 Israel" },
  { value: "+973", label: "+973 Barém" },
  { value: "+974", label: "+974 Catar" },
  { value: "+975", label: "+975 Butão" },
  { value: "+976", label: "+976 Mongólia" },
  { value: "+977", label: "+977 Nepal" },
  { value: "+98", label: "+98 Irã" },
  { value: "+992", label: "+992 Tajiquistão" },
  { value: "+993", label: "+993 Turcomenistão" },
  { value: "+994", label: "+994 Azerbaijão" },
  { value: "+995", label: "+995 Geórgia" },
  { value: "+996", label: "+996 Quirguistão" },
  { value: "+998", label: "+998 Uzbequistão" },
];

export enum AppointmentSettingsRequiredFields {
  cpf = "cpf",
  address = "address",
  healthplanCardNumber = "healthplanCardNumber",
}

export enum BrowserNames {
  Mozilla = "Mozilla Firefox",
  Chrome = "Google Chrome",
  Edg = "Microsoft Edge",
}

const LinkSuportMozilla =
  "https://support.mozilla.org/pt-BR/kb/painel-de-permissoes-do-site";
const LinkSuportChrome =
  "https://support.google.com/chrome/answer/2693767?hl=pt-BR&co=GENIE.Platform%3DDesktop";
const LinkSuportEdg =
  "https://support.microsoft.com/pt-br/windows/windows-c%C3%A2mera-microfone-e-privacidade-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=permitir%20que%20um%20site%20use%20sua%20c%C3%A2mera%20ou%20microfone";
export const BrowserSupportLinks = {
  [BrowserNames.Mozilla]: LinkSuportMozilla,
  [BrowserNames.Chrome]: LinkSuportChrome,
  [BrowserNames.Edg]: LinkSuportEdg,
};

export const InviteAppointment = "appointment" as const;
export const InviteCreateAccount = "createAccount" as const;
export const InvitationForRecordsUpdate = "recordsUpdate" as const;
export const InvitationList: string[] = [
  InviteAppointment,
  InviteCreateAccount,
  InvitationForRecordsUpdate,
];

export const InvitationMessages = {
  [InviteAppointment]: `Olá %1,
  
Te convido a realizar um novo agendamento.

Veja os horários disponíveis através do site:

%2`,
  [InviteCreateAccount]: `Olá %1,

Te convido a criar um novo cadastro.

Preencha suas informações e crie sua conta através do site:

%2`,
  [InvitationForRecordsUpdate]: `Olá %1,

Te convido a atualizar o seu cadastro.

Preencha suas informações e atualize sua conta através do link:

%2`,
};

export enum FeatureFlag {
  DoctorNotificationCopyEmailAlert = "DoctorNotificationCopyEmailAlert",
}

export const FeatureFlags = {
  [FeatureFlag.DoctorNotificationCopyEmailAlert]: false,
};

export function withFeature<T>(flag: FeatureFlag, feature: T) {
  if (!FeatureFlags[flag]) {
    return undefined;
  }
  return feature;
}

export const UdokSupportContactNumber = "5571999203085";
export const UdokProTawkHelpBaseURL = "https://udok.tawk.help";
export enum UdokProTawkHelpOption {
  generalUdokPro = "generalUdokPro",
  generalDigitalReceipt = "generalDigitalReceipt",
  setUpPublicProfile = "setUpPublicProfile",
  manageSchedulesAppointments = "manageSchedulesAppointments",
}
export function getUdokProHelpURL(option: UdokProTawkHelpOption) {
  return (
    {
      [UdokProTawkHelpOption.generalUdokPro]:
        UdokProTawkHelpBaseURL + "/article/tour-geral-udok-pro",
      [UdokProTawkHelpOption.generalDigitalReceipt]:
        UdokProTawkHelpBaseURL + "/article/prescrição-digital",
      [UdokProTawkHelpOption.setUpPublicProfile]:
        UdokProTawkHelpBaseURL +
        "/article/como-configurar-o-link-público-de-agendamento",
      [UdokProTawkHelpOption.manageSchedulesAppointments]:
        UdokProTawkHelpBaseURL + "/category/agenda-agendamentos",
    }?.[option] ?? ""
  );
}

export const ErroDeadlineForChangeSubscription =
  "timeout for manual signature editing expired";

export const LimitedResourceAlertTexts = {
  title: "Assinatura Udok",
  description:
    "Seu acesso a esse recurso é limitado!\nEscolha um dos nossos planos para utilizar essa ferramenta.",
  buttonLabel: "Ver ofertas",
};

export const ConnectionTimeOptions = [
  { value: 30, label: "30 minutos" },
  { value: 60, label: "1 hora" },
  { value: 120, label: "2 horas" },
  { value: 240, label: "4 horas" },
  { value: 480, label: "8 horas" },
];

export const DefaultCustomOffers = [
  { label: "Telemedicina", mode: Mode.Virtual, visible: true },
  { label: "Agendamento presencial", mode: Mode.Place, visible: true },
];

export enum AppointmentType {
  Virtual = "virtual",
  Place = "place",
  ExamProcedure = "exam_procedure",
}

export const getAppointmentTypeData = (theme: Theme) => ({
  [AppointmentType.Virtual]: {
    label: "Virtual",
    color: theme.palette.primary.main,
  },
  [AppointmentType.Place]: {
    label: "Presencial",
    color: theme.palette.secondary.main,
  },
  [AppointmentType.ExamProcedure]: {
    label: "Exame/Proc",
    color: theme.palette.success.main,
  },
});

export enum gender {
  Female = "female",
  Male = "male",
}

export const getGenderData = (theme: Theme) => ({
  [gender.Female]: {
    label: "Feminino",
    color: theme.palette.primary.main,
  },
  [gender.Male]: {
    label: "Presencial",
    color: theme.palette.secondary.main,
  },
});

export enum ageRange {
  AgeUpToThirty = "ageUpToThirty",
  AgeBetweenThirtyAndSixtyFive = "ageBetweenThirtyAndSixtyFive",
  AgeAboveSixtyFive = "ageAboveSixtyFive",
}

export const getAgeRangeData = (theme: Theme) => ({
  [ageRange.AgeUpToThirty]: {
    label: "Até 30 anos",
    color: theme.palette.primary.main,
  },
  [ageRange.AgeBetweenThirtyAndSixtyFive]: {
    label: "Entre 30 e 65 anos",
    color: theme.palette.success.main,
  },
  [ageRange.AgeAboveSixtyFive]: {
    label: "Acima de 65 anos",
    color: theme.palette.warning.main,
  },
});

export const videoTypes = [
  "video/mp4",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/x-flv",
  "video/webm",
];

export const imageTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
  "image/tiff",
  "image/x-icon",
  "image/vnd.microsoft.icon",
  "image/vnd.adobe.photoshop",
  "image/vnd.dwg",
  "image/x-xcf",
  "image/heic",
  "image/heif",
  "image/jp2",
];

export const DefaultTussCode = "00000000";
