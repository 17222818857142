import React from "react";
import { connect } from "react-redux";
import { sendMessage } from "ducks/socket";
import { AppDispatch } from "ducks/state";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { InputBase, IconButton } from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import { getUserMe } from "ducks/user";
import AudioComposer from "./AudioComposer";
import FileComposer from "./FileComposer";
import QuickResponseComposer from "./QuickResponseComposer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      flex: 1,
      backgroundColor: "#fff",
      padding: "4px 14px",
      margin: 12,
      borderRadius: 50,
      "& textarea": {
        padding: "6px 0 7px",
        scrollbarWidth: "thin",
        scrollbarColor: "#dad7d7 #F4F4F4",
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F4",
        },
        "&::-webkit-scrollbar": {
          width: 6,
          background: "#F4F4F4",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#dad7d7",
        },
      },
    },
  })
);

type ComposerProps = {
  [k: string]: any;
} & ReturnType<typeof getUserMe> &
  ReturnType<typeof mapDispatchToProps>;

const Composer = (props: ComposerProps) => {
  const { userID, megrID, currentUser, sendMessage, handleSend } = props;
  const classes = useStyles();
  const [msg, setMsg] = React.useState("");
  const currentUserID = currentUser?.userID;

  const onSend = React.useCallback(
    (value: any, type: string, uri?: string, timeAudio?: string) => {
      let message: {
        targets: any;
        fromID: string;
        toID: string;
        type: string;
        value: any;
        data: any;
        megrID?: string;
        status: [string];
      };
      if (!value || !currentUserID || !userID) return;

      let targets = [currentUserID, userID];
      const d: any = {
        image: { fileID: uri },
        archive: { fileID: uri },
        audio: { fileID: uri, timeAudio: timeAudio },
      };
      message = {
        targets: targets,
        fromID: currentUserID,
        toID: userID,
        type: type,
        value: value,
        data: d[type] || {},
        status: [currentUserID],
      };
      if (megrID) {
        message.megrID = megrID;
      }
      sendMessage(message);
      handleSend?.();
    },
    [currentUserID, megrID, userID, sendMessage, handleSend]
  );

  const onSubmitText = () => {
    if (msg.trim().length > 0) {
      onSend(msg.trim(), "text");
      setMsg("");
    }
  };

  const handleAudio = (fileID: string, name: string, duration: string) => {
    onSend(name, "audio", fileID, duration);
  };
  const handleFile = (t: string, fileID: string, name: string) => {
    onSend(name, t, fileID, "");
  };
  const handleQuickMsg = (msg: string) => {
    onSend(msg, "text", "", "");
  };

  return (
    <>
      <QuickResponseComposer
        style={{ marginLeft: 12 }}
        userID={userID}
        currentUserID={currentUserID}
        onResponse={handleQuickMsg}
      />
      <FileComposer
        userID={userID}
        currentUserID={currentUserID}
        onFile={handleFile}
      />
      <InputBase
        multiline
        rowsMax={2}
        className={classes.input}
        placeholder="Digite uma mensagem"
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            onSubmitText();
          }
        }}
        inputProps={{ maxlength: 480 }}
      />
      <div style={{ marginRight: 12 }}>
        {msg.trim().length > 0 ? (
          <IconButton onClick={onSubmitText}>
            <Icons.Send />
          </IconButton>
        ) : (
          <AudioComposer
            userID={userID}
            currentUserID={currentUserID}
            onAudio={handleAudio}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendMessage: (message: any) => dispatch(sendMessage(message)),
});

export default connect(getUserMe, mapDispatchToProps)(Composer);
