import produce from "immer";
import { Action, Reducer } from "redux";
import { makeid } from "./util";

export function createReducer(intialState: any, handlers: any) {
  return (state = intialState, action: { type: string }) => {
    const handler = handlers[action.type];

    if (!handler) {
      return state;
    }
    return produce(state, (draft: any) => handler(draft, action));
  };
}

type SubType<Base, Condition> = Pick<
  Base,
  {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
  }[keyof Base]
>;

type GetArgumentType<original extends Function> = original extends (
  ...x: infer argumentsType
) => any
  ? argumentsType
  : never;
type QuickActionCreator<T extends Function, A> = (
  ...args: GetArgumentType<T>
) => Action<A>;
type ActionGroup<T> = {
  [K in keyof SubType<T, (...args: any) => void>]: T[K] extends Function
    ? QuickActionCreator<T[K], any>
    : never;
};

export class Hen<T> {
  name: string;
  state: T;

  constructor(initialState: T) {
    this.state = initialState;
    this.name = Math.random() * 100 + "";
  }
}

export function hen<T extends Hen<any>>(
  cls: T,
  injectedReducers: { [k: string]: any } = {}
): [Reducer, ActionGroup<T>] {
  const actionPrefix = (cls.name ?? cls.constructor.name) + makeid(5);
  let reducers: { [k: string]: any } = {};
  let actions: { [k: string]: any } = {};
  // create reducers
  Reflect.ownKeys(Reflect.getPrototypeOf(cls) ?? {})
    .concat(Reflect.ownKeys(cls))
    .forEach((key) => {
      if (key === "constructor") {
        return;
      }
      const actionType = `${actionPrefix}.${key as string}`;
      const p = (cls as any)[key];
      if (typeof p !== "function") {
        return;
      }

      reducers[actionType] = (
        state: T,
        action: { type: string; payload: any }
      ) => {
        let reducerClass = new (cls as any).constructor(state);
        reducerClass[key](...action.payload);
        return state;
      };

      actions[key as any] = function () {
        const act = {
          type: actionType,
          payload: Array.from(arguments),
        };
        return act;
      };
    });
  const red = createReducer(cls.state, { ...reducers, ...injectedReducers });
  return [red, actions as ActionGroup<T>];
}
