import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  TriggerAction,
  CreateTriggerForm,
  FilterTriggerAction,
  Trigger,
  Action,
} from "@udok/lib/api/models";
import {
  createTriggerAction,
  fetchTriggerActions,
  fetchTriggerAction,
  deleteTriggerAction,
  updateTriggerAction,
  fetchTriggers,
  fetchAction,
} from "@udok/lib/api/triggerActions";
import { getToken, UNAUTHORIZED } from "./auth";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  triggerActionByID: { [tracID: string]: TriggerAction | undefined };
  triggers: Trigger[];
  actions: Action[];
};

// Reducers
const initialState: InitialState = {
  triggerActionByID: {},
  triggers: [],
  actions: [],
};

class TriggerActions extends Hen<InitialState> {
  loadTriggerAction(ta: TriggerAction) {
    this.state.triggerActionByID[ta.tracID] = ta;
  }
  triggerActionsLoad(tas: TriggerAction[]) {
    tas.forEach((ta) => {
      this.state.triggerActionByID[ta.tracID] = ta;
    });
  }
  triggersLoad(t: Trigger[]) {
    this.state.triggers = t;
  }
  actionsLoad(t: Action[]) {
    this.state.actions = t;
  }
}

export const [Reducer, actions] = hen(new TriggerActions(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const triggerActionByIDSelector = (state: RootState) =>
  state.triggerActions.triggerActionByID;
const triggersSelector = (state: RootState) => state.triggerActions.triggers;
const actionsSelector = (state: RootState) => state.triggerActions.actions;

export const getListTriggerActions = createSelector(
  [triggerActionByIDSelector],
  (triggerActionByID) => {
    return {
      list: Object.keys(triggerActionByID)
        .map((tracID) => triggerActionByID[tracID])
        .filter((l) => !!l && !l.deletedAt)
        .sort((a, b) =>
          moment(b?.createdAt).diff(moment(a?.createdAt))
        ) as TriggerAction[],
    };
  }
);

export const getOneTriggerAction = (props: { tracID: string }) =>
  createSelector([triggerActionByIDSelector], (triggerActionByID) => {
    return {
      triggerAction: triggerActionByID?.[props.tracID],
    };
  });

export const getTriggersAndActions = createSelector(
  [triggersSelector, actionsSelector],
  (triggers, actions) => {
    return { triggers, actions };
  }
);

// Actions
export function createOneTriggerAction(
  form: CreateTriggerForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createTriggerAction(apiToken, form)
      .then((r) => {
        dispatch(actions.loadTriggerAction(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadTriggerActions(
  filter?: FilterTriggerAction
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchTriggerActions(apiToken, filter)
      .then((r) => {
        dispatch(actions.triggerActionsLoad(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneTriggerAction(tracID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchTriggerAction(apiToken, tracID)
      .then((r) => {
        dispatch(actions.loadTriggerAction(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedTriggerAction(
  tracID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const triggerActionExist = Boolean(
      state.triggerActions.triggerActionByID[tracID]
    );
    if (triggerActionExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneTriggerAction(tracID));
  };
}

export function removeTriggerAction(tracID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteTriggerAction(apiToken, tracID)
      .then((r) => {
        dispatch(actions.loadTriggerAction(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeTriggerAction(
  form: CreateTriggerForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateTriggerAction(apiToken, form)
      .then((r) => {
        dispatch(actions.loadTriggerAction(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadTriggers(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return fetchTriggers()
      .then((r) => {
        dispatch(actions.triggersLoad(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadActions(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return fetchAction()
      .then((r) => {
        dispatch(actions.actionsLoad(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
