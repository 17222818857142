import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={23.145} height={18.186} viewBox="0 0 23.145 18.186" {...props}>
      <g data-name="Group 299" fill="#fff">
        <path
          data-name="Path 2196"
          d="M13.658 67.145a8.2 8.2 0 003.313-2.409 5.145 5.145 0 000-6.638 8.2 8.2 0 00-3.313-2.409 11.633 11.633 0 00-4.566-.889 11.637 11.637 0 00-4.566.885A8.2 8.2 0 001.214 58.1 5.216 5.216 0 000 61.417a5.075 5.075 0 00.917 2.893 7.547 7.547 0 002.519 2.29q-.129.31-.265.568a3.274 3.274 0 01-.323.5l-.291.374q-.104.135-.336.381-.233.245-.3.323c0-.009-.017.011-.052.058s-.054.069-.058.065-.022.017-.052.064L1.718 69l-.032.064a.277.277 0 00-.026.077.505.505 0 00-.007.084.26.26 0 00.013.084.428.428 0 00.149.271.4.4 0 00.265.1h.039a10.825 10.825 0 001.111-.207 10.861 10.861 0 003.591-1.653 12.97 12.97 0 002.273.207 11.622 11.622 0 004.564-.882z"
          transform="translate(0 -54.804) translate(0 54.804) translate(0 -54.804)"
        />
        <path
          data-name="Path 2197"
          d="M190.138 165.746a5.041 5.041 0 00-.052-5.87 7.656 7.656 0 00-2.635-2.3 6.647 6.647 0 01-.568 5.244 8.645 8.645 0 01-2.48 2.738 11.437 11.437 0 01-3.41 1.666 13.646 13.646 0 01-3.991.581q-.387 0-1.136-.051a10.853 10.853 0 006.1 1.7 12.976 12.976 0 002.273-.207 10.315 10.315 0 004.7 1.86.39.39 0 00.284-.09.477.477 0 00.168-.284c0-.052 0-.08.013-.084s.011-.032-.007-.084l-.026-.078-.032-.064a.62.62 0 00-.045-.071.555.555 0 00-.052-.065l-.058-.064-.052-.058q-.064-.078-.3-.323t-.336-.381q-.1-.136-.291-.375a3.265 3.265 0 01-.323-.5q-.136-.258-.265-.568a7.578 7.578 0 002.521-2.272z"
          transform="translate(0 -54.804) translate(0 54.804) translate(-167.91 -152.928)"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
