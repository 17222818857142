import React from "react";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import theme from "@udok/lib/themes/paperbase";
import {
  createStyles,
  Grid,
  Hidden,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import AppointmentNewEra from "components/AdCopy/AppointmentScreens";
import { useHistory } from "react-router-dom";

export const PasswordResetChoose = Loadable({
  loader: () => import("containers/Auth/PasswordResetChoose"),
  loading: Loader,
});

export const PasswordResetSucess = Loadable({
  loader: () => import("containers/Auth/PasswordResetSucess"),
  loading: Loader,
});

export const SignoutContainer = Loadable({
  loader: () => import("containers/Auth/SignoutContainer"),
  loading: Loader,
});

export const PasswordReset = Loadable({
  loader: () => import("containers/Auth/PasswordReset"),
  loading: Loader,
});

export const TokenLogin = Loadable({
  loader: () => import("containers/Auth/FormTokenLogin"),
  loading: Loader,
});

export const OnboardingClinicForm = Loadable({
  loader: () => import("containers/Auth/OnboardingClinicForm"),
  loading: Loader,
});

const styles = (theme: Theme) =>
  createStyles({
    container: {
      margin: 0,
      padding: 0,
      height: "100vh",
    },
    grid: {
      height: "100%",
    },
    bg: {
      background:
        'linear-gradient( 45deg, rgba(3, 69, 183, 0.8), rgba(3, 77, 136, 0.5) ), url("/static/img/intro-bg.jpg") center top no-repeat',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    highlighBorder: {
      width: 600,
      borderRadius: "12px",
      border: "solid #093CFF 1px",
      boxShadow: "20px 20px 60px #004395ad, -20px -20px 60px #004395ad",
    },
    text: {
      margin: 24,
      color: theme.palette.neutral.lightest,
    },
    formSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        background:
          'linear-gradient( 45deg, rgba(3, 69, 183, 0.8), rgba(3, 77, 136, 0.5) ), url("/static/img/intro-bg.jpg") center top no-repeat',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    formInner: {
      margin: 24,
      maxWidth: 640,
    },
    formWrapper: {
      [theme.breakpoints.up("md")]: {
        maxWidth: 500,
      },
      [theme.breakpoints.down("md")]: {
        background: theme.palette.neutral.lightest,
        borderRadius: 24,
        border: "solid #093CFF 1px",
        boxShadow: "20px 20px 60px #004395ad, -20px -20px 60px #004395ad",
        padding: 24,
      },
    },
    formTitle: {
      color: theme.palette.primary.darkest,
      textAlign: "center",
      marginTop: 24,
    },
    formText: {
      color: theme.palette.neutral.darkest,
      textAlign: "center",
      marginTop: 24,
    },
  });

export interface OnboardingContainerProps extends WithStyles<typeof styles> {}
const OnboardingContainer = ({ classes }: OnboardingContainerProps) => {
  const history = useHistory();
  return (
    <div className={classes.container}>
      <Grid container className={classes.grid}>
        <Hidden mdDown>
          <Grid item lg={6} className={classes.bg}>
            <div className={classes.highlighBorder}>
              <Typography variant="h2" className={classes.text}>
                A nova era das consultas!
              </Typography>
              <Typography variant="body1" className={classes.text}>
                Agendamento de consultas, biblioteca colaborativa, plataforma de
                comunicação ágil, algoritmo inteligente de agendamento de
                consultas, tudo em um único lugar para otimizar a relação
                profissional de saúde-paciente.
              </Typography>
              <AppointmentNewEra />
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={6} className={classes.formSection}>
          <div className={classes.formWrapper}>
            <Typography variant="h4" className={classes.formTitle}>
              Modernize a sua clínica agora!
            </Typography>
            <Typography variant="body1" className={classes.formText}>
              Crie sua conta no Udok e comece a usar um sistema de consultas
              moderno e prático
            </Typography>
            <OnboardingClinicForm
              className={classes.formInner}
              onSuccess={({ slug }) => history.push(`/${slug}/admin`)}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const StyledOnboarding = withStyles(styles)(OnboardingContainer);

export default (props: OnboardingContainerProps) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledOnboarding {...props} />
    </ThemeProvider>
  );
};
