import * as React from "react";
// @ts-ignore
import createDOMForm from "rc-form/lib/createDOMForm";
// @ts-ignore
import { formShape } from "rc-form";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { QuickResponse } from "@udok/lib/api/models";
import { shrinker } from "@udok/lib/internal/util";
import TextListInput from "@udok/lib/components/Input/TextListInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    scrollContainer: {
      "& textarea": {
        scrollbarWidth: "thin",
        scrollbarColor: "#dad7d7 #F4F4F4",
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F4",
        },
        "&::-webkit-scrollbar": {
          width: 6,
          background: "#F4F4F4",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#dad7d7",
        },
      },
    },
  })
);

export type QuickResponseFormProps = {
  onSubmit: (err: any, values: any, form: any) => void;
  initialValues?: Partial<QuickResponse>;
  form: formShape;
  loading: boolean;
};

const QuickResponseForm = (props: QuickResponseFormProps) => {
  const { form, loading, initialValues, onSubmit } = props;
  const { getFieldError, getFieldValue, getFieldProps } = form;
  const classes = useStyles();
  const theme = useTheme();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFieldsAndScroll(
      { scroll: { offsetTop: theme.spacing(10) } },
      (err: any, values: any) => {
        onSubmit(err, values, form);
      }
    );
  };

  const validateString = (a: any, value: string, cb: (e?: string) => void) => {
    if (!value || !value.trim()) {
      cb("Campo obrigatorio");
      return;
    }
    cb();
  };

  const validateStringArray = (
    a: any,
    value: string[],
    cb: (e?: string) => void
  ) => {
    if (!value || value.length === 0) {
      cb("Campo obrigatorio");
      return;
    }
    cb();
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        required
        fullWidth
        margin="normal"
        variant="outlined"
        label="Titulo"
        InputProps={{ required: false }}
        InputLabelProps={{ ...shrinker(getFieldValue("title")) }}
        error={Boolean(getFieldError("title"))}
        helperText={getFieldError("title")}
        {...getFieldProps("title", {
          rules: [validateString],
          initialValue: initialValues?.title,
        })}
      />
      <TextListInput
        required
        label="Palavra-chave"
        margin="normal"
        error={Boolean(getFieldError("keywords"))}
        errorMessage={getFieldError("keywords")}
        {...getFieldProps("keywords", {
          rules: [validateStringArray],
          initialValue: initialValues?.keywords,
        })}
      />
      <TextField
        required
        multiline
        rows={2}
        fullWidth
        margin="normal"
        type="textarea"
        variant="outlined"
        label="Resposta"
        InputProps={{ required: false }}
        className={classes.scrollContainer}
        InputLabelProps={{ ...shrinker(getFieldValue("response")) }}
        error={Boolean(getFieldError("response"))}
        helperText={getFieldError("response")}
        {...getFieldProps("response", {
          rules: [validateString],
          initialValue: initialValues?.value,
        })}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: 24,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          <span>Salvar</span>
          {loading && <CircularProgress size={24} />}
        </Button>
      </div>
    </form>
  );
};

export default createDOMForm()(QuickResponseForm);
