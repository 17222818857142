import React, { CSSProperties } from "react";

export type ImagePlaceholderProps = {
  height?: number;
  style?: CSSProperties;
};
export default (props: ImagePlaceholderProps) => {
  return (
    <div
      style={{
        backgroundColor: "rgb(232,232,232)",
        background:
          "linear-gradient(0deg, rgba(232,232,232,1) 0%, rgba(230,230,230,0.9556023092830882) 100%)",
        width: "100%",
        height: props.height ?? 100,
        ...(props.style ?? {}),
      }}
    ></div>
  );
};
