import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M8.4.601a4.01 4.01 0 00-4 4c0 2.202 1.798 4 4 4s4-1.798 4-4-1.798-4-4-4zm0 1.2c1.553 0 2.8 1.247 2.8 2.8 0 1.554-1.246 2.8-2.8 2.8a2.79 2.79 0 01-2.8-2.8c0-1.553 1.246-2.8 2.8-2.8zm-4.6 8.4c-.987 0-1.8.813-1.8 1.8v.48c0 1.176.746 2.231 1.884 2.957C5.02 16.163 6.578 16.6 8.4 16.6s3.379-.438 4.516-1.163c1.138-.726 1.884-1.78 1.884-2.957V12c0-.987-.813-1.8-1.8-1.8H3.8zm0 1.2H13c.339 0 .6.261.6.6v.48c0 .666-.429 1.371-1.329 1.945-.9.574-2.244.975-3.871.975-1.628 0-2.971-.401-3.871-.975-.9-.574-1.329-1.28-1.329-1.945V12c0-.338.261-.6.6-.6z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .601)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
