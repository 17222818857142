import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "./Button/IconButtonOutlined";
import Icons from "./Icon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      maxHeight: "100%",
      maxWidth: "100%",
    },
    tools: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(1),
    },
    toolsItens: {
      marginRight: theme.spacing(1),
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      maxHeight: "100%",
      maxWidth: "100%",
      "&>div": {
        width: "100%",
      },
    },
    imageSizeControll: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
    },
    image: {
      objectFit: "contain",
      maxHeight: "100%",
      maxWidth: "100%",
    },
  })
);

export type ZoomImageProps = {
  src: string;
  alt: string;
  toolbar?: React.ReactNode | React.ReactNodeArray;
};

const ZoomImage = (props: ZoomImageProps) => {
  const { src, alt, toolbar } = props;
  const classes = useStyles();

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform, }: any) => (
        <React.Fragment>
          <div className={classes.container}>
            <div className={classes.tools}>
              {toolbar}
              <IconButton
                color="primary"
                onClick={zoomIn}
                className={classes.toolsItens}
              >
                <Icons.ZoomIn />
              </IconButton>
              <IconButton
                color="primary"
                onClick={zoomOut}
                className={classes.toolsItens}
              >
                <Icons.ZoomOut />
              </IconButton>
              <IconButton color="primary" onClick={resetTransform}>
                <Icons.ZoomOutMap />
              </IconButton>
            </div>
            <div className={classes.imageContainer}>
              <TransformComponent>
                <div className={classes.imageSizeControll}>
                  <img src={src} alt={alt} className={classes.image} />
                </div>
              </TransformComponent>
            </div>
          </div>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default ZoomImage;
