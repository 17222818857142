import React from "react";
import moment, { Moment } from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type CRUDFilter = {
  [k: string]: { filter: { [k: string]: any } };
};

export type RouterQueryString = { [k: string]: string | undefined };

export enum SettingsKeys {
  InstantAppointmentDuration = "instant_appointment_duration",
  PasswordResetSuccessActionTitle = "password_reset_success_action_title",
  PasswordResetSuccessActionUrl = "password_reset_success_action_url",
  CRUDListFilters = "crud_list_filters",
  RouterQueryString = "router_query_string",
  LastSeenSubscriptionUpdateAlert = "last_seen_subscription_update_alert",
}

export type Settings = {
  [SettingsKeys.InstantAppointmentDuration]: number;
  [SettingsKeys.PasswordResetSuccessActionTitle]: string;
  [SettingsKeys.PasswordResetSuccessActionUrl]: string;
  [SettingsKeys.LastSeenSubscriptionUpdateAlert]: string;
  [SettingsKeys.CRUDListFilters]: CRUDFilter;
  [SettingsKeys.RouterQueryString]: RouterQueryString;
};

type ExpirableSetting<K extends keyof Settings> = {
  value: Settings[K];
  expiredAt?: Moment;
};

export const getLocalConfig = <K extends keyof Settings>(key: K) => {
  const item = localStorage.getItem(key);
  let val: ExpirableSetting<K> | undefined;
  if (item) {
    try {
      val = JSON.parse(item);
    } catch (e) {
      return val;
    }
  }

  return val;
};

export const useLocalConfig = <K extends keyof Settings>(key: K) => {
  const [setting, setSetting] = React.useState(getLocalConfig(key));

  const setLocalConfig = React.useCallback(
    (value: Settings[K]) => {
      const val = JSON.stringify({ value });

      localStorage.setItem(key, val);
      setSetting({ value });
    },
    [key]
  );

  const value = setting?.value;
  return [value, setLocalConfig] as [typeof value, typeof setLocalConfig];
};

export const useExpiringLocalConfig = <K extends keyof Settings>(key: K) => {
  const [setting, setSetting] = React.useState(getLocalConfig(key));

  const setLocalConfig = React.useCallback(
    (value: Settings[K], expiredAt: Moment) => {
      const val = JSON.stringify({ value, expiredAt });

      localStorage.setItem(key, val);
      setSetting({ value, expiredAt });
    },
    [key]
  );

  let value = setting?.value;
  if (!!setting?.expiredAt && moment().isAfter(moment(setting?.expiredAt))) {
    value = undefined;
  }
  return [value, setLocalConfig] as [typeof value, typeof setLocalConfig];
};
