import React from "react";
import {
  createStyles,
  useTheme,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import AlertButton from "./AlertButton";
import { ActiveChatUserContext } from "@udok/lib/components/UI/UIContext";
import Conversations from "containers/Chat/Conversations";
import { useMediaQuery } from "@material-ui/core";
import Popover from "@udok/lib/components/ResponsivePopover";
import ItemComponent from "containers/Chat/Conversations/ItemComponent";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    popover: {
      height: 400,
      width: 400,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        width: "100%",
      },
    },
  })
);

export interface AlertMenuProps {
  id: string;
  actionComponent?: (
    props:
      | { onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }
      | any
  ) => React.ReactNode | React.ReactNodeArray | null;
}

function AlertMenu(props: AlertMenuProps) {
  const { actionComponent, id } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ActionButton = (actionComponent ?? AlertButton) as any;
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const { setChat } = React.useContext(ActiveChatUserContext);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserIDChange = React.useCallback(
    (userID: string) => {
      setAnchorEl(null);
      setChat({ userID });
    },
    [setChat]
  );

  const itemComponent = React.useCallback(
    (props: any) => {
      return <ItemComponent {...props} onClick={handleUserIDChange} />;
    },
    [handleUserIDChange]
  );

  return (
    <div>
      <ActionButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        small={isSmallerScreen}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.popover}>
          <Conversations listItemComponent={itemComponent} />
        </div>
      </Popover>
    </div>
  );
}

export default AlertMenu;
