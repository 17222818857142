import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  PrescriptionLayoutForm,
  PrescriptionLayoutView,
  PrescriptionLayoutFilter,
  PrescriptionLayoutBindings,
  PrescriptionLayoutHistory,
} from "@udok/lib/api/models";

export const createPrescriptionLayout = async (
  token: string,
  data: PrescriptionLayoutForm
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/prescription-layouts`, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as PrescriptionLayoutView;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl1");
      return Promise.reject(new Error(err));
    });
};

export const updatePrescriptionLayout = async (
  token: string,
  plteID: string,
  data: PrescriptionLayoutForm
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/prescription-layouts/${plteID}`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PrescriptionLayoutView;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl2");
      return Promise.reject(new Error(err));
    });
};

export const fetchPrescriptionLayout = (token: string, plteID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/prescription-layouts/${plteID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as PrescriptionLayoutView;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl3");
      return Promise.reject(new Error(err));
    });
};

export const fetchPrescriptionLayouts = (
  token: string,
  f?: PrescriptionLayoutFilter
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/prescription-layouts${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as PrescriptionLayoutView[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl4");
      return Promise.reject(new Error(err));
    });
};

export const deletePrescriptionLayout = async (
  token: string,
  plteID: string
) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/prescription-layouts/${plteID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PrescriptionLayoutView;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl5");
      return Promise.reject(new Error(err));
    });
};

export const createPrescriptionLayoutBinding = async (
  token: string,
  data: PrescriptionLayoutBindings
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/prescription-layout-bindings`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PrescriptionLayoutBindings;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl6");
      return Promise.reject(new Error(err));
    });
};

export const fetchPrescriptionLayoutBinding = async (
  token: string,
  resourceID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/prescription-layout-bindings/${resourceID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PrescriptionLayoutBindings;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl7");
      return Promise.reject(new Error(err));
    });
};

export const fetchPrescriptionLayoutHistory = (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/prescription-layout-history`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as PrescriptionLayoutHistory[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pl8");
      return Promise.reject(new Error(err));
    });
};
