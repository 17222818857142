import React from "react";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { loadUserMe, userLoadedView } from "ducks/user";
import { AppDispatch } from "ducks/state";
import Branch from "branch-sdk";

type UserLoaderProps = {
  loadUserMe: () => Promise<void>;
} & ReturnType<typeof userLoadedView>;

const UserLoader = React.memo((p: UserLoaderProps) => {
  const { loadUserMe, payload, profile } = p;
  const [loading, setL] = React.useState(false);
  const userID = profile?.userID;
  const email = profile?.email;

  React.useEffect(() => {
    setL(true);
    loadUserMe()
      .catch(console.warn)
      .finally(() => {
        setL(false);
      });
  }, [loadUserMe, payload]);

  React.useEffect(() => {
    try {
      Branch.setIdentity(`${email}||${userID}`);
    } catch (e) {
      Sentry.captureException(e);
    }
    try {
      Sentry.setUser({
        id: userID,
        email,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [userID, email]);

  return loading ? <LinearProgress color="primary" /> : null;
});

export default connect(userLoadedView, (dispatch: AppDispatch) => ({
  loadUserMe: () => dispatch(loadUserMe()),
}))(UserLoader);
