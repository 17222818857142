import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  QuickResponse,
  SendInvite,
  QuickResponseFilter,
} from "@udok/lib/api/models";

export const createQuickResponse = async (
  token: string,
  data: QuickResponse
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/responder/quickresponses`, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as QuickResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "msg1");
      return Promise.reject(err);
    });
};

export const fetchQuickResponse = async (token: string, QuickID: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/responder/quickresponses/${QuickID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as QuickResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "msg1");
      return Promise.reject(err);
    });
};

export const updateQuickResponse = async (
  token: string,
  data: QuickResponse
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/responder/quickresponses/${data.quickID}`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as QuickResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "msg2");
      return Promise.reject(err);
    });
};

export const fetchQuickResponses = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/responder/quickresponses`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as QuickResponse[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "msg3");
      return Promise.reject(err);
    });
};

const makeCancelableQuickResponsesFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, filter?: QuickResponseFilter) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const f = {
      limit: 100,
      ...filter,
    };
    return axios
      .get(
        `${
          process.env.REACT_APP_API_PATH
        }/responder/quickresponses${toQueryString(f)}`,
        {
          cancelToken: cancel.token,
          headers: {
            Authorization: token,
          },
        }
      )
      .then((r) => {
        return r.data.data.items as QuickResponse[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as QuickResponse[];
        }
        const err = deriveErrorMessage(e, "msg4");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchQuickResponseSearch = makeCancelableQuickResponsesFetch();

export const deleteQuickResponses = async (token: string, quickID: number) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/responder/quickresponses/${quickID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as QuickResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "msg5");
      return Promise.reject(err);
    });
};

export const sendInvitation = async (token: string, invite: SendInvite) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/invite-users`,
      invite,
      { headers: { Authorization: token } }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "msg6");
      return Promise.reject(new Error(err));
    });
};
