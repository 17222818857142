import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { getClinicProfile } from "ducks/clinic";
import { AppDispatch } from "ducks/state";
import { Clinic } from "@udok/lib/api/models";
import Image from "components/Placeholder/Image";

const defaultHeight = 120;

type ProfileLoaderProps = {
  profile?: Clinic;
  height?: number;
  style?: CSSProperties;
};
const ProfileLoader = (p: ProfileLoaderProps) => {
  const { profile, height } = p;

  return profile?.avatar ? (
    <img
      style={p.style}
      src={`${process.env.REACT_APP_BASE_PATH}/files/${profile.avatar}`}
      height={height ?? defaultHeight}
      alt={profile.name}
    />
  ) : (
    <Image style={p.style} height={height ?? defaultHeight} />
  );
};

export default connect(getClinicProfile, (dispatch: AppDispatch) => ({}))(
  ProfileLoader
);
