import React from "react";
import {
  MenuItem,
  List,
  ListItemText,
  Badge,
  Button,
  BadgeOrigin,
  PopoverProps,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ResponsivePopover from "@udok/lib/components/ResponsivePopover";
import { EventAlert } from "@udok/lib/api/models";
import { format, calculateTransparency } from "@udok/lib/internal/util";
import clsx from "clsx";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: theme.spacing(1, 2),
    },
    listContainer: {
      padding: theme.spacing(1),
      maxWidth: 500,
      maxHeight: "35vh",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "100%",
        maxWidth: "100%",
      },
    },
    scrollBar: {
      overflowX: "auto",
      scrollbarWidth: "thin",
      scrollbarColor: "#dad7d7 #F4F4F4",
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F4F4F4",
      },
      "&::-webkit-scrollbar": {
        width: 6,
        background: "#F4F4F4",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#dad7d7",
      },
    },
    itemContainer: {
      display: "flex",
      width: "100%",
    },
    title: {
      marginRight: theme.spacing(1),
    },
    listItem: {
      flexDirection: "column",
      whiteSpace: "break-spaces",
      position: "relative",
      marginBottom: theme.spacing(1),
    },
    listItemUnread: {
      backgroundColor: calculateTransparency(
        theme.palette.warning.lightest ?? "",
        theme.palette.neutral.lightest ?? "",
        0.3
      ),
      "&:hover": {
        backgroundColor: calculateTransparency(
          theme.palette.warning.lightest ?? "",
          theme.palette.neutral.lightest ?? "",
          0.6
        ),
      },
    },
    bottomContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      minHeight: theme.spacing(4.5),
    },
    readButton: {
      display: "none",
    },
  })
);

const EventButtonID = "event_button";
const EventButtonPopoverID = "event_button_popover";

export type Event = EventAlert & {
  text: {
    primary?: React.ReactNode;
    secondary?: React.ReactNode;
  };
  prefix?: React.ReactNode;
  sufix?: React.ReactNode;
};

type Props<C extends React.ElementType> = {
  component?: C;
  unreadCount?: number;
  list: Event[];
  listTitle: string;
  badgeOrigin?: BadgeOrigin;
  popoverProps?: Omit<PopoverProps, "id" | "open" | "anchorEl" | "onClose">;
  emptyText?: string;
  actionItems?: React.ReactNode;
  loading?: boolean;
  onRead?: (eve: Event) => void;
  onPressItem?: (eve: Event) => void;
} & Omit<React.ComponentProps<C>, "option">;

const EventAlerts = <C extends React.ElementType>(props: Props<C>) => {
  const {
    component,
    unreadCount = 0,
    list,
    badgeOrigin,
    popoverProps,
    children,
    emptyText = "Lista vazia",
    listTitle = "",
    actionItems,
    loading,
    onPressItem,
    onRead,
    ...others
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handlePress = React.useCallback(
    (event: Event) => {
      onPressItem?.(event);
      handleClose();
    },
    [onPressItem, handleClose]
  );

  const Btn = component ?? Button;
  return (
    <>
      <Btn {...others} id={EventButtonID} onClick={handleOpen}>
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
            ...badgeOrigin,
          }}
          badgeContent={
            loading ? (
              <CircularProgress color="inherit" size={8} />
            ) : (
              unreadCount
            )
          }
          color="error"
          max={9}
          showZero={false}
        >
          {children}
        </Badge>
      </Btn>
      <ResponsivePopover
        id={EventButtonPopoverID}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        dialogCloseOnGoBack
        {...popoverProps}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
          ...popoverProps?.anchorOrigin,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
          ...popoverProps?.transformOrigin,
        }}
        popoverHeaderClassName={classes.header}
        actionItems={actionItems}
        title={
          <Typography className={classes.title}>
            <b>{listTitle}</b>
          </Typography>
        }
      >
        <List className={clsx(classes.listContainer, classes.scrollBar)}>
          {list.map((eve, i) => (
            <RenderItem
              key={`${eve.notiID}-${i}`}
              event={eve}
              onClick={handlePress}
              onRead={onRead}
            />
          ))}
          {list.length == 0 ? (
            <MenuItem disabled>
              <ListItemText
                primaryTypographyProps={{ style: { textAlign: "center" } }}
                primary={emptyText}
              />
            </MenuItem>
          ) : null}
        </List>
      </ResponsivePopover>
    </>
  );
};

const RenderItem = ({
  event,
  onClick,
  onRead,
}: {
  event: Event;
  onClick?: (eve: Event) => void;
  onRead?: (eve: Event) => void;
}) => {
  const classes = useStyles();
  const createAt = moment(event.createdAt);
  const notfDate = createAt.isValid()
    ? createAt.local().format(`${format.DAYMON} ${format.TIME24H}`)
    : undefined;

  return (
    <Badge
      color="error"
      style={{ flexDirection: "column", width: "100%" }}
      variant="dot"
      invisible={!!event?.readAt}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem
        className={clsx(classes.listItem, {
          [classes.listItemUnread]: !event?.readAt,
        })}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.(event);
        }}
      >
        <div className={classes.itemContainer}>
          {event?.prefix}
          <ListItemText
            primary={event.text?.primary}
            secondary={event.text?.secondary}
          />
          {event?.sufix}
        </div>
        <div className={classes.bottomContainer}>
          <Typography style={{ fontSize: 12 }} color="textSecondary">
            {notfDate}
          </Typography>
          <Button
            color="default"
            className={clsx({
              [classes.readButton]: event?.readAt || !onRead,
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRead?.(event);
            }}
          >
            Marcar como lido
          </Button>
        </div>
      </MenuItem>
    </Badge>
  );
};

export default EventAlerts;
