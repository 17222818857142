import React from "react";
import { connect } from "react-redux";
import { createOnetimeLogin } from "ducks/user";
import Icons from "components/Icon";

import Dialog from "@udok/lib/components/Dialog/ResponsiveDialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

interface BubleVideoSessionProps {
  viseID: string;
  createOnetimeLogin: () => Promise<string>;
  loading?: boolean;
  mode?: "modal" | "window";
}

const BubleVideoSession = (props: BubleVideoSessionProps) => {
  const { mode = "window", createOnetimeLogin, viseID } = props;
  const classes = useStyles();
  const [src, setSrc] = React.useState("");
  const handlePress = () => {
    const action = mode === "modal" ? handleClickOpen : window.open;
    if (src) {
      action(src);
      return;
    }
    createOnetimeLogin().then((token) => {
      const room = viseID;
      const src = `${process.env.REACT_APP_VIDEOSERVER_PATH}/${encodeURI(
        room
      )}?token=${token}`;
      setSrc(src);
      action(src);
    });
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenNew = () => {
    window.open(src);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "#008893",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={handlePress}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: 20,
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div style={{ maxWidth: 155, marginRight: 15 }}>
            <p style={{ color: "#fff", fontSize: 16 }}>
              Foi iniciada uma sessão de vídeo
            </p>
          </div>
          <div
            style={{
              display: "flex",
              width: 48,
              height: 48,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#00ACBA",
              borderRadius: 100,
              borderWidth: 0,
            }}
          >
            <Icons.Videocam style={{ color: "#fff" }} />
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Icons.Close />
            </IconButton>

            <IconButton
              edge="end"
              color="inherit"
              onClick={handleOpenNew}
              aria-label="close"
            >
              <Icons.Launch />
            </IconButton>
          </Toolbar>
        </AppBar>
        {src ? (
          <iframe
            title="Udok Vídeo"
            style={{ height: "100%" }}
            allow="camera; microphone"
            src={src}
          ></iframe>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default connect(
  () => ({}),
  (dispatch: any) => ({
    createOnetimeLogin: () => dispatch(createOnetimeLogin()),
  })
)(BubleVideoSession);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
