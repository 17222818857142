import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      isolation: "isolate",
    }}
    viewBox="0 0 18 18"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" fillRule="evenodd">
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={2.881}
        cy={8.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={5}
        cy={8.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={7.119}
        cy={8.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={9.238}
        cy={8.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={8.75}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={9.238}
        cy={10.875}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={9.238}
        cy={13}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={9.238}
        cy={15.125}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={9.238}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={10.875}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={13}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={15.125}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={0.762}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={2.881}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={5}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx={7.119}
        cy={17.25}
        rx={0.762}
        ry={0.75}
      />
      <path d="M12 18H2.75h12.5c1.51 0 2.75-1.24 2.75-2.75V2.75C18 1.24 16.76 0 15.25 0H2.75C1.24 0 0 1.24 0 2.75V6c.007.402.34.726.75.726s.743-.324.75-.726V2.75c0-.699.551-1.25 1.25-1.25h12.5c.699 0 1.25.551 1.25 1.25v12.5a1.24 1.24 0 0 1-1.25 1.25h-3.262a.757.757 0 0 0-.763.75c0 .414.342.75.763.75H12Z" />
    </g>
  </svg>
);

export default SvgComponent;
