import { createReducer } from "@udok/lib/internal/store";
import { createSelector } from "reselect";
import { Permission as pm } from "@udok/lib/internal/constants";
import { selectUserPermissions } from "ducks/user";
import { RootState } from "ducks/state";
import Icons from "components/Icon";

export interface MenuItem {
  title: string;
  description?: string;
  to: string;
  icon: keyof typeof Icons;
  illustration?: string;
  permissions: pm[];
}

export interface MenuView {
  title?: string;
  to?: string;
  icon?: keyof typeof Icons;
  permissions?: pm[];
  children: MenuItem[];
}

export type InitialState = {
  lateralMenu: MenuView[];
  quickAccess: MenuView[];
  finances: MenuView[];
  home: MenuView[];
  patients: MenuView[];
  doctors: MenuView[];
};

// Reducers
export const initialState = {
  lateralMenu: [
    {
      title: "Página inicial",
      permissions: [pm.Admin, pm.Agenda],
      to: "/admin",
      icon: "House",
      children: [],
    },
    {
      title: "Dashboard",
      to: "/admin/dashboard",
      icon: "AssessmentOutlined",
      permissions: [pm.Admin, pm.Agenda],
      children: [],
    },
    {
      title: "Agenda",
      permissions: [pm.Admin, pm.Agenda],
      to: "/admin/calendar",
      icon: "Calendar",
      children: [
        {
          title: "Agendamentos",
          to: "/admin/appointments",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Configure os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Configuração de agenda",
          to: "/admin/schedule",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Configure os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Configuração de PA",
          to: "/admin/immediate-care",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Configure os horários de pronto atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Pronto atendimento",
          to: "/admin/immediate-care/session",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Gerencie as salas de pronto atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Bloqueio de horários",
          to: "/admin/scheduleLock",
          icon: "LockOpenOutlined",
          illustration: "CalendarBlock",
          description: "Configure bloqueios para os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Gerar cupom",
          to: "/admin/voucher",
          icon: "ConfirmationNumberOutlined",
          permissions: [pm.Admin, pm.Agenda],
        },
      ],
    },
    {
      title: "Pacientes",
      to: "/admin/patients",
      icon: "Examination",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Enviar convite",
          to: "/admin/sendinvitation",
          icon: "LocalHospital",
          illustration: "Invite",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Avaliações",
          to: "/admin/review",
          icon: "ThumbUpOutlined",
          illustration: "Invite",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Etiquetas",
          to: "/admin/record-flag",
          icon: "ThumbUpOutlined",
          illustration: "Invite",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Mensagens",
      to: "/admin/conversations",
      icon: "Chat",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Respostas rápidas",
          to: "/admin/quick-response",
          icon: "Chat",
          illustration: "Chat",
          permissions: [pm.Admin, pm.Agenda],
        },
      ],
    },
    {
      title: "Minhas finanças",
      to: "/admin/finances",
      icon: "DollarSingle",
      permissions: [pm.Admin],
      children: [
        {
          title: "Conta bancária",
          to: "/admin/finances/bank-accounts",
          icon: "DescriptionOutlined",
          illustration: "DocumentEdit",
          description: "Crie e edite formulários de pré atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Cartões de credito",
          to: "/admin/finances/credit-card",
          icon: "Bank",
          illustration: "CCGeneric",
          description: "Cadastre seu cartão de credito",
          permissions: [pm.Admin],
        },
        {
          title: "Faturamento",
          to: "/admin/finances/invoices",
          icon: "Invoice",
          description: "Visualize seu faturamento",
          permissions: [pm.Admin],
        },
        {
          title: "Configurações",
          to: "/admin/finances/invoices-settings",
          icon: "Invoice",
          description: "Visualize seu faturamento",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Configuração",
      to: "/admin/settings",
      icon: "Config",
      permissions: [pm.Admin],
      children: [
        {
          title: "Formulários pré atendimento",
          to: "/admin/documents",
          icon: "DescriptionOutlined",
          illustration: "DocumentEdit",
          description: "Crie e edite formulários de pré atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Arquivos de pacientes",
          to: "/admin/collectdocuments",
          icon: "Collections",
          illustration: "CollectUpload",
          description:
            "Configure o carregamento de documentos?(ex: exames, documentos, guias, etc)",
          permissions: [pm.Admin],
        },
        {
          title: "Tipos de atendimentos",
          to: "/admin/appointmentype",
          icon: "EventNoteOutlined",
          illustration: "AddAppoType",
          description:
            "Configure os tipos de atendimentos?(ex: acompanhamento, primeiro atendimento, triagem, etc)",
          permissions: [pm.Admin],
        },
        {
          title: "Adicionar profissional",
          to: "/admin/doctors",
          icon: "PeopleOutline",
          illustration: "MedicalComputer",
          description: "Adicione profissionais à equipe da sua clínica",
          permissions: [pm.Admin],
        },
        {
          title: "Clínicas afiliadas",
          to: "/admin/clinic-branches",
          icon: "Apartment",
          illustration: "Franchise",
          description: "Gerencie as suas afiliações com outras clínicas",
          permissions: [pm.Admin],
        },
        {
          title: "Exames e procedimentos",
          to: "/admin/exams-procedures",
          icon: "ListAlt",
          illustration: "Procedures",
          description: "Gerencie os exames e procedimentos atendidos",
          permissions: [pm.Admin],
        },
        {
          title: "Instruções de agendamento",
          to: "/admin/appointment-instructions",
          icon: "ListAlt",
          illustration: "Procedures",
          description: "Gerencie as instruções de agendamento",
          permissions: [pm.Admin],
        },
        {
          title: "Recursos compartilhados",
          to: "/admin/shared-resources",
          icon: "BusinessOutlined",
          illustration: "",
          description: "Gerencie os recursos compartilhados",
          permissions: [pm.Admin],
        },
        {
          title: "Gatilhos e ações",
          to: "/admin/trigger-actions",
          icon: "Config",
          illustration: "Algorithm",
          description:
            "Configure ações que serão executadas a partir de um evento",
          permissions: [pm.Admin],
        },
        {
          title: "Formulário de anamnese",
          to: "/admin/medical-history/template",
          icon: "Config",
          illustration: "Algorithm",
          description: "Edição do formulário de anamnese",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Perfil Udok",
      icon: "User",
      to: "/admin/clinicprofile",
      permissions: [pm.Admin],
      children: [
        {
          title: "Endereços",
          to: "/admin/locations",
          icon: "PinDropOutlined",
          illustration: "Location",
          description: "Gerenciar endereço dos locais de atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Especialidades atendidas",
          to: "/admin/config/specialties",
          icon: "ListAlt",
          illustration: "SocialCareColor",
          description: "Selecione as especialidades atendidas",
          permissions: [pm.Admin],
        },
        {
          title: "Usuário do sistema",
          to: "/admin/config/users",
          icon: "PeopleAlt",
          illustration: "DatabaseColor",
          description: "Gerencie contas e acesso de usuários",
          permissions: [pm.Admin],
        },
        {
          title: "Customizar receituário",
          to: "/admin/prescriptionlayout",
          icon: "NoteAddOutlined",
          illustration: "",
          permissions: [pm.Admin],
        },
        {
          title: "Publicações",
          to: "/admin/media-post",
          icon: "GradeRounded",
          illustration: "",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  home: [
    {
      title: "Tela inicial",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Agenda",
          to: "/admin/calendar",
          icon: "CalendarToday",
          illustration: "Calendar",
          description:
            "Agende e visualize suas consultas, exames e procedimentos",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Pronto atendimento",
          to: "/admin/immediate-care/session",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Gerencie as salas de pronto atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Pacientes",
          to: "/admin/explore/patients",
          icon: "Examination",
          illustration: "Patient",
          description: "Informações de pacientes.",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Profissionais de saúde",
          to: "/admin/explore/doctors",
          icon: "PeopleOutline",
          illustration: "MedicalComputer",
          description: "Gerenciar equipe de profissionais",
          permissions: [pm.Admin],
        },
        {
          title: "Configurações Udok",
          to: "/admin/explore/settings",
          icon: "Config",
          illustration: "Settings",
          description: "Configurações Udok",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  doctors: [
    {
      title: "Profissionais de saúde",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Mensagens",
          to: "/admin/conversations",
          icon: "Message",
          illustration: "ChatMessages",
          description: "Comunicação com profissional.",
          permissions: [pm.Admin],
        },
        {
          title: "Especialidades atendidas",
          to: "/admin/config/specialties",
          icon: "ListAlt",
          illustration: "SocialCareColor",
          description: "Selecione as especialidades atendidas",
          permissions: [pm.Admin],
        },
        {
          title: "Adicionar profissional",
          to: "/admin/doctors",
          icon: "PeopleOutline",
          illustration: "MedicalComputer",
          description: "Adicione profissionais à equipe da sua clínica",
          permissions: [pm.Admin],
        },
        {
          title: "Customizar receituário",
          to: "/admin/prescriptionlayout",
          icon: "NoteAddOutlined",
          illustration: "TemplateEditor",
          description: "Configure o modelo utilizado nos documentos prescritos",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  patients: [
    {
      title: "Pacientes",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Cadastros",
          to: "/admin/patients",
          icon: "Examination",
          illustration: "Patient",
          description: "Informações de pacientes.",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Cupom de agendamento",
          to: "/admin/voucher",
          icon: "ConfirmationNumberOutlined",
          illustration: "Voucher",
          description: "Dispensar cobrança de agendamentos",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Enviar convite",
          to: "/admin/sendinvitation",
          icon: "LocalHospital",
          illustration: "Invite",
          description: "Convite para paciente",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Formulários pré atendimento",
          to: "/admin/documents",
          icon: "DescriptionOutlined",
          illustration: "DocumentEdit",
          description: "Crie e edite formulários de pré atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Arquivos de pacientes",
          to: "/admin/collectdocuments",
          icon: "Collections",
          illustration: "CollectUpload",
          description:
            "Configure o carregamento de documentos?(ex: exames, documentos, guias, etc)",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  quickAccess: [
    {
      title: "Gestão de Agenda",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Calendário",
          to: "/admin/calendar",
          icon: "CalendarToday",
          illustration: "Calendar",
          description:
            "Agende e visualize suas consultas, exames e procedimentos",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Configuração de agenda",
          to: "/admin/schedule",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Configure os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Bloqueio de horários",
          to: "/admin/scheduleLock",
          icon: "LockOpenOutlined",
          illustration: "CalendarBlock",
          description: "Configure bloqueios para os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Tipos de atendimentos",
          to: "/admin/appointmentype",
          icon: "EventNoteOutlined",
          illustration: "AddAppoType",
          description:
            "Configure os tipos de atendimentos?(ex: acompanhamento, primeiro atendimento, triagem, etc)",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Perfil Udok",
      permissions: [pm.Admin],
      children: [
        {
          title: "Perfil da clínica",
          to: "/admin/clinicprofile",
          icon: "LocalHospital",
          illustration: "BuildingClinicColor",
          description: "Altere as informações visíveis publicamente",
          permissions: [pm.Admin],
        },
        {
          title: "Publicações",
          to: "/admin/media-post",
          icon: "GradeRounded",
          illustration: "Blog",
          description: "Gerencie suas publicações",
          permissions: [pm.Admin],
        },
        {
          title: "Endereços",
          to: "/admin/locations",
          icon: "PinDropOutlined",
          illustration: "Location",
          description: "Gerenciar endereço dos locais de atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Usuário do sistema",
          to: "/admin/config/users",
          icon: "PeopleAlt",
          illustration: "DatabaseColor",
          description: "Gerencie contas e acesso de usuários",
          permissions: [pm.Admin],
        },
        {
          title: "Clínicas afiliadas",
          to: "/admin/clinic-branches",
          icon: "Apartment",
          illustration: "Franchise",
          description: "Gerencie as suas afiliações com outras clínicas",
          permissions: [pm.Admin],
        },
        {
          title: "Minhas finanças",
          to: "/admin/finances",
          icon: "Bank",
          illustration: "BankColor",
          description: "Gerencie suas informações financeiras",
          permissions: [pm.Admin],
        },
        {
          title: "Mais configurações",
          to: "/admin/settings",
          icon: "Config",
          illustration: "Settings",
          description: "Mais configurações",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  finances: [
    {
      title: "Minhas finanças",
      permissions: [pm.Admin],
      children: [
        {
          title: "Conta bancária",
          to: "/admin/finances/bank-accounts",
          icon: "Bank",
          illustration: "BankColor",
          description: "Cadastre sua conta bancária para recebimentos",
          permissions: [pm.Admin],
        },
        {
          title: "Cartões de credito",
          to: "/admin/finances/credit-card",
          icon: "Bank",
          illustration: "CCGeneric",
          description: "Cadastre seu cartão de credito",
          permissions: [pm.Admin],
        },
        {
          title: "Faturamento",
          to: "/admin/finances/invoices",
          icon: "Invoice",
          illustration: "Invoice",
          description: "Visualize seu faturamento",
          permissions: [pm.Admin],
        },
        {
          title: "Configurações",
          to: "/admin/finances/invoices-settings",
          icon: "Config",
          illustration: "Settings",
          description: "Configurações de emissão de nota",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
};

export const Reducer = createReducer(initialState, {});

// Selectors
const mainSelector = (state: RootState) => state.menu;

const validateRoles = (permissions: pm[], roles: string[]) => {
  const p = new Set(roles);
  return !(
    permissions.length > 0 && permissions.filter((r) => p.has(r)).length === 0
  );
};

export const getSidebarMenu = createSelector(
  [mainSelector, selectUserPermissions],
  (state, roles) => {
    let filtredMenu = state.lateralMenu
      .filter((sec) => validateRoles(sec.permissions ?? [], roles ?? []))
      .map((sec) => ({
        ...sec,
        children:
          sec.children?.filter?.((ch) =>
            validateRoles(ch?.permissions ?? [], roles ?? [])
          ) ?? [],
      }));
    return {
      menu: filtredMenu,
    };
  }
);

export const makeSectionView = (section: keyof InitialState) =>
  createSelector([mainSelector, selectUserPermissions], (state, roles) => {
    let filtredMenu = state[section]
      .filter((sec) => validateRoles(sec.permissions ?? [], roles ?? []))
      .map((sec) => ({
        ...sec,
        children:
          sec.children?.filter?.((ch) =>
            validateRoles(ch?.permissions ?? [], roles ?? [])
          ) ?? [],
      }));
    return {
      menu: filtredMenu as MenuView[],
    };
  });

export const getFinancesMenu = createSelector(
  [mainSelector, selectUserPermissions],
  (state, roles) => {
    let filtredMenu = state.finances
      .filter((sec) => validateRoles(sec.permissions ?? [], roles ?? []))
      .map((sec) => ({
        ...sec,
        children:
          sec.children?.filter?.((ch) =>
            validateRoles(ch?.permissions ?? [], roles ?? [])
          ) ?? [],
      }));
    return {
      menu: filtredMenu,
    };
  }
);
