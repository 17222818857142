import moment, { Moment } from "moment";
import { DocumentModel, ContactPhone, ContactPhoneApps } from "../api/models";
import { BrowserNames } from "./constants";
import characterWidth from "./characterWidth.json";

export const format = {
  SPTBR: "DD/MM/YYYY",
  DASHUN: "YYYY-MM-DD",
  EXTPTBR: "DD [de] MMMM, YYYY",
  MONYEA: "MM/YYYY",
  MONYEAR: "MM/YY",
  DAYMON: "DD/MM",
  RFC3349: "YYYY-MM-DDTHH:mm:ssZ",
  TIME: "hh:mm",
  TIME24H: "HH:mm",
  FULLTIME24H: "HH:mm:ss",
  FULLDATE: "DD/MM/YYYY HH:mm:ss",
  DAYMONYEAH: "DD MMM YYYY HH:mm",
  FULLDAY: "dddd",
  VARIANTEXTPTBR: "DD [de] MMMM [de] YYYY",
  STPEUA: "YYYY/MM/DD",
  SHORTSTP: "YYY/MM/DD",
  DATEHOUR: "YYYY-MM-DD HH:mm:ss",
  DATETIMELOCAL: "YYYY-MM-DDTHH:mm",
  YEAMON: "YYYY-MM",
  HUDDLED: "YYYYMMDD",
  HUDDLEDTIME: "HHmm",
  HUDDLEDDATEHOUR: "YYYYMMDDHHmm",
  SHRTPTBR: "DD [de] MMMM",
};

export type DateFormatType = keyof typeof format;
export function formatDate(date: string, outputFormat: DateFormatType) {
  return moment(date).format(format[outputFormat]);
}

export function deriveErrorMessage(e: any, code: string) {
  const fromErr = (e?.response?.data?.error || e)?.message;
  return `${code}: ${fromErr}`;
}

let ScrollbarWidthCache: number | null = null;
/*
 * Returns the width of the browser's scrollbar
 * */
export function getScrollbarWidth() {
  if (ScrollbarWidthCache != null) {
    return ScrollbarWidthCache;
  }
  let outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  // @ts-ignore
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  let inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode && outer.parentNode.removeChild(outer);

  ScrollbarWidthCache = widthNoScroll - widthWithScroll;
  return ScrollbarWidthCache;
}

/*
 * Throttles a event listeners with requestAnimationFrame
 * */
export function throttle(
  type: string,
  name: string,
  obj: Element | Window = window
) {
  let running = false;

  let func = function () {
    if (running) {
      return;
    }

    running = true;

    requestAnimationFrame(function () {
      obj.dispatchEvent(new CustomEvent(name));
      running = false;
    });
  };

  obj.addEventListener(type, func);
}

export function serialize(obj: { [k: string]: any }, prefix?: string): string {
  var str: string[] = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const v = obj[p];
      var k = prefix ? prefix : p;

      if (v === undefined) continue;
      str.push(
        v !== null && typeof v === "object"
          ? serialize(v as any, k)
          : fixedEncodeURIComponent(k) + "=" + fixedEncodeURIComponent(v)
      );
    }
  }
  return str.join("&");
}

export function toQueryString(
  obj: { [k: string]: any },
  prefix?: string
): string {
  const r = serialize(obj, prefix);

  return r.length > 0 ? `?${r}` : r;
}

export function fixedEncodeURIComponent(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}

export function parseQueryString<T extends { [k: string]: any }>(
  queryString: string
) {
  var query: any = {};
  const pairs = queryString.replace(/^\?/, "").split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  return query as T;
}

export const CountryCodeRegex = new RegExp(
  /(?:\+|)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)?(\d*)$/
);

export function countryPhoneNormalizer(v: any, prev: any) {
  if (!v?.replace || v === prev) {
    return v;
  }
  var currentValue = v.replace(/[^\d+]/g, "");
  if (v[0] === "+") {
    const phoneParse = CountryCodeRegex.exec(currentValue);
    if (!phoneParse?.[1]) {
      return v;
    }
    if (phoneParse?.[1] === "55") {
      return `+${phoneParse?.[1]}${
        phoneParse?.[2] ? " " + phoneNormalizer(phoneParse[2], prev) : ""
      }`;
    }

    return `+${phoneParse?.[1]}${
      phoneParse?.[2] ? " " + phoneParse[2].slice(0, 15) : ""
    }`;
  }

  return phoneNormalizer(currentValue, prev);
}

export function phoneNormalizer(v: any, prev: any) {
  if (!v?.replace || v === prev) {
    return v;
  }
  var normalized = "";
  var currentValue = v.replace(/[^\d]/g, "");
  currentValue.replace(
    /^\D*(\d{0,2})\D*(\d{0,5})\D*(\d{0,4})/,
    function (_match: any, d1: any, d2: any, d3: any) {
      if (d1.length) {
        normalized += "(" + d1;
        if (d1.length === 2) {
          if (d2.length > 0) {
            normalized += ")";
          }
          if (d2.length) {
            normalized += " " + d2;
            if (d2.length === 5) {
              if (d3.length > 0) {
                normalized += "-";
              }
              if (d3.length) {
                normalized += d3;
              }
            }
          }
        }
      }
    }
  );

  return normalized;
}

export function normalizeMoney(val: string) {
  const r = (val || "").replace(/[^\d,]/, "");
  const s = r.split(",");
  if (s.length <= 1) {
    return r;
  }
  const start = [...s].splice(0, s.length - 1)?.[0];
  const last = [...s].splice(s.length - 1, 1)?.[0];

  return [
    ...(start ? [...start?.split?.(""), ","] : []),
    ...last?.split?.("")?.splice?.(0, 2),
  ].join("");
}

export function formatMoneyInFloat(val: string, defaltValue?: number) {
  val = val?.replace?.(/\./g, "");
  const part = val?.split?.(/\,/g);
  const newNumber = parseFloat(part?.join?.("."));
  if (isNaN(newNumber)) {
    return defaltValue ?? 0;
  }
  return newNumber;
}

export function formatBRL(n: number) {
  var n2 = n.toFixed(2).split(".");
  n2[0] = n2[0].split(/(?=(?:...)+$)/).join(".");
  return n2.join(",");
}

export function fmtCurrency(n: number, s: string = ""): string {
  n = Number(n);
  if (n === 0 || !n) {
    return "0";
  }

  let r: string = s === "R$" ? formatBRL(n) : n.toFixed(2);

  return `${s} ${r}`;
}

export function shrinker(v: any): object {
  return (Boolean(v) && { shrink: Boolean(v) }) || {};
}

export function fuzzySearch(term: string, text: string) {
  if (typeof term !== "string" || typeof text !== "string") return false;
  // Build Regex String
  var matchTerm = ".*";

  // Split all the search terms
  var terms = removeDiacriticsFromString(term).split(" ");
  term = term.replace(/\W/g, ""); // strip non alpha numeric

  for (var i = 0; i < terms.length; i++) {
    matchTerm += "(?=.*" + terms[i] + ".*)";
  }

  matchTerm += ".*";

  // Convert to Regex
  // => /.*(?=.*TERM1.*)(?=.*TERM2.*).*/
  var matchRegex = new RegExp(matchTerm.toUpperCase());

  return removeDiacriticsFromString(text).toUpperCase().match(matchRegex);
}

export function debounce<T extends Function>(
  func: T,
  wait: number,
  immediate: boolean
) {
  let timeout: any;
  return function () {
    // @ts-ignore
    let context: any = this;
    let args: any = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  } as any as T;
}

export const qtyResults = (qtd?: string) => {
  if (qtd?.match?.(/[^\d\s]/)) {
    return [];
  }
  const qty = parseInt(qtd ?? "");

  if (typeof qty !== "number" || qty === 0 || isNaN(qty)) {
    return [];
  }
  return [
    `${qty} caixa${qty > 1 ? "s" : ""}`,
    `${qty} comprimido${qty > 1 ? "s" : ""}`,
    `${qty} cápsula${qty > 1 ? "s" : ""}`,
    `${qty} ampola${qty > 1 ? "s" : ""}`,
    `${qty} grama${qty > 1 ? "s" : ""}`,
    `${qty} gota${qty > 1 ? "s" : ""}`,
    `${qty} tubo${qty > 1 ? "s" : ""}`,
    `${qty} frasco${qty > 1 ? "s" : ""}`,
    `${qty} sachê${qty > 1 ? "s" : ""}`,
    `${qty} dose${qty > 1 ? "s" : ""}`,
    `${qty} adesivo${qty > 1 ? "s" : ""}`,
    `${qty} ml`,
    `${qty} mg`,
    `${qty} cc`,
  ];
};

export function millisToMinAndSec(millis: number) {
  var minutes = Math.floor(millis / 60000);
  var seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
  return minutes + "0:" + (seconds < 10 ? "0" : "") + seconds;
}

export function prettyFilename(s: string, max = 20) {
  if (typeof s !== "string") {
    return "";
  }

  if (s.length > max) {
    return `${s.substring(0, max * 0.6)}[...]${s.substring(
      s.length - max * 0.4
    )}`;
  }

  return s;
}

export function onlyNumbers(value: string) {
  var numberPattern = /\d+/g;
  return (value.match(numberPattern)?.join?.("") ?? "") as string;
}

export function addressToSingleLine(addr: any) {
  return `${[addr?.street ?? "", addr?.cep ?? "", addr?.city ?? ""]
    .filter((a) => !!a)
    .join(" - ")}${addr?.state ? ", " + addr?.state : ""}`;
}

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export function resetFormAndRun(form: any, cb: () => void) {
  const values = form.getFieldsValue();
  form.setFieldsInitialValue(values);
  form.resetFields();
  setTimeout(() => {
    cb();
  }, 100);
}

export function makeSEOSlug(s: string) {
  s = s?.normalize?.("NFD")?.replace?.(/[\u0300-\u036f]/g, "") ?? s;
  s = s
    .toLocaleLowerCase()
    .replace(/[^\w\s\-]|_/g, "")
    .replace(/\s+/g, " ")
    .replace(/\s/g, "-")
    .replace(/(\-)\1+/g, "-");
  return s;
}

export function cpfNormalize(value: any, prev: any) {
  if (value === prev) {
    return value ?? "";
  }
  var normalized = value?.replace?.(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3-$4"
  );
  return normalized ?? "";
}

export function validateCPF(cpf: any) {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  // Valida 1o digito
  var add = 0;
  for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  var rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export function cnpjNormalize(value: any, prev: any) {
  if (value === prev) {
    return value;
  }
  var normalized = value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
  return normalized;
}

export function validateCNPJ(cnpj: any) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (
    cnpj.length !== 14 ||
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  ) {
    return false;
  }

  var size = cnpj.length - 2;
  var numbers = cnpj.substring(0, size);
  var digits = cnpj.substring(size);
  var sum = 0;
  var pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  var d = parseInt(digits.charAt(0));
  var result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (!isNaN(d) && result !== d) {
    return false;
  }

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let k = size; k >= 1; k--) {
    sum += numbers.charAt(size - k) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  d = parseInt(digits.charAt(1));
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (!isNaN(d) && result !== d) {
    return false;
  }

  return true;
}

export function calculateTransparency(fg: string, bg: string, opacit: number) {
  const rgbFg = hexToRgb(fg);
  const rgbBg = hexToRgb(bg);
  if (!rgbFg || !rgbBg) {
    return fg;
  }

  const r = Math.floor(rgbFg.r * opacit + rgbBg.r * (1 - opacit));
  const g = Math.floor(rgbFg.g * opacit + rgbBg.g * (1 - opacit));
  const b = Math.floor(rgbFg.b * opacit + rgbBg.b * (1 - opacit));

  return "#" + ((r << 16) | (g << 8) | b).toString(16);
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function getContrastingColor(hexColor: string) {
  const rgb = hexToRgb(hexColor);
  if (!rgb) {
    return hexColor;
  }
  let { r, g, b } = rgb;
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
}

export function makeid(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function gradientColor(hexColor: string) {
  const rgb = hexToRgb(hexColor);
  if (!rgb) {
    return hexColor;
  }
  let { r, g, b } = rgb;

  r = numberInRange(Math.floor((r * 11) / 3), 255, 0);
  g = numberInRange(Math.floor((g * 147) / 69), 255, 0);
  b = numberInRange(Math.floor((b * 216) / 183), 255, 0);
  return rgbToHex(r, g, b);
}

export function numberInRange(n: number, max: number, min: number) {
  if (min > max) {
    const aux = max;
    max = min;
    min = aux;
  }
  if (n < min) {
    return min;
  }
  if (n > max) {
    return max;
  }
  return n;
}

export function emailValidation(email: string) {
  if (email.indexOf("@") !== -1) {
    return true;
  }
  return false;
}

// Hack needed to avoid JSON-Serialization validation error from Next.js https://github.com/zeit/next.js/discussions/11209
// >>> Reason: `undefined` cannot be serialized as JSON. Please use `null` or omit this value all together.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteUndefined = (obj: Record<string, any> | undefined): void => {
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      if (obj[key] && typeof obj[key] === "object") {
        deleteUndefined(obj[key]);
      } else if (typeof obj[key] === "undefined") {
        try {
          delete obj[key]; // eslint-disable-line no-param-reassign
        } catch (e) {
          obj[key] = null;
        }
      }
    });
  }
};

export function getDateRange(
  startDate: Date | Moment,
  endDate: Date | Moment,
  type: "days" | "hours" | "months" | "years"
): moment.Moment[] {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let range: Moment[] = [];
  for (let i = 0; i <= diff; i++) {
    range.push(moment(startDate).add(i, type));
  }
  return range;
}

export function getIncrementalDateRange(
  startDate: Date | Moment,
  max: number,
  duration: number,
  type: "days" | "hours" | "months" | "years" | "minutes"
) {
  let range: Moment[] = [];
  for (let i = 0; i < max; i++) {
    range.push(moment(startDate).add(duration * i, type));
  }
  return range;
}

export function asString(a: any): string | undefined {
  return a ? String(a) : undefined;
}

export function asArray(a: any): any[] | undefined {
  if (!!a && typeof a === "string") {
    return [a];
  }
  return a && Array.isArray(a) ? a : undefined;
}

export function parseQueryDate(d: any) {
  const b = moment(d, format.RFC3349);
  return b.isValid() ? b.utc().format() : undefined;
}

export function deepCopyObj<T>(obj: T): T {
  if (null == obj || "object" != typeof obj) return obj;
  if (obj instanceof Date) {
    let copy = new Date();
    copy.setTime(obj.getTime());
    return copy as any as T;
  }
  if (obj instanceof Array) {
    let copy: any[] = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopyObj(obj[i]);
    }
    return copy as any as T;
  }
  if (obj instanceof Object) {
    let copy = {} as T;
    for (var attr in obj) {
      if ((obj as Object).hasOwnProperty(attr))
        copy[attr] = deepCopyObj(obj[attr]);
    }
    return copy as any as T;
  }
  throw new Error("Unable to copy obj this object.");
}

export function injectScript(scriptId: string, scriptLink: string) {
  return new Promise((resolve, reject) => {
    const existingscript = document.getElementById(scriptId);
    if (!existingscript) {
      const script = document.createElement("script");
      script.setAttribute("async", "");
      script.setAttribute("id", scriptId);
      script.setAttribute("type", "text/javascript");
      script.addEventListener("load", () => {
        if (resolve) {
          resolve(script);
        }
      });
      script.addEventListener("error", (e) => {
        if (reject) {
          reject(e);
        }
      });
      script.src = scriptLink;
      const node = document.getElementsByTagName("script")[0];
      node?.parentNode?.insertBefore?.(script, node);
    } else if (resolve) {
      resolve(existingscript);
    }
  });
}

export function extractNDigits(s: string, n: number) {
  const m = s.match(new RegExp(`^[\\D]*(\\d{1,${n}})`));
  return [s.slice(m?.[0]?.length ?? 0), m?.[1] ?? ""] as [string, string];
}

export function dateNormalizerSPTBR(date: string) {
  if (!date?.trim?.()) {
    return "";
  }
  let clean = date.replace(/[^\d,\/]/g, "");
  let day,
    month,
    year = "";
  [clean, day] = extractNDigits(clean, 2);
  [clean, month] = extractNDigits(clean, 2);
  [clean, year] = extractNDigits(clean, 4);
  return [day, month, year].filter((d) => !!d).join("/");
}

function getRandomByte() {
  var result = new Uint8Array(1);
  if (window.crypto && window.crypto.getRandomValues) {
    var result = new Uint8Array(1);
    window.crypto.getRandomValues(result);
    return result[0];
  }
  //@ts-ignore
  else if (window.msCrypto && window.msCrypto.getRandomValues) {
    var result = new Uint8Array(1);
    //@ts-ignore
    window.msCrypto.getRandomValues(result);
    return result[0];
  } else {
    return Math.floor(Math.random() * 256);
  }
}

export function getRandomString(length: number, pattern: RegExp) {
  let l: any = { length: length };
  return Array.apply(null, l)
    .map(() => {
      let result;
      while (true) {
        result = String.fromCharCode(getRandomByte());
        if (pattern.test(result)) {
          return result;
        }
      }
    })
    .join("");
}

export function generateUdokPassword() {
  const pattern = new RegExp(/[a-zA-Z0-9_\-\+\.]/);
  const digit = new RegExp(/[0-9]/gi);
  const nondigit = new RegExp(/[^0-9]/gi);
  let password = getRandomString(8, pattern);
  if (!digit.test(password)) {
    const n = getRandomString(1, digit);
    password = `${n}${password.substring(0, 7)}`;
  }
  if (!nondigit.test(password)) {
    const c = getRandomString(1, nondigit);
    password = `${c}${password.substring(0, 7)}`;
  }

  return password;
}

export function getMobileOperatingSystem() {
  var userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //@ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function DeepLinker(options: {
  onIgnored: () => void;
  onFallback: () => void;
  onReturn: () => void;
}) {
  if (!options) {
    throw new Error("no options");
  }

  var hasFocus = true;
  var didHide = false;

  // window is blurred when dialogs are shown
  function onBlur() {
    hasFocus = false;
  }

  // document is hidden when native app is shown or browser is backgrounded
  function onVisibilityChange(e: any) {
    if (e.target.visibilityState === "hidden") {
      didHide = true;
    }
  }

  // window is focused when dialogs are hidden, or browser comes into view
  function onFocus() {
    if (didHide) {
      if (options.onReturn) {
        options.onReturn();
      }

      didHide = false; // reset
    } else {
      // ignore duplicate focus event when returning from native app on
      // iOS Safari 13.3+
      if (!hasFocus && options.onFallback) {
        // wait for app switch transition to fully complete - only then is
        // 'visibilitychange' fired
        setTimeout(function () {
          // if browser was not hidden, the deep link failed
          if (!didHide) {
            options.onFallback();
          }
        }, 1000);
      }
    }

    hasFocus = true;
  }

  // add/remove event listeners
  // `mode` can be "add" or "remove"
  function bindEvents(mode: "add" | "remove") {
    [
      [window, "blur", onBlur],
      [document, "visibilitychange", onVisibilityChange],
      [window, "focus", onFocus],
    ].forEach(function (conf) {
      // @ts-ignore
      conf[0][mode + "EventListener"](conf[1], conf[2]);
    });
  }

  // add event listeners
  bindEvents("add");

  // expose public API
  // @ts-ignore
  this.destroy = bindEvents.bind(null, "remove");
  // @ts-ignore
  this.openURL = function (url) {
    // it can take a while for the dialog to appear
    var dialogTimeout = 1000;

    setTimeout(function () {
      if (hasFocus && options.onIgnored) {
        options.onIgnored();
      }
    }, dialogTimeout);

    window.location = url;
  };
}

export function formatOffset(offset: number) {
  if (isNaN(offset)) {
    return "+00:00";
  }
  let hour: number | string = Math.abs(Math.floor(offset / 3600));
  let min: number | string = Math.abs(Math.floor((offset % 3600) / 60));
  if (hour < 10) {
    hour = `0${hour}`;
  }
  if (min < 10) {
    min = `0${min}`;
  }
  const operator = offset < 0 ? "-" : "+";
  return `${operator}${hour}:${min}`;
}

export function ruleOfThree(a1: number, a2: number, b1: number) {
  const b2 = Math.floor((b1 * a2) / a1);
  return b2;
}

function imgLoaded(imgElement: HTMLImageElement) {
  return imgElement.complete && imgElement.naturalHeight !== 0;
}

export const loadImage = async (url: string) =>
  new Promise<HTMLImageElement>((res) => {
    const imgEl = new Image();
    // make cross origin anonymous so the canvas isn't tainted
    imgEl.crossOrigin = "anonymous";
    //@ts-ignore
    imgEl.crossorigin = "anonymous";
    imgEl.src = url;
    if (imgLoaded(imgEl)) {
      res(imgEl);
    } else {
      imgEl.addEventListener(
        "load",
        function () {
          res(imgEl);
        },
        false
      );
    }
  });

export function resizeString(text: string, max: number) {
  let maxIndex = text.length;
  if (maxIndex > max) {
    const ids = text.substring(0, max).lastIndexOf(" ");
    if (ids !== -1) {
      maxIndex = ids;
    } else {
      maxIndex = max;
    }
  }
  return text.substring(0, maxIndex);
}

export function replaceAt(text: string, index: number, replacement: string) {
  let newText = text.split("");
  newText[index] = replacement;
  return newText.join("");
}

export function itop(n: number) {
  return n * 72.119921875;
}

export function getCharacterWidth(char: string, fontSize: number) {
  var width: number | undefined =
    characterWidth[char as keyof typeof characterWidth];
  if (!width) {
    width = 0;
  }
  return (fontSize * width) / 12;
}

export function splitTextInLines(
  text: string,
  fontSize: number,
  maxlineWidth: number
) {
  // Remove accents
  const textArray = Array.from(
    text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  );

  let lineList: string[] = [];
  let lineWidth = 0;
  let iFirstColumn = 0;
  let lastBreakIndex = 0;
  let lastWidth = 0;
  textArray.forEach((char, i, list) => {
    const last = i == list.length - 1;
    let previous = "";
    if (lineWidth == 0) {
      iFirstColumn = i;
    }
    if (char == " ") {
      lastBreakIndex = i;
      lastWidth = lineWidth;
    }
    if (i !== 0) {
      previous = list[i - 1];
    }
    if (char == "\n" && !last) {
      if (lineWidth != 0 || (lineWidth == 0 && previous == "\n")) {
        lineWidth = 0;
        lastBreakIndex = 0;
        lastWidth = 0;
        lineList = [...lineList, text.slice(iFirstColumn, i + 1)];
        return;
      }
    }
    if (char != "\n") {
      lineWidth += getCharacterWidth(char, fontSize);
    }
    if (lineWidth >= maxlineWidth || last) {
      let lw = 0;
      let iLastColumn = i;
      let isBreackLine = lastBreakIndex != 0 && lastBreakIndex != i && !last;
      if (isBreackLine) {
        iLastColumn = lastBreakIndex;
        lw = lineWidth - lastWidth;
      }
      lineList = [...lineList, text.slice(iFirstColumn, iLastColumn + 1)];
      if (isBreackLine) {
        iFirstColumn = lastBreakIndex + 1;
      }
      lastBreakIndex = 0;
      lineWidth = lw;
      return;
    }
  });

  return lineList;
}

export function validateEntryPerLines(
  text: string,
  fontSize: number,
  maxlineWidth: number,
  maxLines: number
) {
  const lines = splitTextInLines(text, fontSize, maxlineWidth);
  let lineWidth = 0;
  Array.from(lines[lines.length - 1] ?? []).forEach((char) => {
    if (char === "\n") {
      lineWidth = maxlineWidth;
      return;
    }
    lineWidth += getCharacterWidth(char, fontSize);
  });

  const linePercentage = (lineWidth * 3.3) / maxlineWidth;
  const textPercentage = ((lines.length - 1) * 100) / maxLines + linePercentage;
  return {
    valid: lines.length <= maxLines,
    percent: textPercentage < 0 ? 0 : Math.floor(textPercentage),
  };
}

export function formatItem(docmodel: string) {
  const def = (_item: any, _idx: number) => ({
    title: "Sem pré-visualização",
    description: "",
  });
  const prescription = (item: any, _idx: number) => ({
    title: item?.drugName,
    description: `Qtd.: ${item?.quantity} \nPosologia: ${item?.usage}${
      item?.observation ? `\nObs.: ${item?.observation}` : ""
    }`,
  });

  const formats: {
    [k: string]: (
      item: any,
      idx: number
    ) => { title: string; description: string };
  } = {
    [DocumentModel.prescription]: prescription,
    [DocumentModel.prescriptionSimple]: prescription,
    [DocumentModel.prescriptionControlled]: prescription,
    [DocumentModel.exam]: (item: any, _idx: number) => ({
      title: (item?.contents ?? [])?.slice?.(0, 40),
      description: item?.contents?.length > 40 ? item?.contents : "",
    }),
    [DocumentModel.medicalCertificate]: (item: any, _idx: number) => ({
      title: (item?.contents ?? [])?.slice?.(0, 40),
      description: item?.contents?.length > 40 ? item?.contents : "",
    }),
    [DocumentModel.medicalReport]: (item: any, _idx: number) => ({
      title: (item?.contents ?? [])?.slice?.(0, 40),
      description: item?.contents?.length > 40 ? item?.contents : "",
    }),
    [DocumentModel.freeDocument]: (item: any, _idx: number) => ({
      title: item?.fields?.title || (item?.contents ?? [])?.slice?.(0, 40),
      description: item?.contents?.length > 40 ? item?.contents : "",
    }),
  };
  return formats[docmodel] ?? def;
}

export function maxTen(n: number): string {
  if (n > 9) {
    return "9+";
  }
  return `${n || 0}`;
}

export class AppointmentError extends Error {
  data: { patiID?: string } | undefined;
  constructor(message: string, data?: { patiID?: string }) {
    super(message);
    this.data = data;
  }
}

type DetailError = {
  domain: string;
  reason: string;
  message: string;
  location?: string;
  locationType?: string;
  extendedHelp?: string;
  sendReport?: string;
};

export class APIError extends Error {
  errors: DetailError[];
  constructor(message: string, errors?: DetailError[]) {
    super(message);
    this.errors = errors ?? [];
  }
}

export function getImageURL(baseURL: string, img?: string | File) {
  if (!img) {
    return undefined;
  }
  return typeof img === "string"
    ? baseURL + img
    : window.URL.createObjectURL(img);
}

export const promiseWithTimeout = <T>(
  promise: Promise<T>,
  timeout?: number
) => {
  let timeoutId: NodeJS.Timeout;
  const timeoutPromise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error("Request timed out"));
    }, timeout ?? 2000);
  });
  const p = Promise.race([promise, timeoutPromise]);
  p.finally(() => {
    clearTimeout(timeoutId);
  });
  return p;
};

export function isEmptyObject(obj: Object) {
  return (
    Object.keys(obj).filter((key) => !!obj[key as keyof typeof obj]).length ===
    0
  );
}

export function firstContactPhone(
  contacts: ContactPhone[],
  linkedApp?: ContactPhoneApps
) {
  if (contacts.length == 0) {
    return "";
  }
  if (!!linkedApp) {
    let cellPhones: ContactPhone[] = [];
    contacts.forEach((contact) => {
      const phone = contact.phone.replace(/[^\d+]+/g, "");
      let isValidPhone = phone.length > 0;
      // if the number is from Brazil, validate
      if (isValidPhone && (phone[0] != "+" || phone.includes("+55"))) {
        isValidPhone = isCellPhone(phone);
      }
      if (isValidPhone) {
        cellPhones = [...cellPhones, contact];
      }
    });
    if (cellPhones.length == 0) {
      cellPhones = contacts;
    }
    cellPhones.forEach((contact) => {
      const la = contact.linkedApps.join(", ");
      if (la.includes(linkedApp)) {
        return contact.phone.replace(/[^\d+]+/g, "");
      }
    });
    return cellPhones?.[0]?.phone?.replace?.(/[^\d+]+/g, "");
  }
  let validPhones: ContactPhone[] = [];
  contacts.forEach((contact) => {
    const phone = contact.phone.replace(/[^\d+]+/g, "");
    if (phone.length > 0) {
      validPhones = [...validPhones, contact];
    }
  });
  if (validPhones.length == 0) {
    validPhones = contacts;
  }
  return validPhones?.[0]?.phone?.replace?.(/[^\d+]+/g, "");
}

// This function only validates brazilian phone numbers
export function isCellPhone(phone: string) {
  phone = phone.replace("+55", "");
  if (phone.length >= 10) {
    const p = invertString(phone);
    const f1 = parseInt(p[7]);
    const f2 = parseInt(p[8]);
    if ((f1 >= 6 && f1 <= 9) || (f2 >= 6 && f2 <= 9)) {
      return true;
    }
  }
  return false;
}

export function invertString(s: string) {
  let runes = s.split("");
  const length = runes.length - 1;
  let j = length;
  let newS: string[] = [];
  for (let i = 0; i <= length; i++) {
    newS[i] = runes[j];
    j--;
  }
  return newS.join("");
}

export function countryCodeConcatenation(phone: string, prefix?: string) {
  const pNumber = onlyNumbers(phone);
  if (pNumber.length > 11) {
    const phoneParse = CountryCodeRegex.exec(phone);
    if (!phoneParse?.[1] || !phoneParse?.[2]) {
      return (prefix ?? "") + pNumber;
    }
    return (prefix ?? "") + phoneParse?.[1] + phoneParse?.[2];
  }
  return `${prefix ?? ""}55${pNumber}`;
}

export function openInNewTab(href: string) {
  Object.assign(document.createElement("a"), {
    target: "_blank",
    rel: "noopener noreferrer",
    href: href,
  }).click();
}

const NameConnectors = [
  "a",
  "e",
  "i",
  "o",
  "u",
  "de",
  "da",
  "do",
  "du",
  "des",
  "das",
  "dos",
  "dus",
];
export function nameNormalize(value: string, prev: string = "") {
  if (value === prev || (value ?? "").length === 0) {
    return value;
  }
  const name =
    value.toUpperCase() === value ? value.toLocaleLowerCase() : value;
  return name
    .replace(/\.\s{0,1}/g, ". ")
    .split(/\s+/)
    .map((str) => {
      if (str.length <= 1) {
        return str.toLocaleLowerCase();
      }
      if (str[0].toLocaleUpperCase() === str[0]) {
        return str[0] + str.slice(1).toLocaleLowerCase();
      }
      if (NameConnectors.indexOf(str.toLowerCase()) !== -1) {
        return str.toLowerCase();
      }
      return str[0].toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
    })
    .join(" ");
}

export function removeDiacriticsFromString(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function compareStringArrays(l1: string[], l2: string[]) {
  return (
    l1.sort((a, b) => (a < b ? -1 : 1)).join(", ") ===
    l2.sort((a, b) => (a < b ? -1 : 1)).join(", ")
  );
}

export const getBrowserName = () => {
  var sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf("Edg") > -1) {
    return BrowserNames.Edg;
  }
  if (sUsrAg.indexOf("Chrome") > -1) {
    return BrowserNames.Chrome;
  }
  if (sUsrAg.indexOf("Firefox") > -1) {
    return BrowserNames.Mozilla;
  }
  return "";
};

export function removeNamePrefixes(name: string) {
  return name.replace(/(?:Sr|Dr)[\\.a]*/g, "");
}

export function breakPhone(phone: string) {
  if (phone.length < 3) {
    return { ddd: "", number: "" };
  }
  const phoneNumber = onlyNumbers(phone);
  const ddd = phoneNumber.substring(0, 2);
  const number = phoneNumber.substring(2, phoneNumber.length);
  return { ddd, number };
}

export function generateWhatsappURL(phone: string, message?: string) {
  return `${process.env.REACT_APP_WHATSAPPURL}send${toQueryString({
    phone: countryCodeConcatenation(phone),
    text: message,
  })}`;
}

export function sortArrayByString(valA: string, valB: string, valF: string) {
  const filter = removeDiacriticsFromString(valF).toLowerCase();
  const a = removeDiacriticsFromString(valA).toLowerCase();
  const b = removeDiacriticsFromString(valB).toLowerCase();
  if (a == filter && b != filter) {
    return -1;
  }
  if (a.startsWith(filter) && !b.startsWith(filter)) {
    return -1;
  }
  if (!a.startsWith(filter) && b.startsWith(filter)) {
    return 1;
  }
  if (a.startsWith(filter) && b.startsWith(filter) && a < b) {
    return -1;
  }
  const indexA = a.indexOf(filter);
  const indexB = b.indexOf(filter);
  if (indexA !== -1 && indexB === -1) {
    return -1;
  }
  if (indexA === -1 && indexB !== -1) {
    return 1;
  }
  if (indexA < indexB) {
    return -1;
  }
  if (indexA > indexB) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
}

export function bytesToMegabytes(bytes: number) {
  const megabytes = bytes / (1024 * 1024);
  return megabytes;
}

export function promiseAllSettled(promises: Promise<any>[]) {
  return Promise.all(
    promises.map((p: any) =>
      p
        .then((value: any) => ({
          status: "fulfilled",
          value,
          reason: undefined,
        }))
        .catch((reason: any) => ({
          status: "rejected",
          value: undefined,
          reason,
        }))
    )
  );
}
