import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useGetAppointmentByMegrID } from "hooks/schedule";
import { Typography, Button, useMediaQuery } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { format } from "@udok/lib/internal/util";
import { ActiveChatUserContext } from "@udok/lib/components/UI/UIContext";

import moment from "moment";
moment.locale("pt-br");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    text: {
      textDecoration: "underline",
    },
  })
);

export type AppointmentByMegrIDProps = {
  megrID: string;
};

const AppointmentByMegrID = (props: AppointmentByMegrIDProps) => {
  const { megrID } = props;
  const { slug } = useParams<{ slug: string }>();
  const { setChat } = React.useContext(ActiveChatUserContext);
  const [, appointment] = useGetAppointmentByMegrID(megrID);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const handleClick = React.useCallback(() => {
    if (appointment) {
      if (isSmallerScreen) {
        setChat(null);
      }
      history.push(`/${slug}/admin/appointments/${appointment.appoID}`);
    }
  }, [setChat, slug, isSmallerScreen, history, appointment]);

  if (!appointment) {
    return null;
  }

  const markedAt = moment(appointment.markedAt).local();
  let text = `Ver agendamento.`;
  if (markedAt.isValid()) {
    text = `Agendamento do dia ${markedAt.format(format.DAYMONYEAH)}`;
  }
  return (
    <Button onClick={handleClick} className={classes.root} variant="text">
      <Typography className={classes.text} variant="caption">
        {text}
      </Typography>
    </Button>
  );
};

export default AppointmentByMegrID;
