import * as React from "react";
import { Dialog, DialogProps, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDialogUrlHash } from "@udok/lib/hooks/layout";

export type ResponsiveDialogProps = {
  closeOnGoBack?: boolean;
} & DialogProps;

const ResponsiveDialog = (props: ResponsiveDialogProps) => {
  const { open, onClose, closeOnGoBack, ...others } = props;
  useDialogUrlHash(
    open,
    React.useCallback(() => {
      onClose?.({}, "backdropClick");
    }, [onClose]),
    !closeOnGoBack
  );

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <div>
      <Dialog
        {...others}
        open={open}
        onClose={onClose}
        fullScreen={isSmallerScreen}
      />
    </div>
  );
};

export default ResponsiveDialog;
