import { toQueryString } from "@udok/lib/internal/util";
import {
  TriggerAction,
  CreateTriggerForm,
  FilterTriggerAction,
  Trigger,
  Action,
} from "@udok/lib/api/models";
import {
  makeItemFetch,
  makeItemsFetch,
  makeItemPost,
  makeItemPut,
  makeItemDelete,
} from "@udok/lib/api/axios";

export const createTriggerAction = async (
  token: string,
  data: CreateTriggerForm
) =>
  makeItemPost<TriggerAction, CreateTriggerForm>({
    url: `/api/trigger-actions`,
    errLabel: "ta1",
    token,
    data,
  });

export const fetchTriggerActions = (token: string, f?: FilterTriggerAction) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<TriggerAction[]>({
    url: `/api/trigger-actions${filter}`,
    errLabel: "ta2",
    token,
  });
};

export const fetchTriggerAction = (token: string, tracID: string) =>
  makeItemFetch<TriggerAction>({
    url: `/api/trigger-actions/${tracID}`,
    errLabel: "ta3",
    token,
  });

export const deleteTriggerAction = (token: string, tracID: string) =>
  makeItemDelete<TriggerAction>({
    url: `/api/trigger-actions/${tracID}`,
    errLabel: "ta4",
    token,
  });

export const updateTriggerAction = async (
  token: string,
  data: CreateTriggerForm
) =>
  makeItemPut<TriggerAction, CreateTriggerForm>({
    url: `/api/trigger-actions/${data.tracID}`,
    errLabel: "ta5",
    token,
    data,
  });

export const fetchTriggers = () =>
  makeItemsFetch<Trigger[]>({
    url: `/api/triggers`,
    errLabel: "ta6",
  });

export const fetchAction = () =>
  makeItemsFetch<Action[]>({
    url: `/api/actions`,
    errLabel: "ta7",
  });
