import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name={2891162}
      xmlns="http://www.w3.org/2000/svg"
      width={72.834}
      height={72.834}
      viewBox="0 0 72.834 72.834"
      fill="#565656"
      {...props}
    >
      <path
        data-name="Caminho 43"
        d="M72.834 6.069v45.522a6.087 6.087 0 01-6.069 6.069H52.926A15.9 15.9 0 0037.176 44h-.759v-1.6a18.087 18.087 0 004.552-6.282V44h27.313V8.346a3.8 3.8 0 00-3.793-3.793H44.763a3.8 3.8 0 00-3.793 3.793v13.292a17.821 17.821 0 00-4.553-6.313V6.069A6.087 6.087 0 0142.486 0h24.278a6.087 6.087 0 016.069 6.069z"
      />
      <path
        data-name="Caminho 44"
        d="M54.625 24.274a4.552 4.552 0 114.548-4.548 4.558 4.558 0 01-4.548 4.548z"
      />
      <path
        data-name="Caminho 45"
        d="M61.453 36.413H47.797a2.277 2.277 0 01-2.276-2.272 6.835 6.835 0 016.828-6.828h4.552a6.835 6.835 0 016.828 6.828 2.277 2.277 0 01-2.276 2.272z"
      />
      <path
        data-name="Caminho 46"
        d="M46.28 72.834h-44A2.277 2.277 0 010 70.556v-10.62a11.392 11.392 0 0111.38-11.38h25.8a11.392 11.392 0 0111.38 11.38v10.62a2.277 2.277 0 01-2.28 2.278z"
      />
      <path
        data-name="Caminho 47"
        d="M10.925 26.099a.516.516 0 01.03-.212 13.647 13.647 0 0126.675.091c0 .03.03.091.03.121-.03 0-.091.03-.121.03-5.1 1.366-8.406-4.188-8.528-4.431a1.527 1.527 0 00-1.093-.728 1.545 1.545 0 00-1.275.395c-6.432 5.978-15.018 4.825-15.718 4.734z"
      />
      <path
        data-name="Caminho 48"
        d="M35.871 29.376c-4.1 0-7.01-2.853-8.467-4.673-6.919 5.432-15.083 4.643-16.752 4.431a6.175 6.175 0 00.061 1.3 13.655 13.655 0 0027.131-.091 5.343 5.343 0 00.061-1.214 7.918 7.918 0 01-2.033.243z"
      />
    </svg>
  );
}

export default SvgComponent;
