import { toQueryString } from "@udok/lib/internal/util";
import { makeItemFetch, makeItemsFetch } from "@udok/lib/api/axios";
import {
  CidsusUsage,
  MedicineUsage,
  PrescriptionDashboard,
  DoctorPrescriptionDashboard,
  AppointmentDashboard,
  AppointmentDoctorDashboard,
  PatientDashboard,
  ChatDashboard,
  AppointmentPlanDashboard,
  AppointmentTypeDashboard,
  AppointmentTypeByDateDashboard,
} from "@udok/lib/api/models";

export type FilterCidsusUsage = {
  displayCode?: string;
  displayName?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  limit?: number;
  offset?: number;
};

export const fetchCidsusUsage = async (
  token: string,
  filter?: FilterCidsusUsage
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<CidsusUsage[]>({
    url: `/api/dashboard/cid-usage${f}`,
    errLabel: "CidsusUsage",
    token,
  });
};

export type FilterMedicineUsage = {
  drugName?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  limit?: number;
  offset?: number;
};

export const fetchMedicineUsage = async (
  token: string,
  filter?: FilterMedicineUsage
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<MedicineUsage[]>({
    url: `/api/dashboard/drug-usage${f}`,
    errLabel: "MedicineUsage",
    token,
  });
};

export type FilterPrescriptionDashboard = {
  createdAtGte?: string;
  createdAtLte?: string;
};

export const fetchPrescriptionDashboard = async (
  token: string,
  filter?: FilterPrescriptionDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemFetch<PrescriptionDashboard>({
    url: `/api/dashboard/prescription-statistics${f}`,
    errLabel: "PrescriptionDashboard",
    token,
  });
};

export type FilterDoctorPrescriptionDashboard = {
  doctorName?: string;
  createdAtGte?: string;
  createdAtLte?: string;
  limit?: number;
  offset?: number;
};

export const fetchDoctorPrescriptionDashboard = async (
  token: string,
  filter?: FilterDoctorPrescriptionDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<DoctorPrescriptionDashboard[]>({
    url: `/api/dashboard/prescription-doctor${f}`,
    errLabel: "DoctorPrescriptionDashboard",
    token,
  });
};

export type FilterAppointmentDashboard = {
  startDate?: string;
  endDate?: string;
  comparativeStartDate?: string;
  comparativeEndDate?: string;
};

export const fetchAppointmentDashboard = async (
  token: string,
  filter?: FilterAppointmentDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemFetch<AppointmentDashboard>({
    url: `/api/dashboard/appointment-statistics${f}`,
    errLabel: "AppointmentDashboard",
    token,
  });
};

export type FilterAppointmentDoctorDashboard = {
  doctorName?: string;
  markedAtGte?: string;
  markedAtLte?: string;
  limit?: number;
  offset?: number;
};

export const fetchAppointmentDoctorDashboard = async (
  token: string,
  filter?: FilterAppointmentDoctorDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<AppointmentDoctorDashboard[]>({
    url: `/api/dashboard/appointment-doctor${f}`,
    errLabel: "AppointmentDoctorDashboard",
    token,
  });
};

export type FilterAppointmentTypeDashboard = {
  type?: string;
  markedAtGte?: string;
  markedAtLte?: string;
  groupedBy?: "day" | "month" | "year"
};

export const fetchAppointmentTypeEvolutionDashboard = async (
  token: string,
  filter?: FilterAppointmentTypeDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<AppointmentTypeByDateDashboard[]>({
    url: `/api/dashboard/appointment-type-evolution${f}`,
    errLabel: "AppointmentTypeDashboard",
    token,
  });
};

export const fetchAppointmentTypeStatisticsDashboard = async (
  token: string,
  filter?: FilterAppointmentTypeDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<AppointmentTypeDashboard[]>({
    url: `/api/dashboard/appointment-type-statistics${f}`,
    errLabel: "AppointmentTypeDashboard",
    token,
  });
};

export type FilterAppointmentPlanDashboard = {
  heplID?: string | null;
  planName?: string | null;
  markedAtGte?: string | null;
  markedAtLte?: string | null;
};

export const fetchAppointmentPlanStatisticsDashboard = async (
  token: string,
  filter?: FilterAppointmentPlanDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<AppointmentPlanDashboard[]>({
    url: `/api/dashboard/appointment-plan-statistics${f}`,
    errLabel: "AppointmentTypeDashboard",
    token,
  });
};

export const fetchPatientDashboard = async (token: string) => {
  return makeItemFetch<PatientDashboard>({
    url: `/api/dashboard/patient-statistics`,
    errLabel: "PatientDashboard",
    token,
  });
};

export type FilterChatDashboard = {
  createdAtGte?: string;
  createdAtLte?: string;
};

export const fetchChatDashboard = async (
  token: string,
  filter?: FilterChatDashboard
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemFetch<ChatDashboard>({
    url: `/api/dashboard/chat${f}`,
    errLabel: "ChatDashboard",
    token,
  });
};
