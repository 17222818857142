import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1.0625em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M12.19.601c-.223 0-.442.019-.656.055l-.132.546A1.524 1.524 0 019.49 2.306l-.46-.136c-.275.355-.496.759-.649 1.196l.34.327c.624.6.624 1.597 0 2.197l-.34.327c.153.437.373.84.65 1.197l.459-.137a1.523 1.523 0 011.912 1.105l.132.545a3.929 3.929 0 001.313 0l.131-.545a1.524 1.524 0 011.913-1.105l.46.137c.275-.356.496-.76.649-1.197l-.34-.327a1.525 1.525 0 010-2.197l.34-.327a4.225 4.225 0 00-.65-1.196l-.459.136a1.524 1.524 0 01-1.913-1.104l-.131-.546a3.925 3.925 0 00-.656-.055zM1.714 1.744C.77 1.744 0 2.514 0 3.458v8c0 .945.77 1.714 1.714 1.714h3.238v1.905H3.62a.572.572 0 100 1.143H5.43a.57.57 0 00.185 0h4.006a.57.57 0 00.185 0h1.813a.572.572 0 100-1.143h-1.333v-1.905h3.238c.945 0 1.714-.769 1.714-1.714V8.69a4.94 4.94 0 01-1.143.673v2.095a.572.572 0 01-.571.572H9.808a.576.576 0 00-.185 0H5.618a.57.57 0 00-.186 0H1.714a.572.572 0 01-.571-.572v-8c0-.315.256-.571.571-.571H7.62a4.94 4.94 0 01.673-1.143H1.714zM12.19 3.649a1.143 1.143 0 110 2.285 1.143 1.143 0 010-2.285zm-6.095 9.523h3.048v1.905H6.095v-1.905z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .601)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
