import { AppThunk, RootState } from "ducks/state";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { getToken, UNAUTHORIZED } from "./auth";
import {
  CidsusUsage,
  MedicineUsage,
  PrescriptionDashboard,
  DoctorPrescriptionDashboard,
  AppointmentDashboard,
  AppointmentDoctorDashboard,
  AppointmentTypeDashboard,
  AppointmentPlanDashboard,
  PatientDashboard,
  ChatDashboard,
  AppointmentTypeByDateDashboard,
} from "@udok/lib/api/models";
import {
  fetchCidsusUsage,
  fetchMedicineUsage,
  fetchAppointmentTypeEvolutionDashboard,
  fetchAppointmentTypeStatisticsDashboard,
  fetchAppointmentPlanStatisticsDashboard,
  fetchPatientDashboard,
  FilterCidsusUsage,
  FilterAppointmentTypeDashboard,
  FilterMedicineUsage,
  fetchDoctorPrescriptionDashboard,
  fetchPrescriptionDashboard,
  fetchAppointmentDashboard,
  fetchAppointmentDoctorDashboard,
  FilterPrescriptionDashboard,
  FilterDoctorPrescriptionDashboard,
  FilterAppointmentDashboard,
  FilterAppointmentDoctorDashboard,
  fetchChatDashboard,
  FilterChatDashboard,
  FilterAppointmentPlanDashboard,
} from "@udok/lib/api/dashboard";
import { createSelector } from "reselect";

export type InitialState = {
  cidsusUsage?: CidsusUsage[];
  drugUsage?: MedicineUsage[];
  prescriptionStatistics?: PrescriptionDashboard;
  prescriptionDoctor?: DoctorPrescriptionDashboard[];
  appointmentStatistics?: AppointmentDashboard;
  appointmentDoctor?: AppointmentDoctorDashboard[];
  appointmentTypeEvolutionDashboard?: AppointmentTypeByDateDashboard[];
  appointmentTypeStatisticsDashboard?: AppointmentTypeDashboard[];
  appointmentPlanStatisticsDashboard?: AppointmentPlanDashboard[];
  patientDashboard?: PatientDashboard;
  chatDashboard?: ChatDashboard;
};

const initialState: InitialState = {
  cidsusUsage: undefined,
  drugUsage: undefined,
  prescriptionStatistics: undefined,
  prescriptionDoctor: undefined,
  appointmentStatistics: undefined,
  appointmentDoctor: undefined,
  appointmentTypeEvolutionDashboard: undefined,
  appointmentTypeStatisticsDashboard: undefined,
  appointmentPlanStatisticsDashboard: undefined,
  patientDashboard: undefined,
  chatDashboard: undefined,
};

// Reducers
class DashboardSlice extends Hen<InitialState> {
  cidsusUsagesLoaded(data: CidsusUsage[]) {
    this.state.cidsusUsage = data;
  }

  drugUsageLoaded(data: MedicineUsage[]) {
    this.state.drugUsage = data;
  }

  prescriptionStatisticsLoaded(data: PrescriptionDashboard) {
    this.state.prescriptionStatistics = data;
  }

  prescriptionDoctorLoaded(data: DoctorPrescriptionDashboard[]) {
    this.state.prescriptionDoctor = data;
  }

  appointmentStatisticsLoaded(data: AppointmentDashboard) {
    this.state.appointmentStatistics = data;
  }

  appointmentDoctorLoaded(data: AppointmentDoctorDashboard[]) {
    this.state.appointmentDoctor = data;
  }

  appointmentTypeEvolutionDashboardLoaded(
    data: AppointmentTypeByDateDashboard[]
  ) {
    this.state.appointmentTypeEvolutionDashboard = data;
  }

  appointmentTypeStatisticsDashboardLoaded(data: AppointmentTypeDashboard[]) {
    this.state.appointmentTypeStatisticsDashboard = data;
  }

  appointmentPlanStatisticsDashboardLoaded(data: AppointmentPlanDashboard[]) {
    this.state.appointmentPlanStatisticsDashboard = data;
  }

  patientDashboardLoaded(data: PatientDashboard) {
    this.state.patientDashboard = data;
  }

  chatDashboardLoaded(data: ChatDashboard) {
    this.state.chatDashboard = data;
  }
}

export const [Reducer, actions] = hen(new DashboardSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.dashboard;

export const getCidsusUsage = createSelector(
  mainSelector,
  (state) => state.cidsusUsage
);

export const getDrugUsage = createSelector(
  mainSelector,
  (state) => state.drugUsage
);

export const getPrescriptionStatistics = createSelector(
  mainSelector,
  (state) => state.prescriptionStatistics
);

export const getPrescriptionDoctor = createSelector(
  mainSelector,
  (state) => state.prescriptionDoctor
);

export const getAppointmentStatistics = createSelector(
  mainSelector,
  (state) => state.appointmentStatistics
);

export const getAppointmentDoctor = createSelector(
  mainSelector,
  (state) => state.appointmentDoctor
);

export const getAppointmentTypeEvolutionDashboard = createSelector(
  mainSelector,
  (state) => state.appointmentTypeEvolutionDashboard
);

export const getAppointmentTypeStatisticsDashboard = createSelector(
  mainSelector,
  (state) => state.appointmentTypeStatisticsDashboard
);

export const getAppointmentPlanStatisticsDashboard = createSelector(
  mainSelector,
  (state) => state.appointmentPlanStatisticsDashboard
);

export const getPatientDashboard = createSelector(
  mainSelector,
  (state) => state.patientDashboard
);

export const getChatDashboard = createSelector(
  mainSelector,
  (state) => state.chatDashboard
);

// Actions
export function loadCidsusUsage(
  filter?: FilterCidsusUsage
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchCidsusUsage(apiToken, filter)
      .then((data: CidsusUsage[]) => {
        dispatch(actions.cidsusUsagesLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadDrugUsage(
  filter?: FilterMedicineUsage
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchMedicineUsage(apiToken, filter)
      .then((data: MedicineUsage[]) => {
        dispatch(actions.drugUsageLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPrescriptionStatistics(
  filter?: FilterPrescriptionDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchPrescriptionDashboard(apiToken, filter)
      .then((data: PrescriptionDashboard) => {
        dispatch(actions.prescriptionStatisticsLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPrescriptionDoctor(
  filter?: FilterDoctorPrescriptionDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchDoctorPrescriptionDashboard(apiToken, filter)
      .then((data: DoctorPrescriptionDashboard[]) => {
        dispatch(actions.prescriptionDoctorLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentStatistics(
  filter?: FilterAppointmentDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentDashboard(apiToken, filter)
      .then((data: AppointmentDashboard) => {
        dispatch(actions.appointmentStatisticsLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentDoctor(
  filter?: FilterAppointmentDoctorDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentDoctorDashboard(apiToken, filter)
      .then((data: AppointmentDoctorDashboard[]) => {
        dispatch(actions.appointmentDoctorLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentTypeEvolutionDashboard(
  filter?: FilterAppointmentTypeDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentTypeEvolutionDashboard(apiToken, filter)
      .then((data: AppointmentTypeByDateDashboard[]) => {
        dispatch(actions.appointmentTypeEvolutionDashboardLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentTypeStatisticsDashboard(
  filter?: FilterAppointmentTypeDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentTypeStatisticsDashboard(apiToken, filter)
      .then((data: AppointmentTypeDashboard[]) => {
        dispatch(actions.appointmentTypeStatisticsDashboardLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentPlanStatisticsDashboard(
  filter?: FilterAppointmentPlanDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentPlanStatisticsDashboard(apiToken, filter)
      .then((data: AppointmentPlanDashboard[]) => {
        dispatch(actions.appointmentPlanStatisticsDashboardLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientDashboard(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchPatientDashboard(apiToken)
      .then((data: PatientDashboard) => {
        dispatch(actions.patientDashboardLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadChatDashboard(
  filter?: FilterChatDashboard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchChatDashboard(apiToken, filter)
      .then((data: ChatDashboard) => {
        dispatch(actions.chatDashboardLoaded(data));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
