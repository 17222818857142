import { toQueryString } from "@udok/lib/internal/util";
import {
  MedicalHistoryTemplate,
  MedicalHistoryTemplateForm,
  MedicalHistoryDocumentView,
  MedicalHistoryDocumentForm,
  FilterMedicalHistoryTemplate,
  FilterMedicalHistoryDocument,
  FilterMedicalHistoryTimeline,
} from "@udok/lib/api/models";
import {
  makeItemFetch,
  makeItemsFetch,
  makeItemPost,
  makeItemPut,
  makeItemDelete,
} from "@udok/lib/api/axios";

export const medicalHistoryTemplates = (
  token: string,
  f?: FilterMedicalHistoryTemplate
) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<MedicalHistoryTemplate[]>({
    url: `/api/medical-history/template${filter}`,
    errLabel: "MHT1",
    token,
  });
};

export const createMedicalHistoryTemplate = async (
  token: string,
  data: MedicalHistoryTemplateForm
) =>
  makeItemPost<MedicalHistoryTemplate, MedicalHistoryTemplateForm>({
    url: `/api/medical-history/template`,
    errLabel: "MHT2",
    token,
    data,
  });

export const updateMedicalHistoryTemplate = async (
  token: string,
  doteID: string,
  data: MedicalHistoryTemplateForm
) =>
  makeItemPut<MedicalHistoryTemplate, MedicalHistoryTemplateForm>({
    url: `/api/medical-history/template/${doteID}`,
    errLabel: "MHT3",
    token,
    data,
  });

export const getMedicalHistoryTemplate = (token: string, doteID: string) =>
  makeItemFetch<MedicalHistoryTemplate>({
    url: `/api/medical-history/template/${doteID}`,
    errLabel: "MHT6",
    token,
  });

export const deleteMedicalHistoryTemplate = (token: string, doteID: string) =>
  makeItemDelete<MedicalHistoryTemplate>({
    url: `/api/medical-history/template/${doteID}`,
    errLabel: "MHT7",
    token,
  });

export const createMedicalHistoryDocument = async (
  token: string,
  data: MedicalHistoryDocumentForm
) =>
  makeItemPost<MedicalHistoryDocumentView, MedicalHistoryDocumentForm>({
    url: `/api/medical-history/document`,
    errLabel: "MHT4",
    token,
    data,
  });

export const getMedicalHistoryDocument = (
  token: string,
  patiID: string,
  f?: FilterMedicalHistoryDocument
) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<MedicalHistoryDocumentView[]>({
    url: `/api/medical-history/document/${patiID}${filter}`,
    errLabel: "MHT5",
    token,
  });
};

export const getMedicalHistoryDocumentTimeline = (
  token: string,
  patiID: string,
  doteID: string,
  f?: FilterMedicalHistoryTimeline
) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<MedicalHistoryDocumentView[]>({
    url: `/api/medical-history/history/${patiID}/${doteID}${filter}`,
    errLabel: "MHT8",
    token,
  });
};
