import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  IconButton,
  Typography,
  TypographyProps,
  ListItemText,
  ListItemAvatar,
  ListItem,
} from "@material-ui/core";
import StatusView from "@udok/lib/components/UserStatus/StatusView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(1),
      },
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    name: {
      color: theme.palette.grey[800],
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 200,
    },
  })
);

export type UserStatusProps = {
  status: boolean;
  src?: string;
  name?: string;
  nameProps?: TypographyProps;
};

const UserStatus = (props: UserStatusProps) => {
  const { status, src, name, nameProps } = props;
  const classes = useStyles();

  return (
    <ListItem component="div" dense className={classes.root}>
      <ListItemAvatar>
        <IconButton disabled>
          <Avatar className={classes.avatar} alt={name} src={src}>
            {src ? undefined : name?.[0]?.toUpperCase?.() ?? "U"}
          </Avatar>
        </IconButton>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography key={name} className={classes.name} {...nameProps}>
            {name}
          </Typography>
        }
        secondary={<StatusView status={status} />}
      />
    </ListItem>
  );
};

export default UserStatus;
