import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: 0,
      padding: theme.spacing(2),
      position: "relative",
      flexWrap: "wrap-reverse",
    },
    title: {
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
      },
    },
    rightActions: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        position: "initial",
        justifyContent: "flex-end",
        width: "100%",
      },
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  titleTypographyProps?: TypographyProps;
  closeButtonProps?: IconButtonProps;
  style?: React.CSSProperties;
  rightToolbar?: React.ReactNode;
  rightStyle?: React.CSSProperties;
  classOverlay?: {
    rightActions?: string;
  };
}

export default withStyles(styles)((props: DialogTitleProps) => {
  const {
    children,
    classes,
    onClose,
    className,
    titleTypographyProps,
    closeButtonProps,
    rightToolbar,
    rightStyle,
    classOverlay,
    ...other
  } = props;
  const handleClose = React.useCallback(() => {
    onClose?.();
  }, [onClose]);
  return (
    <MuiDialogTitle
      disableTypography
      className={clsx(classes.root, className)}
      {...other}
    >
      <Typography
        variant="h6"
        className={clsx(classes.title, titleTypographyProps?.className)}
        {...titleTypographyProps}
      >
        {children}
      </Typography>
      <div
        className={clsx(classes.rightActions, classOverlay?.rightActions)}
        style={rightStyle}
      >
        {rightToolbar}
        {onClose ? (
          <IconButton
            {...closeButtonProps}
            aria-label="close"
            className={clsx(classes.closeButton, closeButtonProps?.className)}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});
