import React from "react";
import { useDispatch } from "react-redux";
import { connectWS, fetchActivities, fetchActivitiesGroup } from "ducks/socket";
import { AppDispatch } from "ducks/state";

const Register = () => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(connectWS());
  }, [dispatch]);

  React.useEffect(() => {
    const t = setInterval(() => {
      Promise.all([
        dispatch(fetchActivitiesGroup()),
        dispatch(fetchActivities()),
      ]);
    }, 30000);
    return () => {
      clearInterval(t);
    };
  }, [dispatch]);

  return null;
};

export default Register;
