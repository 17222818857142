import React from "react";

export default () => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        textAlign: "center",
        height: 350,
        width: 600,
      }}
    >
      <div
        className="product-screen-1 wow fadeInUp"
        data-wow-delay="0.4s"
        data-wow-duration="0.6s"
        style={{
          visibility: "visible",
          animationDuration: "0.6s",
          animationDelay: "0.4s",
          animationName: "fadeInUp",
          position: "absolute",
          zIndex: 3,
          right: 64,
          bottom: 0,
          top: 30,
        }}
      >
        <img width={280} src="/static/img/product-screen-1.png" alt="" />
      </div>

      <div
        className="product-screen-2 wow fadeInUp"
        data-wow-delay="0.2s"
        data-wow-duration="0.6s"
        style={{
          visibility: "visible",
          animationDuration: "0.6s",
          animationDelay: "0.4s",
          animationName: "fadeInUp",
          position: "absolute",
          zIndex: 2,
          right: 164,
          bottom: 0,
          top: 90,
        }}
      >
        <img width={280} src="/static/img/product-screen-2.png" alt="" />
      </div>

      <div
        className="product-screen-3 wow fadeInUp"
        data-wow-duration="0.6s"
        style={{
          visibility: "visible",
          animationDuration: "0.6s",
          animationDelay: "0.4s",
          animationName: "fadeInUp",
          position: "absolute",
          zIndex: 1,
          left: 64,
          bottom: 0,
          top: 150,
        }}
      >
        <img width={280} src="/static/img/product-screen-3.png" alt="" />
      </div>
    </div>
  );
};
