import React from "react";
import { Virtuoso } from "react-virtuoso";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  List,
  LinearProgress,
  Tabs,
  Tab,
  Divider,
} from "@material-ui/core";
import Item, { ItemProps } from "@udok/lib/components/Chat/Conversations/Item";
import Avatar from "containers/User/Avatar";
import { ChatActivity } from "@udok/lib/api/models";
import ListSearch from "@udok/lib/components/Input/ListSearchInput";
import { removeDiacriticsFromString } from "@udok/lib/internal/util";
import { useParams } from "react-router-dom";
import { useConversationsWithUsers } from "hooks/socket";
import clsx from "clsx";

interface ConversationsProps {
  loading?: boolean;
  selectedUserID?: string;
  listItemComponent?: React.ElementType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      width: "100%",
      height: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column",
    },
    emptyData: {
      flex: 1,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    emptyTitle: { fontSize: 16, color: "#6f5e5b" },
    itemUserStatus: {
      position: "absolute",
      bottom: "25%",
      left: theme.spacing(3),
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    scrollContainer: {
      "& > div": {
        scrollbarWidth: "thin",
        scrollbarColor: "#dad7d7 #F4F4F4",
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F4",
        },
        "&::-webkit-scrollbar": {
          width: theme.spacing(),
          background: "#F4F4F4",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#dad7d7",
          borderRadius: 100,
        },
      },
    },
  })
);

enum Section {
  all = "*",
  patient = "patient",
  professionals = "professionals",
}

function sectionList(s: Section, list: ChatActivity[]) {
  switch (s) {
    case Section.patient:
      return list.filter((a) => a.applID === s);
    case Section.professionals:
      return list.filter((a) => a.applID === "doctor" || a.applID === "clinic");
    default:
      return list;
  }
}

function Conversations(props: ConversationsProps) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [section, setSection] = React.useState(Section.all);
  const timeout = React.useRef(null as NodeJS.Timeout | null);
  const [loading, listActivities] = useConversationsWithUsers();

  const handleChange = React.useCallback(
    (event: any, val: Section) => setSection(val),
    []
  );
  const onChangeTextSearch = React.useCallback((st: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setSearchTerm(st);
    }, 200);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ListSearch
          onChange={onChangeTextSearch}
          placeholder="Procurar uma conversa"
        />
        {loading ? <LinearProgress /> : null}
        <Tabs
          value={section}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          onChange={handleChange}
          style={{ marginLeft: 0 }}
          classes={{ indicator: classes.tabIndicator }}
        >
          <Tab value={Section.all} label="Todos" />
          <Tab value={Section.professionals} label="Profissionais da clínica" />
          <Tab value={Section.patient} label="Pacientes" />
        </Tabs>
        <Divider />
        <List className={clsx(classes.container, classes.scrollContainer)}>
          <ContactList
            searchTerm={searchTerm}
            listActivities={sectionList(section, listActivities)}
            {...props}
          />
        </List>
      </div>
    </div>
  );
}

export default Conversations;

const ContactList = (
  props: ConversationsProps & {
    listActivities: ChatActivity[];
    searchTerm: string;
  }
) => {
  const { selectedUserID, listItemComponent, listActivities, searchTerm } =
    props;
  const ListItemComponent = listItemComponent;
  const classes = useStyles();

  const filteredData = listActivities.filter((d) => {
    const nameMatch = removeDiacriticsFromString(d?.username ?? "")
      ?.toLocaleLowerCase?.()
      ?.indexOf?.(
        removeDiacriticsFromString(searchTerm ?? "").toLocaleLowerCase() ?? ""
      );
    return nameMatch > -1;
  });

  return (
    <>
      {listActivities.length <= 0 ? (
        <div className={classes.emptyData}>
          <div style={{ margin: 24 }}>
            <Typography align="center" className={classes.emptyTitle}>
              Você não possui conversas
            </Typography>
          </div>
        </div>
      ) : null}
      <Virtuoso
        totalCount={filteredData.length}
        overscan={10}
        itemContent={(index: number) => (
          <MemoizedItem
            key={filteredData[index].withUserID}
            item={filteredData[index]}
            handleProfile={() => {}}
            selectedUserID={selectedUserID}
            component={ListItemComponent}
          />
        )}
        computeItemKey={(index: number) =>
          filteredData[index].withUserID +
          filteredData[index].messagePreview +
          filteredData[index].unreadCount
        }
        style={{ flex: 1 }}
      />
    </>
  );
};

const MemoizedItem = React.memo(
  (props: Omit<ItemProps, "to" | "avatarComponent">) => {
    const { slug } = useParams<{ slug: string }>();
    return (
      <Item
        avatarComponent={(p) => <Avatar userID={p.userID} />}
        to={(userID) => `/${slug}/admin/conversations/${userID}`}
        {...props}
      />
    );
  },
  (p1, p2) => {
    return (
      p1.item?.withUserID === p2.item?.withUserID &&
      p1.selectedUserID === p2.selectedUserID
    );
  }
);
