import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M315.793 19.932h-59.796v37.372h41.109v70.384c0 61.82-50.297 112.117-112.117 112.117S72.873 189.509 72.873 127.689V57.304h39.864V19.932h-58.55c-10.321 0-18.686 8.365-18.686 18.686v89.071c0 82.431 67.058 149.489 149.489 149.489s149.489-67.058 149.489-149.489V38.618c0-10.321-8.365-18.686-18.686-18.686z" />
      <path d="M388.668 292.749v88.448c0 51.518-41.913 93.431-93.431 93.431s-93.431-41.913-93.431-93.431V259.114h-37.372v122.083c0 72.122 58.674 130.803 130.803 130.803 72.122 0 130.803-58.681 130.803-130.803v-88.448h-37.372z" />
      <path d="M407.361 171.912c-38.132 0-69.145 31.013-69.145 69.139 0 38.12 31.013 69.139 69.139 69.139 38.126 0 69.139-31.019 69.145-69.139-.001-38.126-31.019-69.139-69.139-69.139zm-.006 100.906c-17.521 0-31.773-14.251-31.773-31.766s14.251-31.766 31.773-31.766c17.515 0 31.766 14.251 31.766 31.766s-14.251 31.766-31.766 31.766zM112.114 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686S130.8 68.871 130.8 58.55V18.686C130.8 8.365 122.435 0 112.114 0zM256.62 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686s18.686-8.365 18.686-18.686V18.686C275.306 8.365 266.941 0 256.62 0z" />
    </svg>
  );
}

export default SvgComponent;
