import { toQueryString } from "@udok/lib/internal/util";
import {
  ImmediateCareForm,
  ImmediateCareView,
  FilterImmediateCare,
  PublicImmediateCare,
  FilterPublicImmediateCare,
} from "@udok/lib/api/models";
import {
  makeItemFetch,
  makeItemsFetch,
  makeItemPost,
  makeItemPut,
  makeItemDelete,
} from "@udok/lib/api/axios";

export const createImmediateCare = async (
  token: string,
  data: ImmediateCareForm
) =>
  makeItemPost<ImmediateCareView, ImmediateCareForm>({
    url: `/api/immediate-care`,
    errLabel: "IC1",
    token,
    data,
  });

export const listImmediateCare = (token: string, f?: FilterImmediateCare) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<ImmediateCareView[]>({
    url: `/api/immediate-care${filter}`,
    errLabel: "IC2",
    token,
  });
};

export const getImmediateCare = (token: string, imcaID: string) =>
  makeItemFetch<ImmediateCareView>({
    url: `/api/immediate-care/${imcaID}`,
    errLabel: "IC3",
    token,
  });

export const deleteImmediateCare = async (token: string, imcaID: string) =>
  makeItemDelete<ImmediateCareView>({
    url: `/api/immediate-care/${imcaID}`,
    errLabel: "IC4",
    token,
  });

export const updateImmediateCare = async (
  token: string,
  imcaID: string,
  data: ImmediateCareForm
) =>
  makeItemPut<ImmediateCareView, ImmediateCareForm>({
    url: `/api/immediate-care/${imcaID}`,
    errLabel: "IC5",
    token,
    data,
  });

export const getPublicImmediateCare = (slug: string) =>
  makeItemFetch<PublicImmediateCare>({
    url: `/api/immediate-care-public/${slug}`,
    errLabel: "IC6",
  });

export const listPublicImmediateCare = (f: FilterPublicImmediateCare) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<PublicImmediateCare[]>({
    url: `/api/immediate-care-public${filter}`,
    errLabel: "IC7",
  });
};
