import { hen, Hen } from "@udok/lib/internal/store";
import { RootState } from "ducks/state";
import { createSelector } from "reselect";
import { AppThunk } from "ducks/state";
export interface NotificationMessage {
  status: string;
  message: string;
  temporary?: boolean;
  actions?: NotificationActions[];
}

export type NotificationActions = {
  url: string;
  name: string;
  prefix?: string;
  suffix?: string;
};

export type InitialState = {
  sections: { [key: string]: Array<NotificationMessage> };
};

// Reducers
const initialState: InitialState = {
  sections: {},
};

class NotificationSlice extends Hen<InitialState> {
  firstDismissed(section: string) {
    this.state.sections[section]?.shift?.();
  }

  allDismissed(section: string) {
    this.state.sections[section] = [];
  }

  oneAdded(section: string, m: NotificationMessage) {
    const sections = [...(this.state.sections[section] || []), m];
    this.state.sections[section] = sections;
  }
}

export const [noteReducer, actions] = hen(new NotificationSlice(initialState));

// Selectors
const mainSelector = (state: RootState) => state.notification;

export const getNotificationFrom = (section: string) =>
  createSelector(mainSelector, (state) => {
    return {
      notification: (state.sections[section] || [])[0],
      count: (state.sections[section] || []).length,
    };
  });

// Actions
export function dismissFirstNotificationFrom(section: string) {
  return actions.firstDismissed(section);
}

export function dismissAllNotificationsFrom(section: string) {
  return actions.allDismissed(section);
}

export function newNotification(section: string, note: NotificationMessage) {
  return actions.oneAdded(section, note);
}

export function saveSuccessNotification(
  message: string,
  section?: string
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(
      newNotification(section ?? "general", {
        status: "success",
        message,
      })
    );
  };
}
