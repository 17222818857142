import React from "react";
import { PrivateFileUploadedResponse } from "@udok/lib/api/user";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SpeedDial, { OpenReason } from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Icons from "@udok/lib/components/Icon";
import PrivateUploadButton from "containers/File/PrivateUploadButton";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      position: "absolute",
      bottom: 0,
      right: -8,
    },
    exampleWrapper: {
      position: "relative",
      height: 40,
      width: 40,
    },
    fabRoot: {
      boxShadow: "none",
      backgroundColor: "initial",
      color: "rgba(0, 0, 0, 0.54)",
    },
  })
);

const Composer = (props: {
  userID: string;
  currentUserID?: string;
  onFile: (type: "archive" | "image", fileID: string, name: string) => void;
  [k: string]: any;
}) => {
  const { userID, onFile } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const acceptFile = (f: PrivateFileUploadedResponse) => {
    handleClose();
    onFile("archive", f.fileID, f.name);
  };
  const acceptImage = (f: PrivateFileUploadedResponse) => {
    handleClose();
    onFile("image", f.fileID, f.name);
  };

  const handleOpen = (e: any, reason: OpenReason) => {
    if (reason !== "toggle") {
      return;
    }
    setOpen(true);
  };

  const actions = [
    {
      icon: (
        <PrivateUploadButton
          userID={userID}
          inputProps={{ accept: "*/*" }}
          onFile={acceptFile}
        />
      ),
      name: "Documento",
    },
    {
      icon: (
        <PrivateUploadButton
          userID={userID}
          icon={<Icons.Image />}
          inputProps={{ accept: "image/*" }}
          onFile={acceptImage}
        />
      ),
      name: "Imagem",
    },
  ];

  return (
    <div className={classes.exampleWrapper}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<Icons.AttachFile />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        FabProps={
          {
            classes: { root: classes.fabRoot },
            color: "default",
            size: "small",
            component: ({ c, ...other }: any) => <IconButton {...other} />,
          } as any
        }
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default Composer;
