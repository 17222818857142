import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="2983720-1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 54.373 50.763"
      {...props}
    >
      <path
        data-name="Caminho 41"
        d="M35.384 10.411A18.651 18.651 0 000 18.584a18.384 18.384 0 001.778 7.943L.032 35.263A1.594 1.594 0 001.9 37.138l8.844-1.748a18.323 18.323 0 003.688 1.29 21.793 21.793 0 0120.952-26.269z"
      />
      <path
        data-name="Caminho 42"
        d="M52.594 40.119a18.534 18.534 0 00.8-1.966h-.058a18.6 18.6 0 00-16.733-24.538 18.583 18.583 0 10-.893 37.147 18.381 18.381 0 007.919-1.778c9.686 1.915 8.93 1.779 9.153 1.779a1.594 1.594 0 001.562-1.905z"
      />
    </svg>
  );
}

export default SvgComponent;
