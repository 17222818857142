import React from "react";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import { Route, Switch, useParams } from "react-router";
import SecureContainer from "containers/Auth/SecureContainer";
import Paperbase from "components/UI/Paperbase";
import Menu from "containers/Auth/Menu";
import { ExtendedMenu } from "containers/Auth/ProfileMenu";
import Logo from "containers/Clinic/Logo";
import { Link } from "react-router-dom";
import EventsAlert from "containers/EventsAlert";
import ChatRegister from "containers/Chat/Register";
import AlertConversations from "containers/Chat/Alert/AlertConversations";
import FloatingChat from "containers/Chat/Alert/FloatingChat";
import { useConnectRealtime } from "hooks/realtime";

export const Navigation = Loadable({
  loader: () => import("screens/admin/navigation"),
  loading: Loader,
});

export const Schedule = Loadable({
  loader: () => import("screens/admin/schedule"),
  loading: Loader,
});

export const Specialty = Loadable({
  loader: () => import("screens/admin/specialty"),
  loading: Loader,
});

export const Users = Loadable({
  loader: () => import("screens/admin/users"),
  loading: Loader,
});

export const Calendar = Loadable({
  loader: () => import("screens/admin/calendar"),
  loading: Loader,
});

export const Document = Loadable({
  loader: () => import("screens/admin/documents"),
  loading: Loader,
});

export const ClinicProfile = Loadable({
  loader: () => import("screens/admin/clinic"),
  loading: Loader,
});

export const ClinicBranch = Loadable({
  loader: () => import("screens/admin/clinicBranch"),
  loading: Loader,
});

export const Doctors = Loadable({
  loader: () => import("screens/admin/doctors"),
  loading: Loader,
});

export const CollectDocuments = Loadable({
  loader: () => import("screens/admin/collectDocument"),
  loading: Loader,
});

export const Locations = Loadable({
  loader: () => import("screens/admin/locations"),
  loading: Loader,
});

export const AppointmentType = Loadable({
  loader: () => import("screens/admin/appointmentType"),
  loading: Loader,
});

export const Finances = Loadable({
  loader: () => import("screens/admin/finances"),
  loading: Loader,
});

export const Voucher = Loadable({
  loader: () => import("screens/admin/voucher"),
  loading: Loader,
});

export const ScheduleLock = Loadable({
  loader: () => import("screens/admin/scheduleLock"),
  loading: Loader,
});

export const Appointment = Loadable({
  loader: () => import("screens/admin/appointment"),
  loading: Loader,
});

export const Settings = Loadable({
  loader: () => import("screens/admin/advancedSettings"),
  loading: Loader,
});

export const Patients = Loadable({
  loader: () => import("screens/admin/patients"),
  loading: Loader,
});

export const Prescriptionlayout = Loadable({
  loader: () => import("screens/admin/prescriptionLayout"),
  loading: Loader,
});

export const SendInvitation = Loadable({
  loader: () => import("screens/admin/sendInvitation"),
  loading: Loader,
});

export const ConversationsScreen = Loadable({
  loader: () => import("screens/admin/conversations"),
  loading: Loader,
});

export const ExamProcedureScreen = Loadable({
  loader: () => import("screens/admin/examProcedure"),
  loading: Loader,
});

export const QuickResponse = Loadable({
  loader: () => import("screens/admin/quickResponse"),
  loading: Loader,
});

export const Dashboard = Loadable({
  loader: () => import("screens/admin/dashboard"),
  loading: Loader,
});

export const TriggerActions = Loadable({
  loader: () => import("screens/admin/triggerActions"),
  loading: Loader,
});

export const AppointmentReview = Loadable({
  loader: () => import("screens/admin/appointmentReview"),
  loading: Loader,
});

export const MediaPost = Loadable({
  loader: () => import("screens/admin/mediaPost"),
  loading: Loader,
});

export const AppointmentInstruction = Loadable({
  loader: () => import("screens/admin/appointmentInstruction"),
  loading: Loader,
});

export const SharedResource = Loadable({
  loader: () => import("screens/admin/sharedResource"),
  loading: Loader,
});

export const medicalHistory = Loadable({
  loader: () => import("screens/admin/medicalHistory"),
  loading: Loader,
});

export const ImmediateCare = Loadable({
  loader: () => import("screens/admin/immediateCare"),
  loading: Loader,
});

export const RecordFlag = Loadable({
  loader: () => import("screens/admin/recordFlag"),
  loading: Loader,
});

const ConnectRealtime = () => {
  useConnectRealtime();
  return null;
};

export default () => {
  const { slug } = useParams<{ slug: string }>();

  return (
    <SecureContainer>
      <ChatRegister />
      <ConnectRealtime />
      <Paperbase
        navigator={Menu}
        headerItems={
          <Link to={`/${slug}/admin`}>
            <Logo height={65} style={{ maxWidth: 200 }} />
          </Link>
        }
        toolbarItems={
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 12,
            }}
          >
            <AlertConversations id="header-chat-alert" />
            <EventsAlert />
            <ExtendedMenu />
          </div>
        }
      >
        <Switch>
          <Route path="/:slug/admin/schedule" component={Schedule} />
          <Route path="/:slug/admin/documents" component={Document} />
          <Route
            path="/:slug/admin/calendar/:mode?/:date?"
            strict={false}
            component={Calendar}
          />
          <Route path="/:slug/admin/locations" component={Locations} />
          <Route path="/:slug/admin/clinicprofile" component={ClinicProfile} />
          <Route path="/:slug/admin/clinic-branches" component={ClinicBranch} />
          <Route path="/:slug/admin/config/users" component={Users} />
          <Route path="/:slug/admin/config/specialties" component={Specialty} />
          <Route path="/:slug/admin/doctors" component={Doctors} />
          <Route
            path="/:slug/admin/collectdocuments"
            component={CollectDocuments}
          />
          <Route
            path="/:slug/admin/appointmentype"
            component={AppointmentType}
          />
          <Route path="/:slug/admin/finances" component={Finances} />
          <Route path="/:slug/admin/voucher" component={Voucher} />
          <Route path="/:slug/admin/scheduleLock" component={ScheduleLock} />
          <Route path="/:slug/admin/appointments" component={Appointment} />
          <Route path="/:slug/admin/settings" component={Settings} />
          <Route path="/:slug/admin/patients" component={Patients} />
          <Route path="/:slug/admin/dashboard" component={Dashboard} />
          <Route path="/:slug/admin/review" component={AppointmentReview} />
          <Route
            path="/:slug/admin/prescriptionlayout"
            component={Prescriptionlayout}
          />
          <Route
            path="/:slug/admin/sendinvitation"
            component={SendInvitation}
          />
          <Route
            path="/:slug/admin/conversations/:userID?"
            component={ConversationsScreen}
          />
          <Route
            path="/:slug/admin/exams-procedures"
            component={ExamProcedureScreen}
          />
          <Route
            path="/:slug/admin/trigger-actions"
            component={TriggerActions}
          />
          <Route path="/:slug/admin/quick-response" component={QuickResponse} />
          <Route path="/:slug/admin/media-post" component={MediaPost} />
          <Route
            path="/:slug/admin/appointment-instructions"
            component={AppointmentInstruction}
          />
          <Route
            path="/:slug/admin/shared-resources"
            component={SharedResource}
          />
          <Route
            path="/:slug/admin/medical-history"
            component={medicalHistory}
          />
          <Route path="/:slug/admin/immediate-care" component={ImmediateCare} />
          <Route path="/:slug/admin/record-flag" component={RecordFlag} />
          <Route path="/:slug/admin" component={Navigation} />
        </Switch>
        <FloatingChat />
      </Paperbase>
    </SecureContainer>
  );
};
