import React from "react";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./audioplayer.css";
import { ComponentProps } from "@udok/lib/components/Chat/DirectMessage/ChatMessage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedButton: {
      borderRadius: 20,
      height: 30,
      width: theme.spacing(5),
      margin: theme.spacing(1, 2),
      minWidth: 0,
    },
  })
);

type Props = {
  src: string;
  avatar: React.ReactNode;
} & ComponentProps;

let timeout: any = null;
const RenderAudioPlayer = (props: Props) => {
  const [isPlaying, setPlaying] = React.useState(false);
  const [speed, setSpeed] = React.useState(1);
  const classes = useStyles();
  const audioRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (audioRef?.current?.audio?.current) {
      audioRef.current.audio.current.playbackRate = speed;
    }
  }, [speed, audioRef]);

  const handleChangePlaybackSpeed = React.useCallback(
    () =>
      setSpeed((spd) => {
        if (spd === 2) {
          return 1;
        }
        return spd + 0.5;
      }),
    []
  );

  const handlePlay = React.useCallback(() => {
    if (!isPlaying) {
      setPlaying(true);
    }
    if (timeout) {
      clearTimeout(timeout);
    }
  }, [isPlaying]);

  const handleEnd = React.useCallback(() => {
    timeout = setTimeout(() => {
      setPlaying(false);
    }, 1000);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <AudioPlayer
        ref={audioRef}
        showSkipControls={false}
        showJumpControls={false}
        customControlsSection={["MAIN_CONTROLS"] as any}
        customProgressBarSection={[
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.DURATION,
        ]}
        layout="horizontal-reverse"
        src={props.src}
        autoPlayAfterSrcChange={false}
        onPlay={handlePlay}
        onEnded={handleEnd}
      />
      {isPlaying ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.speedButton}
            onClick={handleChangePlaybackSpeed}
          >
            {speed}
            <sub>x</sub>
          </Button>
        </div>
      ) : (
        props.avatar
      )}
    </div>
  );
};

export default RenderAudioPlayer;
