import * as React from "react";
import { WithStyles, withStyles, Theme } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icons from "components/Icon";
import blue from "@material-ui/core/colors/blue";

export interface Profile {
  name: string;
  avatar?: string;
}

const styles = (theme: Theme) => ({
  root: {},
});
export interface ProfileButtonProps extends WithStyles<typeof styles> {
  profile: Profile;
  urls: { signout: string };
  component?: React.ElementType;
}

const ProfileButton = (props: ProfileButtonProps) => {
  const { profile, classes, urls, component } = props;
  const [anchorEl, setAnchorEl] = React.useState(
    null as (EventTarget & HTMLDivElement) | null
  );
  const avatar: string = profile.avatar || "usuário";
  const PressButton = component ?? Avatar;

  return (
    <div className={classes.root}>
      <PressButton
        style={{ cursor: "pointer" }}
        alt={profile.name}
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
        src={avatar}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem component="a">{profile.name}</MenuItem>
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={(props: any) => <Link {...props} to={props.href} />}
          href={urls.signout}
        >
          <ListItemText primary="Sair" />
          <ListItemIcon style={{ minWidth: 24 }}>
            <Icons.ExitToApp
              style={{ width: 24, height: 24, color: blue[600] }}
            />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(ProfileButton);
