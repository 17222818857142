import React from "react";
import { useSelector } from "react-redux";
import { getClinicProfile } from "ducks/clinic";
import AuthCard, { AuthCardProps } from "@udok/lib/components/AuthCard";

const CardLogoText = (props: AuthCardProps) => {
  const { profile } = useSelector(getClinicProfile);

  return (
    <AuthCard
      {...props}
      cardLogoTextProps={{
        ...props?.cardLogoTextProps,
        text: profile?.name ?? "",
        logoSRC: `${process.env.REACT_APP_BASE_PATH}/files/${profile?.avatar}`,
        logoALT: profile?.name ?? "",
      }}
    />
  );
};

export default CardLogoText;
