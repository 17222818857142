import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  AppointmentType,
  FilterSearchAppointmentType,
} from "@udok/lib/api/models";

export const createAppointmentType = async (
  token: string,
  appoType: AppointmentType
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/appointment-types`, appoType, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as AppointmentType;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APT1");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentTypes = (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-types`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentType[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APT2");
      return Promise.reject(new Error(err));
    });
};

export const searchAppointmentTypes = (f?: FilterSearchAppointmentType) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-types/search${filter}`)
    .then((r) => {
      return r.data.data.items as AppointmentType[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APT6");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentType = (token: string, aptyID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-types/${aptyID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as AppointmentType;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APT3");
      return Promise.reject(new Error(err));
    });
};

export const deleteAppointmentType = async (token: string, aptyID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/appointment-types/${aptyID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as AppointmentType;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APT4");
      return Promise.reject(new Error(err));
    });
};

export const updateAppointmentType = async (
  token: string,
  appoType: AppointmentType
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/appointment-types/${appoType.aptyID}`,
      appoType,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentType;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APT5");
      return Promise.reject(new Error(err));
    });
};
