import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  MediaPostForm,
  MediaPost,
  FilterMediaPost,
} from "@udok/lib/api/models";

export const createMediaPost = async (token: string, form: MediaPostForm) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/media-post`, form, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as MediaPost;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MP1");
      return Promise.reject(new Error(err));
    });
};

export const listMediaPosts = (token: string, filter?: FilterMediaPost) => {
  const f = filter ? toQueryString({ filter }) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/media-post${f}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.items as MediaPost[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MP2");
      return Promise.reject(new Error(err));
    });
};

export const getMediaPost = (token: string, mepoID: number) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/media-post/${mepoID}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as MediaPost;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MP3");
      return Promise.reject(new Error(err));
    });
};

export const deleteMediaPost = async (token: string, mepoID: number) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/media-post/${mepoID}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as MediaPost;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MP4");
      return Promise.reject(new Error(err));
    });
};

export const updateMediaPost = async (
  token: string,
  form: MediaPostForm & { mepoID: number }
) => {
  return axios
    .put(`${process.env.REACT_APP_API_PATH}/media-post/${form.mepoID}`, form, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as MediaPost;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MP5");
      return Promise.reject(new Error(err));
    });
};

export const listPublications = (filter?: FilterMediaPost) => {
  const f = filter ? toQueryString(filter) : "";

  return axios
    .get(`${process.env.REACT_APP_API_PATH}/publications${f}`)
    .then((r) => {
      return r.data.data.items as MediaPost[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MP6");
      return Promise.reject(new Error(err));
    });
};

export const getPublication = (slug: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/publications/${slug}`)
    .then((r) => {
      return r.data.data.item as MediaPost;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "MP7");
      return Promise.reject(new Error(err));
    });
};
