import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  Location,
  GeoLocation,
  GeoCity,
  FilterGeoCity,
} from "@udok/lib/api/models";
import {
  createLocation,
  fetchLocations,
  fetchLocation,
  deleteLocation,
  updateLocation,
  geoLocationsCity,
} from "@udok/lib/api/location";
import { fetchGeoLocationSearch } from "@udok/lib/api/search";
import { plansSelector } from "ducks/clinic";
import { getToken, UNAUTHORIZED } from "./auth";
import { selectUserPermissions } from "ducks/user";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  locationByID: { [locaID: string]: Location };
  cityByID: { [geciID: number]: GeoCity | undefined };
  listedCities: number[];
};

// Reducers
const initialState: InitialState = {
  locationByID: {},
  cityByID: {},
  listedCities: [],
};

class Locations extends Hen<InitialState> {
  locaLoaded(loca: Location) {
    this.state.locationByID[loca.locaID] = loca;
  }
  locationsLoaded(loca: Location[]) {
    loca.forEach((l) => {
      this.state.locationByID[l.locaID] = l;
    });
  }
  listCities(cities: GeoCity[]) {
    const list = cities.map((city) => {
      this.state.cityByID[city.geciID] = city;
      return city.geciID;
    });
    this.state.listedCities = list;
  }
}

export const [Reducer, actions] = hen(new Locations(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.location;

export const locationByIDSelector = (state: RootState) =>
  state.location.locationByID;
const citySelector = (state: RootState) => state.location.cityByID;
const listedCitiesSelector = (state: RootState) => state.location.listedCities;

export const getListlocation = createSelector(
  [mainSelector, selectUserPermissions],
  (state, roles) => {
    return {
      list: Object.keys(state.locationByID)
        .map((locaID) => state.locationByID[locaID])
        .filter((l) => !l.deletedAt)
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
      roles,
    };
  }
);

export const getOneLocation = (props: { locaID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      location: state.locationByID[props.locaID],
    };
  });

export const SchedulePlansAlertView = (
  state: RootState,
  props: { locaID: string }
) =>
  createSelector([mainSelector, plansSelector], (state, plans) => {
    const location = state.locationByID[props.locaID] ?? {};
    const listPlans = Object.keys(plans).map((id) => plans[id]);
    return {
      location,
      listPlans,
    };
  });

export const searchGeoLocationCityListView = createSelector(
  [citySelector, listedCitiesSelector],
  (cityByID, listedCities) => {
    const allCities = Object.keys(cityByID)
      .map((id) => cityByID[parseInt(id)])
      .filter((b) => !!b) as GeoCity[];

    const filteredCities = listedCities
      .map((id) => cityByID[id])
      .filter((b) => !!b) as GeoCity[];
    return {
      allCities,
      filteredCities,
    };
  }
);

// Actions
export function createOneLocation(
  location: Location
): AppThunk<Promise<Location>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createLocation(apiToken, location)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Localização criada com sucesso",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadLocations(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchLocations(apiToken)
      .then((r) => {
        dispatch(actions.locationsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneLocation(locaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchLocation(apiToken, locaID)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeLocation(locaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteLocation(apiToken, locaID)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeLocation(location: Location): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateLocation(apiToken, location)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function searchLocationByCep(
  cep: string
): AppThunk<Promise<GeoLocation>> {
  return async (dispatch) => {
    return fetchGeoLocationSearch(cep)
      .then((r) => r[0])
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedLocation(locaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const locationExist = Boolean(state.location.locationByID[locaID]);
    if (locationExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneLocation(locaID));
  };
}

export function searchGeoLocationCity(
  filter?: FilterGeoCity
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return geoLocationsCity(filter)
      .then((r) => {
        if (r) {
          dispatch(actions.listCities(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
