import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={512}
      viewBox="0 0 128 128"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M90 2.8C74.8 2.8 62.5 15 62 30.7c-.4 14.5 13.5 33.6 21.8 43.6 1.5 1.8 3.8 2.9 6.2 2.9s4.6-1.1 6.2-2.9c8.3-10.1 22.3-29.1 21.8-43.6-.5-15.7-12.8-27.9-28-27.9zm0 38c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zM12.4 98.2c.4 1.2 1.6 2 2.8 2 .3 0 .7-.1 1-.2l25.4-9.2 12.3 33.8c.4 1.2 1.6 2 2.8 2 .3 0 .7-.1 1-.2 1.6-.6 2.4-2.3 1.8-3.8L46.2 85.8 35.6 56.7c-.6-1.6-2.3-2.4-3.8-1.8-1.6.6-2.4 2.3-1.8 3.8L39.6 85l-25.4 9.2c-1.6.7-2.4 2.4-1.8 4zM56.2 57.7l-6.6 2.4-2.7-7.5c-.6-1.6-2.3-2.4-3.8-1.8-1.6.6-2.4 2.3-1.8 3.8L45 65c.4 1.2 1.6 2 2.8 2 .3 0 .7-.1 1-.2l9.4-3.4c1.6-.6 2.4-2.3 1.8-3.8s-2.3-2.4-3.8-1.9z" />
      <path d="M49.1 76.2l16.1 44.1c.4 1.2 1.6 2 2.8 2 .3 0 .7-.1 1-.2 1.6-.6 2.4-2.3 1.8-3.8l-6.5-17.9 31.9-11.6 2.4 6.6c.4 1.2 1.6 2 2.8 2 .3 0 .7-.1 1-.2 1.6-.6 2.4-2.3 1.8-3.8l-2.4-6.6 6.6-2.4c1.6-.6 2.4-2.3 1.8-3.8-.6-1.6-2.3-2.4-3.8-1.8L97 82.2 62.3 94.9 55.8 77 67 72.8c1.6-.6 2.3-2.3 1.8-3.9-.6-1.6-2.3-2.3-3.9-1.8l-14.1 5.2c-.222.088-1.1.464-1.593 1.449-.585 1.164-.176 2.272-.107 2.451z" />
    </svg>
  );
}

export default SvgComponent;
