import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="#8B96A6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 1.5c4.703 0 8.5 3.797 8.5 8.5 0 4.703-3.797 8.5-8.5 8.5A8.489 8.489 0 011.5 10c0-4.703 3.797-8.5 8.5-8.5zm-.765 4.743a.75.75 0 00-.515.227l-3 3a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.751.751 0 100-1.5H8.06l1.72-1.72a.75.75 0 00-.545-1.287z" />
    </svg>
  );
}

export default SvgComponent;
