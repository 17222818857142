import * as React from "react";
import clsx from "clsx";
import { IconButton, IconButtonProps } from "@material-ui/core";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import typeTheme from "@udok/lib/themes/paperbase";

const Variant = {
  outlined: "outlined",
  contained: "contained",
};

type DefaultTheme = typeof typeTheme | Theme;

const useStyles = makeStyles((theme: DefaultTheme) =>
  createStyles({
    iconButton: {
      borderRadius: 5,
      height: 40,
    },
    outlinedIconButton: {
      border: "solid 1px",
      "&:hover": {
        backgroundColor: theme.palette?.primary?.lightest,
      },
    },
    containedIconButton: {
      backgroundColor: theme.palette?.primary?.main,
      color: theme.palette?.neutral?.lightest,
      "&:hover": {
        backgroundColor: theme.palette?.primary?.dark,
      },
    },
  })
);

export type IconButtonOutlinedProps<C extends React.ElementType> = {
  component?: C;
  variant?: keyof typeof Variant;
} & IconButtonProps &
  Omit<React.ComponentProps<C>, "options">;

const IconButtonOutlined = <C extends React.ElementType>(
  props: IconButtonOutlinedProps<C>
) => {
  const { className, variant = Variant.outlined, disabled, ...others } = props;
  const classes = useStyles();
  const theme: DefaultTheme = useTheme();

  return (
    <IconButton
      className={clsx(classes.iconButton, className, {
        [classes.outlinedIconButton]: variant === Variant.outlined,
        [classes.containedIconButton]: variant === Variant.contained,
      })}
      style={
        variant === Variant.contained && disabled
          ? {
              backgroundColor: theme.palette?.neutral?.light,
            }
          : undefined
      }
      disabled={disabled}
      {...others}
    />
  );
};

export default IconButtonOutlined;
