import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { onReadMessage, onDeleteMessage } from "ducks/socket";
import { useListMessages } from "hooks/socket";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Toolbar, DialogContent } from "@material-ui/core";
import UserStatus from "./UserStatus";
import Composer from "./Composer";
import MessageList, {
  MessageListRef,
} from "@udok/lib/components/Chat/DirectMessage/MessageList";
import Dialog from "@udok/lib/components/Dialog/ResponsiveDialog";
import DialogTitle from "@udok/lib/components/Dialog/Title";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import {
  Message,
  Actions,
} from "@udok/lib/components/Chat/DirectMessage/ChatMessage";
import { QuickResponse } from "@udok/lib/api/models";
import QuickresponseCreate from "containers/Quickresponse/Create";
import { ChatMessageSize } from "@udok/lib/components/UI/UIContext";
import ChatMessage from "./ChatMessage";
import { downloadFileBlob } from "ducks/files";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "space-between",
      backgroundColor: "#eaeff1",
    },
    toolbarTop: {
      backgroundColor: theme.palette.grey[100],
      height: 60,
      minHeight: 60,
      padding: 0,
    },
    toolbarBottom: {
      backgroundColor: theme.palette.grey[100],
      padding: 0,
    },
    actionContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
    messageSection: {
      flex: 10,
      position: "relative",
    },
    toolbarRight: {
      padding: theme.spacing(2),
    },
    groupSection: {
      padding: "2px 8px",
      background: "#dbdbdb",
      borderRadius: 8,
      margin: 12,
      marginBottom: 0,
    },
    dialogTitle: {
      alignItems: "center",
      flexWrap: "nowrap",
    },
    oneLineText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      height: "fit-content",
    },
  })
);

export type DirectMessageSimpleProps = {
  userID: string;
  megrID?: string;
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
  size?: ChatMessageSize;
};

export default function DirectMessageSimple(props: DirectMessageSimpleProps) {
  const classes = useStyles();
  const messagesRef = React.useRef<MessageListRef>(null);
  const handleSend = React.useCallback(() => {
    messagesRef?.current?.scrollToBottom();
  }, [messagesRef]);

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbarTop}>
        {props.leftToolbar}
        <UserStatus userID={props.userID} />
        {props.rightToolbar}
      </Toolbar>
      <div className={classes.messageSection}>
        <ConnectedMessageList
          ref={messagesRef}
          userID={props.userID}
          size={props?.size ?? ChatMessageSize.large}
        />
      </div>

      <Toolbar disableGutters className={classes.toolbarBottom}>
        <Composer
          key={props.userID}
          userID={props.userID}
          megrID={props.megrID}
          handleSend={handleSend}
        />
      </Toolbar>
    </div>
  );
}

const QuickresponseCreateDialog = ({
  open,
  close,
  message,
}: {
  open: boolean;
  close: (val?: string) => void;
  message?: Message;
}) => {
  const classes = useStyles();
  const handleClose = () => {
    close();
  };
  const initialValues: Partial<QuickResponse> = {
    value: message?.text,
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle
        id="form-dialog-title"
        onClose={handleClose}
        className={classes.dialogTitle}
        titleTypographyProps={{ className: classes.oneLineText }}
      >
        Nova resposta rápida
      </DialogTitle>
      <DialogContent>
        <QuickresponseCreate
          onSuccess={handleClose}
          initialValues={initialValues}
        />
      </DialogContent>
    </Dialog>
  );
};

export type MessageListProps = {
  [k: string]: any;
  size: ChatMessageSize;
};
const [rendererQuickresponse, quickresponseCreate] = createModal(
  QuickresponseCreateDialog
);
export const ConnectedMessageList = React.forwardRef(
  (props: MessageListProps, ref: React.Ref<MessageListRef | null>) => {
    const { userID, megrID, size } = props;
    const [listMessages, unreadMessages] = useListMessages(userID, megrID);
    const dispatch: AppDispatch = useDispatch();

    const handleRangeChanged = React.useCallback(
      ({ startIndex, endIndex }) => {
        let unreadMsgs: number[] = [];
        let targets = new Set();
        for (let i = startIndex; i <= endIndex; i++) {
          const message = listMessages[i];
          const curUserID = message?.user?.currentUser?.userID;
          const messageUserID = message?.user?._id;
          const messageIsUnread = !message?.status?.find?.(
            (m: string) => m === curUserID
          );
          if (curUserID && messageIsUnread && message?._id) {
            targets.add(curUserID);
            targets.add(messageUserID);
            unreadMsgs.push(message?._id);
          }
        }

        if (unreadMsgs.length > 0) {
          dispatch(onReadMessage(Array.from(targets) as string[], unreadMsgs));
        }
      },
      [listMessages, dispatch]
    );
    const handleDeleteMessage = React.useCallback(
      (message: any) => {
        dispatch(onDeleteMessage(message._id));
      },
      [dispatch]
    );
    const handleAddQuickResponse = React.useCallback((message: Message) => {
      quickresponseCreate({ message });
    }, []);
    const handleDownloadImage = React.useCallback((message: Message) => {
      dispatch(downloadFileBlob(message.fileID, message.filename))
    }, [dispatch]);

    const actions = React.useMemo(
      () =>
        [
          {
            label: "Apagar mensagem",
            onClick: handleDeleteMessage,
          },
          {
            label: "Salvar como resposta rápida",
            onlyInTypes: ["text"],
            onClick: handleAddQuickResponse,
          },
          {
            label: "Baixar imagem",
            onlyInTypes: ["image"],
            onClick: handleDownloadImage,
          },
        ] as Actions[],
      [handleDeleteMessage, handleAddQuickResponse, handleDownloadImage]
    );

    const unreadCount = React.useMemo(() => {
      return unreadMessages?.unreadCount;
    }, [unreadMessages]);

    return (
      <>
        <MessageList
          ref={ref}
          unreadCount={unreadCount}
          listMessages={listMessages}
          rangeChanged={handleRangeChanged}
          itemContent={(index: number) => {
            return (
              <ChatMessage
                index={index}
                moreActions={actions}
                message={listMessages[index]}
                previousMessage={listMessages[index - 1]}
                last={index === listMessages.length - 1}
                size={size}
                handleDownloadImage={handleDownloadImage}
              />
            );
          }}
        />
        {rendererQuickresponse}
      </>
    );
  }
);
