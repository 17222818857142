import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { loadClinicProfile } from "ducks/clinic";
import { AppDispatch } from "ducks/state";
import { unauthorized, getToken } from "ducks/auth";

type ProfileLoaderProps = {
  children: JSX.Element | null;
};

const ProfileLoader = React.memo((p: ProfileLoaderProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [loading, setL] = React.useState(false);
  const { slug } = useParams<{ slug: string }>();
  const { token } = useSelector(getToken);

  React.useEffect(() => {
    setL(true);
    dispatch(loadClinicProfile(slug))
      .then((r) => {
        if (
          (r?.clinID?.length ?? 0) + (token.payload?.clinID?.length ?? 0) <
          36 * 2
        ) {
          return;
        }
        if (r?.clinID !== token.payload?.clinID) {
          dispatch(unauthorized());
        }
      })
      .finally(() => {
        setL(false);
      });
  }, [slug, token, dispatch]);

  return loading ? <LinearProgress color="primary" /> : p.children;
});

export default ProfileLoader;
