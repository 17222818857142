import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ isolation: "isolate" }}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="M14.08 21H2a2.074 2.074 0 0 1-2-2V5c.04-1.09.91-1.96 2-2h20c1.09.04 1.96.91 2 2v10.53c-.58-.53-1.25-.92-2-1.19V5H2v14h12.08c-.05.33-.08.66-.08 1 0 .34.03.68.08 1ZM14 17H4v-1.25c0-1.66 3.34-2.5 5-2.5 1.66 0 5 .84 5 2.5V17Zm0-6h4v1h-4v-1ZM9 7C7.63 7 6.5 8.13 6.5 9.5S7.63 12 9 12s2.5-1.13 2.5-2.5S10.37 7 9 7Zm5 2h6v1h-6V9Zm0-2h6v1h-6V7Z" />
      <path d="M20.5 14.5v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2ZM20 19" />
    </g>
  </svg>
);

export default SvgComponent;
