import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  ImmediateCareForm,
  ImmediateCareView,
  FilterImmediateCare,
  ImmediateCareSession,
  ImmediateCareSessionForm,
} from "@udok/lib/api/models";
import {
  createImmediateCare,
  listImmediateCare,
  getImmediateCare,
  deleteImmediateCare,
  updateImmediateCare,
} from "@udok/lib/api/immediateCare";
import {
  createImmediateCareSession,
  finishImmediateCareSession,
  createImmediateCareSessionView,
  completeCareToImmediateCareSession,
} from "@udok/lib/api/immediateCareSession";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";

export type InitialState = {
  immediateCareByID: { [imcaID: string]: ImmediateCareView };
  immediateCareByImcsID: { [imcsID: string]: string | undefined };
};

// Reducers
const initialState: InitialState = {
  immediateCareByID: {},
  immediateCareByImcsID: {},
};

class ImmediateCares extends Hen<InitialState> {
  immediateCareLoaded(ic: ImmediateCareView) {
    this.state.immediateCareByID[ic.imcaID] = ic;
    const imcsID = ic.currentSession?.imcsID;
    if (imcsID) {
      this.state.immediateCareByImcsID[imcsID] = ic.imcaID;
    }
  }
  immediateCaresLoaded(ics: ImmediateCareView[]) {
    ics.forEach((ic) => {
      this.state.immediateCareByID[ic.imcaID] = ic;
      const imcsID = ic.currentSession?.imcsID;
      if (imcsID) {
        this.state.immediateCareByImcsID[imcsID] = ic.imcaID;
      }
    });
  }
  immediateCaresRemoved(imcaID: string) {
    delete this.state.immediateCareByID[imcaID];
  }
  immediateCareSessionLoaded(ics: ImmediateCareSession) {
    const current = this.state.immediateCareByID?.[ics.imcaID];
    if (current) {
      this.state.immediateCareByID[ics.imcaID] = {
        ...current,
        currentSession: ics,
      };
      this.state.immediateCareByImcsID[ics.imcsID] = ics.imcaID;
    }
  }
}

export const [Reducer, actions] = hen(new ImmediateCares(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.immediateCare;

export const getListImmediateCare = createSelector([mainSelector], (state) => {
  return {
    list: Object.keys(state.immediateCareByID)
      .map((imcaID) => state.immediateCareByID[imcaID])
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
  };
});

export const getOneImmediateCare = (props: { imcaID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      immediateCare: state.immediateCareByID[props.imcaID],
    };
  });

export const getOneImmediateCareSession = (props: { imcsID: string }) =>
  createSelector(mainSelector, (state) => {
    const imcaID = state.immediateCareByImcsID?.[props.imcsID] ?? "";
    return {
      immediateCare: state.immediateCareByID?.[imcaID],
    };
  });

// Actions
export function createNewImmediateCare(
  form: ImmediateCareForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createImmediateCare(apiToken, form)
      .then((r) => {
        dispatch(actions.immediateCareLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadImmediateCare(
  filter?: FilterImmediateCare
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return listImmediateCare(apiToken, filter)
      .then((r) => {
        dispatch(actions.immediateCaresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedImmediateCareByImcsID(
  imcsID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const icExist = Boolean(state.immediateCare.immediateCareByImcsID[imcsID]);
    if (icExist) {
      return Promise.resolve();
    }
    return dispatch(loadImmediateCare({ imcsID }));
  };
}

export function loadOneImmediateCare(imcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getImmediateCare(apiToken, imcaID)
      .then((r) => {
        dispatch(actions.immediateCareLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedImmediateCare(
  imcaID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const icExist = Boolean(state.immediateCare.immediateCareByID[imcaID]);
    if (icExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneImmediateCare(imcaID));
  };
}

export function removeImmediateCare(imcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteImmediateCare(apiToken, imcaID)
      .then((r) => {
        dispatch(actions.immediateCaresRemoved(r.imcaID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeImmediateCare(
  imcaID: string,
  form: ImmediateCareForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateImmediateCare(apiToken, imcaID, form)
      .then((r) => {
        dispatch(actions.immediateCareLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function startImmediateCareSession(
  form: ImmediateCareSessionForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createImmediateCareSession(apiToken, form)
      .then((r) => {
        dispatch(actions.immediateCareSessionLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Sessão iniciada",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function finishOneImmediateCareSession(
  imcsID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return finishImmediateCareSession(apiToken, imcsID)
      .then((r) => {
        dispatch(actions.immediateCareSessionLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Sessão finalizada",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function viewImmediateCareSession(
  imcsID: string
): AppThunk<Promise<void>> {
  return async (_dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createImmediateCareSessionView(apiToken, imcsID)
      .then(() => {})
      .catch((e) => {
        console.warn(e);
        throw e;
      });
  };
}

export function completeCare(
  imcsID: string,
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return completeCareToImmediateCareSession(apiToken, imcsID, appoID)
      .then(() => {})
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
