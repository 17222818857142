import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Icons from "../Icon";
import { MaxFileSize } from "@udok/lib/internal/constants";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: "none",
    },
  })
);

function ContainedIconButton(props: {
  icon?: any;
  disabled?: boolean;
  inputProps?: React.InputHTMLAttributes<any>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classes?: {
    root?: string;
    iconButton?: string;
  };
}) {
  const { icon, inputProps, disabled, classes: classesProps, onChange } = props;
  const classes = useStyles();

  const id = React.useMemo(() => {
    return new Date().getMilliseconds() + "-" + Math.random() * 4200;
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.[0];
    if (f && f.size >= MaxFileSize) {
      alert("O Arquivo ultrapassa o tamanho máximo de 50MB");
      return;
    }
    onChange?.(e);
  };

  return (
    <div className={classesProps?.root}>
      <input
        className={classes.input}
        type="file"
        {...inputProps}
        id={id}
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={id}>
        <IconButton
          component="span"
          className={clsx(classes.button, classesProps?.iconButton)}
        >
          {icon ?? <Icons.AttachFile />}
        </IconButton>
      </label>
    </div>
  );
}

export default ContainedIconButton;
