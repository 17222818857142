import { toQueryString } from "@udok/lib/internal/util";
import {
  DoctorPreference,
  HealthcareLicense,
  Doctor,
  DoctorPublicProfile,
  DoctorPreferenceForm,
  ClinicDoctor,
  ClinicDoctorListFilter,
  DoctorClinicPatient,
  DoctorLicense,
  License,
  FilterPublicDoctorProfiles,
} from "@udok/lib/api/models";
import {
  makeItemFetch,
  makeItemsFetch,
  makeItemPost,
  makeItemPut,
  makeItemDelete,
} from "@udok/lib/api/axios";

export const createDoctorPreference = (
  token: string,
  data: DoctorPreferenceForm
) =>
  makeItemPost<DoctorPreference, DoctorPreferenceForm>({
    url: `/api/doctor-preferences`,
    errLabel: "Dp1",
    token,
    data,
  });

export const fetchDoctorPreference = (token: string, prefID: string) =>
  makeItemFetch<DoctorPreference>({
    url: `/api/doctor-preferences/${prefID}`,
    errLabel: "Dp2",
    token,
  });

export const fetchListDoctorPreference = (token: string) =>
  makeItemsFetch<DoctorPreference[]>({
    url: `/api/doctor-preferences`,
    errLabel: "Dp3",
    token,
  });

export const fetchLicenseTypes = async () =>
  makeItemsFetch<HealthcareLicense[]>({
    url: `/healthcare-licenses`,
    errLabel: "lct1",
  });

export const fetchDoctor = (token: string, doctID: string) =>
  makeItemFetch<Doctor>({
    url: `/api/doctors/${doctID}`,
    errLabel: "doc1",
    token,
  });

export type FilterDoctorList = {
  listAll?: boolean;
  limit?: number;
  name?: string;
  region?: string;
  documentType?: string;
  documentNumber?: string;
};
export const fetchDoctors = (token: string, f?: FilterDoctorList) => {
  const filter = f ? toQueryString(f ?? { limit: 1000 }) : "";
  return makeItemsFetch<Doctor[]>({
    url: `/api/doctors${filter}`,
    errLabel: "doc2",
    token,
  });
};

export const deleteDoctorFromClinic = (token: string, doctID: string) =>
  makeItemDelete<ClinicDoctor>({
    url: `/api/clinic-doctors/${doctID}`,
    errLabel: "doc4",
    token,
  });

export type AddDoctorToClinicRequest = {
  doctID: string;
  doctorPhone: string;
  specialties?: number[];
  displayOrder: number;
  preferences?: {
    hideProfileInProcedure?: boolean;
  };
};
export const addDoctorToClinic = async (
  token: string,
  data: AddDoctorToClinicRequest
) =>
  makeItemPost<ClinicDoctor, AddDoctorToClinicRequest>({
    url: `/api/clinic-doctors`,
    errLabel: "doc5",
    token,
    data,
  });

export const fetchDoctorPublicProfile = (username: string) =>
  makeItemFetch<DoctorPublicProfile>({
    url: `/api/doctor-profiles/${username}`,
    errLabel: "Dpub1",
  });

export const searchDoctorProfiles = (filter?: FilterPublicDoctorProfiles) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<DoctorPublicProfile[]>({
    url: `/api/search/doctor-profiles${f}`,
    errLabel: "doc16",
  });
};

export const fetcClinicDoctors = (
  token: string,
  filter?: ClinicDoctorListFilter
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<ClinicDoctor[]>({
    url: `/api/clinic-doctors${f}`,
    errLabel: "doc6",
    token,
  });
};

export const fetchClinicDoctor = (token: string, doctID: string) =>
  makeItemFetch<ClinicDoctor>({
    url: `/api/clinic-doctors/${doctID}`,
    errLabel: "doc7",
    token,
  });

export const addClinicDoctorPatient = async (
  token: string,
  data: { doctID: string; patiID: string }
) =>
  makeItemPost<DoctorClinicPatient, typeof data>({
    url: `/api/clinic-doctors-patient`,
    errLabel: "doc8",
    token,
    data,
  });

export const fetchClinicDoctorPatients = async (
  token: string,
  filter?: { doctID?: string; patiID?: string }
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<DoctorClinicPatient[]>({
    url: `/api/clinic-doctors-patient${f}`,
    errLabel: "doc10",
    token,
  });
};

export const fetchDoctorLicenses = async (
  token: string,
  filter?: { prescriptionAllowedLicenses?: boolean }
) => {
  const f = filter ? toQueryString(filter) : "";
  return makeItemsFetch<DoctorLicense[]>({
    url: `/api/doctor-license${f}`,
    errLabel: "doc11",
    token,
  });
};

export const createDoctorLicense = async (token: string, data: License) =>
  makeItemPost<DoctorLicense, License>({
    url: `/api/doctor-license`,
    errLabel: "doc12",
    token,
    data,
  });

export const updateDoctorLicense = async (
  token: string,
  doliID: number,
  data: License
) =>
  makeItemPut<DoctorLicense, License>({
    url: `/api/doctor-license/${doliID}`,
    errLabel: "doc13",
    token,
    data,
  });

export const fetchDoctorLicense = (token: string, doliID: number) =>
  makeItemFetch<DoctorLicense>({
    url: `/api/doctor-license/${doliID}`,
    errLabel: "doc14",
    token,
  });

export const deleteDoctorLicense = (token: string, doliID: number) =>
  makeItemDelete<DoctorLicense>({
    url: `/api/doctor-license/${doliID}`,
    errLabel: "doc15",
    token,
  });
